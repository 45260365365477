import { useQuery } from "react-query";
import ReactQueryKey from "../Constant/ReactQuery";
import { ApiCall } from "../Api/ApiCall";
import { ApiUrl } from "../Api/ApiUrl";
import { useWallet } from "@solana/wallet-adapter-react";
import { useState } from "react";
import { useEffect } from "react";

const UseVestingSchuedule = (wallet_address = "") => {
  const { publicKey, connected } = useWallet();
  let prm = wallet_address === "" ? publicKey?.toString() : wallet_address;
  //@ts-ignore
  const { isIdle, isLoading, isError, data, error, refetch, isFetching } =
    useQuery(
      ReactQueryKey.VESTING_SCHUDLE,
      () => ApiCall("GET", `${ApiUrl.vestingSchuedule}${prm}`, {}),
      {
        enabled: false,
      }
    );

  useEffect(() => {
    if (data === undefined) {
      connected && refetch();
    }
  }, [publicKey, wallet_address]);

  return { isIdle, isLoading, isError, data, error };
};

export default UseVestingSchuedule;