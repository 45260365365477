import React, { useState } from "react";
import InventoryFurniture from "./InventoryFurniture";
import InventoryItems from "./InventoryItems";
import InventoryTemplates from "./InventoryTemplates";

const Inventory = () => {
  const Categories = {
    FURNITURES: "Furnitures",
    ITEMS: "Items",
    TEMPLATES: "Templates",
  };
  const [currentPage, setCurrentPage] = useState(Categories.FURNITURES);
  const cat = [
    {
      catName: Categories.FURNITURES,
    },
    {
      catName: Categories.ITEMS,
    },
    {
      catName: Categories.TEMPLATES,
    },
  ];

  const openTab = (tabName) => {
    setCurrentPage(tabName);
  };

  return (
    <div className="inventoryPage">
      <div className="tab ">
        {cat.map((c, i) => {
          return (
            <button
              key={i}
              className={`tablinks ${currentPage === c.catName && "active"}`}
              onClick={() => openTab(c.catName)}
            >
              {c.catName}
            </button>
          );
        })}
      </div>

      {/* Land */}
      {currentPage === Categories.FURNITURES && <InventoryFurniture />}

      {/* Furniture */}
      {currentPage === Categories.ITEMS && <InventoryItems />}

      {/* Template */}
      {currentPage === Categories.TEMPLATES && <InventoryTemplates />}
    </div>
  );
};

export default Inventory;
