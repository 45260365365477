import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactThreeFbxViewer from "react-three-fbx-for-pyt";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";

const FbxViewer = ({ isShow, fbxurl, handleOnClose }) => {
  //   const [isShowFbxImageViewerModel, setIsShowFbxImageViewerModel] = useState({
  //     isShow: false,
  //     fbxURL: "",
  //   });

  let cameraPosition = {
    x: 150,
    y: 300,
    z: 350,
  };

  function onLoad(e) {
    console.log(e);
  }

  function onError(e) {
    console.log(e);
  }

  return (
    <div>
      {/* -----  MODAL TO SELECT LAND ----- */}
      <Modal
        show={isShow}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge model-landSelect modelCreateInvFurItems alert-model-showFbxViewer"
      >
        <div className="header-wrapper ">
          <div className="header-text-wrapper" style={{ width: "100%" }}>
            <div className="row blkLandSelectionArr">
              <div>
                <ReactThreeFbxViewer
                  cameraPosition={cameraPosition}
                  url={fbxurl}
                  onLoading={onLoad}
                  onError={onError}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="alert-message-wrapper" style={{ display: "flex", flexDirection: "row" }}>
          <PrimaryButton name={"Cancel"} size="md" onClick={() => handleOnClose()} className="proceed-button" />
        </div>
      </Modal>
    </div>
  );
};

export default FbxViewer;
