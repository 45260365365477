import "./CockpitDashboard.scss";
import TableComponent from "../../components/Table/Table";
import { Accordion } from "react-bootstrap";
import Title from "../../components/Title";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import DropDownComponent from "../../components/DropDown/DropDown";
import React, { Suspense, useEffect, useState } from "react";
import Productcard from "../../components/ProductCard/ProductCard";
import useGetStakingClaims from "../../Hooks/useGetStakingClaims";
import TokenTransfer from "../TokenTransfer";
import VestingSchuedule from "../VestingSchedule";
import Staking from "../Staking";
import useSocialLogin from "../../Hooks/useSocialLogin";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchBalance } from "../../Solana/GetBalance";
import { useWallet } from "@solana/wallet-adapter-react";
import LandingPage from "../LandingPage";
import "../../style/CockpitStyle.scss";
import { useQuery } from "react-query";
import ReactQueryKey from "../../Constant/ReactQuery";

const CockpitDashboard = () => {
  const history = useNavigate();
  const { publicKey, connected } = useWallet();
  const [searchParams, setSearchParams] = useSearchParams();
  const isAutoClick = searchParams.get("autoClick");
  //@ts-ignore
  const [balance, setBalance] = useState<string | number>("");
  useEffect(() => {
    getBalance();
  }, [publicKey]);

  const AccessToken = useQuery(ReactQueryKey.SOCIAL_MEDIA_LOGIN, () => {}, {
    enabled: false,
  });

  useEffect(() => {
    document.documentElement.style.setProperty("--base", "hidden");
  }, []);

  const getBalance = async () => {
    const res = await fetchBalance(publicKey?.toString());
    setBalance(res);
  };

  const uniSwap = () => {
    window.open("https://www.mexc.com/exchange/MALL_USDT", "");
  };

  const coinBase = () => {
    window.open(
      "https://raydium.io/swap/?inputCurrency=5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32[…]FrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB&outputAmount=0&fixed=in",
      ""
    );
  };

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <div className={"mall-container"}>
          <div className={"mall-balance"}>
            <span className={"text"}>YOUR BALANCE: </span>
            <span>
              <img
                className="mal-icon"
                src={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
                alt=""
              />
              <span className={"text token"}> {balance}</span>
            </span>
          </div>
          <div className="button-wrapper">
            {/* <PrimaryButton
              name="BUY ON MEXC"
              onClick={uniSwap}
              className="primary-btn"
            /> */}
            <div className={"coinbase-button"}>
              <PrimaryButton
                name="BUY ON RAYDIUM"
                onClick={coinBase}
                className="primary-btn"
              />
            </div>
          </div>
        </div>
        <div className={"dashboard-container"}>
          <div className={"dashboard-content-wrapper"}>
            <VestingSchuedule />
            <TokenTransfer />
            <Staking />
          </div>
        </div>
        {/* @ts-ignore */}
        {/* {(AccessToken.data === undefined || !connected ? true : false) && (
          <LandingPage autoClick={isAutoClick} />
        )} */}
      </Suspense>
      {!connected && <LandingPage autoClick={isAutoClick} />}
    </div>
  );
};

export default CockpitDashboard;

interface AccComponentProps {
  showAmount: boolean;
  amount: string;
}

const AccComponent = ({ showAmount, amount }: AccComponentProps) => {
  const { Item, Header, Body } = Accordion;
  return (
    <div>
      <Accordion defaultActiveKey="0">
        <div className={"border-style"}>
          <Item eventKey="0">
            <Header>
              <div className={"acc-header"}>
                <div className={"acc-title"}>March 25</div>
                <div className={"amount"}>
                  <img src="images/logoSmall.png" alt="" />
                  <span>{amount}</span>
                </div>
              </div>
            </Header>
            <Body>{/* <TableComponent /> */}</Body>
          </Item>
        </div>
        <Item eventKey="1">
          <Header>Accordion Item #2</Header>
          <Body>{/* <TableComponent /> */}</Body>
        </Item>
      </Accordion>
    </div>
  );
};
