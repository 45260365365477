import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import Title from "../../components/Title";
import "./ExperienceWithoutWallet.scss";
import Line from "../../components/Line";
import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";

import { SocialMediaAppId } from "../../Constant/Constant";
import { ApiCall } from "../../Api/ApiCall";
import InputBox from "../../components/InputBox";
import { useQuery, useQueryClient } from "react-query";
import ReactQueryKey from "../../Constant/ReactQuery";
import { BASE_URL, ApiUrl } from "../../Api/ApiUrl";
import axios from "axios";
import { gapi } from "gapi-script";
import SetPassword from "./SetPassword";
import Tag from "../../components/Tag";
import AlertModel from "../../components/AlertModel";
import React from "react";

//import {gapi} from 'gapi-script';

const ExperienceWithoutWallet = () => {
  const [isEmailModalOpened, setIsEmailModalOpened] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState<string>("");
  const [showComingSoonError, setShowComingSoonError] = useState(false);
  const [tokenId, setTokenId] = useState<string | null>("");
  const queryClient = useQueryClient();
  const access_token = localStorage.getItem("access_token");

  // const REDIRECT_URI = "http://localhost:3000";
  // const { isLoading, isError, data, error } = useQuery(
  //   ReactQueryKey.GOOGLE_LOGIN,
  //   () => {
  //     try {
  //       return ApiCall("POST", ApiUrl.googlelogin, {
  //         tokenId: tokenId,
  //       });
  //     } catch (error) {
  //       return "dummy";
  //     }
  //   }
  // );

  async function googleLogin(tokenId) {
    setTokenId(tokenId);
    queryClient.invalidateQueries(ReactQueryKey.GOOGLE_LOGIN);
    // const AccessToken = await ApiCall("POST", ApiUrl.googlelogin, {
    //   tokenId: tokenId,
    // });
    // console.log({ AccessToken });
  }

  const Axios = axios.create({
    baseURL: BASE_URL,
    //@ts-ignore
    headers: { Authorization: `Bearer ${access_token}` },
  });

  const [isEmailConnected, setIsEmailConnected] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const x = 1;

  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState<any>();
  const [cancele, setCancel] = useState(false);
  const [validationClicked, setValidationClicked] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [addPassword, setAddPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [loginClicked, setLoginClicked] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [showDetailsPage, setShowDetailsPage] = useState(false);
  const [showChangePassword, setshowChangePassword] = useState(false);
  const [changeValues, setChangeValues] = useState(false);
  const [isFetchedUserDetails, setIsFetchedUserDetails] = useState(false);

  const [subText, setSubText] = useState("Profile successfully Created!");
  const [showAlert, setshowAlert] = useState(false);
  const [alertHeaderText, setAlertHeaderText] = useState("You are all set!");
  const [stateUsedDetails, setUsedDetails] = useState({
    name: isEmailConnected ? name : "",
    email: isEmailConnected ? email : "",
    password: "",
    confirmPassword: "",
  });
  const [forgotPassword, setForgotPassword] = useState(false);
  let usedDetails = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  function onOKClick() {
    setAlertHeaderText("");
    setSubText("");
    setshowAlert(false);
  }

  const optSubmit = async (otp) => {
    const res = await ApiCall("POST", ApiUrl.verifyOTP, {
      email: stateUsedDetails.email,
      otp: otp,
    });
    console.log({ res });
    if (res.success) {
      setEmailVerified(true);
      setAddPassword(true);
    }
    setForgotPassword(false);
  };

  const validateEmail = (email) => {
    console.log("email", email);
    if (email === "") {
      setInvalidEmail(true);
      return true;
    }
    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setInvalidEmail(false);
      return false;
    } else {
      setInvalidEmail(true);
      return true;
    }
  };

  useEffect(() => {
    console.log("USE EFFECTTttTTTTTTTt! ");
    setTimeout(() => {
      console.log("World!");
    }, 2000);
    const googletoken = localStorage.getItem("googletoken");
    console.log("abc " + googletoken);
    googleLogin(googletoken);
    document.documentElement.style.setProperty("--base", "hidden");
  }, []);

  const saveChanges = async () => {
    let isAnythingUpdated = false;
    setSubText("You have successfully changed your details!");
    setAlertHeaderText("You are all set!");
    if (stateUsedDetails.name !== name) {
      isAnythingUpdated = true;
      const res = await Axios.post(ApiUrl.updateUserName, {
        name: stateUsedDetails.name,
      });
      console.log("res", res);
      usedDetails = { ...stateUsedDetails };
      usedDetails.name = res.data.name;
      setName(res.data.name);
      setUsedDetails(usedDetails);
    }
    if (stateUsedDetails.email !== email) {
      isAnythingUpdated = true;
      try {
        const res = await Axios.post(ApiUrl.updateEmail, {
          email: stateUsedDetails.email,
        });
        console.log("res", res);
        usedDetails = { ...stateUsedDetails };
        usedDetails.email = res.data.email;
        setEmail(res.data.email);
        setUsedDetails(usedDetails);
      } catch (e: any) {
        console.log(e.response.data.message);
        setSubText(e.response.data.message);
        setAlertHeaderText("Error!");
      }
    }
    if (isAnythingUpdated) {
      setshowAlert(true);
      setChangeValues(!changeValues);
    }
  };

  console.log("invalidEmail", showDetailsPage);

  useEffect(() => {
    console.log("useEffect", { loginClicked });
    Axios.get(ApiUrl.user).then((res) => {
      console.log(res.data);
      setName(res.data.name);
      setEmail(res.data.email);
      usedDetails = { ...stateUsedDetails };
      usedDetails.name = res.data.name;
      usedDetails.email = res.data.email;
      setName(res.data.name);
      setEmail(res.data.email);
      setUsedDetails(usedDetails);
      setEmailVerified(!!res.data.emailVerifiedAt);
      setIsFetchedUserDetails(true);
      if (res.data.emailVerifiedAt) {
        setIsEmailConnected(true);
      }
    });
  }, []);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: SocialMediaAppId.GOOGLE,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });

  return (
    <div className="experience-wrapper">
      <div className={"mall-container download-exe"}>
        <div className={"mall-balance"}>
          <img
            src={`${process.env.PUBLIC_URL}/images/DownloadLauncher.png`}
            alt=""
          />
          <div className="launcher-wrapper">
            <div className="text">Download Launcher</div>
            <div className="token">
              You need to download the MetaMall Launcher to experience the
              metaverse
            </div>
          </div>
        </div>
        <div className="button-wrapper">
          {/* <PrimaryButton name="BUY ON MEXC" onClick={uniSwap} className="primary-btn" /> */}
          {process.env.REACT_APP_HIDE_LAUNCHER &&
          process.env.REACT_APP_HIDE_LAUNCHER === "true" ? (
            <div className={"coinbase-button"}>
              <PrimaryButton
                name="COMING SOON"
                className="primary-btn download"
                onClick={() => {
                  setIsEmailModalOpened(true);
                }}
              />
            </div>
          ) : (


/* 
            <div className={"coinbase-button"}>
              <a href="https://installer.launcher.xsolla.com/xlauncher-builds/launcher_builds/16218/10035/prod/web_installer.exe">
                <PrimaryButton
                  name="DOWNLOAD LAUNCHER"
                  className="primary-btn download"
                />
              </a>
            </div>
*/

<div className="button-wrapper">
<React.Fragment>
  <div className={"coinbase-button"}>
    <a href="https://installer.launcher.xsolla.com/xlauncher-builds/xsolla-launcher-update/3108/bin/web_installer.exe">
      <PrimaryButton
        name="DOWNLOAD LAUNCHER WINDOWS "
        className="primary-btn download"
      />
    </a>
  </div>
  <div className={"coinbase-button"}>
    <a href="https://installer.launcher.xsolla.com/xlauncher-builds/xsolla-launcher-update/3107/bin/launcher.dmg">
      <PrimaryButton
        name="DOWNLOAD LAUNCHER MAC"
        className="primary-btn download"
      />
    </a>
  </div>
</React.Fragment>
</div>




          )}
        </div>
      </div>
      <div>
        <div className="mall-container create-accout-screen">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              verticalAlign: "center",
            }}
          >
            <Title
              firstName="PROFILE"
              remainName="DETAILS"
              showBuyBtn={false}
              imageUrl={""}
            />
            <PrimaryButton
              name={changeValues ? "cancel" : "edit"}
              style={{ height: "2rem", marginTop: "1.8rem" }}
              onClick={() => {
                setChangeValues(!changeValues);
              }}
            />
          </div>

          <Line className="" />
          <div className="input-wrapper">
            <div className="verify-email-tag">
              <InputBox
                label={"Avatar Name"}
                placholder={"Name"}
                onChange={(e) => {
                  usedDetails = { ...stateUsedDetails };
                  usedDetails.name = e.target.value;
                  setUsedDetails(usedDetails);
                }}
                message="Enter name"
                isValid={validationClicked && stateUsedDetails.name === ""}
                type="Default"
                value={stateUsedDetails.name}
                disabled={!changeValues}
                focus={changeValues}
              />
            </div>
            <div className="verify-email-tag">
              <InputBox
                label={"Email id"}
                placholder={"Email id"}
                onChange={(e) => {
                  usedDetails = { ...stateUsedDetails };
                  validateEmail(e.target.value);
                  usedDetails.email = e.target.value;
                  setUsedDetails(usedDetails);
                }}
                isValid={invalidEmail}
                message="Invalid email"
                type="Default"
                value={stateUsedDetails.email}
                disabled={
                  !isEmailVerified && !emailVerified ? !changeValues : true
                }
              />
              {!isEmailVerified && !emailVerified ? <></> : <></>}
              <Tag
                tag={isEmailVerified || emailVerified ? "VERIFIED" : "PENDING"}
                className="email-tag"
              />
            </div>
            <div className="verify-email-tag">
              <InputBox
                label={"Password"}
                placholder={"*********"}
                onChange={(e) => {
                  usedDetails = { ...stateUsedDetails };
                  usedDetails.password = e.target.value;
                  setUsedDetails(usedDetails);
                }}
                isValid={invalidEmail}
                message="Invalid email"
                type="password"
                disabled={!showChangePassword}
              />
              <PrimaryButton
                name={"change"}
                className={"create-account-button change-password"}
                onClick={() => {
                  setChangePassword(true);
                }}
              />
            </div>

            <div className="profile-details-button-wrapper">
              {changeValues && (
                <PrimaryButton
                  name={"SAVE CHANGES"}
                  className={"create-account-button save-btn"}
                  onClick={saveChanges}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {changePassword ? <SetPassword AccessToken={access_token} /> : <></>}
      {(showDetailsPage || showAlert) && (
        <AlertModel
          image={`${process.env.PUBLIC_URL}/images/SuccessImage.png`}
          headerText={alertHeaderText}
          subText={subText}
          handleClick={onOKClick}
        />
      )}

      <Modal
        show={isEmailModalOpened}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge"
      >
        <div className="header-wrapper">
          <div className="header-text-wrapper">
            <div
              className="header-text"
              style={{
                textAlign: "center",
                fontSize: "2rem",
                marginTop: "0.5rem",
              }}
            >
              Coming Soon
            </div>
            <div
              className="header-sub-text"
              style={{ marginTop: "1rem", textAlign: "center" }}
            >
              Enter your email id and we will get back to you
            </div>
            <div className="password-box">
              <InputBox
                label={"Email"}
                placholder={"Email"}
                message=""
                type="text"
                value={enteredEmail.toString()}
                onChange={(e) => {
                  setEnteredEmail(e.target.value);
                }}
              />
            </div>
            {showComingSoonError ? (
              <div>Please provide a valid email address</div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name={"Submit"}
            onClick={async () => {
              setShowComingSoonError(false);
              if (
                enteredEmail
                  .toLowerCase()
                  .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  )
              ) {
                const res = await Axios.post(ApiUrl.notifyMe, {
                  email: enteredEmail,
                });
                console.log(res);
                setIsEmailModalOpened(false);
              } else {
                setShowComingSoonError(true);
              }
            }}
            className="proceed-button"
            style={{ marginRight: "2rem" }}
          />
          <PrimaryButton
            name={"Cancel"}
            onClick={() => {
              setShowComingSoonError(false);
              setIsEmailModalOpened(false);
            }}
            className="proceed-button"
          />
        </div>
      </Modal>
    </div>
  );
};

export default ExperienceWithoutWallet;
