import { useState, useRef } from "react";
import { Modal, Form } from "react-bootstrap";
import Line from "../../components/Line";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { ApiCall } from "../../Api/ApiCall";
import { BASE_URL, ApiUrl } from "../../Api/ApiUrl";

const ForgotPassword = ({optSubmit}) => {
  const REDIRECT_URI = "http://localhost:3000";
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState<any>();
  const [cancele, setCancel] = useState(true);
  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();
  const input5 = useRef();
  const input6 = useRef();

  const [inputValue1, setInputValue1] = useState('');
  const [inputValue2, setInputValue2] = useState('');  
  const [inputValue3, setInputValue3] = useState('');  
  const [inputValue4, setInputValue4] = useState('');  
  const [inputValue5, setInputValue5] = useState('');  
  const [inputValue6, setInputValue6] = useState('');  
  
  const submit = async () => {
    console.log(inputValue1, inputValue2, inputValue3, inputValue4, inputValue5, inputValue6); 

  }
  

  return (
    <Modal
      show={cancele}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="forgot-password-wrapper"
    >
      <div className="header-wrapper">
        <div>
          <img
            className={"landing-logo"}
            src={`${process.env.PUBLIC_URL}/images/lock.png`}
            alt="dashboard logo"
          />
        </div>
        <div className="header-text-wrapper">
          <div className="header-text">Complete verification</div>
          <div className="header-sub-text">
            Kindly enter the OTP sent to your registered Email ID
          </div>
        </div>
      </div>
      <Line className="" />
      <div className="opt-input-wrapper">
        {/* @ts-ignore*/}
        <Form.Control
          onChange={(e) => {
            setInputValue1(e.target.value)
            //@ts-ignore
            input1 && input2.current.focus();
          }}
          size="lg"
          type="text"
          //@ts-ignore
          ref={input1}
        />
        {/* @ts-ignore*/}
        <Form.Control
           onChange={(e) => {
            setInputValue2(e.target.value)
            //@ts-ignore
            input3.current.focus();
          }}
          size="lg"
          type="text"
          //@ts-ignore
          ref={input2}
        />
        {/* @ts-ignore*/}
        <Form.Control
          size="lg"
          onChange={(e) => {
            setInputValue3(e.target.value)
            //@ts-ignore
            input4.current.focus();
          }}
          type="text"
          //@ts-ignore
          ref={input3}
        />
        {/* @ts-ignore*/}
        <Form.Control
          onChange={(e) => {
            setInputValue4(e.target.value)
            //@ts-ignore
            input5.current.focus();
          }}
          size="lg"
          type="text"
          //@ts-ignore
          ref={input4}
        />
        {/* @ts-ignore*/}
        <Form.Control
          onChange={(e) => {
            setInputValue5(e.target.value)
            //@ts-ignore
            input6.current.focus();
          }}
          size="lg"
          type="text"
          //@ts-ignore
          ref={input5}
        />
        {/* @ts-ignore*/}
        <Form.Control
           onChange={(e) => {
            setInputValue6(e.target.value)
          }}
          size="lg"
          type="text"
          //@ts-ignore
          ref={input6}
        />
      </div>
      <PrimaryButton
          name={"submit"}
          className={"submit"}
          onClick={()=>optSubmit(`${inputValue1}${inputValue2}${inputValue3}${inputValue4}${inputValue5}${inputValue6}`)}
        />
    </Modal>
  );
};

export default ForgotPassword;
