import React, {
  Children,
  FC,
  useMemo,
  useContext,
  createContext,
  useCallback,
  useState
} from "react";
import {
  ConnectionProvider,
  WalletProvider,
  useWallet
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import NFTDetails, { NFTDetailData, StakingPoolData } from "./NFTDetails";
import BuyNFT, { fetchBalance } from "./BuyNFT";
import { NFTData } from "./Home";
import { ENVIRONMENT } from "../Constants";
import { Button, Modal } from 'react-bootstrap'

export interface WalletModalContextState {
  visible: boolean;
  setVisible: (open: boolean) => void;
}

export const WalletModalContext = createContext<WalletModalContextState>(
  {} as WalletModalContextState
);

export function useWalletModal(): WalletModalContextState {
  return useContext(WalletModalContext);
}

require("@solana/wallet-adapter-react-ui/styles.css");

// Default styles that can be overridden by your app
//require("@solana/wallet-adapter-react-ui/yeah.css");

export function ConnectWallet(props: { children: any }) {
    // const [visible, setVisible] = useState(false);

    // const close = useCallback(() => {
    //     setVisible(false);
    //   }, [setVisible]);
 
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Mainnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets}>
        {/* <WalletModalProvider> */}
          {/* <WalletMultiButton /> */}
          {/* <WalletDisconnectButton /> */}
          {/* <WalletModalContext.Provider
            value={{
              visible,
              setVisible,
            }}
          > */}
            {props.children}
            {/* <ConnectWalletComponent close={close} /> */}
          {/* </WalletModalContext.Provider> */}
        {/* </WalletModalProvider> */}
      </WalletProvider>
    </ConnectionProvider>
  );
}

const ConnectWalletComponent = (props) => {
    const { visible, setVisible } = useWalletModal();
    const { wallets, select } = useWallet();
    
    
    return(
        <Modal show={visible}
        onHide={props.close} >
        <Button
        className="phantom-button metaplex-button"
        onClick={() => {
          //console.log(phatomWallet.name);
          //@ts-ignore
          select('Phantom');
          props.close();
        }}
      >
        {/* <img src={phatomWallet?.icon} style={{ width: '1.2rem' }} /> */}
        &nbsp;Connect to Phantom
      </Button>
      </Modal>
    )
}
