import { ApiUrl, BASE_URL } from "../../Api/ApiUrl";
import ReactQueryKey from "../../Constant/ReactQuery";
import axios from "axios";
import "./Admin.scss";
import React, { useCallback, useRef, useState, useEffect } from "react";
import { useQuery } from "react-query";
import TransferLand from "../Admin/TransferLand";
import Card from "react-bootstrap/Card";
import useAccessTocken from "../../Hooks/useAccessTocken";
import Loader from "../../components/Loader";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";

interface LandDTO {
  nftTokenAddress: string;
  type: string;
  serialNo: string;
}
export const trasferDetailsPopUpState = atom({
  key: "trasferDetailsPopUpState", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

function Admin() {
  const AccessToken = useQuery(ReactQueryKey.SOCIAL_MEDIA_LOGIN, {
    enabled: false,
  });
  const access_token = useAccessTocken();
  const [data, setData] = useState<Array<LandDTO>>();
  const [fullData, setFullData] = useState(Array<LandDTO>);
  const [selectedType, setSelectedType] = useState<string>();
  const [selectedSerialNo, setSelectedSerialNo] = useState<string>();
  const [selectedNFTTokenAddress, setSelectedNFTTokenAddress] =
    useState<string>();

  const [showTransferPopUp, setShowTransferPopUp] = useRecoilState(
    trasferDetailsPopUpState
  );

  const Axios = axios.create({
    baseURL: BASE_URL,
    //@ts-ignore
    headers: { Authorization: `Bearer ${access_token}` },
  });
  async function getMetamallOwnedLands() {
    const res = await Axios.get<Array<LandDTO>>(ApiUrl.metamallOwnedLands);
    console.log("res", res);
    setData(res.data);
    setFullData(res.data);
  }
  useEffect(() => {
    getMetamallOwnedLands();
  }, []);
  return (
    <>
      <div className="experience-wrapper land-center-wrapper adminPage">
        <div className={" download-exe row"}>
          <div className="admin-wrapper">
            {data === undefined ? (
              <Loader className={"recharge-loader"} />
            ) : (
              <>
                <div
                  style={{
                    marginBottom: "1rem",
                    alignItems: "center",
                    display: "flex",
                    padding: "0 15px",
                  }}
                >
                  {" "}
                  <span>Search :</span>
                  <input
                    type="text"
                    className="form-control w-50 textSearch"
                    onChange={(e) => {
                      const needle = e.target.value;
                      const filteredData = fullData.filter(
                        (land) =>
                          land.nftTokenAddress
                            .toLowerCase()
                            .includes(needle.toLowerCase()) ||
                          land.serialNo
                            .toLowerCase()
                            .includes(needle.toLowerCase()) ||
                          land.type.toLowerCase().includes(needle.toLowerCase())
                      );
                      setData(filteredData);
                    }}
                  />
                </div>
                <div className="adminLandSection ">
                  <div className="row">
                    {data !== undefined &&
                      data.map((land, i) => (
                        <Card className="col-md-3 m-0 p-2" key={i}>
                          <Card.Body className="">
                            <div className="cube">{land.type}</div>
                            <div className="cube digit">{land.serialNo}</div>
                            <div className="tokenAddress">
                              {land.nftTokenAddress}
                            </div>
                            <button
                              className="transfer-button btn btn-primary"
                              data-landtype={land.type}
                              data-landSerialNo={land.serialNo}
                              onClick={() => {
                                setSelectedSerialNo(land.serialNo);
                                setSelectedType(land.type);
                                setSelectedNFTTokenAddress(
                                  land.nftTokenAddress
                                );
                                setShowTransferPopUp(true);
                              }}
                            >
                              Transfer
                            </button>
                          </Card.Body>
                        </Card>
                      ))}
                  </div>
                  {showTransferPopUp && (
                    <TransferLand
                      landType={selectedType}
                      landSerialNo={selectedSerialNo}
                      nftTokenAddress={selectedNFTTokenAddress}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
