import { useCallback, useState, useEffect } from "react";
import { PublicKey, Transaction } from "@solana/web3.js";
import { useQuery, useQueryClient } from "react-query";
import ReactQueryKey from "../Constant/ReactQuery";
import { ApiCall } from "../Api/ApiCall";
import { ApiUrl } from "../Api/ApiUrl";
import { makeString } from "../utils/CommonFunction";

type DisplayEncoding = "utf8" | "hex";
type PhantomEvent = "disconnect" | "connect" | "accountChanged";
type PhantomRequestMethod =
  | "connect"
  | "disconnect"
  | "signTransaction"
  | "signAllTransactions"
  | "signMessage";

interface ConnectOpts {
  onlyIfTrusted: boolean;
}

interface PhantomProvider {
  publicKey: PublicKey | null;
  isConnected: boolean | null;
  signTransaction: (transaction: Transaction) => Promise<Transaction>;
  signAllTransactions: (transactions: Transaction[]) => Promise<Transaction[]>;
  signMessage: (
    message: Uint8Array | string,
    display?: DisplayEncoding
  ) => Promise<any>;
  connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>;
  disconnect: () => Promise<void>;
  on: (event: PhantomEvent, handler: (args: any) => void) => void;
  request: (method: PhantomRequestMethod, params: any) => Promise<unknown>;
}

interface Phantom {}

const useConnectWallet = (
  walletApiCall: boolean = true,
  address: string | null = null
) => {
  const [provider, setProvider] = useState<PhantomProvider | undefined>(
    undefined
  );
  const [walletKey, setWalletKey] = useState<any>(undefined);
  const [isConnected, setConnected] = useState<PhantomProvider | false | true>(
    false
  );

  const [crptoMsg, setCrptoMsg] = useState<{
    signedMessage: string;
    message: string;
  }>({ signedMessage: "", message: "" });
  const { isIdle, isLoading, isError, data, error, refetch, isFetching } =
    useQuery(
      ReactQueryKey.WALLET_ADDRESS,
      () => (walletKey === undefined ? "" : walletKey),
      {
        enabled: false,
      }
    );
  console.log("TEST TEST");
  const AccessToken = useQuery(
    ReactQueryKey.SOCIAL_MEDIA_LOGIN,
    () =>
      ApiCall("POST", ApiUrl.walletlogin, {
        walletId: walletKey,
        ...crptoMsg,
      }),
    {
      enabled: false,
    }
  );

  const getProvider = (): PhantomProvider | undefined => {
    //@ts-ignore
    if ("solana" in window) {
      //@ts-ignore
      const provider = window.solana as any;
      if (provider.isPhantom) return provider as PhantomProvider;
    }
  };
  const setAccessToken = (message, signedMessage) => {
    ApiCall("POST", ApiUrl.walletlogin, {
      walletId: walletKey,
      message: message,
      signedMessage: signedMessage,
    }).then((c) => {
      localStorage.setItem("access_token", c.access_token);
    });
  };
  
  const connectWallet = async () => {
    //@ts-ignore
    const { solana } = window;
    if (solana) {
      try {
        const response = await solana.connect();
        const message = makeString(15);
        const encodedMessage = new TextEncoder().encode(message);
        const signedMessage = await solana.request({
          method: "signMessage",
          params: {
            message: encodedMessage,
            display: "hex",
          },
        });
        crptoMsg.message = message;
        crptoMsg.signedMessage = signedMessage.signature;
        const cid = "88qrqeCdcVkThTZvts8J9sQXh8iJKd9VmAEPDNMtjky5";
        //@ts-ignore
        setWalletKey(response.publicKey.toString());
        //setWalletKey(cid)
        setCrptoMsg({ message, signedMessage });
        setConnected(true);
        setAccessToken(message, signedMessage);
      } catch (err) {
        // { code: 4001, message: 'User rejected the request.' }
      }
    }
  };

  const disconnectWallet = async () => {
    //@ts-ignore
    const { solana } = window;
    if (data && solana) {
      await (solana as PhantomProvider).disconnect();
      setWalletKey(null);
      setConnected(false);
    }
  };

  const getWalletKey = () => walletKey;

  useEffect(() => {
    //@ts-ignore
    if (walletKey !== undefined || provider === undefined) {
      const provider = getProvider();

      if (provider) setProvider(provider);
      else setProvider(undefined);
      if (typeof walletKey === "string" || walletKey === null) {
        refetch();
      }
      if (walletKey === null) {
        refetch();
      }
      if (
        crptoMsg.message !== "" &&
        crptoMsg.signedMessage !== "" &&
        walletKey !== undefined &&
        walletKey !== null &&
        walletApiCall
      ) {
        AccessToken.refetch();
      }
      if (address !== null) {
        setWalletKey(address);
      }
    }
  }, [walletKey, isConnected]);
  return {
    walletKey: data,
    connectWallet,
    disconnectWallet,
    getWalletKey,
    isConnected,
  };
};

export default useConnectWallet;
