import { useEffect, useState } from "react";
import "./MyStore.scss";
import Title from "../../components/Title";
import StoreSummary from "./StoreSummary";
import Button from "react-bootstrap/Button";
import { useWallet } from "@solana/wallet-adapter-react";
import { BASE_URL, ApiUrl } from "../../Api/ApiUrl";
import Loader from "../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { FaPlusSquare } from "react-icons/fa";
import { useWindowSize } from "../../Hooks/useWindowSize";
import { MOBILE_SCREEN_SIZE } from "../../Constant/Constant";
import NoDataFound from "../../components/NoDataFound";
import { fetchBalance } from "../../Solana/GetBalance";
import ReactQueryKey from "../../Constant/ReactQuery";
import LandingPage from "../LandingPage";
export interface StoreSummaryDTO {
  id: number;
  name: string;
  logo: string;
  isPublished: boolean;
  nftTokenAddress: string;
}

const MyStores = () => {
  const navigation = useNavigate();
  const { height, width } = useWindowSize();
  const { publicKey, signTransaction, connect, connected, select, wallet } =
    useWallet();
  const [arrStoreSummary, setarrStoreSummary] =
    useState<Array<StoreSummaryDTO>>();
  const [isLoadingSummay, setisLoadingSummay] = useState(true);
 

  useEffect(() => {
      getData();
  }, [publicKey]);

  const getData = async () => {
    if (connected) {
      fetch(`${BASE_URL}/${ApiUrl.getOwnerStores}/${publicKey?.toString()}`)
        .then((response) => response.json())
        .then((data) => {
          setarrStoreSummary(data);
          setisLoadingSummay(false);
        })
        .catch(() => setisLoadingSummay(false));
    }
  };

  return (
    <div className="experience-wrapper land-center-wrapper mystorePage">
      <div className={" download-exe row"}>
        <div className="flex blkHeadingStore">
          {!connected && <LandingPage />}
          <Title
            firstName={"LAND  "}
            remainName={"OWNED"}
            imageUrl={"images/Land.png"}
            showBuyBtn={false}
            className={"land-owned-title"}
          />
          <span>
            {/* <Button
              variant="primary active shadow-none"
              onClick={() => {
                navigation(`/cockpit/mystores/mystoresudpate`);
              }}
            >
              {width !== undefined && width > MOBILE_SCREEN_SIZE ? "CREATE NEW STORE" : <FaPlusSquare />}
            </Button> */}
          </span>
        </div>
        {isLoadingSummay && <Loader className={"cockpit-loader"} />}
        {arrStoreSummary !== undefined &&
          arrStoreSummary.map((k, i) => {
            return (
              <div className="col-lg-6">
                <StoreSummary
                  key={i}
                  storeId={k.id}
                  storeName={k.name}
                  imgPath={k.logo}
                  attributes={[]}
                  isPublished={k.isPublished}
                  nftTokenAddress={k.nftTokenAddress}
                />
              </div>
            );
          })}

        {arrStoreSummary !== undefined && arrStoreSummary.length === 0 && (
          <NoDataFound textMessage="Yo do not own any store" />
        )}
      </div>
    </div>
  );
};

export default MyStores;
