import React from "react";

import "./index.scss";

const Button = ({
  type = "primary",
  className = "",
  disabled = false,
  children,
  ...rest
}): any => {
  return (
    <button
      className={`metamall-btn-${type} ${className} ${
        disabled ? "metamall-btn-disabled" : ""
      }`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
