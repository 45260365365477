import { useQuery } from "react-query";
import ReactQueryKey from "../Constant/ReactQuery";
import { staticApiCall, ApiCall } from "../Api/ApiCall";
import { ApiUrl } from "../Api/ApiUrl";
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect } from "react";

const useGetLiveAuction = () => {
  const { publicKey } = useWallet();
  //@ts-ignore
  const { isIdle, isLoading, isError, data, error, refetch, isFetching } =
    useQuery(
      ReactQueryKey.GET_LIVE_AUCTION,
      () => ApiCall("GET", `${ApiUrl.getAuction}${publicKey?.toString()}`),
      {
        enabled: false,
      }
    );
  // if (
  //   publicKey?.toString() !== null &&
  //   (data === undefined || data.length === 0)
  // ) {
  //   refetch();
  // }
  useEffect(() => {
    refetch();
  }, [publicKey]);

  return {
    isIdle,
    isLoading,
    isError,
    data,
    error,
    isFetching,
    refetch,
  };
};

export default useGetLiveAuction;
