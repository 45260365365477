import "../StakingStyle/App.css";
import "../StakingStyle/yeah.css";
import "../StakingStyle/styles.css";
import { useState, useEffect } from "react";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import {
  useConnection,
  useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  Transaction,
  PublicKey,
  LAMPORTS_PER_SOL,
  Connection,
} from "@solana/web3.js";
import React, { useCallback } from "react";
import { toast } from "react-hot-toast";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { getOrCreateAssociatedTokenAccount } from "../components/getOrCreateAssociatedTokenAccount";
import { createTransferInstruction } from "../components/createTransferInstructions";
import { STACKING_BASE_URL, NETWORK_URL, TOKEN_ID, MALL_TOKEN_ID } from "../Constant/StakingConstant";
import { StakingPoolData } from "./StakingDetails";
import { useNavigate } from "react-router-dom";
import { WalletDisconnectButton } from "@solana/wallet-adapter-react-ui";
import ClockLoader from "react-spinners/ClockLoader";
import { css } from "@emotion/react";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";


const mainnet_endpoint = NETWORK_URL;
//  "https://morning-snowy-rain.solana-mainnet.quiknode.pro/";

/** Mainnet RPC connection */
const connection = new Connection(mainnet_endpoint);

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const getUnixTs = () => {
  return new Date().getTime() / 1000;
};

const DEFAULT_TIMEOUT = 120000;

interface Props {
  children: (sendTransaction: OnSendTransaction) => React.ReactNode;
}

type OnSendTransaction = (toPublicKey: string, amount: number) => void;

export const fetchBalance = async (publicKey: string | undefined) => {
  let walletBalance = 0;
  try {
    const owner = publicKey;
    const MINT = new PublicKey(TOKEN_ID);
    const request_data = {
      jsonrpc: "2.0",
      id: 1,
      method: "getTokenAccountsByOwner",
      params: [owner, { mint: MINT }, { encoding: "jsonParsed" }],
    };

    console.log(" --- request data ----", { request_data });
    const transaction_details: any = await fetch(NETWORK_URL, {
      method: "POST",
      body: JSON.stringify(request_data),
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());

    console.log(
      transaction_details.result.value[0].account.data.parsed.info.tokenAmount
        .uiAmount
    );
    if (
      transaction_details &&
      transaction_details.result &&
      transaction_details.result &&
      transaction_details.result.value[0] &&
      transaction_details.result.value[0].account &&
      transaction_details.result.value[0].account.data.parsed &&
      transaction_details.result.value[0].account.data.parsed.info &&
      transaction_details.result.value[0].account.data.parsed.info
        .tokenAmount &&
      transaction_details.result.value[0].account.data.parsed.info.tokenAmount
        .uiAmount
    ) {
      walletBalance =
        transaction_details.result.value[0].account.data.parsed.info.tokenAmount
          .uiAmount;
    }
  } catch (e) {
    console.log(e);
  }
  return walletBalance;
};

function TestStake(props: {
  stakingPoolDetaills: StakingPoolData | undefined;
}) {
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#99005b");
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  //const { connection } = useConnection();
  const { publicKey, signTransaction, sendTransaction } = useWallet();
  const [amountToStake, setAmountToStake] = useState<number>(0);
  const [isEnoughBalance, setIsEnoughBalance] = useState<boolean>(true);
  const [isEnoughMaxReward, setIsEnoughMaxReward] = useState<boolean>(true);
  const [balance, setBalance] = useState<number>(0);
  const [isStakingDone, setIsStakingDone] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    if (publicKey) {
      fetchBalance(publicKey?.toString()).then((walletBalance) => {
        setBalance(walletBalance);
      });
    } else {
      setBalance(0);
    }
  }, [publicKey]);

  const onSendSPLTransaction =
    async (toPubkey: string, amount: number) => {
      console.log(toPubkey);
      console.log(signTransaction);
      console.log("publicKey", { publicKey }, props);

      if (!toPubkey || !amount || props.stakingPoolDetaills?.id === undefined)
        return;

      const toastId = toast.loading("Processing transaction...");

      try {
        if (!publicKey || !signTransaction) {
          console.log("Wallet not connected");
          throw new WalletNotConnectedError();
        }
        const toPublicKey = new PublicKey(toPubkey);
        const mint = new PublicKey(TOKEN_ID);
        console.log("MINT", { mint });
        console.log(connection);

        const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
          connection,
          publicKey,
          mint,
          publicKey,
          signTransaction
        );
        console.log("fromToken", { fromTokenAccount });

        const toTokenAccount = await getOrCreateAssociatedTokenAccount(
          connection,
          publicKey,
          mint,
          toPublicKey,
          signTransaction
        );

        console.log("toToken", { toTokenAccount });

        const transaction = new Transaction().add(
          createTransferInstruction(
            fromTokenAccount.address, // source
            toTokenAccount.address, // dest
            publicKey,
            amount * 1000,
            [],
            TOKEN_PROGRAM_ID
          )
        );

        // if (transaction){

        //   console.log("transaction confirmed: ", transaction);

        // }

        transaction.feePayer = await publicKey;

        // let blockhash =
        //   (
        //     await connection.getLatestBlockhash('finalized')
        //   ).blockhash ||
        //   (
        //     await connection.getLatestBlockhash('confirmed')
        //   ).blockhash;

        // const isBlockhashValidData = {
        //   jsonrpc: "2.0",
        //   id: 45,
        //   method: "isBlockhashValid",
        //   params: [blockhash, { commitment: "processed" }],
        // };

        // console.log(" --- request data ----", { isBlockhashValidData });

        // const isBlockhashValid: any = await fetch(NETWORK_URL, {
        //   method: "POST",
        //   body: JSON.stringify(isBlockhashValidData),
        //   headers: { "Content-Type": "application/json" },
        // }).then((res) => res.json());

        // console.log("isBlockhashValid: ", isBlockhashValid);

        // if (!isBlockhashValid) {
        //   console.log("Block hash is not valid");
        // }

        // transaction.recentBlockhash = blockhash;

        const signature = await sendTransaction(transaction, connection);

        // const signed = await signTransaction(transaction);

        // const rawTransaction = signed.serialize();

        // const finalResponse = await connection.sendRawTransaction(
        //   rawTransaction,
        //   {
        //     skipPreflight: true,
        //   }
        // );

        console.log("finalResponse", { signature });

        // const signatureStatuses = await connection.getSignatureStatuses([
        //   signature,
        // ]);
        // console.log(" signatureStatuses :",signatureStatuses);
        // const result = signatureStatuses && signatureStatuses.value[0];
        // console.log(" result confirmed and finalized: ",result);

        // if (
        //   result?.confirmationStatus ||
        //   result?.confirmationStatus === "confirmed" ||
        //   result?.confirmationStatus === "finalized"
        // ){
        //   console.log(" signatureStatuses confirmed and finalized");
        // }

        setLoading(true);

        try {
          await connection.confirmTransaction(signature, "processed");
          await connection.confirmTransaction(signature, "max");
          await connection.getParsedTransaction(signature, "confirmed");
        } catch (e) {
          setLoading(false);
          console.log(e);
          setErrorMsg("Blockhash has been expired, please try again!");
          throw e;
        }

        console.log(
          "props.stakingPoolDetaills?.id: ",
          props.stakingPoolDetaills?.id
        );

        const stakeDetailResponse: any = await fetch(
          `${STACKING_BASE_URL}/createVestingContract/${publicKey.toString()}/${
            props.stakingPoolDetaills?.id
          }/${amount}/${signature}`,
          {
            method: "POST",
          }
        ).then((response) => response.json());
        setLoading(false);

        console.log(stakeDetailResponse);
        if (stakeDetailResponse.response === "Data Saved Successfully") {
          setIsStakingDone(true);
          navigation(`/staking/staking-result/${amount}`, {
            state: {
              name: "raeon",
              url: stakeDetailResponse.url,
              code: stakeDetailResponse.redemption_code,
            },
            replace: true,
          });
        } else {
          setErrorMsg(stakeDetailResponse.response);
        }

        toast.success("Transaction sent", {
          id: toastId,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toast.error(`Transaction failed: ${error.message}`, {
          id: toastId,
        });
      }
    }

  return (
    <div className="checkout-details">
      {balance ? <label>Your current wallet balance is {balance}</label> : ""}
      <div className="token-claim-next-dude">
        <label className="payment-method" style={{ marginTop: "15px" }}>
          ENTER STAKING AMOUNT
        </label>
        <input
          type="number"
          min={0}
          value={amountToStake}
          onChange={(e) => {
            try {
              const n = parseInt(e.target.value);
              setAmountToStake(n);
            } catch (error) {
              setAmountToStake(0);
            }
          }}
        />
        <label>
          {!isEnoughBalance
            ? "Entered amount is greater than your current balance"
            : !isEnoughMaxReward
            ? "Pool has been reached to it's maximum limit"
            : ""}
        </label>
        <ClockLoader
          color={color}
          loading={loading}
          css={override}
          size={100}
        />
        {!loading ? (
          // <button
          //   type="submit"
          //   className="registerbtn"
          //   style={{ textAlign: "center" }}
          //   onClick={async () => {
          //     //console.log(publicKey?.toString());
          //     setErrorMsg(undefined);
          //     const walletBalance = await fetchBalance(publicKey?.toString());
          //     let lockedAmount = 0;
          //     const request_data = {
          //       jsonrpc: "2.0",
          //       id: 1,
          //       method: "getTokenAccountsByOwner",
          //       params: [
          //         props.stakingPoolDetaills?.wallet_id,
          //         { mint: TOKEN_ID },
          //         { encoding: "jsonParsed" },
          //       ],
          //     };

          //     console.log(" --- request data ----", { request_data });
          //     const transaction_details: any = await fetch(NETWORK_URL, {
          //       method: "POST",
          //       body: JSON.stringify(request_data),
          //       headers: { "Content-Type": "application/json" },
          //     }).then((res) => res.json());
          //     console.log(transaction_details);
          //     if (
          //       transaction_details &&
          //       transaction_details.result &&
          //       transaction_details.result.value
          //     ) {
          //       const values: Array<any> = transaction_details.result.value;
          //       const stakePoolAccount = values.find(
          //         (v) => v.pubkey == props.stakingPoolDetaills?.account_id
          //       );
          //       if (
          //         stakePoolAccount &&
          //         stakePoolAccount.account &&
          //         stakePoolAccount.account.data
          //       ) {
          //         lockedAmount =
          //           stakePoolAccount.account.data.parsed.info.tokenAmount
          //             .uiAmount;
          //       }
          //     }

          //     setBalance(walletBalance);
          //     if (amountToStake > walletBalance) {
          //       setIsEnoughBalance(false);
          //     } else if (
          //       amountToStake + Number(lockedAmount) >
          //       Number(props.stakingPoolDetaills?.max_reward_value)
          //     ) {
          //       setIsEnoughMaxReward(false);
          //     } else {
          //       setIsEnoughMaxReward(true);
          //       setIsEnoughBalance(true);
          //       console.log("clicked");
          //       console.log(props.stakingPoolDetaills?.wallet_id);
          //       if (props.stakingPoolDetaills?.wallet_id) {
          //         const resp = await onSendSPLTransaction(
          //           // "2wvzwgRRSmu4oixt85LiibPowfhjrJSh21Ugu3tsXggC",
          //           props.stakingPoolDetaills?.wallet_id,
          //           amountToStake
          //         );
          //         console.log("transfer done");
          //         console.log(resp);
          //       } else {
          //         console.log("Unable to find wallet details.");
          //       }
          //     }
          //   }}
          // >
          //   Stake
          // </button>
          <div className="buyland-wrapper" >
          <PrimaryButton name={'Stake'} className={'buy-land-button-style'} 
            onClick={async () => {
              //console.log(publicKey?.toString());
              setErrorMsg(undefined);
              const walletBalance = await fetchBalance(publicKey?.toString());
              let lockedAmount = 0;
              const request_data = {
                jsonrpc: "2.0",
                id: 1,
                method: "getTokenAccountsByOwner",
                params: [
                  props.stakingPoolDetaills?.wallet_id,
                  { mint: TOKEN_ID },
                  { encoding: "jsonParsed" },
                ],
              };

              console.log(" --- request data ----", { request_data });
              const transaction_details: any = await fetch(NETWORK_URL, {
                method: "POST",
                body: JSON.stringify(request_data),
                headers: { "Content-Type": "application/json" },
              }).then((res) => res.json());
              console.log(transaction_details);
              if (
                transaction_details &&
                transaction_details.result &&
                transaction_details.result.value
              ) {
                const values: Array<any> = transaction_details.result.value;
                const stakePoolAccount = values.find(
                  (v) => v.pubkey == props.stakingPoolDetaills?.account_id
                );
                if (
                  stakePoolAccount &&
                  stakePoolAccount.account &&
                  stakePoolAccount.account.data
                ) {
                  lockedAmount =
                    stakePoolAccount.account.data.parsed.info.tokenAmount
                      .uiAmount;
                }
              }

              setBalance(walletBalance);
              if (amountToStake > walletBalance) {
                setIsEnoughBalance(false);
              } else if (
                amountToStake + Number(lockedAmount) >
                Number(props.stakingPoolDetaills?.max_reward_value)
              ) {
                setIsEnoughMaxReward(false);
              } else {
                setIsEnoughMaxReward(true);
                setIsEnoughBalance(true);
                console.log("clicked");
                console.log(props.stakingPoolDetaills?.wallet_id);
                if (props.stakingPoolDetaills?.wallet_id) {
                  const resp = await onSendSPLTransaction(
                    // "2wvzwgRRSmu4oixt85LiibPowfhjrJSh21Ugu3tsXggC",
                    props.stakingPoolDetaills?.wallet_id,
                    amountToStake
                  );
                  console.log("transfer done");
                  console.log(resp);
                } else {
                  console.log("Unable to find wallet details.");
                }
              }
            }}
          />
          </div>
        ) : (
          <div
            style={{
              justifyContent: "center",
              alignItems: "left",
              height: "20vh",
            }}
          >
            <h1> Waiting for the Transaction Confirmation </h1>
            <h2>
              {" "}
              Please do not refresh or close the browser while staking
              transaction is being processed{" "}
            </h2>
            <h2>
              In case staking transaction fails to confirm, please share details
              of token transfer to staking@metamalls.io
            </h2>
          </div>
        )}
        <div
          style={{
            justifyContent: "center",
            alignItems: "left",
            height: "20vh",
          }}
        >
          <h1> {errorMsg ? errorMsg : ""} </h1>
        </div>
      </div>
    </div>
  );
}

export default TestStake;
function sleep(arg0: number) {
  throw new Error("Function not implemented.");
}