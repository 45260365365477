import React, { useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import "./Admin.scss";
import Admin from "./Admin";
import UserCenter from "./UserCenter";
import LandingPage from "../LandingPage";

const AdminMenu = () => {
  const Categories = {
    TRANSFER_LANDS: "Transfer lands",
    USER_CENTER: "Users Center",
  };
  const { connected } = useWallet();
  const [currentPage, setCurrentPage] = useState(Categories.TRANSFER_LANDS);
  const cat = [
    {
      catName: Categories.TRANSFER_LANDS,
    },
    {
      catName: Categories.USER_CENTER,
    },
  ];

  const openTab = (tabName) => {
    setCurrentPage(tabName);
  };

  return (
    <div className="inventoryPage">
      <div className="tab ">
        {cat.map((c, i) => {
          return (
            <button
              key={i}
              className={`tablinks ${currentPage === c.catName && "active"}`}
              onClick={() => openTab(c.catName)}
            >
              {c.catName}
            </button>
          );
        })}
      </div>

      {!connected && <LandingPage />}

      {/* Transfer Lands */}
      {currentPage === Categories.TRANSFER_LANDS && <Admin />}

      {/* User center*/}
      {currentPage === Categories.USER_CENTER && <UserCenter />}
    </div>
  );
};

export default AdminMenu;
