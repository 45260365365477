import React, { useState, useEffect } from "react";
import "../../components/ProductCard/ProductCard.scss";
import "./Inventory.scss";
import Loader from "../../components/Loader";
import Title from "../../components/Title";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { FaGamepad, FaGlobe, FaRegBuilding } from "react-icons/fa";
import InputBox from "../../components/InputBox";
import { ApiUrl, BASE_URL } from "../../Api/ApiUrl";
import axios from "axios";
import ReactQueryKey from "../../Constant/ReactQuery";
import { useQuery } from "react-query";
import TitleInventory from "./TitleInventory";
import { Modal } from "react-bootstrap";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { InputGroup, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NotFound from "../../components/NotFound";
import useAccessTocken from "../../Hooks/useAccessTocken";
import useConnectWallet from "../../Hooks/useConnectWallet";
export interface InventoryItems {
  inventoryItems: Array<InventoryItems>;
}

export interface InventoryItems {
  rowName: string;
  datatableRef: string;
  quantity: number;
  placedQuantity: number;
  updatedQty: number;
  inputRef?: any;
}

const InventoryFurniture = () => {
  const access_token = useAccessTocken();
  // const { disconnectWallet, walletKey, isConnected, connectWallet } =
  //   useConnectWallet();
  // const AccessToken = useQuery<any>(ReactQueryKey.SOCIAL_MEDIA_LOGIN, {
  //   enabled: false,
  // });
  // const access_token = localStorage.getItem("access_token");
  // if (access_token === undefined) {
  //   connectWallet();
  // }
  console.log("access_tokenaccess_token", access_token);
  const navigation = useNavigate();
  const [isLoadingSummay, setisLoadingSummay] = useState(false);
  const [isLoadingGameInventory, setIsLoadingGameInventory] = useState(false);
  const [isLoadingWebInventory, setIsLoadingWebInventory] = useState(false);
  const [showSuccesModal, setShowSuccesModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("Item moved successfully");
  const [lstGameInventory, setLstGameInventory] =
    useState<Array<InventoryItems>>();
  const [lstWebInventory, setLstWebInventory] =
    useState<Array<InventoryItems>>();

  const inventoryType = {
    INVENTORY_WEB: "web",
    INVENTORY_GAME: "game",
  };

  // const [lstwebInput, setLstwebInput] = useState<Array<InputItem>>([]);

  // if (!access_token && !AccessToken.data.access_token) {
  //   navigation("/");
  // }
  const Axios = axios.create({
    baseURL: BASE_URL,
    //@ts-ignore
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });

  async function getFurnitureGameInventory() {
    setIsLoadingGameInventory(true);
    console.log("call >>>>> getFurnitureGameInventory");
    if (access_token !== undefined) {
      await Axios.get<InventoryItems>(ApiUrl.gameInventory)
        .then((res) => {
          setIsLoadingGameInventory(false);
          setLstGameInventory(res.data.inventoryItems);
        })
        .catch((c) => {
          setIsLoadingGameInventory(false);
        });
    }
  }

  async function getFurnitureWebInventory() {
    console.log("call >>>>> getFurnitureWebInventory");
    setIsLoadingWebInventory(true);
    if (access_token !== undefined) {
      await Axios.get<InventoryItems>(ApiUrl.webInventory)
        .then((res) => {
          setIsLoadingWebInventory(false);
          setLstWebInventory(res.data.inventoryItems);
        })
        .catch((c) => {
          setIsLoadingWebInventory(false);
        });
    }
  }

  async function moveToWebInventory(rowName, dataRef, qty) {
    await Axios.post(ApiUrl.moveToWebInventory, {
      rowName: rowName,
      datatableRef: dataRef,
      quantity: qty,
    }).then((res) => {
      getFurnitureGameInventory();
      getFurnitureWebInventory();
      setModalMessage("Item moved successfully");
      setShowSuccesModal(true);
    });
  }

  async function onAddLinkedItemStand() {
    await Axios.post(`${ApiUrl.addNLinkedItemStandsToGameInventory}/10`)
      .then((res) => {
        console.log("addNLinkedItemStandsToGameInventory response");
        console.log({ res });
        setModalMessage("Successfully added 10 stands");
        setShowSuccesModal(true);
      })
      .catch((err) => {
        setModalMessage("You can not perform this operation");
        setShowSuccesModal(true);
      });
  }

  async function moveToGameInventory(rowName, dataRef, qty) {
    await Axios.post(ApiUrl.moveToGameInventory, {
      rowName: rowName,
      datatableRef: dataRef,
      quantity: qty,
    }).then((res) => {
      getFurnitureGameInventory();
      getFurnitureWebInventory();
      setModalMessage("Item moved successfully");
      setShowSuccesModal(true);
    });
  }

  useEffect(() => {
    console.log("call >>>>>", access_token);
    if (!!access_token) {
      console.log("call >>>>> if (!access_token)", access_token);
      getFurnitureGameInventory();
      getFurnitureWebInventory();
    }
  }, [access_token]);

  const DivInventroy = ({ Type, ButtonText }) => {
    return (
      <>
        <Card className="cardInventoryItems">
          <Card.Body
            className="p-0"
            style={{
              background:
                (lstGameInventory !== undefined &&
                  lstGameInventory.length === 0) ||
                (lstWebInventory !== undefined && lstWebInventory.length === 0)
                  ? ""
                  : Type === inventoryType.INVENTORY_WEB
                  ? "#524b6e"
                  : "#3f385f",
            }}
          >
            <div className="row m-0">
              {Type === inventoryType.INVENTORY_GAME &&
                lstGameInventory !== undefined &&
                lstGameInventory.length !== 0 &&
                lstGameInventory.map((k, i) => {
                  return (
                    <div className="col-sm-6 " key={i}>
                      <DivInventroySection
                        ButtonText={ButtonText}
                        Item={k}
                        InventoryType={Type}
                        Index={i}
                      />
                    </div>
                  );
                })}

              {Type === inventoryType.INVENTORY_GAME &&
                lstGameInventory !== undefined &&
                lstGameInventory.length === 0 && (
                  <NotFound title="Game inventory item " />
                )}

              {Type === inventoryType.INVENTORY_WEB &&
                lstWebInventory !== undefined &&
                lstWebInventory.length === 0 && (
                  <NotFound title="Web inventory item " />
                )}

              {Type === inventoryType.INVENTORY_GAME &&
                isLoadingGameInventory && (
                  <Loader className={"cockpit-loader"} />
                )}

              {Type === inventoryType.INVENTORY_WEB &&
                isLoadingWebInventory && (
                  <Loader className={"cockpit-loader"} />
                )}

              {Type === inventoryType.INVENTORY_WEB &&
                lstWebInventory !== undefined &&
                lstWebInventory.length !== 0 &&
                lstWebInventory.map((k, i) => {
                  return (
                    <div className="col-sm-6" key={i}>
                      <DivInventroySection
                        ButtonText={ButtonText}
                        Item={k}
                        InventoryType={Type}
                        Index={i}
                      />
                    </div>
                  );
                })}
            </div>
          </Card.Body>
        </Card>
      </>
    );
  };

  const handleInputChange = async (e, index, InventoryType) => {
    const { name, value } = e.target;
    if (lstGameInventory !== undefined) {
      if (InventoryType === inventoryType.INVENTORY_GAME) {
        const list = [...lstGameInventory];
        list[index][name] = value.replace(/\D/g, "");
        await setLstGameInventory(list);
        await lstGameInventory[index].inputRef.focus();
      }
    }
    if (lstWebInventory !== undefined) {
      if (InventoryType === inventoryType.INVENTORY_WEB) {
        const list = [...lstWebInventory];
        list[index][name] = value.replace(/\D/g, "");
        await setLstWebInventory(list);
        await lstWebInventory[index].inputRef.focus();
      }
    }
  };

  const DivInventroySection = ({ ButtonText, Item, InventoryType, Index }) => {
    return (
      <Card>
        <Card.Body className="p-0">
          <div className="productCard">
            <div className={"nft-wrapper"}>
              <div className={"namePrice"}>
                <img
                  className={"header-icon"}
                  src={`${process.env.PUBLIC_URL}/images/chair.svg`}
                  alt=""
                />
                <span className={"cube"} title={Item.rowName}>
                  {Item.rowName.replace(/_/g, " ")}
                </span>
              </div>
              <div className={"image-wrapper"}>
                <span className="cube digit">Quantity - {Item.quantity}</span>
                {InventoryType === inventoryType.INVENTORY_GAME ? (
                  <span className="cube digit">
                    In Store - {Item.placedQuantity}
                  </span>
                ) : (
                  <></>
                )}
              </div>

              <div className={"details"}>
                <div className="inputAndActionBox">
                  <div className="inputbox-style">
                    <div className="lable">&nbsp;</div>
                    <InputGroup className="mb-3">
                      <input
                        type="text"
                        pattern="[0-9]*"
                        className="form-control"
                        name="updatedQty"
                        placeholder="Quantity"
                        value={Item.updatedQty || 0}
                        ref={(e) => (Item.inputRef = e)}
                        onChange={(e) =>
                          handleInputChange(e, Index, InventoryType)
                        }
                        style={
                          InventoryType === inventoryType.INVENTORY_WEB
                            ? Item.updatedQty < 0 ||
                              Item.updatedQty > Item.quantity
                              ? { border: "1px solid rgb(255, 77, 79)" }
                              : {}
                            : Item.updatedQty < 0 ||
                              Item.updatedQty >
                                Item.quantity - Item.placedQuantity
                            ? { border: "1px solid rgb(255, 77, 79)" }
                            : {}
                        }
                      />
                    </InputGroup>
                  </div>

                  <Button
                    variant="primary active shadow-none m-auto"
                    onClick={async () => {
                      if (InventoryType === inventoryType.INVENTORY_GAME) {
                        await moveToWebInventory(
                          Item.rowName,
                          Item.datatableRef,
                          Item.updatedQty
                        );
                      }
                      if (InventoryType === inventoryType.INVENTORY_WEB) {
                        await moveToGameInventory(
                          Item.rowName,
                          Item.datatableRef,
                          Item.updatedQty
                        );
                      }
                    }}
                    disabled={
                      InventoryType === inventoryType.INVENTORY_WEB
                        ? Item.updatedQty === undefined ||
                          Item.updatedQty < 1 ||
                          Item.updatedQty > Item.quantity
                          ? true
                          : false
                        : Item.updatedQty === undefined ||
                          Item.updatedQty < 1 ||
                          Item.updatedQty > Item.quantity - Item.placedQuantity
                        ? true
                        : false
                    }
                    size="sm"
                  >
                    {ButtonText}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div className="experience-wrapper land-center-wrapper mystorePage inventoryPage">
      <div className={"download-exe row"}>
        <div className="flex blkInventory">
          <div className="row">
            <div className="col-12">
              <PrimaryButton
                name="Buy Packages"
                className="primary-btn download"
                style={{ margin: "auto 5px" }}
                onClick={() => {
                  // window.open("/mplace/assets", "_blank");
                  navigation("/mplace/all/Package");
                }}
              />
            </div>

            <div className="col-xl-6 m-0 p-0">
              {
                <TitleInventory
                  InventoryTitle="Web Inventory"
                  Type={inventoryType.INVENTORY_WEB}
                />
              }
              {
                <DivInventroy
                  Type={inventoryType.INVENTORY_WEB}
                  ButtonText="Move to game inventory"
                />
              }
            </div>
            <div className="col-xl-6 m-0 p-0">
              <span className="inventoryTitle">
                {
                  <TitleInventory
                    InventoryTitle="Game Inventory"
                    Type={inventoryType.INVENTORY_GAME}
                    // IsShowAddLinkedItemStands={true}
                    // handleAddLinkedItemStand={onAddLinkedItemStand}
                  />
                }
                {
                  <DivInventroy
                    Type={inventoryType.INVENTORY_GAME}
                    ButtonText="Move to web inventory"
                  />
                }
              </span>
            </div>
          </div>
          {isLoadingSummay && <Loader className={"cockpit-loader"} />}
        </div>
      </div>

      {/* -----  MODAL TO SELECT LAND ----- */}
      <Modal
        show={showSuccesModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge model-landSelect modelMoveInvFurItems"
      >
        <div className="header-wrapper">
          <div className="header-text-wrapper" style={{ marginBottom: "0px" }}>
            <div className="header-text" style={{ textAlign: "center" }}>
              {modalMessage}
            </div>
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name={"OK"}
            size="md"
            onClick={() => {
              setShowSuccesModal(!showSuccesModal);
            }}
            className="proceed-button"
          />
        </div>
      </Modal>
    </div>
  );
};

export default InventoryFurniture;
