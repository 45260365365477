import { useNavigate, useParams } from "react-router-dom";
import "../../style/App.css";
import Header from "../../components/header/Header";
import "../../style/staking-result.css";
import "../../yeah.css";
import Layout from "../../components/Layout";
import { useEffect } from "react";

function ClaimResult() {
  const { trxid } = useParams<{ trxid: string }>();
  const { currentBalance } = useParams<{ currentBalance: string }>();
  const navigation = useNavigate();

  useEffect(() => {
    document.documentElement.style.setProperty('--base','auto');
  }, [])

  return (
    <div className="tokenclaim-body">
      <Layout>
        <div className="yeah" id="content-stacking">
          <div className="left-line liner">
            <div className="block left-block"></div>
          </div>
          <div className="yeah-overlay1"></div>
          <div className="content-container">
            <section>
              <div className="checkout-content">
                <div className="dudes">
                  <button type="button" className="backbtn" onClick={()=>navigation("/landsale")} >
                    <img src={require("../../imgg/backbtn.png")} alt="" />
                  </button>
                  <div className="checkout-head">
                    <p>
                      TOKEN <span>STAKING</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="token-stakingbox">
                <div className="" id="token-claim">
                  <div className="token-claim-next-dude token-claim-last-dude">
                    <div className="container">
                      <p className="clearfix text-center claimed-text">
                        <img
                          className="metamall-coin"
                          src={require("../../imgg/metamall-coin.png")}
                        />
                      </p>
                      <p className="clearfix text-center claimed-text">
                        TOKENS CLAIMED SUCCESSFULLY!
                        <br />
                        <span style={{ fontSize: "12px" }}>
                          <strong>{trxid} </strong>
                        </span>
                      </p>
                      {/* <p className="clearfix text-center balance-text">
                        <span>
                          <strong>Current Balance: </strong>
                          <img
                            className="claim-last-img"
                            src={require("./imgg/m-icon.png")}
                          />
                          <span> {currentBalance} </span>Tokens
                        </span>
                      </p> */}
                      {/* <WalletDisconnectButton /> */}

                      {/* <button type="submit" className="registerbtn-reverse">
                        <img src={require("./imgg/delete.png")} /> DISCONNECT
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="right-line liner">
            <div className="block right-block"></div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default ClaimResult;
