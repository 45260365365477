import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import { isEqual } from "lodash";
import { PublicKey } from "@solana/web3.js";
import { isEmpty } from "lodash";

import CollapseMenu from "../../../components/CollapseMenu";
import Button from "../../../components/Button";
import ListingCard from "./ListingCard";

import { useAssets } from "../hooks/useAuctionData";

import { Listing } from "../types";
import { useWallet } from "@solana/wallet-adapter-react";
import CreateNFT from "./NFT/CerateNFT";
import LandingPage from "../../../../src/Views/LandingPage/index";
import Loader from "../../../components/Loader";

const menu = [
  {
    key: "land",
    title: "Sell Assets",
    items: [
      {
        key: "all",
        title: "All",
      },
      {
        key: "land",
        title: "Land assets",
      },
      {
        key: "other",
        title: "Other NFTs",
      },
    ],
  },
];

interface FullViewProps {
  assets: Listing[];
  isLoading: boolean;
}

const FullView = ({ assets, isLoading }: FullViewProps) => {
  console.log(assets);
  const [activeKey, setActiveKey] = useState("all");

  const handleChange = (e: string) => {
    setActiveKey(e);
  };

  const getCardsJSX = () => {
    if (isLoading)
      return (
        <div className="marketplace-listing-no-data">
          <p>LOADING</p>
        </div>
      );

    const filteredAssets = isEqual(activeKey, "all")
      ? assets
      : assets.filter(({ type }) => {
          return isEqual(type?.toLowerCase(), activeKey);
        });
    console.log(filteredAssets);

    if (!isLoading && isEmpty(filteredAssets)) {
      return (
        <div className="marketplace-listing-no-data">
          <p>NO DATA FOUND</p>
        </div>
      );
    }

    return (
      <div className="marketplace-listing-cards-container-full-view no-margin">
        {filteredAssets.map((listing: Listing) => (
          <ListingCard
            key={listing.tokenAddress}
            mode="sell"
            listing={listing}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <div
        className="marketplace-listing-section-left"
        style={{ paddingTop: "20px" }}
      >
        {/* {!connected && <LandingPage />} */}
        <CollapseMenu
          defaultActiveKey="land"
          menu={menu}
          activeKey={activeKey}
          onChange={handleChange}
        />
        <button
          className="create-nft-btn"
          onClick={() => {
            handleChange("createnft");
          }}
        >
          Create NFT
        </button>
      </div>

      {/* <input
          className="marketplace-search-input"
          placeholder="Search for NFTs..."
          value={searchTerm}
          onChange={(e) => handleChange(e.target.value)}
        /> */}
      {activeKey === "createnft" ? (
        <CreateNFT />
      ) : (
        <div
          className="marketplace-listing-section-right"
          style={{ paddingTop: 0 }}
        >
          {getCardsJSX()}
        </div>
      )}
    </>
  );
};

interface IProps {
  publicKey: PublicKey | null;
}

const Assets = ({ publicKey }: IProps) => {
  const { connected, connect } = useWallet();
  // console.log(publicKey);
  const { data: assets = [], isLoading } = useAssets(publicKey);

  // const location = useLocation();

  // const pathname = location ? location.pathname.split("/")[2] : "";
  // const collapsed = Boolean(pathname);

  // // to reset search when not at FullView page
  // useEffect(() => {
  //   if (!collapsed) {
  //     setSearchTerm("");
  //   }
  // }, [collapsed]);

  return (
    <>
      {/* {isLoading && <Loader className="cockpit-loader" />}; */}
      <div className="marketplace-listing marketplace-listing-collapsed">
        <FullView assets={assets} isLoading={isLoading} />
      </div>
    </>
  );
};

export default Assets;
