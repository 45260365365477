export const SchueduleTableColName = {
  investmentRound: "Investment Round",
  investorName: "Investor Name",
  safthash: "Saft Hash",
  telegramId: "Telegram Id",
  tokenAllocation: "Token Allocation",
};

export const TokenTransferColName = {
  transferHash: "Transfer Hash",
  month: "Month",
  amount: "Amount",
};

export const HeaderText = {
  dashboard: {
    subText: "Your complete MALL Account View",
    text: "FINANCE ROOM",
    imag: "images/DashboardPage.png",
  },
  login: {
    subText: "Your complete MALL Account View",
    text: "FINANCE ROOM",
    imag: "images/DashboardPage.png",
  },
  land: {
    subText: "With all of the styling tool options available in today’s market",
    text: "LAND CENTER",
    imag: "images/Land.png",
  },
  experience: {
    subText: "With all of the styling tool options available in today’s market",
    text: "EXPERIENCE CENTRE",
    imag: "images/icon-21.png",
  },
  experienceWoWallet: {
    subText: "With all of the styling tool options available in today’s market",
    text: "EXPERIENCE CENTRE",
    imag: "images/icon-21.png",
  },
  mystores: {
    subText: "With all of the styling tool options available in today’s market",
    text: "LAND CENTER",
    imag: "images/icon-21.png",
  },
  mystoreupdate: {
    subText: "With all of the styling tool options available in today’s market",
    text: "My Stores update",
    imag: "images/icon-21.png",
  },
  inventory: {
    subText: "With all of the styling tool options available in today’s market",
    text: "Inventory",
    imag: "images/icon-21.png",
  },
  metamallwallet: {
    subText: "With all of the styling tool options available in today’s market",
    text: "GAME FINANCE",
    imag: "images/icon-21.png",
  },
  admin: {
    subText: "Enables you to transfer the available lands",
    text: "ADMIN CENTRE",
    imag: "images/icon-21.png",
  },
};

export const StakingColName = {
  AMOUNT_PER_SCHEDULE: "REDEMPTION AMOUNT",
  date: "DUE DATE",
};

export const MarketPlaceUrl = "https://nft.metamalls.io/#/";

export const NETWORK_URL =
  process.env.REACT_APP_ONCHAIN_ENDPOINT ||
  "https://solana-api.syndica.io/access-token/cOzUyVAdbFjWBgGDJEmuRkhzZy1vdlF7thGKu5XXkynB1v2v8aFxrh1j5rCPQdze/rpc/"; //"https://api.mainnet-beta.solana.com/";

export const TOKEN_ID = "5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i";

export const apiEnvironment = { dev: "dev", prod: "nft" };

export const walletEnvironment = { dev: "devnet", prod: "mainnet-beta" };

export const SocialMediaAppId = {
  FACEBOOK: "1963323790534486",
  GOOGLE:
  "55968868965-t1f3438b9msel1cu6pq88i8sgvro6unj.apps.googleusercontent.com",
  //"34217282790-855lf2hdqamjrl2p1hdbst99tc678fnq.apps.googleusercontent.com",
    //"34217282790-855lf2hdqamjrl2p1hdbst99tc678fnq.pm",
  // "34217282790-855lf2hdqamjrl2p1hdbst99tc678fnq.apps.googleusercontent.com",
};

export const ADMIN_WALLET_IDS = [
  "79wS1KcTi7qdSdnEcAhYZnwYxyTmM1izyDMPmke5Gxpa",
  //  "ALSe5EzWF6sV1a35egP6kmLfr8CCBFTmm2ZGp3viz3ec",
];
 
export const NFT_GENERATION_WALLET =
  "3ptSYEC5HGG5bbNgQk5fReuZZXmhK3nhpWHLKZcCj2Wp";

export const ADMIN_GAME_WALLET = "79wS1KcTi7qdSdnEcAhYZnwYxyTmM1izyDMPmke5Gxpa";

export const MOBILE_SCREEN_SIZE = 425;
