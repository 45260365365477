import { Spinner } from "react-bootstrap";

interface LoaderType {
  className: string;
}

const Loader = ({ className }: LoaderType) => {
  return (
    <div className={`${className}`}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Loader;
