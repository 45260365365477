import { string } from "../Constant/String";

interface NoDataFound {
  textMessage?: string;
}

const NoDataFound = ({ textMessage = string.nodata }: NoDataFound) => {
  return (
    <div className={"no-data-found-wrapper"}>
      <div className={"text"}>{textMessage}</div>
    </div>
  );
};

export default NoDataFound;