import {
  Transaction,
  PublicKey,
  LAMPORTS_PER_SOL,
  Connection,
} from "@solana/web3.js";

import { TOKEN_ID, NETWORK_URL } from "../Constant/Constant";

export const fetchBalance = async (publicKey: string | undefined) => {
  let walletBalance = 0;
  try {
    const owner = publicKey;
    const MINT = new PublicKey(TOKEN_ID);
    const request_data = {
      jsonrpc: "2.0",
      id: 1,
      method: "getTokenAccountsByOwner",
      params: [owner, { mint: MINT }, { encoding: "jsonParsed" }],
    };

    const transaction_details: any = await fetch(NETWORK_URL, {
      method: "POST",
      body: JSON.stringify(request_data),
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());

    if (
      transaction_details &&
      transaction_details.result &&
      transaction_details.result &&
      transaction_details.result.value[0] &&
      transaction_details.result.value[0].account &&
      transaction_details.result.value[0].account.data.parsed &&
      transaction_details.result.value[0].account.data.parsed.info &&
      transaction_details.result.value[0].account.data.parsed.info
        .tokenAmount &&
      transaction_details.result.value[0].account.data.parsed.info.tokenAmount
        .uiAmount
    ) {
      walletBalance =
        transaction_details.result.value[0].account.data.parsed.info.tokenAmount
          .uiAmount;
    }
  } catch (e) {
    console.log(e);
  }
  return walletBalance;
};
