import { Button } from "react-bootstrap";
import './PrimaryButton.scss';

interface PrimaryButtonType {
    name: string;
    className: string;
    onClick?: ()=>void;
}

const PrimaryButton = (props:any ) => {
    return(
        <Button {...props}>{props.name}</Button>
    )
}

export default PrimaryButton;