import "../style/App.css";
import "../yeah.css";
import "../yeah.css";
import Layout from "./Layout";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASEURL } from "../Constants";
import PrimaryButton from "./PrimaryButton/PrimaryButton";
import NoDataFound from "./NoDataFound";
import ErrorComponent from "./ErrorComponent";

export interface NFTData {
  description: string;
  image: string;
  price: string;
  status: string;
  wallet_id: string;
  type: string;
  attributes: Array<string>;
  id: number;
}

export interface ConvertData {
  metamall: { usd: number };
}

let introVisible = false;

function Home() {
  const navigate = useNavigate();
  const introRef = useRef();
  const [scrolled, setScrolled] = useState(false);
  const [nftDataList, setNftDataList] = useState<Array<NFTData>>([]);
  const [error, setError] = useState(false);
  const [metaToUSD, setMetaToUSD] = useState<ConvertData>();
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    try {
      fetch(
        `https://api.coingecko.com/api/v3/simple/price?ids=metamall&vs_currencies=USD`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log({ data });
          setMetaToUSD(data);
        });

      fetch(`${BASEURL}/getNftMarketplaceList`)
        .then((response) => response.json())
        .then((data) => {
          console.log({ data });
          setNftDataList(data);
        });
    } catch (err) {
      setError(true);
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  const handleScroll = (e) => {
    const {
      srcElement: { scrollTop },
    } = e;
    if (scrollTop > 100) {
      setScrolled(true);
    }
    if (scrollTop === 0) {
      setScrolled(false);
    }
  };

  useEffect(() => {
    const marketplace = document.getElementById("marketplace");
    marketplace?.addEventListener("scroll", handleScroll);
    return () => {
      marketplace?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout scrolled={scrolled}>
      <section data-scroll data-scroll-speed="-10">
        {/*@ts-ignore*/}
        <div ref={introRef}>
          <div className="video parallax">
            <div className="overlay top"></div>

            <div className="center top-center">
              <div className="top-center">
                <img src={require("../images/mainlogo.png")} alt="" />
                <p>
                  NFT LAND <span>Marketplace</span>
                </p>
              </div>
            </div>
            <img
              src={require("../imgg/marketplace-bg.png")}
              alt=""
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </section>
      <div className="yeah">
        <div className="left-line liner">
          {/* <div className="block left-block"></div> */}
        </div>
        <div className="content-container">
          <section className="tabs-section">
            {/* <div className="centered">
              <div className="tab">
                <button className="tablinks active" onClick="openTab(event, &#39;Items&#39;)">Items</button>
							  <button className="tablinks" onClick="openTab(event, &#39;Activity&#39;)">Activity</button>
              </div>
            </div> */}

            <div id="Items" className="tabcontent">
              {nftDataList.map((nftData) => {
                return (
                  <div className="nft-list">
                    <div className="dudes">
                      <div className="nft-img">
                        <img src={nftData.image} alt="" />
                      </div>
                      <div className="nft-details">
                        <h1>{nftData.type}</h1>
                        <ul className="land-attribute">
                          {nftData.attributes.map((attribute) => {
                            return <li>{attribute}</li>;
                          })}
                        </ul>
                        <p>{nftData.description}</p>
                        <div className="dudes">
                          <div className="leftDiv no-mobile">
                            <p>
                              {metaToUSD &&
                              metaToUSD.metamall &&
                              metaToUSD.metamall.usd ? (
                                <>
                                  <img
                                    src={require("../imgg/m-icon.png")}
                                    alt=""
                                    height="20"
                                  />{" "}
                                  {(
                                    Number(nftData.price) /
                                    metaToUSD.metamall.usd
                                  ).toFixed(3)}{" "}
                                  <b>(USD {nftData.price})</b>
                                </>
                              ) : (
                                <></>
                              )}
                            </p>
                            <p>
                              {metaToUSD &&
                              metaToUSD.metamall &&
                              metaToUSD.metamall.usd ? (
                                <>
                                  <img
                                    src={require("../imgg/USDT-icon.png")}
                                    alt=""
                                    height="20"
                                  />{" "}
                                  {nftData.price}{" "}
                                </>
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                          <div className="leftDiv mobile">
                            <p>
                              <span>
                                {metaToUSD &&
                                metaToUSD.metamall &&
                                metaToUSD.metamall.usd ? (
                                  <>
                                    <img
                                      src={require("../imgg/m-icon.png")}
                                      alt=""
                                      height="30"
                                    />{" "}
                                    {(
                                      Number(nftData.price) /
                                      metaToUSD.metamall.usd
                                    ).toFixed(3)}{" "}
                                    <b>(USD {nftData.price})</b>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </span>{" "}
                            </p>
                          </div>

                          <div className="rightDiv">
                            {/* <button
                              type="button"
                              className="buy-land"
                              onClick={() =>
                                navigate(`/landsale/nft-details/${nftData.id}`)
                              }
                            >
                              BUY LAND
                            </button> */}
                            <PrimaryButton
                              name="buy land"
                              className="buy-land-button"
                              onClick={() =>
                                navigate(`/landsale/nft-details/${nftData.id}`)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {nftDataList.length === 0 && <NoDataFound />}
              {error && (
                <ErrorComponent
                  text={"Internal Server Error"}
                  image={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
                />
              )}
            </div>

            <div id="Activity" className="tabcontent"></div>
          </section>
        </div>
        <div className="right-line liner">
          {/* <div className="block right-block"></div> */}
        </div>
      </div>{" "}
    </Layout>
  );
}

export default Home;
