import { useNavigate, useParams } from "react-router-dom";
import "../../StakingStyle/App.css";
import "../../StakingStyle/yeah.css";
import "../../StakingStyle/styles.css";
import WalletClaim from "../../components/WalletClaim";
import StakingClaimView from "../../components/StakingClaimView";
import Layout from "../../components/Layout";
import { useWallet } from "@solana/wallet-adapter-react";
import LandingPage from "../LandingPage";
import { useEffect } from "react";

function StakingClaim() {
  const { amount } = useParams<{ amount: string }>();
  const { currentBalance } = useParams<{ currentBalance: string }>();
  const navigate = useNavigate();
  const { connected } = useWallet();

  useEffect(() => {
    document.documentElement.style.setProperty('--base','auto');
  }, [])

  return (
    <Layout>
      <div className="yeah" id="content-stacking">
        <div className="left-line liner">
          <div className="block left-block"></div>
        </div>
        <div className="yeah-overlay1"></div>
        <div className="content-container">
          <section className="staking-section" >
            <div className="checkout-content">
              <div className="dudes">
                <button
                  type="button"
                  className="backbtn"
                  onClick={() => navigate("/staking")}
                >
                  <img src={require("../../imgg/backbtn.png")} alt="" />
                </button>
                <div className="checkout-head">
                  <p>
                    TOKEN <span>CLAIMING</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="stakingbox-wrapper">
              <div className="" id="token-claim">
                <div id="staking-claim-wrapper">
                  <div className="container">
                    <StakingClaimView />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="right-line liner">
          <div className="block right-block"></div>
        </div>
      </div>
      {!connected && <LandingPage />}
    </Layout>
  );
}

export default StakingClaim;
