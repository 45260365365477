import React from "react";

import "./Table.scss";
import Title from "../Title";
import BootstrapTable from "react-bootstrap-table-next";
const Tag = React.lazy(() => import("../Tag"));

interface TableComponentType {
  className?: string;
  showTag?: boolean;
  columnsName: Array<any>;
  data: Array<any>;
}

// interface Product {

// }

const TableComponent = ({
  className,
  showTag,
  columnsName,
  data,
}: TableComponentType) => {
  const columns = [
    {
      dataField: "id",
      text: "Product ID",
    },
    {
      dataField: "name",
      text: "Product Name",
    },
    {
      dataField: "price",
      text: "Product Price",
    },
  ];

  const columns2 = [
    {
      dataField: "id",
      text: "Product ID",
      render: true,
      headerTitle: true,
    },
    {
      dataField: "name",
      text: "Product Name",
    },
    {
      dataField: "price",
      text: "Product Price",
      formatter: (cell: any, row: any, rowIndex: any, extraData: any) => (
        <div>
          <img className="mal-icon" src="images/SmallLogo.png" alt="" />
        </div>
      ),
    },
  ];

  const columns3 = [
    {
      dataField: "id",
      text: "Product ID",
      render: true,
      headerTitle: true,
    },
    {
      dataField: "name",
      text: "Product Name",
    },
    {
      dataField: "price",
      text: "Product Price",
      formatter: (cell: any, row: any, rowIndex: any, extraData: any) => (
        <Tag tag={"tag"} />
      ),
    },
  ];

  //@ts-ignore
  const products = [
    { id: 1, name: "name", price: 100 },
    { id: 2, name: "name", price: 100 },
    { id: 3, name: "name", price: 100 },
    { id: 4, name: "name", price: 100 },
    { id: 5, name: "name", price: 100 },
  ];

  const tableRowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      if (row.transferHash !== undefined) {
        //@ts-ignore
        window
          .open(`https://solscan.io/tx/${row.transferHash}`, "_blank")
          .focus();
      }
    },
  };

  return (
    <div className={`table-wrapper side-border ${className}`}>
      {/*@ts-ignore*/}
      <BootstrapTable
        keyField="id"
        data={data}
        columns={columnsName}
        bordered={false}
        rowEvents={tableRowEvents}
      />
    </div>
  );
};

export default TableComponent;
