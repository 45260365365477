import React from "react";
import { Link } from "react-router-dom";

import LinesLoader from "../LinesLoader";
import Button from "../Button";

import "./index.scss";

interface IProps {
  isFundTransferInProgress?: boolean;
  isGenerateFeeTransferInProgress?: boolean;
  isNFTTransferInProgress?: boolean;
  isNFTCreationInProgress?: boolean;
  buyFlowSuccess?: boolean;
  sellFlowSuccess?: boolean;
  endSaleSuccess?: boolean;
  createFlowSuccess?: boolean;
  redirect?: string;
}

const text = {
  sell: "Your order is being processed, it may take a few minutes to complete the transaction depending upon the performance of the Solana network. In case of any error, kindly reach out to us at",
  buy: "Your order is being processed, it may take a few minutes to complete the transaction depending upon the performance of the Solana network. In case the NFT is not reflected in your wallet within the next 10 minutes, kindly reach out to us at",
  create:
    "Your NFT has been created. In case the NFT is not reflected in your wallet within the next 10 minutes, kindly reach out to us at ",
};

const FullscreenLoading = ({
  isFundTransferInProgress,
  isGenerateFeeTransferInProgress,
  isNFTTransferInProgress,
  isNFTCreationInProgress,
  buyFlowSuccess,
  sellFlowSuccess,
  endSaleSuccess,
  createFlowSuccess,
  redirect = "/mplace/all",
}: IProps) => {
  if (
    !isFundTransferInProgress &&
    !isGenerateFeeTransferInProgress &&
    !isNFTTransferInProgress &&
    !isNFTCreationInProgress &&
    !buyFlowSuccess &&
    !endSaleSuccess &&
    !sellFlowSuccess &&
    !createFlowSuccess
  )
    return null;
  return (
    <div className="full-screen-loading">
      {isFundTransferInProgress ? (
        <>
          <LinesLoader />
          <div className="spinner-text">
            Transferring funds to the seller’s wallet
          </div>
          <div className="spinner-sub">
            Please do not close or refresh the browser. In case funds are
            deducted from your wallet and this page is stuck for more than 5
            minutes, kindly reach out to us at <b>staking@metamalls.io</b>
          </div>
        </>
      ) : null}
      {isGenerateFeeTransferInProgress ? (
        <>
          <LinesLoader />
          <div className="spinner-text">
            NFT Generation fee transfer in progress
          </div>
          <div className="spinner-sub">
            Please do not close or refresh the browser. In case funds are
            deducted from your wallet and this page is stuck for more than 5
            minutes, kindly reach out to us at <b>staking@metamalls.io</b>
          </div>
        </>
      ) : null}
      {isNFTTransferInProgress ? (
        <>
          <LinesLoader />
          <div className="spinner-text">Your NFT is being transferred</div>
          <div className="spinner-sub">The process may take a few minutes</div>
        </>
      ) : null}
      {isNFTCreationInProgress ? (
        <>
          <LinesLoader />
          <div className="spinner-text">Your NFT is being created</div>
          <div className="spinner-sub">The process may take a few minutes</div>
        </>
      ) : null}
      {buyFlowSuccess || sellFlowSuccess || createFlowSuccess ? (
        <>
          <div className="spinner-text">Congratulations!</div>
          <div className="spinner-sub">
            {buyFlowSuccess
              ? text.buy
              : createFlowSuccess
              ? text.create
              : text.sell}
            <b>staking@metamalls.io</b>
          </div>
          {createFlowSuccess && (
            <div className="spinner-sub">
              You can now list your NFT.{" "}
              <a href="assets" style={{ color: "white" }}>
                Click here
              </a>{" "}
              to proceed.
            </div>
          )}
          <Link to={redirect}>
            <Button className="full-screen-loading-btn" type="primary">
              Back to Listings
            </Button>
          </Link>
        </>
      ) : null}
      {endSaleSuccess ? (
        <>
          <div className="spinner-text">Listing removed successfully!</div>
          {/* <div className="spinner-sub">
            {buyFlowSuccess ? text.buy : text.sell}
            <b>staking@metamalls.io</b>
          </div> */}
          <Link to={redirect}>
            <Button className="full-screen-loading-btn" type="primary">
              Back to Listings
            </Button>
          </Link>
        </>
      ) : null}
    </div>
  );
};

export default FullscreenLoading;
