import Select from "react-select";
import "./DropDown.scss";

export interface ddlProps {
  title?: string;
  handleChange: any;
  options: Array<options>;
  selected?: options;
  isClearable?: boolean;
}

export interface options {
  value: string;
  label: string;
}

const DropDownReactSelect = (props: ddlProps) => {
  //   const options = [
  //     { value: "chocolate", label: "Chocolate" },
  //     { value: "strawberry", label: "Strawberry" },
  //     { value: "vanilla", label: "Vanilla" },
  //   ];

  const colourStyles = {
    control: (base, state) => ({
      ...base,
      padding: "5px",
      color: "#ffffff",
      background: "rgba(255, 255, 255, 0.02)",
      borderRadius: "6px",
      //   borderColor: state.isFocused ? "yellow" : "green",
      boxShadow: state.isFocused ? null : null,
      border: "0.01em solid rgba(255, 255, 255, 0.25)",
      "&:hover": {
        // borderColor: state.isFocused ? "red" : "blue",
      },
    }),
    menuList: (styles) => ({
      ...styles,
      //   backgroundColor: "#1C182E",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#ec9ce32b" : isSelected ? "#ec9ce32b" : undefined,
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
      backgroundColor: "#1C182E",
      color: "#ffffff",
      border: "0.01em solid rgba(255, 255, 255, 0.2)",
    }),
  };
  return (
    <div>
      {props.title !== undefined && (
        <div className="ddlHeading">
          <span>{props.title}</span>
        </div>
      )}

      <Select
        onChange={props.handleChange}
        className="basic-multi-select"
        classNamePrefix="select"
        styles={colourStyles}
        options={props.options}
        isClearable={props.isClearable}
        value={props.selected}
      />
    </div>
  );
};

export default DropDownReactSelect;
