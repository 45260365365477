import React from "react";
import { Button } from "react-bootstrap";
import "./ProductCard.scss";

interface Productcard {
  name?: String;
  image?: string;
  attributes?: Array<any>;
  index: number;
  seller_fee_basis_points?: String;
  NFTClicked?: Function;
  showButton: boolean;
  leaseClicked?: () => void;
  sellClicked?: (index: number) => any;
  nftClicked?: (index: number) => any;
  className?: string;
}

function Productcard({
  name,
  index,
  image,
  seller_fee_basis_points,
  attributes,
  showButton = false,
  leaseClicked,
  sellClicked,
  nftClicked,
  className,
}: Productcard) {
  return (
    <div className={`productCard productCard-animi ${className} `}>
      {/*//@ts-ignore*/}
      <div className={"nft-wrapper"} onClick={() => nftClicked(index)}>
        <div className={"image-wrapper"}>
          <img alt="" className={"image4"} src={image} />
        </div>
        <div className={"details"}>
          <div className={"namePrice"}>
            <span className={"cube"}>{name}</span>
          </div>
          {!showButton && (
            <div className={"click-text"}>
              <span className={"click btn-primary"}>
                {"Click to View Details"}
              </span>
            </div>
          )}
        </div>
      </div>
      {showButton && (
        <></>
        // <div className={"button-wrapper"}>
        //   {/*//@ts-ignore*/}
        //   <Button
        //     className={"sell-button  card-button"}
        //     onClick={() => sellClicked(index)}
        //   >
        //     SELL
        //   </Button>
        //   <Button onClick={leaseClicked} className={"card-button"}>
        //     LEASE
        //   </Button>
        // </div>
      )}
    </div>
  );
}

export default Productcard;

interface ItemTagType {
  name: String;
}
