import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../style/App.css";
import Header from "../../components/header/Header";
import { BASEURL, NETWORK_URL, TOKEN_ID } from "../../Constants";
import { getStakingPoolImage } from "../../FEUtils";
import { ConvertData, NFTData } from "../../components/Home";
import "../../style/StakingDetails.css";
import "../../yeah.css";
import Wallet from "../../components/Wallet";
import BuyNFT from "../../components/BuyNFT";
import Layout from "../../components/Layout";
import { useWallet } from "@solana/wallet-adapter-react";
import LandingPage from "../LandingPage";

export interface StakingPoolData {
  name: string;
  maturity_period: string;
  apy: string;
  max_reward_value: string;
  total_locked_tokens: string;
  total_value_locked: string;
  token_price: string;
  status: string;
  id: number;
  account_id: string;
  wallet_id: string;
}

export interface NFTDetailData {
  row_data: NFTData;
  nft_total_count: { count: string };
}

function NFTDetails() {
  const { connected } = useWallet();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [nftDetail, setNftDetail] = useState<NFTDetailData>();
  const [totalLockedTokens, setTotalLockedTokens] = useState<number>(0);
  const [metaToUSD, setMetaToUSD] = useState<ConvertData>();
  const [tokenType, setTokenType] = useState("mall");

  useEffect(() => {
    async function fetchData() {
      fetch(
        `https://api.coingecko.com/api/v3/simple/price?ids=metamall&vs_currencies=USD`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log({ data });
          setMetaToUSD(data);
        });

      const nftDetailResponse: any = await fetch(
        `${BASEURL}/getNftMarketplaceDetails/${id}`
      ).then((response) => response.json());
      console.log({ nftDetailResponse: nftDetailResponse });
      setNftDetail(nftDetailResponse);
      const request_data = {
        jsonrpc: "2.0",
        id: 1,
        method: "getTokenAccountsByOwner",
        params: [
          nftDetailResponse.wallet_id,
          { mint: TOKEN_ID },
          { encoding: "jsonParsed" },
        ],
      };

      console.log(" --- request data ----", { request_data });
      const transaction_details: any = await fetch(NETWORK_URL, {
        method: "POST",
        body: JSON.stringify(request_data),
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());
      console.log(transaction_details);
      if (
        transaction_details &&
        transaction_details.result &&
        transaction_details.result.value
      ) {
        const values: Array<any> = transaction_details.result.value;
        const stakePoolAccount = values.find(
          (v) => v.pubkey == nftDetailResponse.account_id
        );
        if (
          stakePoolAccount &&
          stakePoolAccount.account &&
          stakePoolAccount.account.data
        ) {
          const lockedAmount =
            stakePoolAccount.account.data.parsed.info.tokenAmount.uiAmount;
          console.log(lockedAmount);
          setTotalLockedTokens(lockedAmount);
        }
      }
    }
    fetchData();
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  const tokenTypeChange = (type) => {
    console.log('type', type);
    setTokenType(type)
  } 

  useEffect(() => {
    document.documentElement.style.setProperty('--base','auto');
  }, [])

  return (
    <Layout>
      <div className="tokenclaim-body checkout-body">
        {/* <div className="container"> */}
        {/* <Header></Header> */}

        <div className="yeah" id="content-stacking">
          <div className="left-line liner">
            <div className="block left-block"></div>
          </div>
          <div className="yeah-overlay1"></div>
          <div className="content-container">
            <section className="checkout-section">
              <div className="checkout-content">
                <div className="dudes">
                  <button
                    type="button"
                    className="backbtn"
                    onClick={() => navigate("/landsale")}
                  >
                    <img src={require("../../imgg/backbtn.png")} alt="" />
                  </button>
                  <div className="checkout-head">
                    <p>
                      NFT <span>CHECKOUT</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="checkout-box">
                <div className="dudes">
                  <div className="checkout-img line">
                    <img
                      className="checkout-mainimg"
                      src={nftDetail?.row_data.image}
                      alt=""
                    />
                    <div className="checkout-details">
                      <h1>{nftDetail?.row_data.type}</h1>
                      <ul>
                        {nftDetail?.row_data.attributes.map((attribute) => {
                          return <li>{attribute}</li>;
                        })}
                      </ul>
                      <p>{nftDetail?.row_data.description}</p>
                      <p className="pb0">
                        <span className="wallet-num">
                          {metaToUSD &&
                          metaToUSD.metamall &&
                          metaToUSD.metamall.usd ? (
                            <>
                              <img
                                src={require("../../imgg/m-icon.png")}
                                alt=""
                                height="20"
                              />{" "}
                              {(
                                Number(nftDetail?.row_data.price) /
                                metaToUSD.metamall.usd
                              ).toFixed(3)}{" "}
                              <b>(USD {nftDetail?.row_data.price})</b>
                            </>
                          ) : (
                            <></>
                          )}
                        </span>{" "}
                        <div className="wallet-num usdt-icon-wrapper ">
                          {metaToUSD &&
                          metaToUSD.metamall &&
                          metaToUSD.metamall.usd ? (
                            <>
                              <img
                                src={require("../../imgg/USDT-icon.png")}
                                alt=""
                                height="20"
                              />{" "}
                              {
                                nftDetail?.row_data.price}{" "}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>{" "}
                      </p>
                      <p style={{ marginTop: "1.5rem" }}>
                        <a
                          href="https://raydium.io/swap/?from=5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i&to=Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"
                          style={{
                            color: "white",
                            textDecoration: "none",
                            fontSize: "1rem",
                          }}
                        >
                          Buy{" "}
                          <img
                            src={require(tokenType === "usdt"
                            ? "../../imgg/m-icon.png"
                            : "../../imgg/m-icon.png")}
                            alt=""
                            height="15"
                          />{" "}
                          Now On Raydium
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://www.mexc.com/exchange/MALL_USDT"
                          style={{
                            color: "white",
                            textDecoration: "none",
                            fontSize: "1rem",
                          }}
                        >
                          Buy{" "}
                          <img
                            src={require(tokenType === "usdt"
                            ? "../../imgg/m-icon.png"
                            : "../../imgg/m-icon.png")}
                            alt=""
                            height="15"
                          />{" "}
                          Now On MEXC
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="checkout-details">
                    <div className="token-claim-dude">
                      <div>
                        {/* <Wallet nftDetails={nftDetail} /> */}
                        {/* @ts-ignore */}
                        <BuyNFT tokenTypeChange={tokenTypeChange} nftDetails={nftDetail} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="right-line liner">
            <div className="block right-block"></div>
          </div>
        </div>
      </div>
      {!connected && <LandingPage />}
    </Layout>
  );
}

export default NFTDetails;
