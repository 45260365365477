import "./VestingSchuedule.scss";
import { Accordion } from "react-bootstrap";
import Title from "../../components/Title";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import UseVestingSchuedule from "../../Hooks/useVestingSchuedule";
import { Suspense, useCallback } from "react";
import { SchueduleTableColName } from "../../Constant/Constant";
import TableComponent from "../../components/Table/Table";
import { useState } from "react";
import Loader from "../../components/Loader";
import { TableDataCondition } from "../../utils/CommonFunction";
import NoDataFound from "../../components/NoDataFound";
import { useWallet } from "@solana/wallet-adapter-react";
import ErrorComponent from "../../components/ErrorComponent";
const VestingSchuedule = ({ wallet_address = "" }) => {
  const { isIdle, isLoading, isError, data, error } =
    UseVestingSchuedule(wallet_address);
  //const data = [];
  const [viewAll, setViewAll] = useState<boolean>(false);
  //   if (data === undefined) {
  //     return <div>Loading...</div>;
  //   }
  const { connected } = useWallet();
  let copyData = data === undefined ? [] : [...data];

  return (
    <div className="vestingWrapper">
      <div className={"staking-table"} style={{ marginTop: "40px" }}>
        <div className={"header-container"}>
          <Title
            firstName={"VESTING "}
            remainName={"SCHEDULE"}
            imageUrl={"images/VectorIcon.png"}
          />
          <div className={"view-all-wrapper"}></div>
        </div>
        {data !== undefined && data.length !== 0 && connected ? (
          <>
            <AccComponent
              showAmount={true}
              amount={"20000000"}
              data={TableDataCondition(data, viewAll) || []}
            />
            {data && data.length > 5 && (
              <div className={"view-all-wrapper"}>
                <PrimaryButton
                  onClick={() => setViewAll(!viewAll)}
                  name={viewAll ? "HIDE" : "VIEW ALL"}
                  className={"view-all"}
                />
              </div>
            )}
          </>
        ) : (data && data.length === 0) || !connected ? (
          <NoDataFound />
        ) : (
          <Loader className={"cockpit-loader"} />
        )}
        {isError && (
          <ErrorComponent
            text={"Internal Server Error"}
            image={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
          />
        )}
      </div>
    </div>
  );
};

interface AccComponentProps {
  showAmount: boolean;
  amount: string;
  data: Array<any>;
}

interface ScheduleArray {
  investmentRound: string;
  investorName: string;
  safthash: string;
  telegramId: string;
  tokenAllocation: Float32Array;
}

interface SchueduleData {}

interface VestingScheduleData {
  month: string;
  tokenAmount: any;
  data: Array<ScheduleArray>;
}

const AccComponent = ({ showAmount, amount, data }: AccComponentProps) => {
  const { Item, Header, Body } = Accordion;
  const [activetab, setActivetab] = useState(null);
  const accClicked = (e: any) => {
    setActivetab(e);
  };

  const colName = useCallback(() => {
    const columnName = Object.keys(data[0].data[0]);
    let colArray: Array<any> = [];
    let lastCol: any = {};
    columnName.forEach((col: string) => {
      if (col === "tokenAllocation") {
        lastCol = {
          //@ts-ignore
          text: SchueduleTableColName[col.toString()],
          dataField: col,
          formatter: (cell: any, row: any, rowIndex: any, extraData: any) => (
            <div>
              <img
                className="mal-icon"
                src={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
                alt=""
              />
              {cell === null ? 0 : cell}
            </div>
          ),
        };
      } else {
        colArray.push({
          //@ts-ignore
          text: SchueduleTableColName[col.toString()],
          dataField: col,
        });
        //return { text: SchueduleTableColName[col.toString()], dataField: col };
      }
    });
    colArray.push(lastCol);
    return colArray;
  }, [data]);

  return (
    <div>
      <Accordion>
        {data.map(
          (
            { month, tokenAmount, data }: VestingScheduleData,
            index: number
          ) => {
            return (
              <Item eventKey={index.toString()}>
                <Header onClick={() => accClicked(index)}>
                  <div className={"acc-header"}>
                    <div className={"acc-title"}>{month}</div>
                    <div className={"amount"}>
                      <img
                        className={"token-icon"}
                        src={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
                        alt=""
                      />
                      <span>{parseFloat(tokenAmount).toFixed(2)}</span>
                    </div>
                  </div>
                </Header>
                <Body>
                  <div className={"border-style"}>
                    <Suspense fallback={<div>Loading...</div>}>
                      {activetab === index && (
                        <TableComponent columnsName={colName()} data={data} />
                      )}
                    </Suspense>
                  </div>
                </Body>
              </Item>
            );
          }
        )}
      </Accordion>
    </div>
  );
};

export default VestingSchuedule;
