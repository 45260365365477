import "./Header.scss";
import "../style.scss";
import { Button } from "react-bootstrap";
import { useCallback, useState, useEffect } from "react";
import { PublicKey, Transaction } from "@solana/web3.js";
import { useQuery } from "react-query";
import ReactQueryKey from "../../Constant/ReactQuery";
//import { useWallet } from '@solana/wallet-adapter-react';
import Dashboard from "../../Views/Dashboard";
import { FaAlignJustify } from "react-icons/fa";

type DisplayEncoding = "utf8" | "hex";
type PhantomEvent = "disconnect" | "connect" | "accountChanged";
type PhantomRequestMethod = "connect" | "disconnect" | "signTransaction" | "signAllTransactions" | "signMessage";

interface ConnectOpts {
  onlyIfTrusted: boolean;
}

interface PhantomProvider {
  publicKey: PublicKey | null;
  isConnected: boolean | null;
  signTransaction: (transaction: Transaction) => Promise<Transaction>;
  signAllTransactions: (transactions: Transaction[]) => Promise<Transaction[]>;
  signMessage: (message: Uint8Array | string, display?: DisplayEncoding) => Promise<any>;
  connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>;
  disconnect: () => Promise<void>;
  on: (event: PhantomEvent, handler: (args: any) => void) => void;
  request: (method: PhantomRequestMethod, params: any) => Promise<unknown>;
}

interface HeaderProps {
  title: string;
  subTitle: string;
  imgUrl: string;
  handleToggleSidebar: Function;
}

const Header = ({ title, subTitle, imgUrl, handleToggleSidebar }: HeaderProps) => {
  return (
    <div className={"header"}>
      <div className={"headerpart"}>
        <div className={"namewrapper"}>
          <div className="btn-toggleSidebar" onClick={(e) => handleToggleSidebar(e)}>
            <FaAlignJustify />
          </div>
          <img className={"dashboard-logo"} src={`${process.env.PUBLIC_URL}/${imgUrl}`} alt="dashboard logo" />
          <div className={"header-text"}>{title}</div>
        </div>
        <div className={"description"}>{subTitle}</div>
      </div>
    </div>
  );
};

export default Header;
