import "../../StakingStyle/App.css";
import "../../StakingStyle/yeah.css";
import "../../StakingStyle/styles.css";
import Layout from "../../components/Layout";
import { useEffect } from "react";
import { useState, useRef } from "react";
import StakingPool from "../../components/StakingPool";
import { useNavigate, useParams } from "react-router-dom";
import { BASEURL } from "../../Constant/StakingConstant";
import NoDataFound from "../../components/NoDataFound";
import ErrorComponent from "../../components/ErrorComponent";

export interface StakingPoolData {
  name: string;
  maturity_period: string;
  apy: string;
  max_reward_value: string;
  total_locked_tokens: string;
  total_value_locked: string;
  token_price: string;
  status: string;
  id: number;
}

let introVisible = false;

function StackingPool() {
  let a = 0;
  const navigate = useNavigate();
  const { mode } = useParams<{ mode: string }>();
  const [scrolled, setScrolled] = useState(false);
  const [error, setError] = useState(false);
  const introRef = useRef();
  const [stakingPools, setStakingPools] = useState<Array<StakingPoolData>>([]);
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    try {
      fetch(`${BASEURL}/getStakingPoolList`)
      .then((response) => response.json())
      .then((data) => {
        console.log({ data });
        setStakingPools(data);
      });
    } catch(err) {
      setError(true);
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  const handleScroll = (e) => {
    const {
      srcElement: { scrollTop },
    } = e;
    if (scrollTop > 100) {
      setScrolled(true);
    }
    if (scrollTop === 0) {
      setScrolled(false);
    }
  };

  useEffect(() => {
    const marketplace = document.getElementById("marketplace");
    marketplace?.addEventListener("scroll", handleScroll);
    return () => {
      marketplace?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--base','auto');
  }, [])

  return (
    <Layout scrolled={scrolled} >
      <section className="without" data-scroll data-scroll-speed="-10">
         {/*@ts-ignore*/}
        <div className="video parallax" ref={introRef} >
          <div className="overlay top"></div>
          <div className="center top-center">
            <div className="top-center">
              {/* <img src={require("../../images/mainlogo.png")} alt="" /> */}
            </div>
          </div>

          <div
            className="metamall-img"
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              src={require("../../imgg/marketplace-bg.png")}
              alt=""
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </section>
      <div className="yeah">
        <div className="left-line liner">
          <div className="block left-block"></div>
        </div>
        <div className="content-container">
          <section className="stakingpools">
            <div className="checkout-content">
              <div className="dudes">
                <button
                  type="button"
                  className="backbtn"
                  onClick={() => navigate('/staking')}
                >
                  <img src={require("../../imgg/backbtn.png")} alt="" />
                </button>
                <div className="checkout-head">
                  <p>
                    TOKEN <span>STAKING</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="token-stakingbox">
              <div className="dudes">
                {stakingPools.map((stakingPool, index) => {
                  if (stakingPool.id == -1 && mode !== "test") {
                    a = 1;
                    return;
                  }
                  if (index % 2 == a) {
                    return (
                      <>
                        <StakingPool pool={stakingPool} />
                        <div></div>
                      </>
                    );
                  } else {
                    return <StakingPool pool={stakingPool} />;
                  }
                })}
                {stakingPools.length === 0 && <NoDataFound/>}
              {error && <ErrorComponent text={'Internal Server Error'} image={`${process.env.PUBLIC_URL}/images/TokenIcon.png`} />}
              </div>
            </div>
          </section>
        </div>
        <div className="right-line liner">
          <div className="block right-block"></div>
        </div>
      </div>
    </Layout>
  );
}

export default StackingPool;
