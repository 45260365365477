import React, { useState, useEffect, useCallback } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import TitleInventory from "./TitleInventory";
import "./Inventory.scss";
import { INVENTORY_GAME, INVENTORY_WEB } from "./constant";
import { Modal } from "react-bootstrap";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import InputBox from "../../components/InputBox";
import {
  FaRegBuilding,
  FaEdit,
  FaUpload,
  FaEye,
  FaWindowClose,
  FaTimes,
  FaTrashAlt,
} from "react-icons/fa";
import { MdViewInAr } from "react-icons/md";
import { notification } from "antd";

import { ApiUrl, BASE_URL } from "../../Api/ApiUrl";
import { useQuery } from "react-query";
import ReactQueryKey from "../../Constant/ReactQuery";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Navigate, useNavigate } from "react-router-dom";
import NotFound from "../../components/NotFound";
import useAccessTocken from "../../Hooks/useAccessTocken";
import ReactThreeFbxViewer from "react-three-fbx-for-pyt";
import DropDownReactSelect from "../../components/DropDown/DropDownReactSelect";
// @ts-ignore
import CloseButton from "../../images/close.svg";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";

let fbxUrl = require("./SM_Iphone_X_Test.fbx");
// let fbxUrl = require("./space-ranger.fbx");
//let fbxUrl = "https://uploads-area.s3.amazonaws.com/linkeditems/2a70abb7-4349-4dbe-98c8-61c444a8e848.fbx";

export interface InventoryLinkItem {
  inventoryLinkedItems: Array<InventoryLinkItems>;
}

export interface InventoryLinkItems {
  threeDModelLink: string;
  name: string;
  imgLink: string;
  marketplaceLink: string;
  id: string;
  aspectRatio: options;
  orientation: options;
}
export interface options {
  value: string;
  label: string;
}

const InventoryItems = () => {
  const access_token = useAccessTocken();
  const AccessToken = useQuery<any>(ReactQueryKey.SOCIAL_MEDIA_LOGIN, {
    enabled: false,
  });
  // const access_token = localStorage.getItem("access_token");
  const navigation = useNavigate();
  const modelClickType = {
    CREATE: "Create",
    UPDATE: "Update",
  };

  const ddlOptionAspectRatio = [
    { value: "16:9", label: "16:9" },
    { value: "30x40", label: "30x40" },
    { value: "40x50", label: "40x50" },
    { value: "50x70", label: "50x70" },
    { value: "60x90", label: "60x90" },
  ];

  const ddlOptionOrientation = [
    { value: "O (Horizontal)", label: "O (Horizontal)" },
    { value: " V (Vertical)", label: "V (Vertical)" },
  ];

  const [isShowInventoryItemCreateModel, setIsShowInventoryItemCreateModel] =
    useState({
      isShow: false,
      type: modelClickType.CREATE,
    });
  const [isValidCreateStoreReq, setIsValidCreateStoreReq] = useState(true);
  const [stateUsedDetails, setUsedDetails] = useState<InventoryLinkItems>({
    name: "",
    threeDModelLink: "",
    imgLink: "",
    marketplaceLink: "",
    id: "",
    aspectRatio: ddlOptionAspectRatio[0],
    orientation: ddlOptionOrientation[0],
  });
  const [loading, setLoading] = useState(false);
  const [isLoadingGameInventory, setIsLoadingGameInventory] = useState(false);
  const [isLoadingWebInventory, setIsLoadingWebInventory] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [lstLinkItemsWebInventory, setLstLinkItemsWebInventory] =
    useState<Array<InventoryLinkItems>>();
  const [lstLinkItemsGameInventory, setLstLinkItemsGameInventory] =
    useState<Array<InventoryLinkItems>>();

  const [image_file, setImage_file] = useState<File>();
  const handleImagePreview = (e) => {
    let image_as_files = e.target.files[0];
    setImage_file(image_as_files);
  };

  const [isShowFbxImageViewerModel, setIsShowFbxImageViewerModel] = useState({
    isShow: false,
    fbxURL: "",
  });
  const [is3DCreateClicked, setIs3DCreateClicked] = useState(false);

  let usedDetails = {
    name: "",
    threeDModelLink: "",
    imgLink: "",
    marketplaceLink: "",
    id: "",
    aspectRatio: ddlOptionAspectRatio[0],
    orientation: ddlOptionOrientation[0],
  };

  let cameraPosition = {
    x: 150,
    y: 300,
    z: 350,
  };

  function onLoad(e) {
    console.log(e);
  }

  function onError(e) {
    console.log(e);
  }

  const onCreateModel = () => {
    setIs3DCreateClicked(false);
    setIsShowInventoryItemCreateModel({
      ...isShowInventoryItemCreateModel,
      isShow: true,
    });
  };

  const onCreate3DModel = () => {
    setIs3DCreateClicked(true);
    setIsShowInventoryItemCreateModel({
      ...isShowInventoryItemCreateModel,
      isShow: true,
    });
  };

  // if (!access_token && !AccessToken.data.access_token) {
  //   navigation("/");
  // }
  const Axios = axios.create({
    baseURL: BASE_URL,
    //@ts-ignore
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });

  async function createFurnitureInventoryItems() {
    try {
      let url = ApiUrl.addLinkedItemToInventory;

      if (stateUsedDetails.id !== "") {
        url = ApiUrl.editLinkedItemToInventory;
      }

      if (image_file) {
        const { name, size } = image_file || {};
        if (!name || !size) {
          throw new Error("File is not valid");
        }
        if (name.split(".").pop() !== "fbx") {
          throw new Error("Invalid file format");
        }
        if (size > 4000000) {
          throw new Error("File size cannot be greater than 4MB");
        }
        const formData = new FormData();
        // @ts-ignore
        formData.append("file", image_file);
        formData.append("name", stateUsedDetails.name);
        formData.append("imageLink", stateUsedDetails.imgLink);
        formData.append("marketplaceLink", stateUsedDetails.marketplaceLink);
        formData.append("aspectRatio", stateUsedDetails.aspectRatio.value);
        formData.append("orientation", stateUsedDetails.orientation.value);
        if (stateUsedDetails.id) {
          formData.append("id", stateUsedDetails.id);
        }
        await Axios.post(url, formData).then((res) => {
          getLinkedItemWebInventory();
          getLinkedItemGameInventory();
          setErrorMsg("");
          resetInventoryItem();
          setImage_file(undefined);
        });
      } else {
        Axios.post(url, {
          name: stateUsedDetails.name,
          imageLink: stateUsedDetails.imgLink,
          marketplaceLink: stateUsedDetails.marketplaceLink,
          id: stateUsedDetails.id,
          aspectRatio: stateUsedDetails.aspectRatio.value,
          orientation: stateUsedDetails.orientation.value,
        }).then((res) => {
          getLinkedItemWebInventory();
          getLinkedItemGameInventory();
          setErrorMsg("");
          resetInventoryItem();
        });
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
      setLoading(false);
      setErrorMsg("");
      resetInventoryItem();
    }
  }

  const handleChange = () => {};

  const resetInventoryItem = () => {
    setUsedDetails({
      name: "",
      threeDModelLink: "",
      imgLink: "",
      marketplaceLink: "",
      id: "",
      aspectRatio: ddlOptionAspectRatio[0],
      orientation: ddlOptionOrientation[0],
    });

    setImage_file(undefined);

    setIsShowInventoryItemCreateModel({
      ...isShowInventoryItemCreateModel,
      isShow: false,
      type: modelClickType.CREATE,
    });
  };

  const confirmType = useCallback(
    (title: string, message: string, deleteLinkItem, typeId) => {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="react-confirm-alert-overlay">
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <h1>{title}</h1>
                <div>{message}</div>
                <div className="react-confirm-alert-button-group">
                  <button
                    className="proceed-button btn btn-primary"
                    onClick={async () => {
                      deleteLinkItem(typeId);
                      onClose();
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="proceed-button btn btn-primary"
                    onClick={async () => {
                      onClose();
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        ),
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    },
    []
  );

  async function getLinkedItemWebInventory() {
    setIsLoadingWebInventory(true);
    await Axios.get<InventoryLinkItem>(ApiUrl.getLinkedWebInventoryItems)
      .then((res) => {
        setIsLoadingWebInventory(false);
        // setLstLinkItemsWebInventory(res.data.inventoryLinkedItems);
        setLstLinkItemsWebInventory(
          res.data.inventoryLinkedItems.sort((a, b) =>
            a.id < b.id ? -1 : a.id > b.id ? 1 : 0
          )
        );
      })
      .catch((c) => {
        setIsLoadingWebInventory(false);
      });
  }

  async function getLinkedItemGameInventory() {
    setIsLoadingGameInventory(true);
    await Axios.get<InventoryLinkItem>(ApiUrl.getLinkedGameInventoryItems)
      .then((res) => {
        //setLstLinkItemsGameInventory(res.data.inventoryLinkedItems);
        console.log(lstLinkItemsGameInventory);
        setIsLoadingGameInventory(false);
        setLstLinkItemsGameInventory(
          res.data.inventoryLinkedItems.sort((a, b) =>
            a.id < b.id ? -1 : a.id > b.id ? 1 : 0
          )
        );
      })
      .catch((c) => {
        setIsLoadingGameInventory(false);
      });
  }

  useEffect(() => {
    getLinkedItemWebInventory();
    getLinkedItemGameInventory();
  }, []);

  async function moveWebToGameInventoryItems(id) {
    await Axios.post(`${ApiUrl.moveToGameInventoryLinkItem}/${id}`).then(() => {
      getLinkedItemWebInventory();
      getLinkedItemGameInventory();
    });
  }

  async function deleteLinkItem(id) {
    await Axios.delete(`${ApiUrl.deleteLinkItem}/${id}`).then(() => {
      getLinkedItemWebInventory();
      getLinkedItemGameInventory();
    });
  }

  async function moveGameToWebInventoryItems(id) {
    await Axios.post(`${ApiUrl.moveToWebInventoryLinkItem}/${id}`).then(() => {
      getLinkedItemWebInventory();
      getLinkedItemGameInventory();
    });
  }

  async function editGameInventory(item) {
    console.log("ItemItem", item);
    debugger;
    setUsedDetails({
      ...stateUsedDetails,
      name: item.name,
      threeDModelLink: item.threeDModelLink,
      imgLink: item.imageLink,
      marketplaceLink: item.marketplaceLink, 
      id: item.id,
      aspectRatio:
        ddlOptionAspectRatio.find((c) => c.value === item.aspectRatio) ||
        ddlOptionAspectRatio[0],
      orientation:
        ddlOptionOrientation.find((c) => c.value === item.orientation) ||
        ddlOptionOrientation[0],
    });

    setIsShowInventoryItemCreateModel({
      ...isShowInventoryItemCreateModel,
      isShow: true,
      type: modelClickType.UPDATE,
    });
  }

  function isValidURL(stringUrl) {
    let url;
    try {
      url = new URL(stringUrl);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  const DivInventroy = ({ Type, ButtonText }) => {
    return (
      <Card className="cardInventoryItems">
        <Card.Body className="p-0">
          <div className="row m-0">
            {Type === INVENTORY_WEB &&
              lstLinkItemsWebInventory !== undefined &&
              lstLinkItemsWebInventory.map((k, i) => {
                return (
                  <div className="col-sm-6" key={i}>
                    <DivInventroySection
                      ButtonText={ButtonText}
                      Item={k}
                      InventoryType={Type}
                    />
                  </div>
                );
              })}

            {Type === INVENTORY_GAME &&
              lstLinkItemsGameInventory !== undefined &&
              lstLinkItemsGameInventory.map((k, i) => {
                return (
                  <div className="col-sm-6" key={i}>
                    <DivInventroySection
                      ButtonText={ButtonText}
                      Item={k}
                      InventoryType={Type}
                    />
                  </div>
                );
              })}

            {Type === INVENTORY_GAME &&
              lstLinkItemsGameInventory !== undefined &&
              lstLinkItemsGameInventory.length === 0 && (
                <NotFound title="Game inventory item " />
              )}

            {Type === INVENTORY_WEB &&
              lstLinkItemsWebInventory !== undefined &&
              lstLinkItemsWebInventory.length === 0 && (
                <NotFound title="web inventory item " />
              )}

            {Type === INVENTORY_GAME && isLoadingGameInventory && (
              <Loader className={"cockpit-loader"} />
            )}

            {Type === INVENTORY_WEB && isLoadingWebInventory && (
              <Loader className={"cockpit-loader"} />
            )}
          </div>
        </Card.Body>
      </Card>
    );
  };

  const DivInventroySection = ({ ButtonText, Item, InventoryType }) => {
    return (
      <Card>
        <Card.Body className="p-0">
          <div className="productCard">
            <div className={"nft-wrapper"}>
              <div>
                <FaEdit
                  className="header-icon"
                  title="Edit Item"
                  onClick={() => {
                    editGameInventory(Item);
                  }}
                />
                <FaTrashAlt
                  className="header-icon"
                  title="Remove Item"
                  onClick={() => {
                    confirmType(
                      "Remove Item",
                      "Are you want to sure remove this item?",
                      deleteLinkItem,
                      Item.id
                    );
                  }}
                />
                {Item.threeDModelLink !== null &&
                  isValidURL(Item.threeDModelLink) && (
                    <Tooltip title="View 3D Modal">
                      <MdViewInAr
                        className="header-icon"
                        onClick={() => {
                          setIsShowFbxImageViewerModel({
                            ...isShowFbxImageViewerModel,
                            isShow: true,
                            fbxURL: Item.threeDModelLink,
                          });
                        }}
                      />
                    </Tooltip>
                  )}
              </div>
              <div className={"namePrice"}>
                <Link
                  to={`/mplace${
                    `${Item.marketplaceLink || ""}`.split("mplace")[1]
                  }`}
                >
                  <img
                    src={Item.imageLink}
                    alt={"Item.name"}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/images/CKPTLogo.png";
                    }}
                    // onClick={() => {
                    //   window.open(Item.marketplaceLink);
                    //   if (
                    //     Item.threeDModelLink !== null &&
                    //     isValidURL(Item.threeDModelLink)
                    //   ) {
                    //     setIsShowFbxImageViewerModel({
                    //       ...isShowFbxImageViewerModel,
                    //       isShow: true,
                    //       fbxURL: Item.threeDModelLink,
                    //     });
                    //   }
                    // }}
                  />
                </Link>
              </div>
              <div className={"namePrice "}>
                <span className={"cube "} title={Item.name}>
                  {Item.name}
                </span>
              </div>
              {/* <Button
                variant="primary active shadow-none m-auto"
                className={"image-wrapper"}
                onClick={async () => {
                  window.open(Item.marketplaceLink);
                }}
                size="sm"
              >
                {"Go To Marketplace"}
              </Button> */}
              {/* <div className={"image-wrapper"}>
                {Item.marketplaceLink ? (
                  <span className="cube digit" title={Item.marketplaceLink}>
                    {Item.marketplaceLink}
                  </span>
                ) : (
                  <span className="cube digit"> &nbsp;</span>
                )}
              </div> */}

              {/* <div className={"image-wrapper"}>
                {Item.aspectRatio ? (
                  <span className="cube digit" title={Item.aspectRatio}>
                    Aspect Ratio : {Item.aspectRatio}
                  </span>
                ) : (
                  <span className="cube digit"> &nbsp;</span>
                )}
              </div>

              <div className={"image-wrapper"}>
                {Item.orientation ? (
                  <span className="cube digit" title={Item.orientation}>
                    Orientation : {Item.orientation}
                  </span>
                ) : (
                  <span className="cube digit"> &nbsp;</span>
                )}
              </div> */}

              <div className={"details"}>
                <div className="inputAndActionBox">
                  <Button
                    variant="primary active shadow-none m-auto"
                    onClick={async () => {
                      if (InventoryType === INVENTORY_GAME) {
                        await moveGameToWebInventoryItems(Item.id);
                      }
                      if (InventoryType === INVENTORY_WEB) {
                        await moveWebToGameInventoryItems(Item.id);
                      }
                    }}
                    size="sm"
                  >
                    {ButtonText}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };
  // const [ddlSelect, SetDdlSelect] = useState({ value: "16:9", label: "16:9" });
  const handleDdlChange = (e) => {
    // SetDdlSelect(e);
    setUsedDetails({ ...stateUsedDetails, aspectRatio: e });
  };
  // const [ddlSelectOrientation, SetDdlSelectOrientation] = useState({
  //   value: "O (Horizontal)",
  //   label: "O (Horizontal)",
  // });
  const handleDdlChangeOrientation = (e) => {
    // SetDdlSelectOrientation(e);
    setUsedDetails({ ...stateUsedDetails, orientation: e });
  };

  return (
    <>
      <div className="experience-wrapper land-center-wrapper mystorePage inventoryPage inventoryPageItemsTab">
        <div className={"download-exe row"}>
          <div className="flex blkInventory">
            <div className="row">
              <div className="col-12">
                <PrimaryButton
                  name="Create 2D item"
                  className="primary-btn download"
                  style={{ margin: "auto 5px" }}
                  onClick={() => {
                    onCreateModel();
                  }}
                />

                <PrimaryButton
                  name="Create 3D item"
                  className="primary-btn download"
                  style={{ margin: "auto 5px" }}
                  onClick={() => {
                    onCreate3DModel();
                  }}
                />

                <PrimaryButton
                  name="List your NFT"
                  className="primary-btn download"
                  style={{ margin: "auto 5px" }}
                  onClick={() => {
                    // window.open("/mplace/assets", "_blank");
                    navigation("/mplace/assets");
                  }}
                />
              </div>
              <div className="col-xl-6 m-0 p-0">
                {
                  <TitleInventory
                    InventoryTitle="Web Inventory"
                    Type={INVENTORY_WEB}
                    IsShowCreate={true}
                    handleToggleModel={onCreateModel}
                    handleToggle3DModel={onCreate3DModel}
                  />
                }

                {
                  <DivInventroy
                    Type={INVENTORY_WEB}
                    ButtonText="Move to game inventory"
                  />
                }
              </div>
              <div className="col-xl-6 m-0 p-0">
                <span className="inventoryTitle">
                  {
                    <TitleInventory
                      InventoryTitle="Game Inventory"
                      Type={INVENTORY_GAME}
                    />
                  }
                  {
                    <DivInventroy
                      Type={INVENTORY_GAME}
                      ButtonText="Move to web inventory"
                    />
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -----  MODAL TO SELECT LAND ----- */}
      <Modal
        show={isShowInventoryItemCreateModel.isShow}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge model-landSelect modelCreateInvFurItems"
      >
        <div className="header-wrapper">
          <div className="header-title">
            <FaRegBuilding className="header-icon" />
          </div>
          <div className="header-text-wrapper">
            <div className="header-text">
              {isShowInventoryItemCreateModel.type === modelClickType.CREATE
                ? "Create"
                : "Update"}
              - Inventory Furniture Item
            </div>
            <div className="header-sub-text"></div>
          </div>
        </div>
        <div className="header-wrapper">
          <div className="header-text-wrapper" style={{ width: "100%" }}>
            <div className="row blkLandSelectionArr">
              <InputBox
                label={"Name"}
                placholder={"Name"}
                onChange={(e) => {
                  usedDetails = { ...stateUsedDetails };
                  usedDetails.name = e.target.value;
                  setUsedDetails(usedDetails);
                }}
                message="Enter name"
                type="Default"
                value={stateUsedDetails.name}
                isValid={stateUsedDetails.name.trim() === "" ? true : false}
              />

              <DropDownReactSelect
                title={"Aspect Ratio"}
                options={ddlOptionAspectRatio}
                handleChange={handleDdlChange}
                selected={stateUsedDetails.aspectRatio}
              />

              <DropDownReactSelect
                title={"Orientation"}
                options={ddlOptionOrientation}
                handleChange={handleDdlChangeOrientation}
                selected={stateUsedDetails.orientation}
              />

              <InputBox
                label={"Image URL"}
                placholder={"Image URL"}
                onChange={(e) => {
                  usedDetails = { ...stateUsedDetails };
                  usedDetails.imgLink = e.target.value;
                  setUsedDetails(usedDetails);
                }}
                message="Enter image URL"
                type="Default"
                value={stateUsedDetails.imgLink}
                isValid={
                  stateUsedDetails.imgLink.trim() === "" && !image_file
                    ? true
                    : false
                }
              />
              {is3DCreateClicked && (
                <>
                  <label style={{ fontWeight: "bold" }}>
                    Upload 3D Model file (.fbx)
                  </label>
                  <div>
                    <label
                      onChange={(e) => {
                        handleImagePreview(e);
                      }}
                    >
                      <input type="file" />
                      <FaUpload className="icon" />
                      {stateUsedDetails.threeDModelLink}
                      {image_file?.name}
                    </label>

                    {image_file && (
                      <label
                        onClick={(e) => {
                          setImage_file(undefined);
                        }}
                      >
                        <FaTimes className="icon" />
                      </label>
                    )}
                  </div>
                </>
              )}
              <InputBox
                label={"Market Place Link"}
                placholder={"Market Place Link"}
                onChange={(e) => {
                  usedDetails = { ...stateUsedDetails };
                  usedDetails.marketplaceLink = e.target.value;
                  setUsedDetails(usedDetails);
                }}
                message="Enter Market Place Link"
                type="Default"
                value={stateUsedDetails.marketplaceLink}
                isValid={
                  stateUsedDetails.marketplaceLink.trim() === "" ? true : false
                }
              />
            </div>
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name={
              isShowInventoryItemCreateModel.type === modelClickType.CREATE
                ? "Create"
                : "Update"
            }
            onClick={() => {
              createFurnitureInventoryItems();
            }}
            className="proceed-button"
            style={{ marginRight: "2rem" }}
            disabled={
              stateUsedDetails.marketplaceLink.trim() === "" ||
              (stateUsedDetails.imgLink.trim() === "" && !image_file) ||
              stateUsedDetails.name.trim() === ""
                ? true
                : false
            }
          />
          <PrimaryButton
            name={"Cancel"}
            size="md"
            onClick={() => {
              resetInventoryItem();
            }}
            className="proceed-button"
          />
        </div>
      </Modal>

      {/* -----  MODAL TO SELECT LAND ----- */}
      <Modal
        show={isShowFbxImageViewerModel.isShow}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setIsShowFbxImageViewerModel({
            ...isShowFbxImageViewerModel,
            isShow: false,
          });
        }}
        className="alert-model-recharge model-landSelect modelCreateInvFurItems alert-model-showFbxViewer"
      >
        <div className="header-wrapper ">
          <div className="header-text-wrapper" style={{ width: "100%" }}>
            <div className="row blkLandSelectionArr">
              <div className="fbx-viewer-wrapper">
                <img
                  onClick={() =>
                    setIsShowFbxImageViewerModel({
                      ...isShowFbxImageViewerModel,
                      isShow: false,
                    })
                  }
                  className="fbx-viewer-wrapper-close-icon"
                  src={CloseButton}
                  alt="close_icon"
                />
                <ReactThreeFbxViewer
                  cameraPosition={cameraPosition}
                  url={isShowFbxImageViewerModel.fbxURL}
                  onLoading={onLoad}
                  onError={onError}
                  backgroundColor="#0d0921"
                  height="600"
                  alpha={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InventoryItems;
