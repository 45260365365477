import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../StakingStyle/App.css";
import "../../StakingStyle/staking-result.css";
import "../../StakingStyle/App.css";
import "../../StakingStyle/yeah.css";
import "../../StakingStyle/styles.css";
import Layout from "../../components/Layout";
import { useEffect } from "react";

export interface stateType {
  from: { pathname: string };
}
function StakingResult() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { amount } = useParams<{ amount: string }>();
  const { currentBalance } = useParams<{ currentBalance: string }>();
  const { code, url } = state as any;

  useEffect(() => {
    document.documentElement.style.setProperty('--base','auto');
  }, [])

  return (
    <Layout>
    <div className="tokenclaim-body">
      <div className="container">
        {/* <Header /> */}
        <div className="yeah" id="content-stacking">
          <div className="left-line liner">
            <div className="block left-block"></div>
          </div>
          <div className="yeah-overlay1"></div>
          <div className="content-container">
            <section>
              <div className="checkout-content">
                <div className="dudes">
                  <button
                    type="button"
                    className="backbtn"
                    onClick={() => navigate('/staking/staking-pools')}
                  >
                    <img src={require("../../imgg/backbtn.png")} alt="" />
                  </button>
                  <div className="checkout-head">
                    <p>
                      TOKEN <span>STAKING</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="token-stakingbox">
                <div className="" id="token-claim">
                  <div className="token-claim-next-dude token-claim-last-dude">
                    <div className="container" style={{ width: "100%" }}>
                      <p className="clearfix text-center claimed-text">
                        <img
                          className="metamall-coin"
                          src={require("../../imgg/metamall-coin.png")}
                        />
                      </p>
                      <p className="clearfix text-center claimed-text">
                        YOU JUST STAKED
                        <br />
                        <span>
                          <strong>{amount} </strong>
                        </span>
                        TOKEN(S)!
                      </p>
                      {/* <p className="clearfix text-center claimed-text"> */}
                      <p className="clearfix text-center claimed-text">
                        <a
                          href={url}
                          target="_blank"
                          style={{ color: "white" }}
                        >
                          View Transaction Details
                        </a>
                        <br />
                        {/* Transaction Hash:   <span><strong>{code} </strong></span> */}
                      </p>
                      {/* <p className="clearfix text-center balance-text">
                        <span>
                          <strong>Current Balance: </strong>
                          <img
                            className="claim-last-img"
                            src={require("./imgg/m-icon.png")}
                          />
                          <span> {currentBalance} </span>Tokens
                        </span>
                      </p> */}
                      {/* <WalletDisconnectButton /> */}

                      {/* <button type="submit" className="registerbtn-reverse">
                        <img src={require("./imgg/delete.png")} /> DISCONNECT
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="right-line liner">
            <div className="block right-block"></div>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
}

export default StakingResult;
