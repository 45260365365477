interface TagProps {
  tag: string;
  className?: string;
}

const Tag = ({ tag, className = '' }: TagProps) => {
  switch (tag) {
    case "CLAIMED":
      return <span className="tag">{tag}</span>;
    case "PENDING":
      return <span className={`pending-tag ${className}`}>{tag}</span>;
    case "VERIFIED":
      return <span className={`tag ${className} `}>{tag}</span>;
  }
  return <div />;
};

export default Tag;
