import { Modal, Button } from "react-bootstrap";
import Line from "../../components/Line";
import InputBox from "../../components/InputBox";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { BASE_URL, ApiUrl } from "../../Api/ApiUrl";
import ReactQueryKey from "../../Constant/ReactQuery";
import { useQuery } from "react-query";
import axios from "axios";
import React, { useCallback, useRef, useState, useEffect } from "react";
import AlertModel from "../../components/AlertModel";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { trasferDetailsPopUpState } from "./Admin";

function TransferLand({ landType, landSerialNo, nftTokenAddress }) {
  const [cancele, setCancel] = useState(true);
  const [validationClicked, setValidationClicked] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [toAddress, setToAddress] = useState("");
  const [alertHeaderText, setAlertHeaderText] = useState("");
  const [alertSubText, setAlertSubText] = useState("");

  const AccessToken = useQuery(ReactQueryKey.SOCIAL_MEDIA_LOGIN, {
    enabled: false,
  });

  const [showTransferPopUp, setShowTransferPopUp] = useRecoilState(
    trasferDetailsPopUpState
  );

  const Axios = axios.create({
    baseURL: BASE_URL,
    //timeout: 1000,
    //@ts-ignore
    headers: { Authorization: `Bearer ${AccessToken.data.access_token}` },
  });

  const transfer = async () => {
    const res = await Axios.post(ApiUrl.transferLand, {
      nftAddress: nftTokenAddress,
      toAddress: toAddress,
    });
    if (res.data.success) {
      setAlertHeaderText("You are all set!");
      setAlertSubText("Land transfer successful.");
    } else {
      setAlertHeaderText("Transfer Failed");
      setAlertSubText("Some error occurred while transferring land.");
    }
    setshowAlert(true);
  };

  console.log("SocialMediaLogin");
  return (
    <>
      <Modal
        show={cancele}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="set-password-wrapper"
      >
        <div className="header-wrapper">
          <div>
            <img
              className={"landing-logo"}
              src={`${process.env.PUBLIC_URL}/images/lock.png`}
              alt="dashboard logo"
            />
          </div>
          <div className="header-text-wrapper">
            <div className="header-text">Transfer land</div>
            <div className="header-sub-text">
              {landType} {landSerialNo}
            </div>
          </div>
        </div>
        <Line className="" />
        <div className="password-box">
          <InputBox
            label={"Target Wallet Address"}
            placholder={"Wallet address"}
            onChange={(e) => {
              setToAddress(e.target.value);
            }}
            message=""
            type="text"
            value={toAddress}
          />
        </div>
        <div style={{ display: "flex" }}>
          <PrimaryButton
            name={"Cancel"}
            className={"set-password-button"}
            onClick={() => {
              setShowTransferPopUp(false);
            }}
          />
          <PrimaryButton
            name={"Transfer"}
            className={"set-password-button"}
            onClick={transfer}
          />
        </div>
      </Modal>
      {showAlert && (
        <AlertModel
          image={`${process.env.PUBLIC_URL}/images/SuccessImage.png`}
          headerText={alertHeaderText}
          subText={alertSubText}
        />
      )}
    </>
  );
}

export default TransferLand;
