import "./Sidebar.scss";
import { useNavigate, useLocation } from "react-router-dom";
import useConnectWallet from "../../Hooks/useConnectWallet";
import { useEffect, useState, useCallback } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { ADMIN_WALLET_IDS } from "../../Constant/Constant";
import { useQueryClient } from "react-query";
import ReactQueryKey from "../../Constant/ReactQuery";
import { FaRegTimesCircle } from "react-icons/fa";
import { useWindowSize } from "../../Hooks/useWindowSize";
import { MOBILE_SCREEN_SIZE } from "../../Constant/Constant";
import LandingPage from "../../Views/LandingPage";
import { ApiUrl, BASE_URL } from "../../Api/ApiUrl";
import axios from "axios";
import { Modal } from "react-bootstrap";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";

const SidBar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { height, width } = useWindowSize();
  const [googleUserWalletConnect, setGoogleUserWalletConnect] = useState({
    isShowLandingPage: false,
    isShowError: false,
    isUserAccessWithConnectWalletData: !localStorage.getItem("googletoken")
      ? true
      : false,
    isConnectedWallet: false,
  });
  const { publicKey, connected, disconnect } = useWallet();
  const { disconnectWallet, walletKey, isConnected, connectWallet } =
    useConnectWallet();

  const queryClient = useQueryClient();

  const mobileScreenSize = MOBILE_SCREEN_SIZE;
  useEffect(() => {
    if (publicKey === undefined || publicKey === null) {
      //@ts-ignore
      //navigate('/connect', {logoutTemp: true})
    }
  }, [publicKey]);

  const closeSidebarMenu = (e) => {
    if (width !== undefined && width <= mobileScreenSize) {
      props.handleToggleSidebar(e);
    }
  };

  async function ValidWalletConnect() {
    if (!!localStorage.getItem("googletoken")) {
      const Axios = axios.create({
        baseURL: BASE_URL,
        //timeout: 1000,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
      });
      Axios.get(ApiUrl.user).then((res) => {
        console.log("resresres", res);
        //@ts-ignore
        if (res.data.wallet_address === null) {
          Axios.post(ApiUrl.userConnectWallet, {
            walletAddress: publicKey?.toString(),
          })
            .then((c) => {
              connectWallet();
              setGoogleUserWalletConnect({
                ...googleUserWalletConnect,
                isUserAccessWithConnectWalletData: true,
                isShowLandingPage: false,
                isShowError: false,
              });
            })
            .catch(function () {
              disconnectWallet();
              disconnect();
              if (connected) {
                console.log("Connected wallet");
              }
              setGoogleUserWalletConnect({
                ...googleUserWalletConnect,
                isUserAccessWithConnectWalletData: false,
                isShowLandingPage: false,
                isShowError: true,
              });
            }); //@ts-ignore
        } else if (publicKey?.toString() == res.data.wallet_address) {
          connectWallet();
          setGoogleUserWalletConnect({
            ...googleUserWalletConnect,
            isUserAccessWithConnectWalletData: true,
            isShowLandingPage: true,
            isShowError: false,
          });
        } else {
          disconnectWallet();
          disconnect();
          setGoogleUserWalletConnect({
            ...googleUserWalletConnect,
            isUserAccessWithConnectWalletData: false,
            isShowLandingPage: false,
            isShowError: true,
          });
        }
      });
    }
  }

  useEffect(() => {
    if (
      !!localStorage.getItem("googletoken") &&
      publicKey !== undefined &&
      publicKey !== null
    ) {
      ValidWalletConnect();
    }
  }, [publicKey, connected]);

  const renderLandingPage = async () => {
    await disconnectWallet();
    await disconnect();
    console.log("isConnected renderLandingPage", connected);
    console.log("isConnected isConnected", isConnected);
    await setGoogleUserWalletConnect({
      ...googleUserWalletConnect,
      isShowLandingPage: true,
    });
  };

  return (
    <>
      {/* Sidebar  */}
      {!connected && googleUserWalletConnect.isShowLandingPage && (
        <LandingPage />
      )}
      <nav id="sidebar" className={props.isActive ? "active" : ""}>
        <div className={"portalSideNav"}>
          <>
            <div className={"group9227"}>
              <FaRegTimesCircle onClick={(e) => props.handleToggleSidebar(e)} />
              {/* <img alt="" className={"logos21"} src="images/logos21.png" /> */}
              <img
                alt=""
                className={"logos21"}
                src={`${process.env.PUBLIC_URL}/images/CKPTLogo.png`}
              />
            </div>
            {googleUserWalletConnect.isUserAccessWithConnectWalletData && (
              <>
                <div
                  onClick={(e) => {
                    navigate("/cockpit/dashboard");
                    closeSidebarMenu(e);
                    // props.handleToggleSidebar(e);
                  }}
                  className={
                    location.pathname.includes("dashboard")
                      ? "selected1"
                      : "not-selected"
                  }
                >
                  <div className={"frame9165"}>
                    <img
                      alt=""
                      className={"wallet"}
                      src={`${process.env.PUBLIC_URL}/images/Group3.png`}
                    />
                    <span className={"dashboard"}>FINANCE ROOM</span>
                  </div>
                </div>
                <div
                  className={
                    location.pathname.includes("land")
                      ? "selected1"
                      : "not-selected"
                  }
                >
                  <div
                    className={"frame9165"}
                    onClick={(e) => {
                      navigate("/cockpit/land");
                      closeSidebarMenu(e);
                      // props.handleToggleSidebar(e);
                    }}
                  >
                    <img
                      alt=""
                      className={"walletTwo"}
                      src={`${process.env.PUBLIC_URL}/images/CombinedShape.png`}
                    />
                    <span className={"dashboard"}>LAND CENTER</span>
                  </div>
                </div>
                <div
                  className={
                    location.pathname.includes("metamallwallet")
                      ? "selected1"
                      : "not-selected"
                  }
                >
                  <div
                    className={"frame9165"}
                    onClick={(e) => {
                      navigate("/cockpit/metamallwallet");
                      closeSidebarMenu(e);
                      // props.handleToggleSidebar(e);
                    }}
                  >
                    <img
                      alt=""
                      className={"walletTwo"}
                      src={`${process.env.PUBLIC_URL}/images/CombinedShape.png`}
                    />
                    <span className={"dashboard"}>GAME FINANCE</span>
                  </div>
                </div>

                {/* UNCOMMENT BELOW BLOCK TO ENABLE ADMIN IN COCKPIT */}
                {publicKey &&
                ADMIN_WALLET_IDS.includes(publicKey.toString()) ? (
                  <div
                    className={
                      location.pathname.includes("admin")
                        ? "selected1"
                        : "not-selected"
                    }
                  >
                    <div
                      className={"frame9165"}
                      onClick={(e) => {
                        navigate("/cockpit/admin");
                        closeSidebarMenu(e);
                        // props.handleToggleSidebar(e);
                      }}
                    >
                      <img
                        alt=""
                        className={"walletTwo"}
                        src={`${process.env.PUBLIC_URL}/images/Admin.png`}
                      />
                      <span className={"dashboard"}>ADMIN</span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}

            <div
              className={
                location.pathname.includes("experience")
                  ? "selected1"
                  : "not-selected"
              }
            >
              <div
                className={"frame9165"}
                onClick={(e) => {
                  if (localStorage.getItem("googletoken")) {
                    navigate("/cockpit/experienceWoWallet");
                  } else {
                    navigate("/cockpit/experience");
                  }
                  closeSidebarMenu(e);
                  // props.handleToggleSidebar(e);
                }}
              >
                <img
                  alt=""
                  className={"walletTwo"}
                  src={`${process.env.PUBLIC_URL}/images/EXPERIENCE.png`}
                />
                <span className={"dashboard"}>EXPERIENCE</span>
              </div>
            </div>

            {/* UNCOMMENT BELOW BLOCK TO ENABLE MY STORES IN COCKPIT */}
            {process.env.REACT_APP_SHOW_UNDER_DEVELOPMENT_FEATURES ===
              "undefined" ||
              (process.env.REACT_APP_SHOW_UNDER_DEVELOPMENT_FEATURES !==
                "false" && (
                <>
                  {/* <div
                    className={
                      location.pathname.includes("mystores")
                        ? "selected1"
                        : "not-selected"
                    }
                  >
                    <div
                      className={"frame9165"}
                      onClick={(e) => {
                        navigate("/cockpit/mystores");
                        closeSidebarMenu(e);
                        // props.handleToggleSidebar(e);
                      }}
                    >
                      <img
                        alt=""
                        className={"walletTwo"}
                        src={`${process.env.PUBLIC_URL}/images/EXPERIENCE.png`}
                      />
                      <span className={"dashboard"}>My Stores</span>
                    </div>
                  </div> */}
                  <div
                    className={
                      location.pathname.includes("inventory")
                        ? "selected1"
                        : "not-selected"
                    }
                  >
                    <div
                      className={"frame9165"}
                      onClick={(e) => {
                        navigate("/cockpit/inventory");
                        closeSidebarMenu(e);
                        // props.handleToggleSidebar(e);
                      }}
                    >
                      <img
                        alt=""
                        className={"walletTwo"}
                        src={`${process.env.PUBLIC_URL}/images/EXPERIENCE.png`}
                      />
                      <span className={"dashboard"}>Inventory</span>
                    </div>
                  </div>
                </>
              ))}

            <div
              className={
                location.pathname.includes("tokentransfer") ? "selected" : ""
              }
            ></div>
            <div style={{ height: "75%" }}></div>
            {localStorage.getItem("googletoken") &&
              !googleUserWalletConnect.isUserAccessWithConnectWalletData && (
                <div className={"selected1"} style={{ marginBottom: "2rem" }}>
                  <div
                    className={"frame9165"}
                    onClick={() => {
                      // window.open("/", "_blank", "noopener,noreferrer");
                      renderLandingPage();
                    }}
                  >
                    <img
                      alt=""
                      className={"walletTwo"}
                      src={`${process.env.PUBLIC_URL}/images/connectwallet.png`}
                    />
                    <span className={"dashboard"}>Connect your wallet</span>
                  </div>
                </div>
              )}

            <div
              className={"group9161"}
              onClick={() => {
                window.open("/", "_blank", "noopener,noreferrer");
              }}
            >
              <img
                alt=""
                className={"walletTwo"}
                src={`${process.env.PUBLIC_URL}/images/NewTab.png`}
              />
              <div className={"messages"}>Metamalls.io</div>
            </div>
            <div
              className={"group9161"}
              onClick={() => {
                localStorage.clear();
                queryClient.invalidateQueries();
                disconnect();
                disconnectWallet();
                navigate("/");
                window.location.reload();
              }}
            >
              <img
                alt=""
                className={"walletTwo"}
                src={`${process.env.PUBLIC_URL}/images/logout.png`}
              />
              <div className={"messages"}>Logout</div>
            </div>
          </>
        </div>
      </nav>

      {/* -----  MODAL Show error message for connect wallet ----- */}
      <Modal
        show={googleUserWalletConnect.isShowError}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge"
      >
        <div className="header-wrapper">
          <div
            className="header-text-wrapper"
            style={{ width: "100%", marginLeft: 0 }}
          >
            <div style={{ textAlign: "center" }}>
              The wallet you are trying to connect is not the one that is
              associated with your account. Either try the correct wallet or
              logout and login using the wallet
            </div>
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name={"Ok"}
            onClick={() => {
              setGoogleUserWalletConnect({
                ...googleUserWalletConnect,
                isShowError: false,
              });
            }}
            className="proceed-button"
          />
        </div>
      </Modal>
    </>
  );
};

export default SidBar;
