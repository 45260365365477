import Loader from "../../components/Loader";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import Title from "../../components/Title";
import useGetStakingClaims from "../../Hooks/useGetStakingClaims";
import "./Staking.scss";
import TableComponent from "../../components/Table/Table";
import { useCallback } from "react";
import { StakingColName } from "../../Constant/Constant";
import { useState, useEffect } from "react";
import moment from "moment";
import Tag from "../../components/Tag";
import { TableDataCondition } from "../../utils/CommonFunction";
import NoDataFound from "../../components/NoDataFound";
import { staticApiCall } from "../../Api/ApiCall";
import { ApiUrl } from "../../Api/ApiUrl";
import { Button, Toast } from "react-bootstrap";
import ToastMessage from "../../components/TostMessage";
import { useMutation, QueryClient, useQueryClient } from "react-query";
import ReactQueryKey from "../../Constant/ReactQuery";
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import ErrorComponent from "../../components/ErrorComponent";

interface StakingData {
  destinationAddress: string;
  mintAddress: string;
  schedules: Array<any>;
  vestingAccount: string;
  vextingContractId: string;
}

const Staking = ({ wallet_address = "" }) => {
  const navigate = useNavigate();
  //@ts-ignore
  const { isIdle, isLoading, isError, data, error, refetch } =
    useGetStakingClaims(wallet_address);
  const { publicKey, connected } = useWallet();
  const [stateColumnName, setColumnName] = useState<Array<any>>([]);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [viewAll, setViewAll] = useState<boolean>(false);
  const [stakingClaimed, setStakingClaimed] = useState([]);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    showToast && hideToadet();
  }, [showToast]);

  const prm = wallet_address === "" ? publicKey?.toString() : wallet_address;

  useEffect(() => {
    if (publicKey !== null) {
      getStakingClaimed();
    }
  }, [publicKey]);

  const setClaimed = (id: string) =>
    staticApiCall(`${ApiUrl.claimToke}/${id}`, "GET");

  const queryClient = new QueryClient();

  const oldData = queryClient.getQueryData(ReactQueryKey.STAKING_CLAIMS);
  const allHeros = useQueryClient();

  const stakingMutation = useMutation(setClaimed, {
    // onMutate: async (id) => {
    //   await queryClient.cancelQueries(ReactQueryKey.STAKING_CLAIMS);
    //   const oldData = queryClient.getQueryData(ReactQueryKey.STAKING_CLAIMS);
    //   console.log('oldData', oldData);
    //   queryClient.setQueryData(ReactQueryKey.STAKING_CLAIMS, (oldData: any) => {
    //     console.log("oldData", oldData);
    //     const filterOldData = oldData.filter(
    //       ({ vextingContractId }: any) => id !== vextingContractId
    //     );
    //     console.log("filterd data", filterOldData);
    //     return filterOldData;
    //   });
    //   setShowToast(true);
    // },
    onSuccess: (result, variables, context) => {
      allHeros.setQueryData(
        ReactQueryKey.STAKING_CLAIMS,
        (oldQueryData: any) => {
          const filterData = oldQueryData.filter(
            ({ vextingContractId }: any) => variables !== vextingContractId
          );
          return filterData;
        }
      );
      setShowToast(true);
    },
  });

  const hideToadet = () => {
    setInterval(() => setShowToast(false), 2000);
  };

  const getStakingClaimed = async () => {
    const res = await staticApiCall(
      `${ApiUrl.stakingClaimed}${prm}`,
      "GET"
    );
    res.successfull && setStakingClaimed(res.successfull.rows);
  };

  const colName = useCallback(() => {
    const columnName = Object.keys(data[0]["schedules"][0]);
    let colArray: Array<any> = [];
    let lastCol: any = {};
    columnName.forEach((col: string) => {
      if (col === "AMOUNT_PER_SCHEDULE") {
        lastCol = {
          //@ts-ignore
          text: StakingColName[col.toString()],
          dataField: col,
          formatter: (cell: any, row: any, rowIndex: any, extraData: any) => (
            <div>
              <img className="mal-icon" src="images/SmallLogo.png" alt="" />
              {cell === null ? 0 : cell}
            </div>
          ),
        };
      } else {
        colArray.push({
          //@ts-ignore
          text: StakingColName[col.toString()],
          dataField: col,
        });
        //return { text: SchueduleTableColName[col.toString()], dataField: col };
      }
    });
    colArray.push(lastCol);
    return colArray;
  }, [data]);

  const createTableCol = () => {
    if (data && data.length > 0 && data[0].hasOwnProperty("schedules")) {
      const columnName = Object.keys(data[0]["schedules"][0]);
      let colArray: Array<any> = [];
      let lastCol: any = {};
      columnName.forEach((col: string) => {
        if (col === "AMOUNT_PER_SCHEDULE") {
          lastCol = {
            //@ts-ignore
            text: StakingColName[col.toString()],
            dataField: col,
            formatter: (cell: any, row: any, rowIndex: any, extraData: any) => (
              <div>
                <img
                  className="mal-icon"
                  src={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
                  alt=""
                />
                {cell === null ? 0 : parseFloat(cell).toFixed(2)}
              </div>
            ),
          };
        } else if (col === "date") {
          colArray.push({
            text: StakingColName[col.toString()],
            dataField: col,
            formatter: (cell: any, row: any, rowIndex: any, extraData: any) => (
              <div>{cell === null ? 0 : cell}</div>
            ),
          });
        } else {
          colArray.push({
            //@ts-ignore
            text: StakingColName[col.toString()],
            dataField: col,
          });
          //return { text: SchueduleTableColName[col.toString()], dataField: col };
        }
      });
      colArray.push(lastCol);
      const status = {
        datafield: "status",
        text: "STATUS",
        formatter: (cell: any, row: any, rowIndex: any, extraData: any) => (
          <>
            {row.status === "CLAIM" ? (
              <Button
                className={"btn-primary"}
                onClick={() => onClaim(row.vextingContractId)}
              >
                CLAIM
              </Button>
            ) : (
              <Tag tag={row.status} />
            )}
          </>
        ),
      };
      colArray.push(status);
      setColumnName(colArray);
    }
  };

  const onClaim = async (id: string) => {
    const res = await staticApiCall(`${ApiUrl.claimToke}/${id}`, "GET");
    refetch();
    setShowToast(true);
    //stakingMutation.mutate(id);
  };

  const createTabledata = () => {
    if (data) {
      const tableData = data
        .filter((item: any) => item.schedules[0].AMOUNT_PER_SCHEDULE > 0)
        .map((colData: Array<StakingData>) => {
          //@ts-ignore
          if (colData.hasOwnProperty("schedules")) {
            //@ts-ignore
            const row = colData["schedules"][0];
            const tableRow = { ...row };
            const now = Date.now();
            const tableDate = parseInt(row.date) * 1000;
            //@ts-ignore
            tableRow.vextingContractId = colData["vextingContractId"];
            const d = new Date(tableDate);
            tableRow.date = moment(tableDate)
              .add(1, "day")
              .format("DD-MM-YYYY");
            console.log({ now });
            console.log({ tableDate });
            const addedDate = moment(tableDate).add(1, "day").valueOf();
            if (addedDate < now) {
              // selected date is in the past
              //@ts-ignore
              //var nowMilliseconds = new Date(date).format("yyyy-mm-dd");
              tableRow.status = "CLAIM";
            } else {
              tableRow.status = "PENDING";
            }
            //tableRow.status = colData["schedules"][0];
            return tableRow;
          }
          //colData.hasOwnProperty("schedules") ? colData["schedules"][0] : []
        });
      const stakingClaimedData = stakingClaimed
        .filter(({ claimed_txn_datetime }) => claimed_txn_datetime !== null)
        .map(({ claimed_txn_datetime, claimed_amount, vextingContractId }) => ({
          date: moment(claimed_txn_datetime).utc().format("DD-MM-YYYY"),
          AMOUNT_PER_SCHEDULE: claimed_amount,
          status: "CLAIMED",
        }));
      console.log({ stakingClaimedData });
      const mergedTableData = [...tableData, ...stakingClaimedData];
      mergedTableData.sort(
        (a, b) =>
          moment(b.date, "DD-MM-YYYY").valueOf() -
          moment(a.date, "DD-MM-YYYY").valueOf()
      );
      setTableData([...tableData, ...stakingClaimedData]);
    }
  };

  useEffect(() => {
    if (data !== undefined) {
      createTableCol();
      createTabledata();
    }
  }, [data]);

  const stakingClicked = () => {
    //window.open('https://staking.metamalls.io/staking-pools', '');
    navigate("/staking/staking-pools");
  };

  //console.log('TableDataCondition(tableData, viewAll)', TableDataCondition(tableData, viewAll));

  return (
    <div className={"staking"}>
      <div className={"staking-table"}>
        <div className={"header-container"}>
          <Title
            firstName={"STAKING  "}
            remainName={"CLAIMS"}
            imageUrl={"images/Staking.png"}
            showBuyBtn={true}
            buttonText={"STAKE TOKENS"}
            buyLand={stakingClicked}
            //className={'title-wrapper'}
          />
        </div>
        {stateColumnName.length !== 0 &&
        tableData.length !== 0 &&
        data.length !== 0 &&
        connected ? (
          <>
            <TableComponent
              columnsName={stateColumnName}
              data={TableDataCondition(tableData, viewAll) || []}
              className={"side-border"}
              showTag={true}
            />
            {tableData.length !== 0 && tableData.length > 5 && (
              <div className={"view-all-wrapper"}>
                <PrimaryButton
                  onClick={() => setViewAll(!viewAll)}
                  name={viewAll ? "HIDE" : "VIEW ALL"}
                  className={"view-all"}
                />
              </div>
            )}
          </>
        ) : (data && data.length === 0) || !connected ? (
          <NoDataFound />
        ) : (
          <div>
            <Loader className={"bootstrap-loader"} />
          </div>
        )}
      </div>
      {showToast && (
        <div className={"toast-wrapper"}>
          <ToastMessage message={"Token Claimed Successfully"} />
        </div>
      )}
      {isError && (
        <ErrorComponent
          text={"Internal Server Error"}
          image={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
        />
      )}
    </div>
  );
};

export default Staking;
