import { BASE_URL, ApiUrl } from "./ApiUrl";
import axios from "axios";
import { useQuery } from "react-query";
import ReactQueryKey from "../Constant/ReactQuery";
import { useEffect } from "react";

const Axios = axios.create({
  baseURL: BASE_URL,
  //timeout: 1000,
  headers: {},
});

interface ApiCallType {
  type: String;
  url: string;
  payload?: any;
}

export const ApiCall = async (type: String, url: string, payload: any = {}) => {
  console.log("1 url", url);
  switch (type) {
    case "GET":
      try {
        const getGesponse = await Axios.get(url);
        return getGesponse.data;
      } catch (err) {
        console.log("get", err);
      }
      break;
    case "PUT":
      try {
        const putResponse = await Axios.put(url, payload);
        return putResponse.data;
      } catch (err) {
        console.log("put", err);
      }
      break;
    case "POST":
      try {
        console.log("post url", url, payload);
        const putResponse = await Axios.post(url, payload);
        return putResponse.data;
      } catch (err) {
        //@ts-ignore
        return err;
        console.log("post", err);
      }
      break;
  }
};

export const staticApiCall = async (
  url: string,
  type: string,
  payload: any = {}
) => {
  // const response = await axios.get(url);
  // console.log('api call', response);
  // return response.data;

  switch (type) {
    case "GET":
      try {
        const getGesponse = await axios.get(url);
        return getGesponse.data;
      } catch (err) {
        return err;
        console.log("get", err);
      }
      break;
    case "PUT":
      try {
        const putResponse = await axios.put(url, payload);
        return putResponse.data;
      } catch (err) {
        console.log("put", err);
      }
      break;
    case "POST":
      console.log("api call post");
      try {
        const putResponse = await axios.post(url, payload);
        return putResponse.data;
      } catch (err) {
        return err;
        console.log("post", err);
      }
      break;
  }
};
