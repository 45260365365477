// @ts-nocheck

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import Form from "react-bootstrap/Form";
import { isEqual } from "lodash";
import {
  PublicKey as SolanaPublicKey,
  Transaction,
  Connection,
  SystemProgram,
  Keypair,
  sendAndConfirmTransaction,
} from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

import { ADMIN_WALLET_IDS } from "../../../Constant/Constant";
import { NETWORK_URL } from "../../../Constant/StakingConstant";
import { getOrCreateAssociatedTokenAccount } from "../../../components/getOrCreateAssociatedTokenAccount";
import { createTransferInstruction } from "../../../components/createTransferInstructions";
import {
  useListingDetail,
  postTransferRequest,
  listItemRequest,
  useTradeHistory,
  useAssets,
  addAuditLog,
} from "../hooks/useAuctionData";
import Button from "../../../components/Button";
import InsufficientFundsModal from "../../../components/InsufficientFundsModal";
import FullscreenLoading from "../../../components/FullscreenLoading";
import TradeHistory from "./TradeHistory";
import { Input, notification } from "antd";
import InputBox from "../../../components/InputBox";
import { ApiUrl } from "../../../Api/ApiUrl";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const currencyImg = {
  mall: require("../../../imgg/malltoken.png"),
  usdt: require("../../../imgg/USDT-icon.png"),
};

const fetchBalance = async (
  publicKey: string | undefined,
  tokenAddress: string
) => {
  let walletBalance = 0;
  try {
    const owner = publicKey;
    const MINT = new SolanaPublicKey(tokenAddress);
    const request_data = {
      jsonrpc: "2.0",
      id: 1,
      method: "getTokenAccountsByOwner",
      params: [owner, { mint: MINT }, { encoding: "jsonParsed" }],
    };

    const transaction_details: any = await fetch(NETWORK_URL, {
      method: "POST",
      body: JSON.stringify(request_data),
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());

    if (
      Array.isArray(transaction_details?.result?.value) &&
      transaction_details?.result?.value[0] &&
      transaction_details?.result?.value[0]?.account?.data?.parsed?.info
        ?.tokenAmount?.uiAmount
    ) {
      return transaction_details.result.value[0].account.data.parsed.info
        .tokenAmount.uiAmount;
    }
  } catch (e) {
    console.log(e);
  }
  return walletBalance;
};

const ListingDetail = () => {
  const navigation = useNavigate();
  const [isFundTransferInProgress, setIsFundTransferInProgress] =
    useState(false);
  const [isNFTTransferInProgress, setIsNFTTransferInProgress] = useState(false);

  const [message, setMessage] = useState("");
  const [showFundIssue, setShowFundIssue] = useState(false);
  const [mallBalance, setMallBalance] = useState(0);
  const [subMessage, setSubMessage] = useState("");

  const [sellFlowSuccess, setSellFlowSuccess] = useState(false);

  const [formData, setFormData] = useState({ currency: "mall" });

  const { tokenAddress = "" } = useParams();

  /** Mainnet RPC connection */
  const mainnet_endpoint = NETWORK_URL;
  const connection = new Connection(mainnet_endpoint);

  const {
    publicKey,
    connect,
    connected,
    disconnect,
    wallet,
    select,
    signTransaction,
  } = useWallet();

  const { data: assets = [], isLoading } = useAssets(publicKey);

  const asset =
    assets.find((el) => isEqual(el.tokenAddress, tokenAddress)) || {};

  const {
    id,
    name,
    image,
    currency,
    seller_fee_basis_points,
    attributes = [],

    // asset props
    tokenIcon = require("../../../img/landsale-banner.png"),
    // tokenAddress,
    tokenName,
    tokenSymbol,
    type,
  } = asset;

  const handleBuy = async () => {
    const balance = await fetchBalance(publicKey, tokenAddress[currency]);
    setMallBalance(balance);
    // if (parseFloat(seller_fee_basis_points) <= balance) {
    //   transferToken();
    // } else {
    //   setShowFundIssue(true);
    // }
    if (formData.amount <= 0) {
      notification.error({
        message: "Amount must be a positive integer",
      });
      return;
    }
    transferNft();
  };

  const handleConnect = () => {
    wallet ? connect() : select("Phantom");
    // // alert(2);
    //  disconnect();
    // if (connected) {
    //   // @ts-ignore
    //   select("Phantom");
    // } else if (wallet) {
    //   // alert(1);
    //   connect();
    // }
  };

  useEffect(() => {
    if (wallet) {
      connect();
    }
  }, [wallet]);

  async function getTransaction(staking_Txn_Hash: string) {
    const request_data = {
      jsonrpc: "2.0",
      id: 1,
      method: "getTransaction",
      params: [staking_Txn_Hash, "json"],
    };

    const transaction_details: any = await fetch(endpoint.url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request_data),
    });

    const content = await transaction_details.json();
    return content;
  }

  async function checkTransaction(error): Promise<Boolean> {
    if (error instanceof Error) {
      const message = error.message;
      try {
        // const message =
        //   'Transaction was not confirmed in 60.00 seconds. It is unknown if it succeeded or failed. Check signature 5NW5jk6vohTteJxSbojB6DDBHT7oSpq4t6vtP1j7TvBBcj2eHC7pA2UqhLfKStz5jejshfAsYeM4JE7Nt4Y5icu7 using the Solana Explorer or CLI tools.';

        const signatureIndex = message.indexOf("Check signature ");
        const subMessage = message.substring(signatureIndex + 16);
        const trxHash = subMessage.substring(0, subMessage.indexOf(" "));

        await connection.confirmTransaction(trxHash, "max");
        await connection.getParsedTransaction(trxHash, "confirmed");

        const content = await getTransaction(trxHash);

        if (!content || !content.result || content.result === null) {
          return false;
        }
      } catch (error) {
        console.log(
          "Error in checkTransaction - Transaction hash is invalid",
          error
        );
        return false;
      }
      console.log("Transaction hash is valid.");
      return true;
    }
    return false;
  }

  const transferNft = async () => {
    setIsNFTTransferInProgress(true);
    // data !== undefined && props.setAttributeDataFun(data);
    try {
      const toPublicKey = new SolanaPublicKey(ADMIN_WALLET_IDS[0]);
    const mint = new SolanaPublicKey(
      tokenAddress //"4e6r6UCie2ZVVifRtNGVCD5ijpc3rdGAeRjLNCQgeDfx"
    );
    console.log("MINT", { mint }, toPublicKey);
    const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
      connection,
      publicKey,
      mint,
      publicKey,
      signTransaction
    );
    console.log("fromTokenAccount", fromTokenAccount);

    const toTokenAccount = await getOrCreateAssociatedTokenAccount(
      connection,
      publicKey,
      mint,
      toPublicKey,
      signTransaction
    );
      const transaction = new Transaction().add(
        createTransferInstruction(
          fromTokenAccount.address, // source
          toTokenAccount.address, // dest
          // @ts-ignore
          publicKey,
          1,
          [],
          TOKEN_PROGRAM_ID
        )
      );
      const blockHash = await connection.getRecentBlockhash();
      transaction.feePayer = await publicKey;
      transaction.recentBlockhash = await blockHash.blockhash;
      const signature = await signTransaction(transaction);
      const finalResponse = await connection.sendRawTransaction(
        signature.serialize()
      );
      console.log("finalResponse", finalResponse);
      console.log("finalResponse", { signature });
      //const signatureFormate = bs58.encode(finalResponse);

      //await connection.confirmTransaction(signatureFormate, "processed");
      let runtimes: number = 1;
      while (runtimes <= 4) {
        try {
          runtimes += 1;
          await connection.confirmTransaction(finalResponse, "max");
          await connection.getParsedTransaction(finalResponse, "confirmed");
          runtimes = 6;
        } catch (e) {
          console.log(e);
          console.log(" inside catch ");
          await new Promise((resolve) => setTimeout(resolve, 10000));
          const flag = await checkTransaction(e);
          if (flag) {
            runtimes = 6;
          }
        }
      }

      setTimeout(async () => {
        try {
          let sellAssetReq = {
            transactionHash: finalResponse,
            email: formData.email,
            auction: {
              auctionCreatorWalletAddress: publicKey,
              nftAddress: tokenAddress,
              currency: formData.currency, // mall | usdt
              remote_id: tokenAddress, // nftAddress
              name: tokenName, // tokenName
              symbol: tokenSymbol,
              type: type,
              // description: attributeData?.description
              //   ? attributeData.description
              //   : "",
              seller_fee_basis_points: formData.amount,
              image: tokenIcon, // tokenIcon
              // attributes: attributeData?.attributes
              //   ? attributeData?.attributes
              //   : [],
              // external_url: attributeData?.external_url
              //   ? attributeData.external_url
              //   : "",
              // properties: attributeData?.properties
              //   ? [attributeData.properties]
              //   : [],
            },
          };

          // update Audit log
          await addAuditLog({
            action: ApiUrl.sellAsset,
            additionalInfo: "Start => Market place sell NFT click",
            payload: sellAssetReq,
            walletAddress: publicKey,
          });

          const res = await listItemRequest(sellAssetReq);
          setIsNFTTransferInProgress(false);
          setSellFlowSuccess(true);
        } catch (err) {
          setIsNFTTransferInProgress(false);
          console.log("sellLandAuction", err);
        }
      }, 1000);
    } catch (e) {
      setIsNFTTransferInProgress(false);
      //setLoading(false);
      //setShowAlert={(alertTemp) => assignState(alertTemp)}
      console.log(e);
      // props.setShowAlert(true);
      //setErrorMsg("Blockhash has been expired, please try again!");
      throw e;
    }
  };

  const handleChange = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const assetImage = tokenIcon || require("../../../img/landsale-banner.png");

  return (
    <div className="marketplace-listing-detail">
      <div className="marketplace-listing-detail-safe-area">
        <Button
          variant="primary active shadow-none btn-icon"
          onClick={() => {
            navigation(`/mplace/assets`);
          }}
          style={{ marginBottom: "10px", padding: "20px", fontSize: "20px" }}
        >
          <FaAngleLeft />
        </Button>
        <div className="marketplace-listing-detail-card">
          <img
            className="marketplace-listing-detail-img"
            src={assetImage}
            alt="listing_image"
          />
          <div className="marketplace-listing-detail-content">
            <p className="marketplace-listing-detail-title">{tokenName}</p>
            <p className="marketplace-listing-detail-label">Amount</p>
            <Input
              type="number"
              className="marketplace-input"
              placeholder="Enter Amount"
              value={formData.amount}
              onChange={(e) => handleChange("amount", e.target.value)}
            />
            <p className="marketplace-listing-detail-label">Currency</p>
            <Form.Select
              className="marketplace-input"
              placeholder="Enter Currency"
              value={formData.currency}
              onChange={(e) => handleChange("currency", e.target.value)}
            >
              <option style={{ color: "#000" }} value="mall">
                MALL
              </option>
              <option style={{ color: "#000" }} value="usdt">
                USDT
              </option>
            </Form.Select>
            <p className="marketplace-listing-detail-label">Email</p>
            <Input
              className="marketplace-input"
              placeholder="Enter Email Address"
              value={formData.email}
              onChange={(e) => handleChange("email", e.target.value)}
            />
            <Button
              onClick={connected ? handleBuy : handleConnect}
              type="primary"
              style={{
                height: "40px",
                width: "fit-content",
                padding: "0 10px",
                marginTop: "30px",
              }}
            >
              {connected ? "Sell Now" : "Connect wallet"}
            </Button>
          </div>
        </div>
        {/* <InsufficientFundsModal
          onClose={() => setShowFundIssue(false)}
          isModalVisible={showFundIssue}
          message={`insufficient ${currency}`}
          minimumFunds={(seller_fee_basis_points - mallBalance).toFixed(2)}
          currentFunds={mallBalance}
          tokenType={currency}
        /> */}
        <FullscreenLoading
          isFundTransferInProgress={isFundTransferInProgress}
          isNFTTransferInProgress={isNFTTransferInProgress}
          sellFlowSuccess={sellFlowSuccess}
        />
      </div>
    </div>
  );
};

export default ListingDetail;
