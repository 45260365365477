import { Button } from "react-bootstrap";
import PrimaryButton from "./PrimaryButton/PrimaryButton";

interface TitleProps {
  firstName: string;
  remainName: string;
  imageUrl: string;
  showBuyBtn?: boolean;
  buyLand?: () => void;
  buttonText?: string;
  className?: string;
  buttonClassName?: string;
}

const Title = ({
  firstName,
  remainName,
  imageUrl,
  showBuyBtn = false,
  buyLand,
  buttonText,
  className,
  buttonClassName,
}: TitleProps) => {
  return (
    <div className={`header-title ${className}`} style={showBuyBtn ? { width: "99%" } : {}}>
      {imageUrl !== "" && <img className={"header-icon"} src={`${process.env.PUBLIC_URL}/${imageUrl}`} alt="" />}
      <div className="titleLabel">
        <span className={"bold"}>{firstName}</span>
        <span className={"regular"}>{` ${remainName}`}</span>
      </div>
      {showBuyBtn && (
        <PrimaryButton
          name={buttonText ? buttonText : ""}
          onClick={buyLand}
          className={`buy-button ${buttonClassName} `}
        />
      )}
    </div>
  );
};

export default Title;
