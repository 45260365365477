import React, { useState } from "react";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";
import { StoreListing } from "../storeTypes";

interface IProps {
  lstStoreInfo: StoreListing;
}

const ListingStoreDetails = ({ lstStoreInfo }: IProps) => {
  const { id, name, logo } = lstStoreInfo;
  const defaultImg = "/images/CKPTLogo.png";
  const [imgSrc, setImgSrc] = useState(
    logo === null || logo === undefined ? defaultImg : logo
  );
  const handleError = () => setImgSrc(defaultImg);

  return (
    <div className={`col-auto scale-on-hover`}>
      <Link to={`/mplace/store/${id}`}>
        <div className="marketplace-listing-store-wrapper">
          <img
            className="marketplace-listing-store-banner"
            alt={name}
            src={imgSrc}
            onError={handleError}
          />
          <p className="marketplace-listing-store-title">{name}</p>
        </div>
      </Link>
    </div>
  );
};

export default ListingStoreDetails;
