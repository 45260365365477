import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../style/App.css";
import Header from "../../components/header/Header";
import "../../style/staking-result.css";
import "../../yeah.css";
import "../../yeah.css";
import Layout from "../../components/Layout";
import { useEffect } from "react";

export interface stateType {
  from: { pathname: string };
}
function NFTTransferResult() {
  const navigate = useNavigate();

  const { state } = useLocation();
  // const { nftTransactionResult } = state as any;
  useEffect(() => {
    document.documentElement.style.setProperty('--base','auto');
  }, [])

  return (
    <Layout>
    <div className="tokenclaim-body">
      <div className="container">
        {/* <Header /> */}
        <div className="yeah" id="content-stacking">
          <div className="left-line liner">
            <div className="block left-block"></div>
          </div>
          <div className="yeah-overlay1"></div>
          <div className="content-container">
            <section>
              <div className="checkout-content">
                <div className="dudes">
                  <button
                    type="button"
                    className="backbtn"
                    onClick={() => navigate('/landsale')}
                  >
                    <img src={require("../../imgg/backbtn.png")} alt="" />
                  </button>
                  <div className="checkout-head">
                    <p>NFT TRANSFER RESULT</p>
                  </div>
                </div>
              </div>
              <div className="token-stakingbox">
                <div className="" id="token-claim">
                  <div className="token-claim-next-dude token-claim-last-dude">
                    <div className="container" style={{ width: "100%" }}>
                      <p className="clearfix text-center claimed-text">
                        <img
                          className="metamall-coin"
                          src={require("../../imgg/metamall-coin.png")}
                        />
                      </p>
                      <p
                        className="clearfix text-center claimed-text"
                        style={{ fontSize: "2rem" }}
                      >
                        Congratulations!
                      </p>
                      <p className="clearfix text-center claimed-text">
                        You have successfully purchased land in MetaMall
                      </p>
                      {/* {nftTransactionResult && nftTransactionResult.map((result) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              height: "3rem",
                            }}
                          >
                            <div style={{ marginRight: "2rem" }}>
                              {result.address}
                            </div>
                            <div>
                              <a
                                href={`https://solscan.io/tx/${result.result}?cluster=devnet`}
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                View Transaction Details
                              </a>
                            </div>
                          </div>
                        );
                      })} */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="right-line liner">
            <div className="block right-block"></div>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
}
export default NFTTransferResult;