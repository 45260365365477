import { useQuery } from "react-query";
import ReactQueryKey from "../Constant/ReactQuery";
import { useNavigate } from "react-router-dom";
import { ApiCall } from "../Api/ApiCall";
import { ApiUrl } from "../Api/ApiUrl";
import { useState } from "react";
// import useConnectWallet from "./useConnectWallet";

const useAccessTocken = () => {
  const [walletKey, setWalletKey] = useState<any>(undefined);
  const [crptoMsg, setCrptoMsg] = useState<{
    signedMessage: string;
    message: string;
  }>({ signedMessage: "", message: "" });

  const navigation = useNavigate();

  const setAccessToken = () => {
    ApiCall("POST", ApiUrl.walletlogin, {
      walletId: walletKey,
      ...crptoMsg,
    }).then((c) => {
      localStorage.setItem("access_token", c.access_token);
      return c.access_token;
    });
  };
  
  if (!!localStorage.getItem("access_token")) {
    return localStorage.getItem("access_token");
  } else {
    setAccessToken();
  }

};

export default useAccessTocken;
