import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Pagination, Input } from "antd";
import { isEmpty } from "lodash";

import ListingStoreDetails from "./ListingStoreDetails";
import { StoreListing } from "../storeTypes";
import { useStoreListings } from "../hooks/useStoreData";
import Loader from "../../../components/Loader";

const { Search } = Input;
interface StoreFullViewProps {
  storeListings: StoreListing[];
  isLoading: boolean;
  searchTerm: string;
  setSearchTerm: Function;
}

const ListingStoreCard = ({ lstStoreInfo }) => {
  const { name, logo, id, owners, address, land } = lstStoreInfo;
  const defaultImg = "/images/CKPTLogo.png";
  const [imgSrc, setImgSrc] = useState(
    logo === null || logo === undefined ? defaultImg : logo
  );
  // const handleError = () => setImgSrc(defaultImg);
  return (
    <div className={`marketplace-listing-card scale-on-hover`}>
      <Link to={`./${id}`}>
        <div className="marketplace-listing-card-wrapper">
          <img
            className="marketplace-listing-card-banner"
            alt={name}
            src={imgSrc}
          />
          <p
            className="marketplace-listing-detail-title"
            style={{ marginBottom: 0, fontSize: "22px" }}
          >
            {name}
          </p>
          <p className="marketplace-listing-detail-owner">{address}</p>
          {owners.map(({ wallet_address, name, email, uuid }) => {
            return (
              <div key={uuid}>
                {/* {name && (
                  <p className="marketplace-listing-detail-label">{name}</p>
                )} */}
                <p className="marketplace-listing-detail-label">
                  Owner Wallet Address
                </p>
                <div className="marketplace-listing-tags">
                  <p className="ellipsis">{wallet_address}</p>
                </div>
                {/* {email && (
                  <p className="marketplace-listing-detail-label">{email}</p>
                )} */}
              </div>
            );
          })}

          {land.type && (
            <>
              <p className="marketplace-listing-detail-label">Land</p>
              <p
                className="marketplace-listing-detail-description"
                key={land.uuid}
              >
                {land.type}
              </p>
            </>
          )}
        </div>
      </Link>
    </div>
  );
};

const defaultPagination = {
  page: 1,
  resultsPerPage: 10,
};

const StoreListings = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [pagination, setPagination] = useState(defaultPagination);

  const { data = {}, isLoading } = useStoreListings({
    search: searchTerm,
    ...pagination,
  });

  const { listings: storeListings = [], totalRecords }: any = data;

  const getCardsJSX = () => {
    if (isLoading) return <Loader className="cockpit-loader" />;
    if (!isLoading && isEmpty(storeListings)) {
      return (
        <div className="marketplace-listing-store-no-data">
          <p>NO DATA FOUND</p>
        </div>
      );
    }
    return (
      <>
        <div className="marketplace-listing-cards-container-full-view">
          {storeListings.map((listing: StoreListing) => {
            return <ListingStoreCard key={listing.id} lstStoreInfo={listing} />;
          })}
        </div>
        <Pagination
          className="pagination"
          showSizeChanger
          current={pagination.page}
          pageSize={pagination.resultsPerPage}
          onChange={(page) =>
            setPagination((prev) => ({
              ...prev,
              page,
            }))
          }
          onShowSizeChange={(page, resultsPerPage) =>
            setPagination({
              page,
              resultsPerPage,
            })
          }
          defaultCurrent={1}
          total={totalRecords}
        />
      </>
    );
  };

  return (
    <>
      <div
        className="marketplace-listing marketplace-listing-collapsed"
        style={{ flexDirection: "column", height: "100%" }}
      >
        <Search
          className="marketplace-search-input"
          placeholder="Search Stores"
          enterButton={null}
          allowClear
          value={searchTerm}
          onChange={(e) => {
            setPagination(defaultPagination);
            setSearchTerm(e.target.value);
          }}
        />
        {getCardsJSX()}
      </div>
    </>
  );
};

export default StoreListings;
