import React from "react";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";

import Button from "../../../components/Button";
import { Listing } from "../types";

interface IProps {
  listing: Listing;
  mode?: string;
}

const currencyImg = {
  mall: require("../../../imgg/malltoken.png"),
  usdt: require("../../../imgg/USDT-icon.png"),
};

const ListingCard = ({ listing, mode }: IProps) => {
  const {
    id,
    name,
    image,
    currency,
    seller_fee_basis_points,
    attributes = [],

    // asset props
    tokenIcon = require("../../../img/landsale-banner.png"),
    tokenAddress,
    tokenName,
    tokenSymbol,
    type,
  } = listing;

  // fallback image
  const assetImage = tokenIcon || require("../../../img/landsale-banner.png");
  const saleMode = isEqual(mode, "sell");

  const getLabel = () => {
    // this is for assets
    if (type) {
      switch (type) {
        case "Land":
          return "Land Piece";
        default:
          return "Other NFT";
      }
    }
    // add here when types are present for listings
    return "Land Piece";
  };

  return (
    <div
      className={`marketplace-listing-card ${saleMode ? "" : "scale-on-hover"}`}
    >
      <Link to={saleMode ? "" : `../${id}`}>
        <div className="marketplace-listing-card-wrapper">
          <img
            className="marketplace-listing-card-banner"
            alt={name}
            src={mode === "sell" ? assetImage : image}
          />
          <p className="marketplace-listing-card-title">{name || tokenName}</p>
          {!saleMode && (
            <div className="marketplace-listing-card-price">
              <img
                src={currencyImg[currency] || currencyImg.mall}
                className="marketplace-listing-card-currency"
                alt={currency}
              />
              <span>{seller_fee_basis_points}</span>
            </div>
          )}
          <p className="marketplace-listing-card-label">{getLabel()}</p>
          <div className="marketplace-listing-tags">
            {attributes.map(({ value, uuid }) => (
              <p key={uuid}>{value}</p>
            ))}
          </div>
          {saleMode && (
            <Link to={`../sell/${tokenAddress}`}>
              <Button className="marketplace-listing-card-sell-btn">
                Sell
              </Button>
            </Link>
          )}
        </div>
      </Link>
    </div>
  );
};

export default ListingCard;
