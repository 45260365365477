import React, { FC, useMemo } from "react";
import "../style/App.css";
import "../yeah.css";
import "../yeah.css";
import { useState, useEffect } from "react";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import {
  useConnection,
  useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { Transaction, PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { toast } from "react-hot-toast";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { getOrCreateAssociatedTokenAccount } from "./getOrCreateAssociatedTokenAccount";
import { createTransferInstruction } from "./createTransferInstructions";
import { BASEURL, NETWORK_URL, TOKEN_ID } from "../Constants";
import { StakingPoolData } from "./NFTDetails";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../ClaimView.css";

const dayjs = require("dayjs");

interface Schedule {
  date: string;
  AMOUNT_PER_SCHEDULE: string;
}
interface ContractInfo {
  destinationAddress: string;
  mintAddress: string;
  schedules: Array<Schedule>;
  vestingAccount: string;
  vextingContractId: string;
}
interface ClaimedContractInfo {
  claimed_amount: string;
  claimed_datetime: string;
  claimed_txn_datetime: string;
}

interface ContractInfoData {
  data: Array<ContractInfo>;
}

async function getClaim(
  vestingContractId
): Promise<{ successfull: string } | null> {
  const result = await fetch(`${BASEURL}/getClaim/${vestingContractId}`)
    .then((response) => response.json())
    .then((data) => {
      console.log(data.successfull);
      return data;
    });
  return result;
}

export const fetchBalance = async (publicKey: string | undefined) => {
  let walletBalance = 0;
  try {
    const owner = publicKey;
    const MINT = new PublicKey(TOKEN_ID);
    const request_data = {
      jsonrpc: "2.0",
      id: 1,
      method: "getTokenAccountsByOwner",
      params: [owner, { mint: MINT }, { encoding: "jsonParsed" }],
    };

    console.log(" --- request data ----", { request_data });
    const transaction_details: any = await fetch(NETWORK_URL, {
      method: "POST",
      body: JSON.stringify(request_data),
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());

    console.log(
      transaction_details.result.value[0].account.data.parsed.info.tokenAmount
        .uiAmount
    );
    if (
      transaction_details &&
      transaction_details.result &&
      transaction_details.result &&
      transaction_details.result.value[0] &&
      transaction_details.result.value[0].account &&
      transaction_details.result.value[0].account.data.parsed &&
      transaction_details.result.value[0].account.data.parsed.info &&
      transaction_details.result.value[0].account.data.parsed.info
        .tokenAmount &&
      transaction_details.result.value[0].account.data.parsed.info.tokenAmount
        .uiAmount
    ) {
      walletBalance =
        transaction_details.result.value[0].account.data.parsed.info.tokenAmount
          .uiAmount;
    }
  } catch (e) {
    console.log(e);
  }
  return walletBalance;
};

function compare(a, b) {
  if (Number(a?.schedules[0].date) < Number(b?.schedules[0].date)) {
    return -1;
  }
  if (Number(a?.schedules[0].date) > Number(b?.schedules[0].date)) {
    return 1;
  }
  return 0;
}

function LandSaleClaimView() {
  const navigation = useNavigate();
  const { publicKey, signTransaction, sendTransaction } = useWallet();
  const schdules: Array<Schedule> = [];
  //let contractInfoData: ContractInfoData;
  const [unclaimedContractInfoData, setUnclaimedContractInfoData] =
    useState<Array<ContractInfo>>();
  const [claimedContractInfoData, setClaimedContractInfoData] =
    useState<Array<ClaimedContractInfo>>();
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch(`${BASEURL}/getContractInfo/${publicKey?.toString()}`)
      .then((response) => response.json())
      .then((data) => {
        data.sort(compare);
        setUnclaimedContractInfoData(data);
      });
    fetch(`${BASEURL}/getStakingClaimed/${publicKey?.toString()}`)
      .then((response) => response.json())
      .then((data) => {
        setClaimedContractInfoData(data.successfull.rows);
      });
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [publicKey]);

  return publicKey && unclaimedContractInfoData && claimedContractInfoData ? (
    <div className="claim-tabs" style={{ justifyContent: "center" }}>
      <Tabs
        selectedTabClassName="tab-active border-claim"
        style={{ height: "100%", flexGrow: 1 }}
      >
        <TabList className="tabsingle-line">
          <Tab className="tabsingle">Unclaimed</Tab>
          <Tab className="tabsingle">Claimed</Tab>
        </TabList>

        <TabPanel style={{ height: "100%", flexGrow: 1 }}>
          {unclaimedContractInfoData.map((data) => {
            if (Number(data.schedules[0].AMOUNT_PER_SCHEDULE) > 0)
              return (
                <div
                  className="stake-claim"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "10px",
                    margin: "20px",
                    width: "400px",
                    justifyContent: "space-evenly",
                    borderRadius: "8px",
                    padding: "5px",
                    outline: "solid 1px white",
                    minHeight: "70px",
                  }}
                >
                  <div
                    className="left"
                    style={{
                      lineHeight: "28px",
                    }}
                  >
                    <div>
                      {new Date(
                        Number(data?.schedules[0].date) * 1000
                      ).toDateString()}
                    </div>
                    <div>
                      <img
                        className="claim-last-img"
                        src={require("../imgg/m-icon.png")}
                      />{" "}
                      {data?.schedules[0].AMOUNT_PER_SCHEDULE}
                    </div>
                  </div>
                  <div className="right">
                    {new Date(Number(data?.schedules[0].date) * 1000) <=
                    new Date() ? (
                      <button
                        className="registerbtn unclaimBtn"
                        style={{
                          width: "100px",
                          height: "20pxx",
                        }}
                        onClick={async () => {
                          const result = await getClaim(data.vextingContractId);
                          if (result?.successfull) {
                            navigation(`/claim-result/${result.successfull}`, {
                              state: { name: "raeon" },
                              replace: true,
                            });
                          } else {
                            console.log(result);
                          }
                        }}
                      >
                        Claim
                      </button>
                    ) : (
                      <button
                        className="registerbtn unclaimBtn"
                        style={{
                          width: "100px",
                          cursor: "not-allowed",
                        }}
                      >
                        Pending
                      </button>
                    )}
                  </div>
                </div>
              );
          })}
        </TabPanel>
        <TabPanel>
          {claimedContractInfoData.map((data) => {
            if (Number(data.claimed_amount) > 0)
              return (
                <div
                  className="stake-claim"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "10px",
                    margin: "20px",
                    width: "400px",
                    justifyContent: "space-evenly",
                    borderRadius: "8px",
                    padding: "5px",
                    outline: "solid 1px white",
                    minHeight: "70px",
                  }}
                >
                  <div
                    className="left"
                    style={{
                      lineHeight: "28px",
                    }}
                  >
                    <div>
                      {new Date(data?.claimed_txn_datetime).toDateString()}
                    </div>
                    <div>
                      <img
                        className="claim-last-img"
                        src={require("../imgg/m-icon.png")}
                      />{" "}
                      {data.claimed_amount}
                    </div>
                  </div>
                  <div className="right">
                    <button className="claimBtn">Claimed</button>
                  </div>
                </div>
              );
          })}
        </TabPanel>
      </Tabs>
    </div>
  ) : (
    <></>
  );
}

export default LandSaleClaimView;