import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../components/Loader";
import { ApiUrl, BASE_URL } from "../../Api/ApiUrl";
import Card from "react-bootstrap/Card";
import useAccessTocken from "../../Hooks/useAccessTocken";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
import NoDataFound from "../../components/NoDataFound";

export interface userInfo {
  wallet_address: string;
  email: string;
}

const UserCenter = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<Array<userInfo>>();
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>();
  const access_token = useAccessTocken();
  const { Search } = Input;
  const Axios = axios.create({
    baseURL: BASE_URL,
    //@ts-ignore
    headers: { Authorization: `Bearer ${access_token}` },
  });
  async function getMetamallOwnedLands() {
    const res = await Axios.get<Array<userInfo>>(ApiUrl.userAll);
    setData(res.data);
  }

  async function filterUser(text: string) {
    console.log("texttexttext", text);
    if (text !== undefined && text !== "") {
      const res = await Axios.get<Array<userInfo>>(
        `${ApiUrl.userAllSearch}/${text}`
      );
      setData(res.data);
      setIsSearch(true);
    } else {
      getMetamallOwnedLands();
      setIsSearch(false);
    }
  }
  useEffect(() => {
    getMetamallOwnedLands();
  }, []);

  const handleChange = (e: string) => {
    setSearchTerm(e);
    filterUser(e);
  };

  return (
    <div className="experience-wrapper land-center-wrapper adminPage">
      <div className={" download-exe row"}>
        <div className="adminLandSection ">
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  marginBottom: "1rem",
                  alignItems: "center",
                  display: "flex",
                  padding: "0 15px",
                }}
              >
                {" "}
                <span>Search :</span>
                <input
                  type="text"
                  className="form-control w-50 textSearch"
                  onChange={(e) => handleChange(e.target.value)}
                />
              </div>
            </div>
            {isSearch === true && data?.length === 0 && <NoDataFound />}
            {data === undefined && <Loader className={"recharge-loader"} />}
            {data !== undefined &&
              data.map((usr, i) => (
                <Card className="col-md-4 m-0 p-2" key={i}>
                  <Card.Body
                    className="link-primary"
                    onClick={() =>
                      navigate(
                        `/cockpit/admin/userdetails/${usr.wallet_address}`
                      )
                    }
                  >
                    <div className="cube">{usr.email}</div>
                    <div className="cube digit">{usr.wallet_address}</div>
                  </Card.Body>
                </Card>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCenter;
