import React from 'react';

interface LineProps {
    className: string;
}

const Line = ({className}: LineProps) => {
  return <div id={'line'} className={className} ></div>;
};

export default Line;