export const getStakingPoolImage = (name: string | undefined): string => {
  switch (name) {
    case "Staking Pool 1":
      return "staking-pool1.png";
    case "Staking Pool 2":
      return "staking-pool2.png";
    case "Staking Pool 3":
      return "staking-pool3.png";
    case "Staking Pool 4":
      return "staking-pool4.png";
    case "Staking Pool 5":
      return "staking-pool5.png";
    case "Staking Pool 6":
      return "staking-pool6.png";
    case "Staking Pool 7":
      return "staking-pool7.png";
    case "Staking Pool 8":
      return "staking-pool8.png";
    case "Staking Pool 9":
      return "staking-pool9.png";
    case "Staking Pool 10":
      return "staking-pool10.png";
  }
  return "staking-pool1.png";
};
