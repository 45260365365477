//export const BASEURL = "http://65.0.17.77";
//export const BASEURL = "http://13.233.30.28/api/landsale";
//export const BASEURL = "http://localhost:8080/landsale";
export const BASEURL = "https://nft.metamalls.io/landsaleapi/landsale";
export const STACKING_BASE_URL = "https://staking.metamalls.io/api/";
export const MARKETPLACE_SERVICE_BASEURL =
  "https://nft.metamalls.io/cockpitapi/api";

//export const BASEURL = "/api";
export const TOKEN_ID = "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"; //"CedGzCs94g69Qjpe4KWWfhwQc5ViGx4oDB6K7daQdL28";

export const MALL_TOKEN_ID = "5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i"; //"CedGzCs94g69Qjpe4KWWfhwQc5ViGx4oDB6K7daQdL28";
export const USDT_TOKEN_ID = "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"; //"CedGzCs94g69Qjpe4KWWfhwQc5ViGx4oDB6K7daQdL28";

export const tokenAddress = {
  mall: '5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i',
  usdt: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
};

export const NETWORK_URL =
  process.env.REACT_APP_ONCHAIN_ENDPOINT ||
  "https://solana-api.syndica.io/access-token/cOzUyVAdbFjWBgGDJEmuRkhzZy1vdlF7thGKu5XXkynB1v2v8aFxrh1j5rCPQdze/rpc/"; //"https://api.mainnet-beta.solana.com"; //"https://silent-nameless-breeze.solana-devnet.quiknode.pro/76c3153d2150a50eb44d28136875c03d4feaba18/";
export const ENVIRONMENT = "development";
