import "./LauncherPage.scss";
import "../../StakingStyle/App.css";
import "../../StakingStyle/yeah.css";
import "../../StakingStyle/styles.css";
import React from "react";
import Layout from "../../components/Layout";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";

const LauncherPage = (props) => {

  return (
    <Layout>
      <section className="without" data-scroll data-scroll-speed="-10">
        <div className="video parallax">
          <div className="overlay top"></div>

          <div className="center top-center">
            <div className="top-center">
              <img src={require("../../images/mainlogo.png")} alt="" />
            </div>
          </div>
          <div className="metamall-img">
          <img
            src={require("../../imgg/marketplace-bg.png")}
            alt=""
            width="100%"
            height="100%"
            />
            </div>
        </div>
      </section>
      <div className="yeah">
        <div className="left-line liner">
          <div className="block left-block"></div>
        </div>
        <div className="experience-wrapper content-container">
          <section className="stakingpools">
            <div className={"mall-container download-exe"}>
              <div className={"mall-balance"}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/DownloadLauncher.png`}
                  alt=""
                />
                <div className="launcher-wrapper">
                  <div className="text">Download Launcher</div>
                  <div className="token">
                    You need to download the MetaMall Launcher to experience the
                    metaverse
                  </div>
                </div>
              </div>
              <div className="button-wrapper">
                <React.Fragment>
                  <div className={"coinbase-button"}>
                    <a href="https://installer.launcher.xsolla.com/xlauncher-builds/xsolla-launcher-update/3108/bin/web_installer.exe">
                      <PrimaryButton
                        name="DOWNLOAD LAUNCHER WINDOWS"
                        className="primary-btn download"
                      />
                    </a>
                  </div>
                  <div className={"coinbase-button"}>
                    <a href="https://installer.launcher.xsolla.com/xlauncher-builds/xsolla-launcher-update/3107/bin/launcher.dmg">
                      <PrimaryButton
                        name="DOWNLOAD LAUNCHER MAC"
                        className="primary-btn download"
                      />
                    </a>
                  </div>
                </React.Fragment>
              </div>
            </div>
          </section>
        </div>
        <div className="right-line liner">
          <div className="block right-block"></div>
        </div>
      </div>
    </Layout>
  );
};

export default LauncherPage;
