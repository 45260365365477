import "../style/App.css";
import "../yeah.css";
import "../yeah.css";
import { useState, useEffect } from "react";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import {
  useConnection,
  useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { Transaction, PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import React, { useCallback } from "react";
import { toast } from "react-hot-toast";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { getOrCreateAssociatedTokenAccount } from "./getOrCreateAssociatedTokenAccount";
import { createTransferInstruction } from "./createTransferInstructions";
import {
  BASEURL,
  MALL_TOKEN_ID,
  NETWORK_URL,
  TOKEN_ID,
  USDT_TOKEN_ID,
} from "../Constants";
import { NFTDetailData, StakingPoolData } from "./NFTDetails";
import { useNavigate } from "react-router-dom";
import { WalletDisconnectButton } from "@solana/wallet-adapter-react-ui";
import ClockLoader from "react-spinners/ClockLoader";
import { css } from "@emotion/react";
import { ConvertData, NFTData } from "./Home";
import PrimaryButton from "./PrimaryButton/PrimaryButton";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

interface Props {
  children: (sendTransaction: OnSendTransaction) => React.ReactNode;
}

type OnSendTransaction = (toPublicKey: string, amount: number) => void;

export const fetchBalance = async (
  publicKey: string | undefined,
  tokenType: string
) => {
  let walletBalance = 0;
  try {
    const owner = publicKey;
    const MINT = new PublicKey(
      tokenType === "usdt" ? USDT_TOKEN_ID : MALL_TOKEN_ID
    );
    const request_data = {
      jsonrpc: "2.0",
      id: 1,
      method: "getTokenAccountsByOwner",
      params: [owner, { mint: MINT }, { encoding: "jsonParsed" }],
    };

    console.log(" --- request data ----", { request_data });
    const transaction_details: any = await fetch(NETWORK_URL, {
      method: "POST",
      body: JSON.stringify(request_data),
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());

    if (
      transaction_details &&
      transaction_details.result &&
      transaction_details.result &&
      transaction_details.result.value[0] &&
      transaction_details.result.value[0].account &&
      transaction_details.result.value[0].account.data.parsed &&
      transaction_details.result.value[0].account.data.parsed.info &&
      transaction_details.result.value[0].account.data.parsed.info
        .tokenAmount &&
      transaction_details.result.value[0].account.data.parsed.info.tokenAmount
        .uiAmount
    ) {
      console.log(
        transaction_details.result.value[0].account.data.parsed.info.tokenAmount
          .uiAmount
      );
      walletBalance =
        transaction_details.result.value[0].account.data.parsed.info.tokenAmount
          .uiAmount;
    }
  } catch (e) {
    console.log(e);
  }
  return walletBalance;
};

function BuySpecificNFT(props: {
  nftDetails: NFTDetailData | undefined;
  nftTokenAddress: string;
}) {
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#99005b");
  const [totalAvailableNFT, setTotalAvailableNFT] = useState(undefined);
  const [tokenType, setTokenType] = useState("mall");
  const handleChange = (e) => {
    //@ts-ignore
    props.tokenTypeChange(e.target.value);
    setTokenType(e.target.value);
  };
  const { connection } = useConnection();
  const { publicKey, signTransaction, sendTransaction } = useWallet();
  const [amountToStake, setAmountToStake] = useState<number>(1);
  const [isEnoughBalance, setIsEnoughBalance] = useState<boolean>(true);
  const [isEnoughMaxReward, setIsEnoughMaxReward] = useState<boolean>(true);
  const [balance, setBalance] = useState<number | undefined>(undefined);
  const [isStakingDone, setIsStakingDone] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    if (publicKey) {
      fetchBalance(publicKey?.toString(), tokenType).then((walletBalance) => {
        setBalance(walletBalance);
      });
    } else {
      setBalance(undefined);
    }
  }, [publicKey, tokenType]);

  useEffect(() => {}, [tokenType]);

  const onSendSPLTransaction = useCallback(
    async (
      toPubkey: string,
      amount: number,
      nftCount: number,
      tokenType: string
    ) => {
      console.log(toPubkey);
      console.log(signTransaction);
      console.log("publicKey", { publicKey });
      console.log({ tokenType });

      if (!toPubkey || !amount) return;
      const toastId = toast.loading("Processing transaction...");

      try {
        if (!publicKey || !signTransaction) {
          console.log("Wallet not connected");
          throw new WalletNotConnectedError();
        }
        const toPublicKey = new PublicKey(toPubkey);
        const mint = new PublicKey(
          tokenType === "usdt" ? USDT_TOKEN_ID : MALL_TOKEN_ID
        );
        console.log("MINT", { mint });
        console.log(connection);
        console.log(props.nftDetails?.row_data.type);

        const nftBuyingResponse: any = await fetch(
          `${BASEURL}/specificNftBuyingRequest/${publicKey.toString()}/${nftCount}/${
            props.nftDetails?.row_data.type
          }/${amount}/${props.nftTokenAddress}`,
          {
            method: "POST",
          }
        ).then((response) => response.json());

        const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
          connection,
          publicKey,
          mint,
          publicKey,
          signTransaction
        );
        console.log("fromToken", { fromTokenAccount });

        const toTokenAccount = await getOrCreateAssociatedTokenAccount(
          connection,
          publicKey,
          mint,
          toPublicKey,
          signTransaction
        );

        console.log("toToken", { toTokenAccount });
        console.log("amount", amount);

        const transaction = new Transaction().add(
          createTransferInstruction(
            fromTokenAccount.address, // source
            toTokenAccount.address, // dest
            publicKey,
            amount,
            [],
            TOKEN_PROGRAM_ID
          )
        );

        const blockHash = await connection.getRecentBlockhash();
        transaction.feePayer = await publicKey;
        transaction.recentBlockhash = await blockHash.blockhash;
        const signed = await signTransaction(transaction);

        const finalResponse = await connection.sendRawTransaction(
          signed.serialize()
        );

        console.log("finalResponse", { finalResponse });

        setLoading(true);
        await connection.confirmTransaction(finalResponse, "max");
        await connection.getParsedTransaction(finalResponse, "confirmed");

        const nftTransferResponse: any = await fetch(
          `${BASEURL}/getSpecificNftTokenFromPool/${publicKey.toString()}/${
            props.nftDetails?.row_data.type
          }/${nftCount}/landsaleportal/${props.nftTokenAddress}`,
          {
            method: "POST",
          }
        ).then((response) => response.json());

        console.log(publicKey?.toString());

        setLoading(false);

        if (
          nftTransferResponse.response === "NFT Token transferred successfully"
        ) {
          setIsStakingDone(true);
          navigation(`/landsale/nft-result`, {
            state: {
              name: "raeon",
              nftTransactionResult: nftTransferResponse.transactionResult,
            },
            replace: true,
          });
        }

        toast.success("Transaction sent", {
          id: toastId,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toast.error(`Transaction failed: ${error.message}`, {
          id: toastId,
        });
      }
    },
    [publicKey, sendTransaction, connection]
  );

  return (
    <div className="checkout-details">
      {balance || balance === 0 ? (
        <label>
          Your current wallet balance is {balance}{" "}
          <img
            src={require(tokenType === "usdt"
              ? "../imgg/USDT-icon.png"
              : "../imgg/m-icon.png")}
            alt=""
            height="13"
          />{" "}
        </label>
      ) : (
        ""
      )}

      <div className="token-claim-next-dude token-buy-next-dude">
        <select
          className="select-dropdown"
          value={tokenType}
          onChange={handleChange}
        >
          <option className={"options"} value="mall">
            Mall
          </option>
          <option value="usdt">USDT</option>
        </select>{" "}
        <label>
          {!isEnoughBalance
            ? "Insufficient Fund - Required amount is greater than wallet balance"
            : !isEnoughMaxReward
            ? "The selected piece of land is not available for sale"
            : ""}
        </label>
        <ClockLoader
          color={color}
          loading={loading}
          css={override}
          size={100}
        />
        {!loading ? (
          // <button
          //   type="submit"
          //   className="registerbtn"
          //   style={{ textAlign: "center" }}
          //   onClick={async () => {
          //     const walletBalance = await fetchBalance(
          //       publicKey?.toString(),
          //       tokenType
          //     );
          //     await setBalance(walletBalance);
          //     const nftDetailResponse: any = await fetch(
          //       `${BASEURL}/getNftMarketplaceDetails/${props.nftDetails?.row_data.id}`
          //     ).then((response) => response.json());
          //     console.log({ nftDetailResponse: nftDetailResponse });
          //     await setTotalAvailableNFT(
          //       nftDetailResponse.nft_total_count.count
          //     );
          //     const metaToUSD: ConvertData = await fetch(
          //       `https://api.coingecko.com/api/v3/simple/price?ids=metamall&vs_currencies=USD`
          //     ).then((response) => response.json());
          //     console.log({ "USD VALUE": metaToUSD.metamall.usd });
          //     if (
          //       amountToStake > Number(nftDetailResponse.nft_total_count.count)
          //     ) {
          //       setIsEnoughMaxReward(false);
          //     } else if (
          //       walletBalance <
          //       amountToStake *
          //         (Number(props.nftDetails?.row_data.price) /
          //           metaToUSD.metamall.usd)
          //     ) {
          //       setIsEnoughBalance(false);
          //     } else {
          //       setIsEnoughMaxReward(true);
          //       setIsEnoughBalance(true);
          //       if (props.nftDetails?.row_data.wallet_id) {
          //         const resp = await onSendSPLTransaction(
          //           props.nftDetails?.row_data.wallet_id,
          //           amountToStake * Number(props.nftDetails.row_data.price),
          //           amountToStake,
          //           tokenType
          //         );
          //         console.log("transfer done");
          //         // console.log(resp);
          //       } else {
          //         console.log("Unable to find wallet details.");
          //       }
          //     }
          //   }}
          // >
          //   Buy
          // </button>
          <div className="buyland-wrapper">
            <PrimaryButton
              name="buy land"
              className="buy-land-button-style"
              onClick={async () => {
                const walletBalance = await fetchBalance(
                  publicKey?.toString(),
                  tokenType
                );
                await setBalance(walletBalance);
                const nftDetailResponse: any = await fetch(
                  `${BASEURL}/getNftMarketplaceDetails/${props.nftDetails?.row_data.id}`
                ).then((response) => response.json());
                console.log({ nftDetailResponse: nftDetailResponse });
                await setTotalAvailableNFT(
                  nftDetailResponse.nft_total_count.count
                );
                const metaToUSD: ConvertData = await fetch(
                  `https://api.coingecko.com/api/v3/simple/price?ids=metamall&vs_currencies=USD`
                ).then((response) => response.json());
                console.log({ "USD VALUE": metaToUSD.metamall.usd });
                if (
                  amountToStake >
                  Number(nftDetailResponse.nft_total_count.count)
                ) {
                  setIsEnoughMaxReward(false);
                } else if (
                  walletBalance <
                  amountToStake *
                    (Number(props.nftDetails?.row_data.price) /
                      metaToUSD.metamall.usd)
                ) {
                  setIsEnoughBalance(false);
                } else {
                  setIsEnoughMaxReward(true);
                  setIsEnoughBalance(true);
                  if (props.nftDetails?.row_data.wallet_id) {
                    const resp = await onSendSPLTransaction(
                      props.nftDetails?.row_data.wallet_id,
                      amountToStake * Number(props.nftDetails.row_data.price),
                      amountToStake,
                      tokenType
                    );
                    console.log("transfer done");
                    // console.log(resp);
                  } else {
                    console.log("Unable to find wallet details.");
                  }
                }
              }}
            />
          </div>
        ) : (
          <div
            style={{
              justifyContent: "center",
              alignItems: "left",
              height: "20vh",
            }}
          >
            <h1> REQUEST RECEIVED! </h1>
            <p>
              NOTE: Land transfers generally take a few minutes to complete. We
              request you to be patient while we process this transaction. Reach
              out to us at <b>staking@metamalls.io</b> in case the NFT takes
              longer than 1 hour to reflect in your wallet.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default BuySpecificNFT;
