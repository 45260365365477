import "../../style/App.css";
import "../../yeah.css";
import "../../yeah.css";
import Layout from "../../components/Layout";
import { useEffect } from "react";
import { useState } from "react";
import StakingPool from "../../components/StakingPool";
import { useNavigate, useParams } from "react-router-dom";
import { BASEURL } from "../../Constants";

export interface StakingPoolData {
  name: string;
  maturity_period: string;
  apy: string;
  max_reward_value: string;
  total_locked_tokens: string;
  total_value_locked: string;
  token_price: string;
  status: string;
  id: number;
}

function Dashboard() {
  let a = 0;
  const navigate = useNavigate();
  const { mode } = useParams<{ mode: string }>();
  const [stakingPools, setStakingPools] = useState<Array<StakingPoolData>>([]);
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch(`${BASEURL}/getStakingPoolList`)
      .then((response) => response.json())
      .then((data) => {
        console.log({ data });
        setStakingPools(data);
      });
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--base','auto');
  }, [])

  return (
    <Layout>
      <section className="without" data-scroll data-scroll-speed="-10">
        <div className="video parallax">
          <div className="overlay top"></div>
          <div className="center top-center">
            <div className="top-center">
              <img src={require("../../images/mainlogo.png")} alt="" />
              <p>{/* TOKEN <span>STAKING</span> */}</p>
            </div>
          </div>

          <div
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              src={require("../../imgg/marketplace-bg.png")}
              alt=""
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </section>
      <div className="yeah">
        <div className="left-line liner">
          <div className="block left-block"></div>
        </div>
        <div className="content-container">
          <section className="stakingpools">
            <div className="checkout-content">
              <div className="dudes">
                <button
                  type="button"
                  className="backbtn"
                  onClick={() => navigate(-1)}
                >
                  <img src={require("../../imgg/backbtn.png")} alt="" />
                </button>
                <div className="checkout-head">
                  <p>
                    TOKEN <span>STAKING</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="token-stakingbox">
              <div className="dudes">
                {stakingPools.map((stakingPool, index) => {
                  if (stakingPool.id == -1 && mode !== "test") {
                    a = 1;
                    return;
                  }
                  if (index % 2 == a) {
                    return (
                      <>
                        <StakingPool pool={stakingPool} />
                        <div></div>
                      </>
                    );
                  } else {
                    return <StakingPool pool={stakingPool} />;
                  }
                })}
              </div>
            </div>
          </section>
        </div>
        <div className="right-line liner">
          <div className="block right-block"></div>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
