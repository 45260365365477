import { useQuery } from "react-query";
import ReactQueryKey from "../Constant/ReactQuery";
import { ApiCall } from "../Api/ApiCall";
import { ApiUrl } from "../Api/ApiUrl";
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect } from "react";

interface UseTokenTransferType {
  walletKey: string;
}

const useTokenTransfer = (wallet_address = "") => {
  const { publicKey, connected } = useWallet();
  //@ts-ignore
  const { isIdle, isLoading, isError, data, error, refetch, isFetching } =
    useQuery(
      ReactQueryKey.TOKEN_TRANSFER,
      () =>
        ApiCall(
          "GET",
          `${ApiUrl.getTokenTransfer}${
            wallet_address === "" ? publicKey?.toString() : wallet_address
          }`,
          {}
        ),
      {
        enabled: false,
      }
    );
  // if(publicKey?.toString() !== null && data === undefined) {
  //     refetch();
  // }

  useEffect(() => {
    if (data === undefined) {
      connected && refetch();
    }
  }, [publicKey, wallet_address]);

  return { isIdle, isLoading, isError, data, error };
};

export default useTokenTransfer;
