import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PrimaryButton from "./PrimaryButton/PrimaryButton";

const AlertModel = ({ image, headerText, subText, handleClick = () => {} }) => {
  const [cancele, setCancel] = useState(true);

  return (
    <Modal
      show={cancele}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="alert-model"
    >
      <div className="alert-message-wrapper">
        <img src={image} alt="" />
        <div className="header-text">{headerText}</div>
        <div className="sub-text" style={{ textAlign: "center" }}>
          {subText}
        </div>
        <PrimaryButton
          name={"PROCEED"}
          onClick={() => {
            setCancel(false);
            handleClick();
          }}
          className="proceed-button"
        />
      </div>
    </Modal>
  );
};

export default AlertModel;
