import axios from "axios";
import { useQuery } from "react-query";
import moment from "moment";
import { PublicKey } from "@solana/web3.js";

import ReactQueryKey from "../../../Constant/ReactQuery";
import { ApiUrl } from "../../../Api/ApiUrl";
interface IData {
  search: string;
  page?: number;
  resultsPerPage?: number;
}

const getStoreListings = async ({ search, page, resultsPerPage }: IData) => {
  try {
    const res = await axios.post(
      `${ApiUrl.marketPlaceAllStores}/page/${page}/perPage/${resultsPerPage}`,
      [
        {
          key: "name",
          searchValue: search,
        },
      ]
    );
    return {
      listings: res.data?.stores,
      totalRecords: res.data?.metadata?.totalRecords,
    };
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getStoreListingDetail = async (id: string) => {
  if (!id) return {};
  try {
    const res = await axios.get(`${ApiUrl.marketplaceStoreDetails}/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const useStoreListings = (data: IData) => {
  return useQuery([ReactQueryKey.MARKETPLACE_STORE_LISTINGS, data], () =>
    getStoreListings(data)
  );
};

export const useStoreListingDetail = (
  id: string,
  isRefreshing: boolean = false
) => {
  return useQuery(
    [ReactQueryKey.SELECTED_LISTING, id],
    () => getStoreListingDetail(id),
    {
      refetchInterval: (data, query) => {
        if (isRefreshing) return 5000;
        return false;
      },
    }
  );
};
