import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { useStoreListingDetail } from "../hooks/useStoreData";
import { BsLink45Deg, BsEyeFill } from "react-icons/bs";
import FbxViewer from "../../../components/FbxViewer/FbxViewer";
import { ApiUrl } from "../../../Api/ApiUrl";
import axios from "axios";
import { StoreListing, LinkedItems, LinkMaster } from "./../storeTypes";
import { Modal } from "react-bootstrap";
import ReactThreeFbxViewer from "react-three-fbx-for-pyt";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import NoDataFound from "../../../components/NoDataFound";
import BackButton from "../../../components/BackButton";

const StoreListingDetails = () => {
  const { id = "" } = useParams();
  const [listing, setListing] = useState<StoreListing>();
  const [linkItemMaster, setLinkItemMaster] = useState<Array<LinkedItems>>();

  const [isShowFbxImageViewerModel, setIsShowFbxImageViewerModel] = useState({
    isShow: false,
    fbxURL: "",
  });

  const getStoreListingDetail = async (id: string) => {
    if (!id) return {};
    try {
      await axios.get(`${ApiUrl.marketplaceStoreDetails}/${id}`).then((res) => {
        console.log("res.data", res.data);
        setListing(res.data);
        setLinkItemMaster(res.data.linkedItems);
      });
    } catch (err) {}
  };

  useEffect(() => {
    getStoreListingDetail(id);
  }, []);

  return (
    <div>
      {listing &&
      Object.keys(listing).length === 0 &&
      Object.getPrototypeOf(listing) === Object.prototype ? (
        <Loader className={"cockpit-loader"} />
      ) : (
        <div className="marketplace-listing-detail">
          <div className="marketplace-listing-detail-safe-area">
            <BackButton />
            <div className="marketplace-listing-detail-card">
              <div className="marketplace-listing-detail-img">
                <img
                  style={{ width: "100%" }}
                  src={"/images/CKPTLogo.png"}
                  alt="listing_image"
                />
                {listing !== undefined && (
                  <>
                    <div className="storeDetailsInfo">
                      <p className="marketplace-listing-detail-title">
                        Store Info
                      </p>
                      <p className="marketplace-listing-detail-label">
                        <span className="marketplace-heading">Name :</span>{" "}
                        {listing.name}
                      </p>
                      <p className="marketplace-listing-detail-label">
                        <span className="marketplace-heading">Address :</span>{" "}
                        {listing.address}
                      </p>
                    </div>

                    {listing !== undefined && listing.owners && (
                      <div className="storeDetailsInfo">
                        <p className="marketplace-listing-detail-title">
                          Owner
                        </p>
                        {listing.owners.map(
                          ({ wallet_address, name, email, uuid }) => {
                            return (
                              <>
                                {name && (
                                  <p
                                    className="marketplace-listing-detail-label"
                                    key={uuid}
                                  >
                                    <span className="marketplace-heading">
                                      Name :
                                    </span>{" "}
                                    {name}
                                  </p>
                                )}
                                <p className="marketplace-listing-detail-label">
                                  <span className="marketplace-heading">
                                    Wallet Address :
                                  </span>{" "}
                                  {wallet_address}
                                </p>
                                {email && (
                                  <p
                                    className="marketplace-listing-detail-label"
                                    key={uuid}
                                  >
                                    <span className="marketplace-heading">
                                      Email :
                                    </span>{" "}
                                    {email}
                                  </p>
                                )}
                              </>
                            );
                          }
                        )}
                      </div>
                    )}

                    {listing.owners && (
                      <div className="storeDetailsInfo">
                        <p className="marketplace-listing-detail-title">Land</p>

                        {listing.land.type && (
                          <p className="marketplace-listing-detail-label">
                            <span className="marketplace-heading">Type :</span>{" "}
                            {listing.land.type}
                          </p>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="marketplace-listing-detail-content ">
                <div className="storeDetailsInfo">
                  {linkItemMaster !== undefined && (
                    <p className="marketplace-listing-detail-title">
                      Items in Store
                    </p>
                  )}
                  {linkItemMaster !== undefined && (
                    <div className="row storeDetails">
                      {linkItemMaster.map((linkItem: LinkedItems) => {
                        return (
                          <div className="col-6 marketplace-listing-detail-itemsInStoreCard">
                            <div className="card">
                              <div className="card-body">
                                {" "}
                                <div className="">
                                  {linkItem.linkedItemMaster !== undefined &&
                                    linkItem.linkedItemMaster && (
                                      <p className="marketplace-listing-detail-label">
                                        <span className="marketplace-heading">
                                          Name :
                                        </span>{" "}
                                        {linkItem.linkedItemMaster.name}
                                      </p>
                                    )}

                                  {linkItem.linkedItemMaster.imageLink && (
                                    <p className="marketplace-listing-detail-label">
                                      <span className="marketplace-heading image">
                                        {" "}
                                        Image :{" "}
                                      </span>
                                      <img
                                        style={{ width: "60px" }}
                                        src={
                                          linkItem.linkedItemMaster.imageLink
                                        }
                                        alt={linkItem.linkedItemMaster.name}
                                      />
                                    </p>
                                  )}

                                  {linkItem.linkedItemMaster
                                    .marketplaceLink && (
                                    <p
                                      className="marketplace-listing-detail-label"
                                      key={linkItem.uuid}
                                    >
                                      Marketplace link:
                                      <a
                                        className="linkIcon"
                                        href={
                                          linkItem.linkedItemMaster
                                            .marketplaceLink
                                        }
                                        style={{ color: "white" }}
                                        target="_blank"
                                        title="Marketplace link"
                                      >
                                        <span>
                                          <BsLink45Deg />
                                        </span>
                                      </a>
                                    </p>
                                  )}

                                  {linkItem.linkedItemMaster
                                    .threeDModelLink && (
                                    <p
                                      className="marketplace-listing-detail-label linkIcon"
                                      key={linkItem.uuid}
                                    >
                                      3D View:
                                      <span>
                                        <BsEyeFill
                                          onClick={() => {
                                            setIsShowFbxImageViewerModel({
                                              ...isShowFbxImageViewerModel,
                                              fbxURL:
                                                linkItem.linkedItemMaster
                                                  .threeDModelLink,
                                              isShow: true,
                                            });
                                          }}
                                        />
                                      </span>
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      {linkItemMaster.length === 0 && (
                        <NoDataFound textMessage="No items available in the store" />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <FbxViewer
        fbxurl={isShowFbxImageViewerModel.fbxURL}
        isShow={isShowFbxImageViewerModel.isShow}
        handleOnClose={() => {
          setIsShowFbxImageViewerModel({
            ...isShowFbxImageViewerModel,
            isShow: false,
          });
        }}
      />
    </div>
  );
};

export default StoreListingDetails;
