import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import React, { useState, useCallback, useEffect } from "react";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import App from "./App";

import reportWebVitals from "./components/reportWebVitals";
import Home from "./components/Home";
import NFTDetails from "./Views/NFTDetails";
import SpecificNFTDetails from "./Views/SpecificNFTDetails";
import NFTTransferResult from "./Views/NFTTransferResult";
//import ClaimResult from "./ClaimResult";
import TestConnection from "./components/PhantomConnection";
import WalletClaim from "./components/WalletClaim";
import Claim from "./Views/Claim";
import ComingSoon from "./components/ComingSoon";
import OOPSMobile from "./components/OOPSMobile";
import Dashboard from "./Views/Dashboard";
import ClaimResult from "./Views/ClaimResult";
import Layout from "./components/Layout";
import StakingHome from "./Views/StakingHome";
import StakingClaim from "./Views/StakingClaim";
import StakingDetails from "./Views/StakingDetails";
import StakingResult from "./Views/StakingResult";
import StakingPools from "./Views/StackingPools";
import HomeScreen from "./Views/HomeScreen";
import LandingPage from "./Views/LandingPage";
import LauncherPage from "./Views/Launcher";
import SidBar from "./components/SideBar/SideBar";
import Header from "./components/Header/HeaderUi";
import CockpitDashboard from "./Views/CockpitDashboard";
import CockpitLogin from "./Views/CockpitLogin";
import Marketplace from "./Views/Marketplace";
import UserDetails from "./Views/Admin/UserDetails";

import { HeaderText } from "./Constant/Constant";
import "./CockpitApp.scss";
import "./CockpitAppResponsive.scss";
import LandUI from "./Views/LandUI";
import {
  useConnection,
  useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { useQuery } from "react-query";
import ReactQueryKey from "./Constant/ReactQuery";
import Experience from "./Views/Experience";
import MyStores from "./Views/MyStores";
import MyStoresUpdate from "./Views/MyStores/MyStoresUpdate";
import Inventory from "./Views/Inventory/Inventory";
import ExperienceWithoutWallet from "./Views/ExperienceWithoutWallet";
import MetamallWallet from "./Views/MetamallWallet";
import Admin from "./Views/Admin/AdminMenu";
import { isMobile } from "./utils/CommonFunction";

const Router = () => {
  const refreshPage = (child: any) => {
    // console.log("refreshPage");
    return <PrivateRoute>{child}</PrivateRoute>;
  };

  const isMobile = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
  };

  // if (isMobile()) {
  //   return (
  //     <BrowserRouter>
  //       <Routes>
  //         <Route path="/connect">
  //           <Route index element={<OOPSMobile />} />
  //         </Route>
  //       </Routes>
  //     </BrowserRouter>
  //   );
  // }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/connect" element={<LandingPage />} />
        <Route path="/launcher" element={<LauncherPage />} />
        <Route path="/landsale">
          <Route index element={<Home />} />
          <Route path="staking-pools" element={<Dashboard />} />
          <Route path="staking-pools/mode=:mode" element={<Dashboard />} />
          <Route path="claim" element={<Claim />} />
          <Route path="nft-details/:id" element={<NFTDetails />} />
          <Route
            path="nft-details/store/uuid/:uuid"
            element={<SpecificNFTDetails />}
          />
          <Route path="nft-result" element={<NFTTransferResult />} />
          <Route path="claim-result/:trxid" element={<ClaimResult />} />
        </Route>
      </Routes>
      <Routes>
        <Route path="/staking">
          <Route index element={<StakingPools />} />
          <Route path="staking-pools" element={<StakingPools />} />
          <Route path="staking-pools/mode=:mode" element={<StakingPools />} />
          <Route path="claim" element={<StakingClaim />} />
          <Route path="staking-details/:id" element={<StakingDetails />} />
          <Route path="staking-result/:amount" element={<StakingResult />} />
          <Route path="claim-result/:trxid" element={<ClaimResult />} />
        </Route>
      </Routes>
      <Routes>
        <Route path="/mplace/*" element={<Marketplace />} />
      </Routes>
      <Routes>
        <Route path="/cockpit">
          <Route
            index
            element={
              <AppLayout>
                <CockpitDashboard />
              </AppLayout>
            }
          />
          <Route
            path="dashboard"
            element={
              <AppLayout>
                <CockpitDashboard />
              </AppLayout>
            }
          />
          <Route path="login" element={<CockpitLogin />} />
          <Route
            path="land"
            element={
              <AppLayout>
                <LandUI />
              </AppLayout>
            }
          />
          <Route
            path="experience"
            element={
              <AppLayout>
                <Experience />
              </AppLayout>
            }
          />
          <Route
            path="experienceWoWallet"
            element={
              <AppLayout>
                <ExperienceWithoutWallet />
              </AppLayout>
            }
          />

          <Route
            path="metamallwallet"
            element={
              <AppLayout>
                <MetamallWallet />
              </AppLayout>
            }
          />
          {/* <Route
            path="mystores"
            element={
              <AppLayout>
                <MyStores />
              </AppLayout>
            }
          /> */}
          <Route
            path="mystores/mystoresudpate/:storeId"
            element={
              <AppLayout>
                <MyStoresUpdate />
              </AppLayout>
            }
          />
          <Route
            path="mystores/mystoresudpate"
            element={
              <AppLayout>
                <MyStoresUpdate />
              </AppLayout>
            }
          />
          <Route
            path="inventory"
            element={
              <AppLayout>
                <Inventory />
              </AppLayout>
            }
          />
          <Route path="admin">
            <Route
              index
              element={
                <AppLayout>
                  <RecoilRoot>
                    <Admin />
                  </RecoilRoot>
                </AppLayout>
              }
            />
            <Route
              path="userdetails/:wallet_address"
              element={
                <AppLayout>
                  <UserDetails />
                </AppLayout>
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

interface AppLayoutProps {
  children: any;
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const location = useLocation();

  console.log(location);

  const headerTitle: string =
    (location && location.pathname.split("/")[2]) || "dashboard";
  console.log("headerTitle", headerTitle);
  const [isActiveSidebar, setIsActiveSidebar] = useState<boolean>(false);

  const handleOnClickSidebar = () => {
    setIsActiveSidebar(isActiveSidebar ? false : true);
  };

  return (
    <div className={"router-wrapper"}>
      <SidBar
        headerTitle={headerTitle}
        isActive={isActiveSidebar}
        handleToggleSidebar={handleOnClickSidebar}
      />
      <div className={"head-part-wrapper"}>
        {/*//@ts-ignore*/}
        <Header
          title={HeaderText[headerTitle].text}
          subTitle={HeaderText[headerTitle].subText}
          imgUrl={HeaderText[headerTitle].imag}
          handleToggleSidebar={handleOnClickSidebar}
        />
        <div className={"app-layout-wrapper"}>{children}</div>
      </div>
    </div>
  );
};

interface PrivateRouteProps {
  children: any;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const location = useLocation();
  const { data } = useQuery(ReactQueryKey.PATH_NAME, () => location.pathname);
  const { connected } = useWallet();
  console.log("connected", connected);
  return connected ? children : <Navigate to="/connect" />;
};

export default Router;
