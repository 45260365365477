import React, { useEffect, useState, useCallback } from "react";
import Title from "../../components/Title";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./MyStore.scss";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  FaAngleLeft,
  FaCheckCircle,
  FaCamera,
  FaIgloo,
  FaObjectGroup,
  FaQrcode,
  FaEdit,
} from "react-icons/fa";
import InputBox from "../../components/InputBox";
import axios from "axios";
import { Modal } from "react-bootstrap";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { ApiUrl, BASE_URL } from "../../Api/ApiUrl";
import { useQuery } from "react-query";
import ReactQueryKey from "../../Constant/ReactQuery";
import Card from "react-bootstrap/Card";
import { getNftTokenData } from "../../Solana/GetNFT";
import Loader from "../../components/Loader";
import Productcard from "../../components/ProductCard/ProductCard";
import NoDataFound from "../../components/NoDataFound";
import ErrorComponent from "../../components/ErrorComponent";
import { TableDataCondition } from "../../utils/CommonFunction";
import useAccessTocken from "../../Hooks/useAccessTocken";

export interface OwnerList {
  uuid: number;
  name: string;
  wallet_address: string;
  isNewAdded: boolean;
}

export interface ItemCreatorList {
  uuid: number;
  name: string;
  wallet_address: string;
  isNewAdded: boolean;
  user: OwnerList;
}

export interface ShopCreatorList {
  uuid: number;
  name: string;
  wallet_address: string;
  isNewAdded: boolean;
  user: OwnerList;
}
export interface Land {
  id: number;
  name: string;
  description: string;
  attributes: Array<string>;
  status: boolean;
  image: string;
  price: number;
  wallet_id: number;
}
let nftArray: Array<any> = [];
const MyStoresUpdate = () => {
  const access_token = useAccessTocken();
  const navigation = useNavigate();
  // const access_token = localStorage.getItem("access_token");
  const [loading, setLoading] = useState(false);
  const [storeData, setStoreData] = useState<any>();
  const [landType, setLandType] = useState<string>("");
  const [serialNo, setSerialNo] = useState<string>("");
  const [lstOwner, setLstOwner] = useState<Array<OwnerList>>();
  const [lstItemCreators, setLstItemCreators] =
    useState<Array<ItemCreatorList>>();
  const [lstShopCreators, setLstShopCreators] =
    useState<Array<ShopCreatorList>>();
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [ownerToAdd, setOwnerToAdd] = useState("");
  const [shopCreatorToAdd, setShopCreatorToAdd] = useState("");
  const [itemCreatorToAdd, setItemCreatorToAdd] = useState("");
  const [nftData, setNftData] = useState<Array<any>>([]);
  const [nft, setNft] = useState<null | Array<any>>(null);
  const [nftViewAll, setNFTViewAll] = useState<boolean>(false);

  const [stateUsedDetails, setUsedDetails] = useState({
    uuid: "",
    name: "",
    password: "",
    desc: "",
    logoUrl: "/images/CKPTLogo.png",
    storeLength: "",
    storeWidth: "",
    landType: "",
    landUrl: "",
    landNFTAddress: "",
  });

  let usedDetails = {
    uuid: "",
    name: "",
    password: "",
    desc: "",
    logoUrl: "/images/CKPTLogo.png",
    storeLength: "",
    storeWidth: "",
    landType: "",
    landUrl: "",
    landNFTAddress: "",
  };

  const confirmType = useCallback(
    (title: string, message: string, remove, walletAddress) => {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="react-confirm-alert-overlay">
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <h1>{title}</h1>
                <div>{message}</div>
                <div className="react-confirm-alert-button-group">
                  <button
                    className="proceed-button btn btn-primary"
                    onClick={async () => {
                      remove(walletAddress);
                      onClose();
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="proceed-button btn btn-primary"
                    onClick={async () => {
                      onClose();
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        ),
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    },
    []
  );

  const [isAddOwnerModalOpened, setIsAddOwnerModalOpened] = useState(false);
  const [isAddOwnerClicked, setIsAddOwnerClicked] = useState(false);
  const [isSelectLandModalOpened, setIsSelectLandModalOpened] = useState(false);

  const [isAddShopCreatorModalOpened, setIsAddShopCreatorModalOpened] =
    useState(false);
  const [isAddShopCreatorClicked, setIsAddShopCreatorClicked] = useState(false);
  const [isAddItemCreatorModalOpened, setIsAddItemCreatorModalOpened] =
    useState(false);
  const [isAddItemCreatorClicked, setIsAddItemCreatorClicked] = useState(false);
  const [isValidCreateStoreReq, setIsValidCreateStoreReq] = useState(true);

  const [landError, setLandError] = useState(false);

  const { storeId } = useParams<{ storeId: string }>();
  console.log(storeId);

  const AccessToken = useQuery(ReactQueryKey.SOCIAL_MEDIA_LOGIN, {
    enabled: false,
  });
  const Axios = axios.create({
    baseURL: BASE_URL,
    //@ts-ignore
    headers: { Authorization: `Bearer ${access_token}` },
  });

  async function onAddOwner(walletAddress: string) {
    try {
      Axios.post(ApiUrl.addToStore + `/${storeId}/owner/${walletAddress}`).then(
        (res) => {
          console.log(res);

          // for displaying
          let objClone = structuredClone(lstOwner);
          lstOwner?.push({
            ...objClone,
            wallet_address: walletAddress,
            isNewAdded: true,
          });

          // getStoreDetail();
          setLoading(false);
          setErrorMsg("");
          setIsAddOwnerModalOpened(false);
          setIsAddOwnerClicked(false);
          setOwnerToAdd("");
        }
      );
    } catch (err) {
      console.log(err);
      setLoading(false);
      setErrorMsg("");
      setIsAddOwnerModalOpened(false);
      setIsAddOwnerClicked(false);
    }
  }

  async function onAddShopCreator(walletAddress: string) {
    try {
      Axios.post(
        ApiUrl.addToStore + `/${storeId}/shopCreator/${walletAddress}`
      ).then((res) => {
        console.log(res);

        // for displaying
        let objClone = structuredClone(lstShopCreators);
        lstShopCreators?.push({
          ...objClone,
          user: { wallet_address: walletAddress },
          isNewAdded: true,
        });

        setLoading(false);
        setErrorMsg("");
        setIsAddShopCreatorModalOpened(false);
        setIsAddShopCreatorClicked(false);
        setShopCreatorToAdd("");
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setErrorMsg("");
      setIsAddShopCreatorModalOpened(false);
      setIsAddShopCreatorClicked(false);
    }
  }

  async function onAddItemCreator(walletAddress: string) {
    try {
      Axios.post(
        ApiUrl.addToStore + `/${storeId}/itemCreator/${walletAddress}`
      ).then((res) => {
        console.log(res);

        // for displaying
        let objClone = structuredClone(lstItemCreators);
        lstItemCreators?.push({
          ...objClone,
          user: { wallet_address: walletAddress },
          isNewAdded: true,
        });

        setLoading(false);
        setErrorMsg("");
        setIsAddItemCreatorModalOpened(false);
        setIsAddItemCreatorClicked(false);
        setItemCreatorToAdd("");
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setErrorMsg("");
      setIsAddItemCreatorModalOpened(false);
      setIsAddItemCreatorClicked(false);
    }
  }

  async function removeOwner(walletAddress: string) {
    try {
      Axios.delete(
        ApiUrl.removeOwner + `/${storeId}/owner/${walletAddress}`
      ).then(() => {
        setLstOwner(
          lstOwner?.filter((c) => c.wallet_address !== walletAddress)
        );
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function removeItemCreators(walletAddress: string) {
    try {
      Axios.delete(
        ApiUrl.removeStoreItemCreator +
          `/${storeId}/itemCreator/${walletAddress}`
      ).then(() => {
        setLstItemCreators(
          lstItemCreators?.filter(
            (c) => c.user.wallet_address !== walletAddress
          )
        );
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function removeShopCreators(walletAddress: string) {
    try {
      Axios.delete(
        ApiUrl.removeStoreShopCreator +
          `/${storeId}/shopCreator/${walletAddress}`
      ).then(() => {
        setLstShopCreators(
          lstShopCreators?.filter(
            (c) => c.user.wallet_address !== walletAddress
          )
        );
      });
    } catch (err) {
      console.log(err);
    }
  }
  async function onSelectLand(objLand: Land) {
    setUsedDetails({
      ...stateUsedDetails,
      landType: objLand.name,
      landUrl: objLand.image,
      landNFTAddress: nftData[0],
    });
    usedDetails = { ...stateUsedDetails };
  }

  const [image_file, setImage_file] = useState<string>("");
  const handleImagePreview = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    setUsedDetails({ ...stateUsedDetails, logoUrl: image_as_base64 });
    setImage_file(image_as_files);
  };

  useEffect(() => {
    getStoreDetail();
    getLands();
  }, [storeId]);

  const getStoreDetail = async () => {
    if (storeId !== undefined) {
      Axios.get(ApiUrl.getStore + `/${storeId}`)
        .then((res) => {
          console.log(">>> res", res.data);
          setStoreData(res.data);
          setLstOwner(res.data.owners);
          setLstItemCreators(res.data.itemCreators);
          setLstShopCreators(res.data.shopCreators);
          setLandType(res.data.land.type);
          setSerialNo(res.data.land.serialNo);
          setUsedDetails({
            ...stateUsedDetails,
            uuid: res.data.uuid,
            name: res.data.name,
            desc: res.data.description,
            logoUrl:
              res.data.logo === null ? "/images/CKPTLogo.png" : res.data.logo,
            storeLength:
              res.data.size && res.data.size.length > 0 ? res.data.size[0] : "",
            storeWidth:
              res.data.size && res.data.size.length > 0 ? res.data.size[1] : "",
          });
        })
        .catch((err) => {
          console.log(">>> err", err);
        });
    }
  };

  const getLands = async () => {
    try {
      const res: any = await getNftTokenData();
      console.log("res", res);
      res && setNftData(res.nftData);
      nftArray = res && [...res.nftData];
      res && setNft(res.arr);
    } catch (err) {
      setLandError(true);
    }
  };

  const createStoreReq = async () => {
    if (
      stateUsedDetails.landNFTAddress.trim() === "" ||
      stateUsedDetails.name.trim() === "" ||
      stateUsedDetails.storeLength.trim() === "" ||
      stateUsedDetails.storeWidth.trim() === ""
    ) {
      setIsValidCreateStoreReq(false);
      return;
    }

    let arrSize = [stateUsedDetails.storeLength, stateUsedDetails.storeWidth];
    Axios.put(ApiUrl.createStore, {
      landNFTAddress: stateUsedDetails.landNFTAddress,
      size: arrSize,
      name: stateUsedDetails.name,
      type: stateUsedDetails.landType,
      address: "102, 1st Floor",
      description: stateUsedDetails.desc,
      tagline: "Your Style, Your Store",
    })
      .then(async (res) => {
        const formData = new FormData();
        formData.append("file", image_file);
        await Axios.put(ApiUrl.updateStoreLogo + `/${res.data.uuid}`, formData);
        await navigation(`/cockpit/mystores/mystoresudpate/${res.data.id}`);
      })
      .catch((err) => {
        console.log(">>> err", err);
      });
  };

  const submitStoreReq = async () => {
    let arrSize = [stateUsedDetails.storeLength, stateUsedDetails.storeWidth];
    const url = ApiUrl.updateStore + `/${storeId}`;
    Axios.post(url, {
      name: stateUsedDetails.name,
      description: stateUsedDetails.desc,
      size: arrSize,
    })
      .then(() => {
        if (image_file !== "") {
          const formData = new FormData();
          formData.append("file", image_file);
          Axios.put(
            ApiUrl.updateStoreLogo + `/${stateUsedDetails.uuid}`,
            formData
          );
        }
      })
      .then(() => {
        dispatchEvent(new Event("load"));
      })
      .catch((err) => {
        console.log(">>> err", err);
      });
  };

  const nftClicked = (id: number) => {
    console.log("nftData[id]", nftData[id]);
  };

  return (
    <>
      <div className={"row updateMyStore"}>
        <div style={{ display: "contents" }}>
          <Button
            variant="primary active shadow-none btn-icon"
            onClick={() => {
              navigation(-1);
            }}
            style={{ margin: "0 15px" }}
          >
            <FaAngleLeft />
          </Button>
          <Title
            firstName=""
            remainName={storeId !== undefined ? "EDIT STORE" : "ADD STORE"}
            showBuyBtn={false}
            imageUrl={""}
          />
        </div>

        <div className="row">
          <div className="col-sm-12">
            {storeId === undefined && (
              <>
                <div
                  className={"mall-container blkLandUpload"}
                  style={
                    isValidCreateStoreReq === false &&
                    stateUsedDetails.landNFTAddress === ""
                      ? {
                          border: "1px solid rgb(255, 77, 79)",
                          marginBottom: "0",
                        }
                      : {}
                  }
                >
                  <div className={"mall-blkLandSelect"}>
                    <div className="launcher-wrapper">
                      {stateUsedDetails.landUrl === "" ? (
                        <div className="text">No land piece selected</div>
                      ) : (
                        <>
                          <img
                            src={stateUsedDetails.landUrl}
                            alt="dashboard logo"
                          ></img>
                          <span className="regular">
                            {stateUsedDetails.landType}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <div className={"coinbase-button"}>
                      <PrimaryButton
                        name="SELECT LAND"
                        className="primary-btn download"
                        onClick={async () => {
                          setIsSelectLandModalOpened(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {isValidCreateStoreReq === false &&
                  stateUsedDetails.landNFTAddress === "" && (
                    <div className="invalid-labellable">Land is required</div>
                  )}
              </>
            )}

            <div className="flex mall-container">
              <div className="w-100">
                {storeId !== undefined && (
                  <div
                    className="header-title land-owned-title"
                    style={{ width: "99%" }}
                  >
                    <FaIgloo className="header-icon" />
                    <span className="bold">{landType}</span>
                    <span className="regular"> {serialNo}</span>
                    <span
                      className="flag btn-primary"
                      style={{ float: "right", padding: "8px" }}
                      onClick={() => navigation(`/mplace/store/${storeId}`)}
                    >
                      Inventory items{" "}
                    </span>
                  </div>
                )}

                <div className="row size-box ">
                  <div className="col-auto">
                    <InputBox
                      label={"Store Size"}
                      placholder={""}
                      onChange={(e) => {
                        usedDetails = { ...stateUsedDetails };
                        usedDetails.storeLength = e.target.value;
                        setUsedDetails(usedDetails);
                      }}
                      message="Enter store size"
                      type="number"
                      value={stateUsedDetails.storeLength}
                      isValid={
                        isValidCreateStoreReq === false &&
                        stateUsedDetails.storeLength.trim() === ""
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="col-auto">
                    <span>X</span>
                  </div>
                  <div className="col-auto">
                    {" "}
                    <InputBox
                      label={""}
                      placholder={""}
                      onChange={(e) => {
                        usedDetails = { ...stateUsedDetails };
                        usedDetails.storeWidth = e.target.value;
                        setUsedDetails(usedDetails);
                      }}
                      message="Enter store size"
                      type="number"
                      value={stateUsedDetails.storeWidth}
                      isValid={
                        isValidCreateStoreReq === false &&
                        stateUsedDetails.storeWidth.trim() === ""
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>

                <InputBox
                  label={"Store Name"}
                  placholder={"Store Name"}
                  onChange={(e) => {
                    usedDetails = { ...stateUsedDetails };
                    usedDetails.name = e.target.value;
                    setUsedDetails(usedDetails);
                  }}
                  message="Enter name"
                  type="Default"
                  value={stateUsedDetails.name}
                  isValid={
                    isValidCreateStoreReq === false &&
                    stateUsedDetails.name.trim() === ""
                      ? true
                      : false
                  }
                />
                <div className="inputbox-style">
                  <div className="lable">
                    Store Logo
                    <label onChange={(e) => handleImagePreview(e)}>
                      <input type="file" />
                      <FaEdit className="icon" />
                    </label>
                    <input type="file" className="btn-file"></input>
                  </div>
                  <img
                    src={stateUsedDetails.logoUrl}
                    alt="store log"
                    key={Date.now()}
                  />
                  {/* <div>
                    <label className="btn-primary btn-file-label active" onChange={(e) => handleImagePreview(e)}>
                      <input type="file" />
                      <FaCamera />
                      <span className="p-1">Update Logo</span>
                    </label>
                    <input type="file" className="btn-file"></input>
                  </div> */}
                </div>

                <InputBox
                  label={"Description"}
                  placholder={"Description"}
                  onChange={(e) => {
                    usedDetails = { ...stateUsedDetails };
                    usedDetails.desc = e.target.value;
                    setUsedDetails(usedDetails);
                  }}
                  message="Description"
                  type="text-area"
                  rows={5}
                  value={stateUsedDetails.desc}
                />
                {/* {storeId !== undefined && (
                  <>
                    <div className="inputbox-style">
                      <div className="lable">Store Owners</div>
                      <div className="box-section">
                        {lstOwner !== undefined &&
                          lstOwner.map((k, i) => {
                            return (
                              <span className="multiboxSelection" key={i}>
                                <label> {k.wallet_address}</label>
                                {storeData.primaryOwner.wallet_address !== k.wallet_address ? (
                                  <Button
                                    variant="primary active shadow-none btn-icon"
                                    size="sm"
                                    onClick={() => {
                                      confirmType(
                                        "Remove Owner",
                                        "Are you want to sure remove this owner?",
                                        removeOwner,
                                        k.wallet_address
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  <Button variant="primary active shadow-none btn-icon" size="sm">
                                    Primary Owner
                                  </Button>
                                )}
                              </span>
                            );
                          })}
                        <Button
                          variant="primary active shadow-none m-auto"
                          onClick={async () => {
                            setIsAddOwnerModalOpened(true);
                          }}
                          size="lg"
                        >
                          ADD OWNERS
                        </Button>{" "}
                      </div>
                    </div>
                    <div className="inputbox-style">
                      <div className="lable">Item Creators</div>
                      <div className="box-section">
                        {lstItemCreators !== undefined &&
                          lstItemCreators.map((k, i) => {
                            return (
                              <span className="multiboxSelection" key={i}>
                                <label> {k.user.wallet_address}</label>
                                <Button
                                  variant="primary active shadow-none btn-icon"
                                  size="sm"
                                  onClick={() => {
                                    confirmType(
                                      "Remove item",
                                      "Are you want to sure remove this item?",
                                      removeItemCreators,
                                      k.user.wallet_address
                                    );
                                  }}
                                >
                                  Remove
                                </Button>
                              </span>
                            );
                          })}
                        <Button
                          variant="primary active shadow-none m-auto"
                          size="lg"
                          onClick={async () => {
                            setIsAddItemCreatorModalOpened(true);
                          }}
                        >
                          ITEM CREATORS
                        </Button>{" "}
                      </div>
                    </div>

                    <div className="inputbox-style">
                      <div className="lable">Shop Creators</div>
                      <div className="box-section">
                        {lstShopCreators !== undefined &&
                          lstShopCreators.map((k, i) => {
                            return (
                              <span className="multiboxSelection" key={i}>
                                <label> {k.user.wallet_address}</label>
                                <Button
                                  variant="primary active shadow-none btn-icon"
                                  size="sm"
                                  onClick={async () => {
                                    confirmType(
                                      "Remove shop",
                                      "Are you want to sure remove this shop?",
                                      removeShopCreators,
                                      k.user.wallet_address
                                    );
                                  }}
                                >
                                  Remove
                                </Button>
                              </span>
                            );
                          })}
                        <Button
                          variant="primary active shadow-none m-auto"
                          size="lg"
                          onClick={async () => {
                            setIsAddShopCreatorModalOpened(true);
                          }}
                        >
                          ADD SHOP CREATORS
                        </Button>{" "}
                      </div>
                    </div>
                  </>
                )} */}

                <div className="">
                  {storeId !== undefined ? (
                    <>
                      <Button
                        className="mb-1"
                        variant="primary"
                        size="lg"
                        onClick={submitStoreReq}
                      >
                        SAVE
                      </Button>
                      {/* <Button variant="primary" size="lg" onClick={submitStoreReq}>
                        NEXT
                      </Button> */}
                      {/* <Button
                        variant="primary"
                        size="lg"
                        onClick={() => {
                          navigation(`/cockpit/mystores `);
                        }}
                      >
                        BACK
                      </Button> */}
                    </>
                  ) : (
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={createStoreReq}
                    >
                      Create
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -----  MODAL TO ADD OWNER TO THE STORE ----- */}
      <Modal
        show={isAddOwnerModalOpened}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge"
      >
        <div className="header-wrapper">
          <div>
            <img
              className={"landing-logo"}
              src={`${process.env.PUBLIC_URL}/images/lock.png`}
              alt="dashboard logo"
            />
          </div>
          <div className="header-text-wrapper">
            <div className="header-text">Add owner to the store</div>
            <div className="header-sub-text"></div>
          </div>
        </div>
        <div className="header-wrapper">
          <div className="header-text-wrapper" style={{ width: "100%" }}>
            <div className="password-box">
              <InputBox
                label={"Enter Wallet Address"}
                placholder={"Wallet Address"}
                message=""
                value={ownerToAdd}
                onChange={(e) => {
                  setOwnerToAdd(e.target.value);
                }}
              />
            </div>
            <div style={{ textAlign: "center" }}>{errorMsg}</div>
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name={"Add Owner"}
            disabled={isAddOwnerClicked}
            onClick={async () => {
              setErrorMsg("");
              setLoading(true);
              setIsAddOwnerClicked(true);
              const resp = await onAddOwner(ownerToAdd);
              setIsAddOwnerClicked(false);
            }}
            className="proceed-button"
            style={{ marginRight: "2rem" }}
          />
          <PrimaryButton
            name={"Cancel"}
            disabled={isAddOwnerClicked}
            onClick={() => {
              setErrorMsg("");
              setIsAddOwnerModalOpened(false);
              setIsAddOwnerClicked(false);
            }}
            className="proceed-button"
          />
        </div>
      </Modal>

      {/* -----  MODAL TO ADD SHOP CREATOR TO THE STORE ----- */}
      <Modal
        show={isAddShopCreatorModalOpened}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge"
      >
        <div className="header-wrapper">
          <div>
            <img
              className={"landing-logo"}
              src={`${process.env.PUBLIC_URL}/images/lock.png`}
              alt="dashboard logo"
            />
          </div>
          <div className="header-text-wrapper">
            <div className="header-text">Add shop creator to the store</div>
            <div className="header-sub-text"></div>
          </div>
        </div>
        <div className="header-wrapper">
          <div className="header-text-wrapper" style={{ width: "100%" }}>
            <div className="password-box">
              <InputBox
                label={"Enter Wallet Address"}
                placholder={"Wallet Address"}
                message=""
                value={shopCreatorToAdd}
                onChange={(e) => {
                  setShopCreatorToAdd(e.target.value);
                }}
              />
            </div>
            <div style={{ textAlign: "center" }}>{errorMsg}</div>
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name={"Add Shop Creator"}
            disabled={isAddShopCreatorClicked}
            onClick={async () => {
              setErrorMsg("");
              setLoading(true);
              setIsAddShopCreatorClicked(true);
              const resp = await onAddShopCreator(shopCreatorToAdd);
              setIsAddShopCreatorClicked(false);
            }}
            className="proceed-button"
            style={{ marginRight: "2rem" }}
          />
          <PrimaryButton
            name={"Cancel"}
            disabled={isAddShopCreatorClicked}
            onClick={() => {
              setErrorMsg("");
              setIsAddShopCreatorModalOpened(false);
              setIsAddShopCreatorClicked(false);
            }}
            className="proceed-button"
          />
        </div>
      </Modal>

      {/* -----  MODAL TO ADD ITEM CREATOR TO THE STORE ----- */}
      <Modal
        show={isAddItemCreatorModalOpened}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge"
      >
        <div className="header-wrapper">
          <div>
            <img
              className={"landing-logo"}
              src={`${process.env.PUBLIC_URL}/images/lock.png`}
              alt="dashboard logo"
            />
          </div>
          <div className="header-text-wrapper">
            <div className="header-text">Add item creator to the store</div>
            <div className="header-sub-text"></div>
          </div>
        </div>
        <div className="header-wrapper">
          <div className="header-text-wrapper" style={{ width: "100%" }}>
            <div className="password-box">
              <InputBox
                label={"Enter Wallet Address"}
                placholder={"Wallet Address"}
                message=""
                value={itemCreatorToAdd}
                onChange={(e) => {
                  setItemCreatorToAdd(e.target.value);
                }}
              />
            </div>
            <div style={{ textAlign: "center" }}>{errorMsg}</div>
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name={"Add Item Creator"}
            disabled={isAddItemCreatorClicked}
            onClick={async () => {
              setErrorMsg("");
              setLoading(true);
              setIsAddItemCreatorClicked(true);
              const resp = await onAddItemCreator(itemCreatorToAdd);
              setIsAddItemCreatorClicked(false);
            }}
            className="proceed-button"
            style={{ marginRight: "2rem" }}
          />
          <PrimaryButton
            name={"Cancel"}
            disabled={isAddItemCreatorClicked}
            onClick={() => {
              setErrorMsg("");
              setIsAddItemCreatorModalOpened(false);
              setIsAddItemCreatorClicked(false);
            }}
            className="proceed-button"
          />
        </div>
      </Modal>

      {/* -----  MODAL TO SELECT LAND ----- */}
      <Modal
        show={isSelectLandModalOpened}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge model-landSelect modelSelectLand"
      >
        <div className="header-wrapper">
          <div className="header-text-wrapper" style={{ width: "100%" }}>
            <div className="row blkLandSelectionArr">
              {nft === null ? (
                <Loader className={"bootstrap-loader"} />
              ) : (
                <>
                  {nft && nft.length > 0 ? (
                    //@ts-ignore
                    TableDataCondition(nft, nftViewAll).map(
                      ({ data }: any, index: number) => (
                        <>
                          <div
                            className="col-lg-6 col-sm-12 col-xl-4 col-md-6 land-wrapper"
                            key={index}
                          >
                            <Productcard
                              name={data.name}
                              image={data.image}
                              showButton={false}
                              index={index}
                              nftClicked={(id: number) => {
                                onSelectLand(data);
                                setIsSelectLandModalOpened(false);
                                nftClicked(id);
                              }}
                            />
                          </div>
                        </>
                      )
                    )
                  ) : (
                    <NoDataFound />
                  )}
                  {/* </div> */}
                  {nft && nft.length > 5 && (
                    <div className={"view-all-wrapper"}>
                      <PrimaryButton
                        onClick={() => setNFTViewAll(!nftViewAll)}
                        name={nftViewAll ? "HIDE" : "VIEW ALL"}
                        className={"view-all"}
                      />
                    </div>
                  )}
                </>
              )}
              {landError && (
                <ErrorComponent
                  text={"Internal Server Error"}
                  image={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name={"Cancel"}
            disabled={isAddOwnerClicked}
            onClick={() => {
              setIsSelectLandModalOpened(false);
            }}
            className="proceed-button"
          />
        </div>
      </Modal>
    </>
  );
};

export default MyStoresUpdate;
