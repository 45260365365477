import React, { useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";

import Layout from "../../components/Layout";
import Banner from "./components/Banner";
import Listings from "./components/Listings";
import Assets from "./components/Assets";
import ListingDetail from "./components/ListingDetail";
import TemplateListingDetail from "./components/TemplateListingDetail";
import SellNFT from "./components/SellNFT";

import "./index.scss";
import { walletLogin } from "./hooks/useAuctionData";
import { makeString } from "../../utils/CommonFunction";

import StoreListings from "./components/StoreListings";
import StoreListingDetails from "./components/StoreListingDetails";

let introVisible = false;

const Marketplace = (props) => {
  const introRef = useRef();
  const { publicKey, connected } = useWallet();
  const [scrolled, setScrolled] = useState(false);

  const connectWallet = async () => {
    //let crptoMsg = {message: '', signedMessage: ''};
    //@ts-ignore
    const { solana } = window;
    console.log("connectWallet function");
    if (solana) {
      try {
        //const response = await solana.connect();
        const message = makeString(15);
        const encodedMessage = new TextEncoder().encode(message);
        const signedMessage = await solana.request({
          method: "signMessage",
          params: {
            message: encodedMessage,
            display: "hex",
          },
        });
        walletLogin({
          walletId: publicKey?.toString(),
          message,
          signedMessage: signedMessage.signature,
        });
      } catch (err) {}
    }
  };

  useEffect(() => {
    if (connected) {
      connectWallet();
    }
  }, [connected]);

  const handleScroll = (e) => {
    const {
      srcElement: { scrollTop },
    } = e;
    if (scrollTop > 100) {
      setScrolled(true);
    }
    if (scrollTop === 0) {
      setScrolled(false);
    }
  };

  useEffect(() => {
    const marketplace = document.getElementById("marketplace");
    marketplace?.addEventListener("scroll", handleScroll);
    return () => {
      marketplace?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout scrolled={scrolled}>
      <div className="marketplace">
        <Banner />
        <Routes>
          <Route index element={<Listings />} />
          <Route path="/all" element={<Listings />} />
          <Route path="/all/:type" element={<Listings />} />
          <Route path="/assets" element={<Assets publicKey={publicKey} />} />
          <Route path="/sell/:tokenAddress" element={<SellNFT />} />
          <Route path="/:id" element={<ListingDetail />} />
          <Route path="/template/:id" element={<TemplateListingDetail />} />
          <Route path="/store">
            <Route index element={<StoreListings />} />
            <Route path="/store/:id" element={<StoreListingDetails />} />
          </Route>
        </Routes>
      </div>
    </Layout>
  );
};

export default Marketplace;
