import { Button } from "react-bootstrap";
import Productcard from "../../components/ProductCard/ProductCard";
import Title from "../../components/Title";
import "./LandUI.scss";
import { getNftTokenData } from "../../Solana/GetNFT";
import { useEffect, useState } from "react";
import ToastMessage from "../../components/TostMessage";
import { MarketPlaceUrl } from "../../Constant/Constant";
import Loader from "../../components/Loader";
import useGetLiveAuction from "../../Hooks/useGetLiveAuction";
import Tag from "../../components/Tag";
import TableComponent from "../../components/Table/Table";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { TableDataCondition } from "../../utils/CommonFunction";
import NoDataFound from "../../components/NoDataFound";
import useGetLand from "../../Hooks/useGetLand";
import { useWindowSize } from "../../Hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import LandingPage from "../LandingPage";
import { useWallet } from "@solana/wallet-adapter-react";
import "../../style/CockpitStyle.scss";
import ErrorComponent from "../../components/ErrorComponent";
import { useQuery } from "react-query";
import ReactQueryKey from "../../Constant/ReactQuery";
import MyStores from "../../Views/MyStores";

const arra = [1, 2, 3, 4, 5, 6];
let nftArray: Array<any> = [];

const LandUI = () => {
  const navigate = useNavigate();
  const [nft, setNft] = useState<null | Array<any>>(null);
  const [nftData, setNftData] = useState<Array<any>>([]);
  const [viewAll, setViewAll] = useState(false);
  const [auctionViewAll, setAuctionViewAll] = useState(false);
  const [showToast, setShowTost] = useState(false);
  const [nftViewAll, setNFTViewAll] = useState<boolean>(false);
  const [landError, setLandError] = useState(false);
  const { connected } = useWallet();
  const AccessToken = useQuery(ReactQueryKey.SOCIAL_MEDIA_LOGIN, () => {}, {
    enabled: false,
  });
  const size = useWindowSize();
  const {
    isIdle,
    isLoading,
    isError,
    data = [],
    error,
    refetch,
  } = useGetLiveAuction();
  const LandDetails = useGetLand();
  useEffect(() => {
    if (nft === null) {
      fetchData();
    }
    showToast && hideToadet();
  }, [nft, showToast]);

  useEffect(() => {
    document.documentElement.style.setProperty("--base", "hidden");
  }, []);

  const columns3 = [
    {
      dataField: "type",
      text: "NAME",
    },
    {
      dataField: "serialNo",
      text: "serial No",
    },
    {
      dataField: "transferTransactionHash",
      text: "Transaction Hash",
      formatter: (cell: string) =>
        `${cell.slice(0, 20)}...${cell.slice(cell.length - 10, cell.length)}`,
    },
    {
      dataField: "nftTokenAddress",
      text: "Token Address",
      formatter: (cell: string) =>
        `${cell.slice(0, 20)}...${cell.slice(cell.length - 10, cell.length)}`,
    },
  ];

  const hideToadet = () => {
    setInterval(() => setShowTost(false), 1000);
  };
  const fetchData = async () => {
    try {
      const res: any = await getNftTokenData();
      console.log("res", res);
      res && setNftData(res.nftData);
      nftArray = res && [...res.nftData];
      res && setNft(res.arr);
    } catch (err) {
      setLandError(true);
    }
  };

  const leaseClicked = () => {
    setShowTost(true);
  };

  const buyLand: () => void = () => {
    navigate("/landsale");
  };

  const sellClicked = (id: number) => {
    console.log("nftArray", nftArray, id);
    window.open(`${MarketPlaceUrl}auction/create/${nftData[id]}/1`, " ");
  };

  const auctionClicked = (id: number) => {
    // window.open(`${MarketPlaceUrl}auctionLand/${data[id].id}`, " ");
    navigate(`/mplace/${data[id].id}`);
  };

  const nftClicked = (id: number) => {
    window.open(`${MarketPlaceUrl}art/mint/${nftData[id]}/`, " ");
  };

  console.log("data", data, LandDetails, size);
  //const scrollWidth = size.height ? (11.5 / 100) * size.height : 0;

  return (
    <div className={"land-center-wrapper"}>
      {showToast && (
        <ToastMessage
          className={"toast-message"}
          message="Lease not available now"
        />
      )}
      {/* <div className={"toast-message"}>
      </div> */}
      {LandDetails.data && LandDetails.data.length !== 0 && (
        <div className={"land-trans"}>
          <div className={"header-container"}>
            <Title
              firstName={"LAND "}
              remainName={"TRANSFER"}
              imageUrl={"images/LandTransfer.png"}
              showBuyBtn={false}
            />
            {LandDetails.data &&
              LandDetails.data.length !== 0 &&
              LandDetails.data.length > 5 && (
                <div className={"view-all-wrapper"}>
                  <PrimaryButton
                    onClick={() => setViewAll(!viewAll)}
                    name={viewAll ? "HIDE" : "view all"}
                    className={"view-all"}
                  />
                </div>
              )}
          </div>
          {LandDetails.data === undefined ? (
            <Loader className={"bootstrap-loader"} />
          ) : (
            //@ts-ignore
            LandDetails.data.length > 0 && (
              <div className={"land-wrapper"}>
                <TableComponent
                  columnsName={columns3}
                  data={TableDataCondition(LandDetails.data, viewAll) || []}
                  className={""}
                />
              </div>
            )
          )}
          {LandDetails.data && LandDetails.data.length === 0 && <NoDataFound />}
        </div>
      )}
      {LandDetails.isError && (
        <ErrorComponent
          text={"Internal Server Error"}
          image={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
        />
      )}
      {/* <div className={"land-owned"}>
        <Title
          firstName={"LAND  "}
          remainName={"OWNED"}
          imageUrl={"images/Land.png"}
          showBuyBtn={true}
          buyLand={buyLand}
          buttonText={"BUY LAND"}
          buttonClassName={"buy-button-style"}
          className="land-owned-title"
        />
        {nft === null ? (
          <Loader className={"bootstrap-loader"} />
        ) : (
          <>
            <div className={"land-wrapper"}>
              {nft && nft.length > 0 ? (
                //@ts-ignore
                TableDataCondition(nft, nftViewAll, 5).map(
                  ({ data }: any, index: number) => (
                    <Productcard
                      leaseClicked={leaseClicked}
                      name={data.name}
                      image={data.image}
                      showButton={true}
                      sellClicked={(id: number) => sellClicked(id)}
                      index={index}
                      nftClicked={(id: number) => nftClicked(id)}
                    />
                  )
                )
              ) : (
                <NoDataFound />
              )}
            </div>
            {nft && nft.length > 5 && (
              <div className={"view-all-wrapper"}>
                <PrimaryButton
                  onClick={() => setNFTViewAll(!nftViewAll)}
                  name={nftViewAll ? "HIDE" : "VIEW ALL"}
                  className={"view-all"}
                />
              </div>
            )}
          </>
        )}
        {landError && (
          <ErrorComponent
            text={"Internal Server Error"}
            image={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
          />
        )}
      </div> */}

      <div className={"land-owned ps-0 pb-0"}>
        {/* <Title
          firstName={"LAND  "}
          remainName={"OWNED"}
          imageUrl={"images/Land.png"}
          showBuyBtn={false}
          buyLand={buyLand}
          className="land-owned-title"
        /> */}
        <MyStores />
      </div>

      <div className={"land-owned"}>
        <Title
          firstName={"LIVE  "}
          remainName={"AUCTION"}
          imageUrl={"images/Land.png"}
          showBuyBtn={false}
          buyLand={buyLand}
          className="land-owned-title"
        />
        {data === undefined ? (
          <Loader className={"bootstrap-loader"} />
        ) : (
          data &&
          data.length > 0 && (
            <>
              <div className={"land-wrapper"} style={{ marginTop: "-12px" }}>
                {data &&
                  //@ts-ignore
                  TableDataCondition(data, auctionViewAll, 5).map(
                    ({ name, image }: any, index: number) => (
                      <Productcard
                        leaseClicked={leaseClicked}
                        name={name}
                        image={image}
                        showButton={false}
                        //sellClicked={(id: number) =>sellClicked(id)}
                        index={index}
                        nftClicked={(id: number) => auctionClicked(id)}
                        className={"product-card-wrapper"}
                      />
                    )
                  )}
              </div>
              {nft && nft.length > 5 && (
                <div className={"view-all-wrapper"}>
                  <PrimaryButton
                    onClick={() => setNFTViewAll(!nftViewAll)}
                    name={auctionViewAll ? "HIDE" : "VIEW ALL"}
                    className={"view-all"}
                  />
                </div>
              )}
            </>
          )
        )}
        {isError && (
          <ErrorComponent
            text={"Internal Server Error"}
            image={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
          />
        )}
        {data.length === 0 && <NoDataFound />}
      </div>

      {!connected && <LandingPage />}
    </div>
  );
};

export default LandUI;
