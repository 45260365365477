import useTokenTransfer from "../../Hooks/useTokenTransfer";
import "./TokenTransfer.scss";
import TableComponent from "../../components/Table/Table";
import { useCallback } from "react";
import Title from "../../components/Title";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { useState } from "react";
import Loader from "../../components/Loader";
import { useEffect } from "react";
import { TableDataCondition } from "../../utils/CommonFunction";
import NoDataFound from "../../components/NoDataFound";
import { useWallet } from "@solana/wallet-adapter-react";
import ErrorComponent from "../../components/ErrorComponent";

// const constColName = {
//     amount: 'Amont',
//     month: 'Month',
//     transferHash: 'Transfer Hash'
// }

const TokenTransfer = ({ wallet_address = "" }) => {
  const [viewAll, setViewAll] = useState<boolean>(false);
  const { isIdle, isLoading, isError, data, error } =
    useTokenTransfer(wallet_address);
  const [totalToken, setTotalToken] = useState<any>(null);
  const { connected } = useWallet();
  const createColumn = () => {
    if (data !== undefined) {
      const colNameData = [
        {
          text: "Transfer Hash",
          dataField: "transferHash",
          formatter: (cell: string) =>
            `${cell.slice(0, 25)}...${cell.slice(
              cell.length - 10,
              cell.length
            )}`,
        },
        {
          dataField: "month",
          text: "Month",
        },
        {
          dataField: "amount",
          text: "Amount",
          formatter: (cell: any, row: any, rowIndex: any, extraData: any) => (
            <div>
              <img
                className="mal-icon"
                src={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
                alt=""
              />
              {cell === null ? 0 : parseFloat(cell).toFixed(2)}
            </div>
          ),
        },
      ];

      // const colName = Object.keys(data[0]);
      // //@ts-ignore
      // const colNameData = colName.map((col: string) =>({dataField: col, text: constColName[col]}))
      return colNameData;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (totalToken === null && data !== undefined) {
      const totalToken = data.reduce(
        (a: number, token: any) => a + parseInt(token.amount),
        0
      );
      setTotalToken(totalToken);
    }
  }, [data]);

  useEffect(() => {
    document.documentElement.style.setProperty("--base", "auto");
  }, []);

  return (
    <div className={"token-tranfer"}>
      <div className={"staking-table"}>
        <div className={"header-container"}>
          <Title
            firstName={"TOKEN  "}
            remainName={"TRANSFER"}
            imageUrl={"images/Transfer.png"}
          />
        </div>
        {data !== undefined && data.length !== 0 && connected ? (
          <>
            <TableComponent
              columnsName={createColumn()}
              data={TableDataCondition(data, viewAll) || []}
              className={"side-border"}
            />
            <div className={"total-token"}>
              Total Token:{" "}
              <img
                className={"token-icon"}
                src={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
                alt=""
              />{" "}
              {totalToken !== null && totalToken !== 0 && !isNaN(totalToken)
                ? totalToken.toFixed(2)
                : 0}
            </div>
          </>
        ) : (data && data.length === 0) || !connected ? (
          <NoDataFound />
        ) : (
          <Loader className={"cockpit-loader"} />
        )}
        <div className={"view-all-wrapper"}>
          {data && data.length > 5 && (
            <PrimaryButton
              onClick={() => setViewAll(!viewAll)}
              name={viewAll ? "HIDE" : "VIEW ALL"}
              className={"view-all"}
            />
          )}
        </div>
      </div>
      {isError && (
        <ErrorComponent
          text={"Internal Server Error"}
          image={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
        />
      )}
    </div>
  );
};

export default TokenTransfer;
