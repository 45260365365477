import "./CockpitLogin.scss";
import { Button } from "react-bootstrap";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../style/CockpitStyle.scss";
import GoogleLogin from "react-google-login";
import { SocialMediaAppId } from "../../Constant/Constant";
import Layout from "../../components/Layout";
import { ApiUrl } from "../../Api/ApiUrl";
import { ApiCall } from "../../Api/ApiCall";

const CockpitLogin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("access_token") ? true : false
  );
  const autoButton = React.createRef();

  const navigation = useNavigate();
  async function onSuccess(e) {
    const token = e.tokenId;
    localStorage.clear();
    localStorage.setItem("googletoken", token);
    const AccessToken = await ApiCall("POST", ApiUrl.googlelogin, {
      tokenId: token,
    });
    localStorage.setItem("access_token", AccessToken.access_token);
    console.log("onSuccess", e);
    navigation("/cockpit/experienceWoWallet");
  }

  async function onFailure(e) {
    console.log("onFailure", e);
  }
  return (
    <>
      <Layout>
        <></>
        <div className="marketplace">
        <div className="centerpage">
        <div className="ckpt-logo">
          <img
            className={"landing-logo"}
            src={`${process.env.PUBLIC_URL}/images/CKPTLogo.png`}
            alt="dashboard logo"
          />
        </div>
        <div className="mall-container social-media-wrapper">
          <div className="socialmedia-button">
            <div className="google-login-custom-button">
              <Button
                className="login-button"
                onClick={() => {
                  navigation("/cockpit/dashboard?autoClick=true");
                }}
              >
                Connect Wallet
              </Button>
            </div>
          </div>

          <div className="or-line-wrapper">
            <div className="line"></div>
            <div className="or-text">OR</div>
          </div>

          <div className="socialmedia-button">
            <div className="google-login-custom-button">
              <GoogleLogin
                clientId={SocialMediaAppId.GOOGLE}
                buttonText="Login"
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="login-button btn btn-primary"
                  >
                    <div className="login-wrapper ">
                      <img
                        className={"google-logo google-login-custom-button"}
                        src={`${process.env.PUBLIC_URL}/images/GoogleIcon.png`}
                        alt="dashboard logo"
                      />
                      <div className="text">GOOGLE LOGIN</div>
                    </div>
                  </button>
                )}
                onSuccess={(e) => onSuccess(e)}
                onFailure={(e) => onFailure(e)}
                cookiePolicy={"single_host_origin"}
              />
            </div>
          </div>
        </div>
      </div>
      </div>
      </Layout>
      
    </>
  );
};

export default CockpitLogin;
