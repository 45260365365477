import React, { useState, useEffect } from "react";
import "../../components/ProductCard/ProductCard.scss";
import "./Inventory.scss";
import Loader from "../../components/Loader";
import Title from "../../components/Title";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { FaGamepad, FaGlobe, FaRegBuilding } from "react-icons/fa";
import InputBox from "../../components/InputBox";
import { ApiUrl, BASE_URL } from "../../Api/ApiUrl";
import axios from "axios";
import ReactQueryKey from "../../Constant/ReactQuery";
import { useQuery } from "react-query";
import TitleInventory from "./TitleInventory";
import { Modal } from "react-bootstrap";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { InputGroup, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NotFound from "../../components/NotFound";
import useAccessTocken from "../../Hooks/useAccessTocken";
import useConnectWallet from "../../Hooks/useConnectWallet";
import DropDownReactSelect from "../../components/DropDown/DropDownReactSelect";

export interface InventoryTemplate {
  id: number;
  name: string;
}

const InventoryTemplates = () => {
  const access_token = useAccessTocken();
  console.log("access_tokenaccess_token", access_token);
  const navigation = useNavigate();
  const [isLoadingSummay, setisLoadingSummay] = useState(false);
  const [isLoadingGameInventory, setIsLoadingGameInventory] = useState(false);
  const [isLoadingStoresList, setIsLoadingStoresList] = useState(false);
  const [isLoadingWebInventory, setIsLoadingWebInventory] = useState(false);
  const [showSuccesModal, setShowSuccesModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("Item moved successfully");
  const [lstGameInventory, setLstGameInventory] =
    useState<Array<InventoryTemplate>>();
  const [lstWebInventory, setLstWebInventory] =
    useState<Array<InventoryTemplate>>();
  const [isShowSelectStoreModel, setIsShowSelectStoreModel] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [selectedStoreId, setSelectedStoreId] = useState<number>();

  const [storesList, setStoresList] = useState<
    Array<{ value: string; label: string }>
  >([]);

  const handleDdlChange = (e) => {
    setSelectedStoreId(e.value);
    setSelectedStore(e);
  };

  const inventoryType = {
    INVENTORY_WEB: "web",
    INVENTORY_GAME: "game",
  };

  const Axios = axios.create({
    baseURL: BASE_URL,
    //@ts-ignore
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });

  async function getTemplateGameInventory() {
    setIsLoadingGameInventory(true);
    console.log("call >>>>> getTemplateGameInventory");
    if (access_token !== undefined) {
      await Axios.get<Array<InventoryTemplate>>(ApiUrl.templateGameInventory)
        .then((res) => {
          setIsLoadingGameInventory(false);
          setLstGameInventory(res.data);
          console.log(res.data);
        })
        .catch((c) => {
          setIsLoadingGameInventory(false);
        });
    }
  }

  async function getApplicableStoresForTemplate(templateId) {
    setIsLoadingStoresList(true);
    console.log("call >>>>> getApplicableStoresForTemplate");
    if (access_token !== undefined) {
      await Axios.get<Array<InventoryTemplate>>(
        `${ApiUrl.applicableStores}/${templateId}`
      )
        .then((res) => {
          setStoresList(
            res.data.map((x) => ({ value: x.id + "", label: x.name }))
          );
          if (res.data && res.data.length > 0) {
            setSelectedStoreId(res.data[0].id);
          }
          setIsLoadingStoresList(false);
          console.log(res.data);
        })
        .catch((c) => {
          setIsLoadingStoresList(false);
        });
    }
  }

  async function getTemplateWebInventory() {
    console.log("call >>>>> getTemplateWebInventory");
    setIsLoadingWebInventory(true);
    if (access_token !== undefined) {
      await Axios.get<Array<InventoryTemplate>>(ApiUrl.templateWebInventory)
        .then((res) => {
          setIsLoadingWebInventory(false);
          setLstWebInventory(res.data);
          console.log(res.data);
        })
        .catch((c) => {
          setIsLoadingWebInventory(false);
        });
    }
  }

  async function placeTemplate(storeId, templateId) {
    await Axios.post(`${ApiUrl.placeTemplate}`, {
      templateId: templateId,
      storeId: storeId,
    })
      .then((res) => {
        console.log({ res });
        setSelectedStoreId(undefined);
        setSelectedTemplateId(undefined);
        setStoresList([]);
        setSelectedStore(null);
        setIsShowSelectStoreModel(false);
        getTemplateGameInventory();
        getTemplateWebInventory();
        setModalMessage("Template placed successfully");
        setShowSuccesModal(true);
      })
      .catch((err) => {
        console.log(err);
        setSelectedStoreId(undefined);
        setSelectedTemplateId(undefined);
        setStoresList([]);
        setSelectedStore(null);
        setIsShowSelectStoreModel(false);
        getTemplateGameInventory();
        getTemplateWebInventory();
        setModalMessage("Some error occured");
        setShowSuccesModal(true);
      });
  }

  async function moveToWebInventory(id) {
    await Axios.post(`${ApiUrl.moveTemplateToWebInventory}/${id}`).then(
      (res) => {
        getTemplateGameInventory();
        getTemplateWebInventory();
        setModalMessage("Template moved successfully");
        setShowSuccesModal(true);
      }
    );
  }

  async function moveToGameInventory(id) {
    await Axios.post(`${ApiUrl.moveTemplateToGameInventory}/${id}`).then(
      (res) => {
        getTemplateGameInventory();
        getTemplateWebInventory();
        setModalMessage("Template moved successfully");
        setShowSuccesModal(true);
      }
    );
  }

  useEffect(() => {
    console.log("call >>>>>", access_token);
    if (!!access_token) {
      console.log("call >>>>> if (!access_token)", access_token);
      getTemplateGameInventory();
      getTemplateWebInventory();
    }
  }, [access_token]);

  const DivInventroy = ({ Type, ButtonText }) => {
    return (
      <>
        <Card className="cardInventoryItems">
          <Card.Body
            className="p-0"
            style={{
              background:
                (lstGameInventory !== undefined &&
                  lstGameInventory.length === 0) ||
                (lstWebInventory !== undefined && lstWebInventory.length === 0)
                  ? ""
                  : Type === inventoryType.INVENTORY_WEB
                  ? "#524b6e"
                  : "#3f385f",
            }}
          >
            <div className="row m-0">
              {Type === inventoryType.INVENTORY_GAME &&
                lstGameInventory !== undefined &&
                lstGameInventory.length !== 0 &&
                lstGameInventory.map((k, i) => {
                  return (
                    <div className="col-sm-6 " key={i}>
                      <DivInventroySection
                        ButtonText={ButtonText}
                        Item={k}
                        InventoryType={Type}
                        Index={i}
                      />
                    </div>
                  );
                })}

              {Type === inventoryType.INVENTORY_GAME &&
                lstGameInventory !== undefined &&
                lstGameInventory.length === 0 && (
                  <NotFound title="Game inventory item " />
                )}

              {Type === inventoryType.INVENTORY_WEB &&
                lstWebInventory !== undefined &&
                lstWebInventory.length === 0 && (
                  <NotFound title="Web inventory item " />
                )}

              {Type === inventoryType.INVENTORY_GAME &&
                isLoadingGameInventory && (
                  <Loader className={"cockpit-loader"} />
                )}

              {Type === inventoryType.INVENTORY_WEB &&
                isLoadingWebInventory && (
                  <Loader className={"cockpit-loader"} />
                )}

              {Type === inventoryType.INVENTORY_WEB &&
                lstWebInventory !== undefined &&
                lstWebInventory.length !== 0 &&
                lstWebInventory.map((k, i) => {
                  return (
                    <div className="col-sm-6" key={i}>
                      <DivInventroySection
                        ButtonText={ButtonText}
                        Item={k}
                        InventoryType={Type}
                        Index={i}
                      />
                    </div>
                  );
                })}
            </div>
          </Card.Body>
        </Card>
      </>
    );
  };

  const DivInventroySection = ({ ButtonText, Item, InventoryType, Index }) => {
    return (
      <Card>
        <Card.Body className="p-0">
          <div className="productCard">
            <div className={"nft-wrapper"}>
              <div className={"namePrice"}>
                <img
                  className={"header-icon"}
                  src={`${process.env.PUBLIC_URL}/images/chair.svg`}
                  alt=""
                />
                <span className={"cube"} title={Item.name}>
                  {Item.name}
                </span>
              </div>

              <div className={"detailsForTemplate"}>
                <div className="inputAndActionBoxForTemplate">
                  <Button
                    variant="primary active shadow-none m-auto"
                    onClick={async () => {
                      if (InventoryType === inventoryType.INVENTORY_GAME) {
                        await moveToWebInventory(Item.id);
                      }
                      if (InventoryType === inventoryType.INVENTORY_WEB) {
                        await moveToGameInventory(Item.id);
                      }
                    }}
                    size="sm"
                  >
                    {ButtonText}
                  </Button>
                </div>
                {InventoryType === inventoryType.INVENTORY_GAME && (
                  <div className="inputAndActionBoxForTemplate">
                    {" "}
                    <Button
                      variant="primary active shadow-none m-auto"
                      onClick={async () => {
                        setIsShowSelectStoreModel(true);
                        setSelectedTemplateId(Item.id);
                        getApplicableStoresForTemplate(Item.id);
                      }}
                      size="sm"
                    >
                      Place in a store
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div className="experience-wrapper land-center-wrapper mystorePage inventoryPage">
      <div className={"download-exe row"}>
        <div className="flex blkInventory">
          <div className="row">
            <div className="col-12">
              <PrimaryButton
                name="Buy Templates"
                className="primary-btn download"
                style={{ margin: "auto 5px" }}
                onClick={() => {
                  // window.open("/mplace/assets", "_blank");
                  navigation("/mplace/all/Template");
                }}
              />
            </div>

            <div className="col-xl-6 m-0 p-0">
              {
                <TitleInventory
                  InventoryTitle="Web Inventory"
                  Type={inventoryType.INVENTORY_WEB}
                />
              }
              {
                <DivInventroy
                  Type={inventoryType.INVENTORY_WEB}
                  ButtonText="Move to game inventory"
                />
              }
            </div>
            <div className="col-xl-6 m-0 p-0">
              <span className="inventoryTitle">
                {
                  <TitleInventory
                    InventoryTitle="Game Inventory"
                    Type={inventoryType.INVENTORY_GAME}
                  />
                }
                {
                  <DivInventroy
                    Type={inventoryType.INVENTORY_GAME}
                    ButtonText="Move to web inventory"
                  />
                }
              </span>
            </div>
          </div>
          {isLoadingSummay && <Loader className={"cockpit-loader"} />}
        </div>
      </div>

      {/* -----  MODAL TO SELECT LAND ----- */}
      <Modal
        show={showSuccesModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge model-landSelect modelMoveInvFurItems"
      >
        <div className="header-wrapper">
          <div className="header-text-wrapper" style={{ marginBottom: "0px" }}>
            <div className="header-text" style={{ textAlign: "center" }}>
              {modalMessage}
            </div>
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name={"OK"}
            size="md"
            onClick={() => {
              setShowSuccesModal(!showSuccesModal);
            }}
            className="proceed-button"
          />
        </div>
      </Modal>

      <Modal
        show={isShowSelectStoreModel}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge model-landSelect modelCreateInvFurItems"
      >
        <div className="header-wrapper">
          <div className="header-title">
            <FaRegBuilding className="header-icon" />
          </div>
          <div className="header-text-wrapper">
            <div className="header-text">Select Store</div>
            <div className="header-sub-text"></div>
          </div>
        </div>
        <div className="header-wrapper">
          <div className="header-text-wrapper" style={{ width: "100%" }}>
            <div className="row blkLandSelectionArr">
              <DropDownReactSelect
                title={"Store"}
                options={storesList}
                handleChange={handleDdlChange}
                selected={selectedStore || storesList[0]}
              />
            </div>
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name="Place template"
            onClick={() => {
              placeTemplate(selectedStoreId, selectedTemplateId);
            }}
            className="proceed-button"
            style={{ marginRight: "2rem" }}
          />
          <PrimaryButton
            name={"Cancel"}
            size="md"
            onClick={() => {
              setSelectedStoreId(undefined);
              setSelectedStore(null);
              setSelectedTemplateId(undefined);
              setStoresList([]);
              setIsShowSelectStoreModel(false);
            }}
            className="proceed-button"
          />
        </div>
      </Modal>
    </div>
  );
};

export default InventoryTemplates;
