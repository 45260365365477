import "./HomeScreen.css";
import Layout from "../../components/Layout";
import React, { useEffect, useRef } from "react";
import useWindowSize from "./useWindowSize";
import { ScrollRotate } from "react-scroll-rotate";
//@ts-ignore
import { ReactComponent as RotImage } from "../../imgg/rot.svg";
import useOnScreen from "./useOnScreen";
import { useState } from "react";
//import * as rot from '../../Video/MetaMall_Web_Hero.mp4';

let introVisible = false;

const HomeScreen = () => {
  const windowSize = useWindowSize();

  //2.
  const introRef = useRef();
 
  const scrollingContainerRef = useRef();
  const welcomeRotateImg = useRef();

  const welcomeConImg = useRef();
  //const welcomeCon = useRef();

  //@ts-ignore
  //const introObserver = useOnScreen(introRef);
  const [scrolled, setScrolled] = useState(false);
  //const welcomeObserver = useOnScreen(welcomeCon);

  // 3.
  const data = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  };

  let rotateImage = 0.00001;
  let cpyY = 0;

  // useEffect(() => {
  //   setBodyHeight();
  // }, [windowSize.height]);

  // const setBodyHeight = () => {
  //   document.body.style.height = `${
  //     //@ts-ignore
  //     scrollingContainerRef.current.getBoundingClientRect().height
  //   }px`;
  // };

  // // 5.
  // useEffect(() => {
  //   requestAnimationFrame(() => smoothScrollingHandler());
  // }, []);


  const handleScroll = (e) => {
    const {
      srcElement: { scrollTop },
    } = e;
    if (scrollTop > 100) {
      setScrolled(true);
    }
    if (scrollTop === 0) {
      setScrolled(false);
    }
  };

  useEffect(() => {
    const marketplace = document.getElementById("marketplace");
    marketplace?.addEventListener("scroll", handleScroll);
    return () => {
      marketplace?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect( () => {
    document.documentElement.style.setProperty('--base','auto');
  }, [])

  return (
    <Layout scrolled={scrolled} >
      {/* <div className="parent-wrapper">
        <div ref={scrollingContainerRef}> */}
          {/*@ts-ignore*/}
          <section className="without" data-scroll data-scroll-speed="-10" ref={introRef} >
            <div className="video parallax">
              <div className="overlay top"></div>
              <div className="center">
                <img src={require("../../img/mainlogo.png")} alt="" />
              </div>
              <video
                src={require("../../Video/MetaMall_Web_Hero.mp4")}
                autoPlay
                muted
                loop
              />
            </div>
          </section>
          <div className="static_page_yeah">
            <div className="left-line liner">
              <div className="block left-block"></div>
            </div>
            <div className="content-container">
              <section id=" video_intro" className="video-sec" style={{padding: '0px'}} >
                <div className="no-mobile">
                  <div id="show_bg_2" className="dude">
                    <div className="left half">
                      <h1>
                        <span>WHAT IS</span>
                        <br /> METAMALL?
                      </h1>
                      <p className="desc">
                        MetaMall will provide an unprecedented VR experience on
                        the blockchain.
                      </p>
                      <p>Watch the video to know more!</p>
                    </div>
                    <div className="right half">
                      <div className="box" id="box-cover">
                        <iframe
                          className="video"
                          src="https://drive.google.com/file/d/1WaFR1ryeyP7F1_083yBsJjhqNbN-dZaZ/preview"
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mobile">
                  <div id="show_bg_m" className="dude">
                    <div className="left">
                      <h1>
                        <span>WHAT IS</span>
                        <br /> METAMALL? 
                      </h1>
                      <p className="desc">
                        MetaMall will provide an unprecedented VR experience on
                        the blockchain.
                      </p>
                      <p>Watch the video to know more!</p>
                      <div className="box" id="box-cover-mobile">
                        <img
                          src={require("../../img/video-btn1.png")}
                          style={{
                            height: "120px",
                            margin: "auto",
                            display: "block",
                            cursor: "pointer",
                          }}
                          id="cover-m"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*@ts-ignore*/}
              <section className="intro">
                <div className="cont">
                  <div className="left" data-scroll data-scroll-speed="-1">
                    <h1>
                      WELCOME TO <b>METAMALL</b>
                    </h1>
                    <p className="small">The Decentralized Metaverse Mall</p>
                    <p>
                      Metamall will provide a first-of-its-kind virtual reality
                      (VR) experience on the blockchain, allowing users to
                      build, explore, and trade in their very own virtual mall.
                    </p>
                  </div>
                  <div className="right img" data-scroll data-scroll-speed="2" 
                   //@ts-ignore
                   >
                    <div>
                      <div className="rot-image-wrapper">
                        {/* <ScrollRotate method={"perc"} loops={3}> */}
                          <RotImage
                            style={{ left: "59%" }}
                            //@ts-ignore
                            //ref={welcomeRotateImg}
                          />
                        {/* </ScrollRotate> */}
                      </div>
                      <img
                        src={require("../../img/1_copy.png")}
                        alt=""
                       
                      />
                    </div>
                  </div>
                </div>
              </section>
               {/*@ts-ignore*/}
              <section className="intro2" >
                <div>
                   {/*@ts-ignore*/}
                  <div className="left img" data-scroll data-scroll-speed="2" >
                    <div className="rot-image-wrapper-welcome">
                      {/* <ScrollRotate method={"perc"} loops={3}> */}
                        <RotImage
                          style={{ left: "59%" }}
                          //@ts-ignore
                          //ref={welcomeRotateImg}
                        />
                      {/* </ScrollRotate> */}
                    </div>

                    <img
                      className="skewElem1"
                      src={require("../../img/2_copy.png")}
                      alt=""
                    />
                  </div>
                  <div className="right" data-scroll data-scroll-speed="-1">
                    <h1>
                      INTRODUCING <b>METAMALL</b>
                    </h1>
                    <p className="small">The Unique VR Experience</p>
                    <p>
                      Metamall offers a one-of-a-kind virtual reality experience
                      with a variety of themes, architecture, and interactive VR
                      components. Metamallers will be the name given to token
                      holders. It will serve as a primary center for crypto
                      enthusiasts of all levels. Users will have access to
                      crypto knowledge and immersive entertainment in one
                      location for the first time.{" "}
                    </p>
                  </div>
                </div>
                <div
                  className="wrap"
                  data-scroll
                  data-scroll-speed="3"
                  data-scroll-direction="horizontal"
                >
                  <div>
                    <div className="li">
                      <div></div>
                      <p>THE POWER OF DEFI & NFTS</p>
                    </div>
                    <div className="li">
                      <div></div>
                      <p>THE POWER OF DEFI & NFTS</p>
                    </div>
                    <div className="li">
                      <div></div>
                      <p>THE POWER OF DEFI & NFTS</p>
                    </div>
                  </div>
                </div>
              </section>
              <section className="coin">
                <div>
                  <div className="left" data-scroll data-scroll-speed="-1">
                    <p className="grey">EARN</p>
                    <h1>BUILD WEALTH BY SELLING,</h1>
                    <h1>
                      <b>LEASING, PLAYING, OR STAKING ASSETS</b>
                    </h1>
                    <p>
                      Metamallers are engaging in a highly exhilarating retail
                      and gaming experience and every activity will be an
                      opportunity to build wealth through earning tokens. Real
                      estate sales on Metamall happen in phases post IDO and
                      multiple income sources will be open. Metamallers can
                      build real wealth by selling, leasing or staking assets,
                      by winning in games, performing or hosting and through
                      various other activities
                    </p>
                  </div>
                  <div className="right img" data-scroll data-scroll-speed="2">
                    <img
                      className="rot rot3"
                      src={require("../../img/rot.png")}
                      alt=""
                      style={{ left: "60%" }}
                    />

                    <img
                      className="skewElem2"
                      src={require("../../img/3_copy.png")}
                      alt=""
                    />
                  </div>
                </div>
              </section>
              <section className="heart">
                <div>
                  <div className="left img" data-scroll data-scroll-speed="2">
                    <img
                      className="rot rot4"
                      src={require("../../img/rot.png")}
                      alt=""
                      style={{ left: "38%" }}
                    />

                    <img
                      className="skewElem1"
                      src={require("../../img/4_copy.png")}
                      alt=""
                    />
                  </div>
                  <div className="right" data-scroll data-scroll-speed="-1">
                    <p className="grey">CREATE & WIN</p>
                    <h1>
                      OWN, BUILD <b>AND </b>
                    </h1>
                    <h1>
                      <b>DELIVER EXPERIENCES</b>
                    </h1>
                    <p>
                      On Metamall, users will be able to create stores, games,
                      arena and experiences. Metamall will constantly evolve
                      with users’ imagination and creativity which in turn will
                      further enrich user experience and build engagement and
                      wealth on platform.
                    </p>
                  </div>
                </div>
              </section>
              <section className="joys">
                <div>
                  <div className="left" data-scroll data-scroll-speed="-1">
                    <p className="grey">GAME ON</p>
                    <h1>SHOP, MEET,</h1>
                    <h1>
                      PLAY, <b>WIN</b>
                    </h1>
                    <p>
                      Metamallers can have their own high street, lounges and
                      game zones. Users can organise, host and win competitions
                      on the Metamall as well as shop, meet and engage with
                      friends.
                    </p>
                  </div>
                  <div className="right img" data-scroll data-scroll-speed="2">
                    <img
                      className="rot rot5"
                      src={require("../../img/rot.png")}
                      alt=""
                      style={{ left: "59%" }}
                    />
                    <img
                      className="skewElem2"
                      src={require("../../img/5_copy.png")}
                      alt=""
                    />
                  </div>
                </div>
              </section>
              <section className="team">
                <div className="no-mobile">
                  <h1
                    className="boldHeading"
                    style={{ textAlign: "center", marginBottom: "4%;" }}
                  >
                    TEAM &amp; ADVISORS{" "}
                  </h1>
                </div>
                <div className="mobile">
                  <h1
                    className="boldHeading"
                    style={{ textAlign: "center", marginBottom: "4%;" }}
                  >
                    TEAM &amp; <br />
                    ADVISORS{" "}
                  </h1>
                </div>
                <div className="dudes" id="team">
                  <div className="dude">
                    <img src={require("../../img/dudeL.png")} alt="" />
                    <h1 className="bold">SERGE GIANCHANDANI</h1>
                    <h3>CO-FOUNDER OF METAMALL | SERIAL ENTREPRENEUR</h3>
                    <div>
                      <p> 12 patents on 3D and VR imagery.</p>
                      <p>
                        Founded Egg Solution Optronics. In 2001 became the
                        leader in immersive technology. The company was
                        initially based in France and later was acquired by a US
                        technology group.
                      </p>
                      <p>
                        Co-founded Moksha and scaled from Zero to $20 million
                        with 300+ clients across the globe like Amazon,Kmart,
                        Flipkart etc.
                      </p>
                      <p>
                        Sold his 1st company to Wave Com (Anant Nahata) the
                        owner of KOOVS
                      </p>
                    </div>
                  </div>

                  <div className="dude">
                    <img src={require("../../img/maria.png")} alt="" />
                    <h1 className="bold">Maria (Masha) Prusakova</h1>
                    <h3>Crypto Influencer and Investor</h3>
                    <div>
                      <p>
                        {" "}
                        Masha Prusso is Head of Public Relations at Polygon.
                        </p>
                        <p>
                        Masha is also a partner at Story VC
                        </p>
                        <p>
                        (http://www.storyvc.co/), fund investing in blockchain
                        startups and helping to boost growth with PR. 
                        </p>
                      
                      <p>
                      She co-founded Defy Trends, data analytics platform for
                        crypto traders, and advises Heroes, Coinspaid and
                        Gamerse projects.
                      </p>
                      <p></p>

                      <p>&nbsp;</p>
                      
                    </div>
                  </div>

                  <div className="dude">
                    <img src={require("../../img/sudeep.png")} alt="" />
                    <h1 className="bold">SUNDEEP</h1>
                    <h3>Former Trustswap Core Team Member</h3>
                    <div>
                      <p>
                        {" "}
                        Director of Launchpads at TrustSwap and have access to
                        influencers like Satoshi Stacker, AltcoinDaily, Buzz,
                        CryptoEllis, CryptoFomo and few others.
                      </p>
                    </div>
                  </div>

                  <div className="dude">
                    <img src={require("../../img/edouard.png")} alt="" />
                    <h1 className="bold">Edouard faure cooper</h1>
                    <h3>An Investor and Crypto Advisor</h3>
                    <div></div>
                  </div>
                </div>
             
              </section>
              <section className="partners">
                <div>
                  <h1
                    className="boldHeading"
                    style={{ textAlign: "center", marginBottom: "8%;" }}
                  >
                    PARTNERS
                  </h1>
                </div>
                <div className="partner-container">
                  <div className="partner-block">
                    <img src={require("../../img/1.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/3.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/4.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/5.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/7.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/9.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/10.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/11.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/12.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/13.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/14.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/15.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/16.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/17.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/18.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/19.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/20.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/21.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/22.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/23.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/24.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/25.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/26.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/27.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/28.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/29.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/30.png")} alt="" />
                  </div>
                  <div className="partner-block">
                    <img src={require("../../img/31.png")} alt="" />
                  </div>
                </div>
              </section>
              <section className="contact" id="contact">
                <div>
                  <h1 style={{ textAlign: "left" }}>
                    <b>CONTACT US</b>
                  </h1>
                </div>
                <div>
                  <p>©METAMALL ALL RIGHTS RESERVED, EDGE TREND LIMITED 2021</p>
                  <a href="mailto:contact@metamalls.io">CONTACT@METAMALLS.IO</a>
                </div>

                <div className="footer-social no-mobile">
                  <img
                    src={require("../../img/twitter.svg")}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://x.com/Metamall_io")
                    }
                  />
                  <img
                    src={require("../../img/instagram.svg")}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/metamall_official/"
                      )
                    }
                  />
                  <img
                    src={require("../../img/telegram.svg")}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://t.me/metamall_announcement")
                    }
                  />
                </div>

                <div className="mobile">
                  <div className="socials">
                    <img
                      src={require("../../img/twitter.svg")}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open("https://twitter.com/MetamallVR")
                      }
                    />
                    <img
                      src={require("../../img/instagram.svg")}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/metamall_official/"
                        )
                      }
                    />
                    <img
                      src={require("../../img/telegram.svg")}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open("https://t.me/metamall_announcement")
                      }
                    />
                  </div>
                </div>
              </section>
            </div>
            <div className="right-line liner">
              <div className="block right-block"></div>
            </div>
          </div>
        {/* </div>
      </div> */}
    </Layout>
  );
};

export default HomeScreen;