import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { Input } from "antd";

import Button from "../../../components/Button";
import CollapseMenu from "../../../components/CollapseMenu";
import ListingCard from "./ListingCard";
import TemplateListingCard from "./TemplateListingCard";
import Loader from "../../../components/Loader";
import ListingStoreDetails from "./ListingStoreDetails";

import { useWindowSize } from "../../../Hooks/useWindowSize";
import { useListings, useTemplatesListings } from "../hooks/useAuctionData";

import { useStoreListings } from "../hooks/useStoreData";

import { Listing, TemplateListing } from "../types";
import { StoreListing } from "../storeTypes";

const { Search } = Input;

const menu = [
  {
    key: "Land",
    title: "LAND",
    items: [
      {
        key: "Land",
        title: "All",
      },
      {
        key: "cube",
        title: "Cube",
      },
      {
        key: "cabin",
        title: "Cabin",
      },
      {
        key: "club",
        title: "Club",
      },
      {
        key: "chalet",
        title: "Chalet",
      },
      {
        key: "chateau",
        title: "Chateau",
      },
    ],
  },
  {
    key: "Package",
    title: "Store Package",
    items: [
      {
        key: "Package",
        title: "All",
      },
    ],
  },
  {
    key: "Template",
    title: "Store Template",
    items: [
      {
        key: "Template",
        title: "All",
      },
    ],
  },
  {
    key: "Other",
    title: "Other",
    items: [
      {
        key: "Other",
        title: "All",
      },
    ],
  },
];

interface CollapsedViewProps {
  listings: Listing[];
  isLoading: boolean;
}
interface CollapsedTemplateViewProps {
  listings: TemplateListing[];
  isLoading: boolean;
}
interface CollapsedStoreViewProps {
  storeListings: any;
  isLoading: boolean;
}

interface FullViewProps {
  listings: Listing[];
  isLoading: boolean;
  searchTerm: string;
  setSearchTerm: Function;
  templateListings: TemplateListing[];
}

const CollapsedView = ({ listings }: CollapsedViewProps) => {
  const { width = 0 } = useWindowSize();
  return (
    <>
      <div className="marketplace-listing-header">
        <p style={{ fontSize: "18px" }} className="marketplace-heading">
          NEW<span> LISTINGS</span>
        </p>
        <Link to="./all">
          <Button className="marketplace-listing-view_all_btn" type="secondary">
            VIEW ALL
          </Button>
        </Link>
      </div>
      <div className="marketplace-listing-cards-container">
        {listings.map((listing: Listing, index) => {
          if (index >= width / 500) return null;
          return <ListingCard key={listing.id} listing={listing} />;
        })}
      </div>
    </>
  );
};

const CollapsedPackageListingView = ({ listings }: CollapsedViewProps) => {
  const { width = 0 } = useWindowSize();
  return (
    <>
      <div className="marketplace-listing-header">
        <p style={{ fontSize: "18px" }} className="marketplace-heading">
          PACKAGE<span> LISTINGS</span>
        </p>
        <Link to="./all/Package">
          <Button className="marketplace-listing-view_all_btn" type="secondary">
            VIEW ALL
          </Button>
        </Link>
      </div>
      <div className="marketplace-listing-cards-container">
        {listings.map((listing: Listing, index) => {
          if (index >= width / 500) return null;
          return <ListingCard key={listing.id} listing={listing} />;
        })}
      </div>
    </>
  );
};

const CollapsedTemplatesListingView = ({
  listings,
  isLoading,
}: CollapsedTemplateViewProps) => {
  const { width = 0 } = useWindowSize();
  return (
    <>
      <div className="marketplace-listing-header">
        <p style={{ fontSize: "18px" }} className="marketplace-heading">
          TEMPLATES<span> LISTINGS</span>
        </p>
        {!isLoading && listings && listings.length > 0 && (
          <Link to="./all/Template">
            <Button
              className="marketplace-listing-view_all_btn"
              type="secondary"
            >
              VIEW ALL
            </Button>
          </Link>
        )}
      </div>
      <div className="marketplace-listing-cards-container">
        {!isLoading && (!listings || listings.length <= 0) && (
          <>No templates listed for sale</>
        )}
        {listings.map((listing: TemplateListing, index) => {
          if (index >= width / 500) return null;
          return <TemplateListingCard key={listing.id} listing={listing} />;
        })}
      </div>
    </>
  );
};

const CollapsedViewStore = ({
  storeListings,
  isLoading,
}: CollapsedStoreViewProps) => {
  const { width = 0 } = useWindowSize();

  if (!isLoading && isEmpty(storeListings)) return null;

  return (
    <>
      {isLoading ? (
        <Loader className={"cockpit-loader"} />
      ) : (
        <>
          <div className="marketplace-listing-header">
            <p style={{ fontSize: "18px" }} className="marketplace-heading">
              TOP<span> SELLERS</span>
            </p>
            <Link to="./store">
              <Button
                className="marketplace-listing-view_all_btn"
                type="secondary"
              >
                VIEW ALL
              </Button>
            </Link>
          </div>
          <div className="marketplace-listing-cards-container">
            {storeListings.map((listing: StoreListing, index: number) => {
              if (index >= width / 180) return null;
              return (
                <ListingStoreDetails key={listing.id} lstStoreInfo={listing} />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

const FullView = ({
  listings,
  isLoading,
  searchTerm,
  setSearchTerm,
  templateListings,
}: FullViewProps) => {
  console.log("searchTerm", searchTerm);
  console.log(templateListings);

  const [activeKey, setActiveKey] = useState("");

  const handleChange = (e: string) => {
    setActiveKey(e);
    setSearchTerm(e);
  };

  const getCardsJSX = () => {
    if (isLoading) return <Loader className="cockpit-loader" />;
    if (
      searchTerm && searchTerm.toLowerCase() === "template"
        ? isEmpty(templateListings)
        : isEmpty(listings)
    )
      return (
        <div className="marketplace-listing-store-no-data">
          <p>NO DATA FOUND</p>
        </div>
      );
    return (
      <div className="marketplace-listing-cards-container-full-view">
        {searchTerm &&
          searchTerm.toLowerCase() === "template" &&
          templateListings.map((listing: TemplateListing) => (
            <TemplateListingCard key={listing.id} listing={listing} />
          ))}
        {listings.map((listing: Listing) => (
          <ListingCard key={listing.id} listing={listing} />
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="marketplace-listing-section-left">
        <p className="marketplace-listing-categories">CATEGORIES</p>
        <CollapseMenu
          defaultActiveKey="Land"
          menu={menu}
          activeKey={activeKey}
          onChange={handleChange}
        />
      </div>
      <div className="marketplace-listing-section-right">
        <Search
          className="marketplace-search-input"
          placeholder="Search for NFTs..."
          enterButton={null}
          allowClear
          value={searchTerm}
          onChange={(e) => handleChange(e.target.value)}
        />
        {getCardsJSX()}
      </div>
    </>
  );
};

const Listings = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { data: listings = [], isLoading: listingsLoading } = useListings({
    search: searchTerm,
  });

  const { data: packageListings = [], isLoading: packageListingsLoading } =
    useListings({
      search: "Package",
    });

  const { data: templateListings = [], isLoading: templateListingsLoading } =
    useTemplatesListings();

  const { data = {}, isLoading: storeListingsLoading } = useStoreListings({
    search: searchTerm,
    page: 1,
    resultsPerPage: 10,
  });

  const { listings: storeListings = [] }: any = data;

  const location = useLocation();

  const pathname = location ? location.pathname.split("/")[2] : "";
  const collapsed = Boolean(pathname);

  const { type } = useParams<{ type: string }>();

  // to reset search when not at FullView page
  useEffect(() => {
    console.log(collapsed);
    if (collapsed) {
      console.log(type);
      if (type) {
        setSearchTerm(type);
      } else {
        setSearchTerm("");
      }
    } else {
      setSearchTerm("");
    }
  }, [collapsed]);

  return (
    <div
      className={`marketplace-listing ${
        collapsed ? "marketplace-listing-collapsed" : ""
      }`}
    >
      {!collapsed ? (
        <>
          <CollapsedViewStore
            storeListings={storeListings}
            isLoading={storeListingsLoading}
          />
          <CollapsedView listings={listings} isLoading={listingsLoading} />
          <CollapsedPackageListingView
            listings={packageListings}
            isLoading={packageListingsLoading}
          />
          <CollapsedTemplatesListingView
            listings={templateListings}
            isLoading={templateListingsLoading}
          />
        </>
      ) : (
        <FullView
          listings={listings}
          isLoading={listingsLoading}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          templateListings={templateListings}
        />
      )}
    </div>
  );
};

export default Listings;
