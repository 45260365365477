import React from "react";
import Card from "react-bootstrap/Card";
import { INVENTORY_GAME, INVENTORY_WEB } from "./constant";
import { FaGamepad, FaGlobe } from "react-icons/fa";
import Title from "../../components/Title";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";

const TitleInventory = ({
  InventoryTitle,
  Type,
  IsShowCreate = false,
  handleToggleModel,
  handleToggle3DModel,
}) => {
  const navigation = useNavigate();
  return (
    <Card className="cardHeader header-title">
      <Card.Header>
        {Type === INVENTORY_GAME && <FaGamepad className="header-icon" />}
        {Type === INVENTORY_WEB && <FaGlobe className="header-icon" />}
        <Title
          firstName={InventoryTitle}
          remainName=""
          showBuyBtn={false}
          imageUrl={""}
        />{" "}
        {/* {Type === INVENTORY_WEB && IsShowCreate && (
          <>
            <PrimaryButton
              name="Create 2D item"
              className="primary-btn download"
              style={{ margin: "auto 5px" }}
              onClick={() => {
                handleToggleModel();
              }}
            />

            <PrimaryButton
              name="Create 2D item"
              className="primary-btn download"
              style={{ margin: "auto 5px" }}
              onClick={() => {
                handleToggleModel();
              }}
            />

            <PrimaryButton
              name="Sell your NFT"
              className="primary-btn download"
              style={{ margin: "auto 5px" }}
              onClick={() => {
                navigation("/mplace/assets");
              }}
            />
          </>
        )} */}
      </Card.Header>
    </Card>
  );
};

TitleInventory.defaultProps = {
  handleToggleModel: Object,
  handleToggle3DModel: Object,
};

export default TitleInventory;
