import React from "react";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";

import Button from "../../../components/Button";
import { TemplateListing } from "../types";

interface IProps {
  listing: TemplateListing;
  mode?: string;
}

const currencyImg = {
  mall: require("../../../imgg/malltoken.png"),
  usdt: require("../../../imgg/USDT-icon.png"),
};

const TemplateListingCard = ({ listing, mode }: IProps) => {
  const {
    id,
    name,
    image,
    currency,
    price,

    // asset props
    tokenIcon = require("../../../img/landsale-banner.png"),
    tokenAddress,
    tokenName,
    tokenSymbol,
    type,
  } = listing;

  // fallback image
  const defaultImage = require("../../../img/landsale-banner.png");
  const saleMode = isEqual(mode, "sell");

  return (
    <div
      className={`marketplace-listing-card ${saleMode ? "" : "scale-on-hover"}`}
    >
      <Link to={saleMode ? "" : `../template/${id}`}>
        <div className="marketplace-listing-card-wrapper">
          <img
            className="marketplace-listing-card-banner"
            alt={name}
            src={image || defaultImage}
          />
          <p className="marketplace-listing-card-title">{name || tokenName}</p>
          {!saleMode && (
            <div className="marketplace-listing-card-price">
              <img
                src={currencyImg[currency] || currencyImg.mall}
                className="marketplace-listing-card-currency"
                alt={currency}
              />
              <span>{price}</span>
            </div>
          )}

          {saleMode && (
            <Link to={`../sell/${tokenAddress}`}>
              <Button className="marketplace-listing-card-sell-btn">
                Sell
              </Button>
            </Link>
          )}
        </div>
      </Link>
    </div>
  );
};

export default TemplateListingCard;
