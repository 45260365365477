import { useQuery } from "react-query";
import ReactQueryKey from "../Constant/ReactQuery";
import { staticApiCall, ApiCall } from "../Api/ApiCall";
import { ApiUrl } from "../Api/ApiUrl";
import { useWallet } from "@solana/wallet-adapter-react";

const useGetLand = () => {
  const {publicKey} = useWallet();
  //@ts-ignore
  const { isIdle, isLoading, isError, data, error, refetch, isFetching } =
    useQuery(
      ReactQueryKey.GET_LAND_TRANSACTION,
     () => ApiCall('GET', `${ApiUrl.landTransaction}${publicKey?.toString()}`),
      {
        enabled: false,
      }
    );
  if (publicKey?.toString() !== null && data === undefined) {
    refetch();
  }

  return {
    isIdle,
    isLoading,
    isError,
    data,
    error,
    isFetching,
    refetch
  }
};

export default useGetLand;