import React from "react";
import { useLocation, Link } from "react-router-dom";

import Button from "../../../components/Button";

const Banner = () => {
  const location = useLocation();

  const pathname = location ? location.pathname.split("/")[2] : "";
  const collapsed = Boolean(pathname);
  return (
    <div
      className={`marketplace-banner ${
        collapsed ? "marketplace-banner-collapsed" : ""
      }`}
    >
      {!collapsed && (
        <div className="marketplace-banner-content">
          <p style={{ fontSize: "32px" }} className="marketplace-heading">
            NFT<span> Marketplace</span>
          </p>
          <p className="marketplace-banner-content-subheading">
            Welcome to the one-stop-shop for digital assets from the MetaMall
            Metaverse
          </p>
          <div className="marketplace-banner-content-buttons">
            <Link to="assets">
              <Button onClick={() => {}}>Sell Assets</Button>
            </Link>
            <Link to="all">
              <Button onClick={() => {}}>Buy Assets</Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
