import { useNavigate, useParams } from "react-router-dom";
import "../../style/App.css";
import Header from "../../components/header/Header";
import "../../style/staking-result.css";
import "../../yeah.css";
import WalletClaim from "../../components/WalletClaim";
import "../../yeah.css";
import LandSaleClaimView from "../../components/LandSaleClaimView";
import Layout from "../../components/Layout";
import { useWallet } from "@solana/wallet-adapter-react";
import LandingPage from "../LandingPage";
import { useEffect } from "react";

function Claim() {
  const navigate = useNavigate();
  const { connected } = useWallet();

  const { amount } = useParams<{ amount: string }>();
  const { currentBalance } = useParams<{ currentBalance: string }>();

  useEffect(() => {
    document.documentElement.style.setProperty('--base','auto');
  }, [])

  return (
    <Layout>
      <div className="tokenclaim-body">
        <div className="container">
          {/* <Header></Header> */}

          <div className="yeah" id="content-stacking">
            <div className="left-line liner">
              <div className="block left-block"></div>
            </div>
            <div className="yeah-overlay1"></div>
            <div className="content-container">
              <section>
                <div className="checkout-content">
                  <div className="dudes">
                    <button
                      type="button"
                      className="backbtn"
                      onClick={() => navigate(-1)}
                    >
                      <img src={require("../../imgg/backbtn.png")} alt="" />
                    </button>
                    <div className="checkout-head">
                      <p>TOKEN CLAIMS</p>
                    </div>
                  </div>
                </div>
                <div className="token-stakingbox">
                  <div className="" id="token-claim">
                    <div
                      className="token-claim-next-dude token-claim-last-dude"
                      style={{ minHeight: "1500px" }}
                    >
                      <div className="container">
                        {/* <WalletClaim /> */}
                        <LandSaleClaimView />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="right-line liner">
              <div className="block right-block"></div>
            </div>
          </div>
        </div>
      </div>
      { !connected && <LandingPage/> }
    </Layout>
  );
}

export default Claim;
