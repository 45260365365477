//console.log('process.env', process.env);
import { apiEnvironment } from "../Constant/Constant";
//@ts-ignore
export const BASE_URL = `https://${
  process.env.REACT_APP_SERVICE_BASEURL || "prod.metamalls.io/cockpitapi"
}/api`;
// export const BASE_URL = `http://localhost:5055/api`;

export const ApiUrl = {
  getTokenTransfer: `${BASE_URL}/user/paymentHash/`,
  vestingSchuedule: `${BASE_URL}/user/scheduleForUser/`,
  getStaking: "https://staking.metamalls.io/api/getContractInfo/",
  facebookLogin: "https://nft.metamalls.io/cockpitapi/api/facebooklogin",
  connectGoogle: "/connectGoogle",
  walletlogin: "/walletlogin",
  updateUser: "https://nft.metamalls.io/cockpitapi/api/user",
  stakingClaimed: "https://staking.metamalls.io/api/getStakingClaimed/",
  claimToke: "https://staking.metamalls.io/api/getClaim",
  getAuction: "/auction/live/landauctions/creator/",
  landTransaction: "/store/lands/receiverWallet/",
  nftValidate:
    "https://nft.metamalls.io/cockpitapi/api/store/checkLandsValidity/mainnet",
  createAcc: "/user/connectEmail",
  userConnectWallet: "/user/connectWallet",
  updateUserName: "/user/setName",
  updatePassword: "user/setPassword",
  updateEmail: "user/setEmail",
  verifyMail: "user/initiateEmailVerification",
  verifyOTP: "user/verifyEmail",
  changeName: "user/setName",
  allLands: "land/all",
  metamallOwnedLands: "land/metamallOwned",
  user: "user",
  userAll: "user/all",
  userAllSearch: "user/search",
  transferLand: "auction/admin/transferLand",
  getBalance: "wallet/getBalance",
  addFunds: "wallet/addFunds",
  withdrawFunds: "wallet/withdrawFunds",
  transactionHistory: "wallet/transactionHistory",
  notifyMe: "notifyMe",
  googlelogin: "googlelogin",
  getOwnerStores: "store/owner",
  updateStorePublished: "store/publish/nftTokenAddress",
  addToStore: "store",
  removeOwner: "store",
  removeStoreItemCreator: "store",
  removeStoreShopCreator: "store",
  getStore: "store",
  updateStore: "store/id",
  createStore: "store/createStoreViaCockpit",
  updateStoreLogo: "store/logo/storeuuid",
  gameInventory: "furniture/gameInventory",
  webInventory: "furniture/webInventory/notInGameInventory",
  moveToGameInventory: "furniture/moveToGameInventory",
  moveToWebInventory: "furniture/moveToWebInventory",
  templateGameInventory: "template/gameInventory/notplaced/arrayform",
  templateWebInventory: "template/webInventory/notInGameInventory",
  applicableStores: "template/applicableStores",
  placeTemplate: "store/template",
  moveTemplateToGameInventory: "template/moveToGameInventory",
  moveTemplateToWebInventory: "template/moveToWebInventory",
  addLinkedItemToInventory: "linkedItem/addLinkedItemToInventory",
  editLinkedItemToInventory: "linkedItem/editLinkedItem",
  getLinkedWebInventoryItems: "linkedItem/webInventory/notInGameInventory",
  moveToGameInventoryLinkItem: "linkedItem/moveToGameInventoryById",
  moveToWebInventoryLinkItem: "linkedItem/moveToWebInventoryById",
  deleteLinkItem: "linkeditem/delete",
  getLinkedGameInventoryItems: "linkedItem/gameInventory",
  addNLinkedItemStandsToGameInventory:
    "furniture/addNLinkedItemStandsToGameInventory",
  // marketplaceAuction: `https://nft.metamalls.io/cockpitapi/api/auction`,
  marketplaceAuction: `${BASE_URL}/auction`,
  buyTemplate: `${BASE_URL}/template/buyTemplate`,
  solscanTransactions: "https://api.solscan.io/account/transaction",
  getAssets: `${BASE_URL}/user/getAllNFTsByWalletAddress`,
  sellAsset: `${BASE_URL}/auction/verifyAndCreate`,
  endSale: `${BASE_URL}/auction/endAuction`,
  marketPlaceAllStores: `${BASE_URL}/store`,
  marketplaceStoreDetails: `${BASE_URL}/store`,
  createNFTViaImage: `${BASE_URL}/user/createNFTViaImage`,
  auditLog: `${BASE_URL}/audit/log`,
  auditLogVerified: `${BASE_URL}/audit/logVerified`,
  getAllUnsoldTemplates: `${BASE_URL}/template/all/templateMaster`,
  getTemplateById: `${BASE_URL}/template/templateMaster/id`,
};
