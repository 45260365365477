import axios from "axios";
import { useQuery } from "react-query";
import moment from "moment";
import { PublicKey } from "@solana/web3.js";

import ReactQueryKey from "../../../Constant/ReactQuery";
import { ApiUrl, BASE_URL } from "../../../Api/ApiUrl";

const getEndpoint = (search: string) => {
  if (!search || search === "Other" || search === "Land") return "/live";
  if (["cube", "cabin", "club", "chalet", "chateau"].includes(search))
    return `/search/${search[0].toUpperCase()}${search.slice(
      1,
      search.length
    )}`;
  return `/search/${search}`;
};

const getListings = async ({ search }: IData) => {
  try {
    const res = await axios.get(
      `${ApiUrl.marketplaceAuction}${getEndpoint(search)}`
    );
    console.log("searchsearch", res);

    if (search === "Land" || search === "Other" || search === "Package") {
      return res.data.filter((c) => c.type === search);
    }
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getUnsoldTemplates = async () => {
  try {
    const res = await axios.get(`${ApiUrl.getAllUnsoldTemplates}`);
    console.log("getUnsoldTemplates", res);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const endListingSale = async (data) => {
  try {
    const wallet_access_token = window.localStorage.getItem(
      "wallet_access_token"
    );
    const res = await axios.post(`${ApiUrl.endSale}`, data, {
      headers: {
        Authorization: `Bearer ${wallet_access_token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const postTransferRequest = async (data) => {
  try {
    const wallet_access_token = window.localStorage.getItem(
      "wallet_access_token"
    );
    const res = await axios.post(`${ApiUrl.marketplaceAuction}/buy`, data, {
      headers: {
        Authorization: `Bearer ${wallet_access_token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const buyTemplate = async (data) => {
  try {
    const wallet_access_token = window.localStorage.getItem(
      "wallet_access_token"
    );
    const res = await axios.post(
      `${ApiUrl.buyTemplate}/${data.templateId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${wallet_access_token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const listItemRequest = async (data) => {
  try {
    const wallet_access_token = window.localStorage.getItem(
      "wallet_access_token"
    );
    const res = await axios.put(`${ApiUrl.sellAsset}`, data, {
      headers: {
        Authorization: `Bearer ${wallet_access_token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const createNFT = async (data) => {
  try {
    const wallet_access_token = window.localStorage.getItem(
      "wallet_access_token"
    );
    const res = await axios.put(`${ApiUrl.createNFTViaImage}`, data, {
      headers: {
        Authorization: `Bearer ${wallet_access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const formatIdText = (text) =>
  `${text.slice(0, 25)}...${text.slice(text.length - 10, text.length)}`;

const getTradeHistory = async (id: string) => {
  try {
    if (!id) return [];
    const res = await axios.get(`${ApiUrl.solscanTransactions}?address=${id}`);
    console.log(res.data);
    const { data = [] } = res?.data || {};
    if (data) {
      return data.map(({ txHash, signer: [buyer] = [""], blockTime }) => {
        return [
          formatIdText(txHash),
          formatIdText(buyer),
          moment(blockTime * 1000).fromNow(),
        ];
      });
    }
    return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const walletLogin = async (data) => {
  try {
    const access_token = localStorage.getItem("access_token");
    const res = await axios.post(
      `${BASE_URL}${ApiUrl.walletlogin}`,
      // `https://dev.metamalls.io/marketplace/api/walletlogin`,
      data,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (res.data.access_token) {
      window.localStorage.setItem("wallet_access_token", res.data.access_token);
    }
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getListingDetail = async (id: string) => {
  if (!id) return {};
  try {
    const res = await axios.get(`${ApiUrl.marketplaceAuction}/id/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getTemplateListingDetail = async (id: number) => {
  if (!id) return {};
  try {
    const res = await axios.get(`${ApiUrl.getTemplateById}/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

interface IData {
  search: string;
}

export const useListings = (data: IData) => {
  return useQuery([ReactQueryKey.MARKETPLACE_LISTINGS, data], () =>
    getListings(data)
  );
};

export const useTemplatesListings = () => {
  return useQuery([ReactQueryKey.MARKETPLACE_TEMPLATES_LISTINGS], () =>
    getUnsoldTemplates()
  );
};

export const useListingDetail = (id: string, isRefreshing: boolean = false) => {
  return useQuery(
    [ReactQueryKey.SELECTED_LISTING, id],
    () => getListingDetail(id),
    {
      refetchInterval: (data, query) => {
        if (isRefreshing) return 5000;
        return false;
      },
    }
  );
};

export const useTemplateListingDetail = (
  id: number,
  isRefreshing: boolean = false
) => {
  return useQuery(
    [ReactQueryKey.SELECTED_TEMPLATE_LISTING, id],
    () => getTemplateListingDetail(id),
    {
      refetchInterval: (data, query) => {
        if (isRefreshing) return 5000;
        return false;
      },
    }
  );
};

export const useTradeHistory = (id: string) => {
  return useQuery(
    [ReactQueryKey.TRADE_HISTORY, id],
    () => getTradeHistory(id),
    {
      enabled: !!id,
    }
  );
};

const getAssets = async (walletAddress: PublicKey | null) => {
  try {
    const res = await axios.get(`${ApiUrl.getAssets}/${walletAddress}`);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const useAssets = (walletAddress: PublicKey | null) => {
  return useQuery(
    [ReactQueryKey.ASSETS, walletAddress],
    () => getAssets(walletAddress),
    {
      enabled: !!walletAddress,
    }
  );
};

interface IAuditLogData {
  action: string;
  payload: any;
  additionalInfo: any;
  walletAddress: string;
  relatedTo?: number;
}

export const addAuditLog = async (data: IAuditLogData) => {
  try {
    const res = await axios.post(`${ApiUrl.auditLog}`, data);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

interface IAuditLogVerifiedData {
  action: string;
  payload: any;
  additionalInfo: any;
  relatedTo: number;
}

export const useAuditVerifiedLog = async (data: IAuditLogVerifiedData) => {
  try {
    const wallet_access_token = window.localStorage.getItem(
      "wallet_access_token"
    );
    const res = await axios.post(`${ApiUrl.auditLog}`, data, {
      headers: {
        Authorization: `Bearer ${wallet_access_token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
