import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASEURL, NETWORK_URL, TOKEN_ID } from "../../Constant/StakingConstant";
import { getStakingPoolImage } from "../../FEUtils";
import "../../StakingStyle/StakingDetails.css";
import Wallet from "../../StackingComponent/Wallet";
import "../../StakingStyle/App.css";
import "../../StakingStyle/yeah.css";
import "../../StakingStyle/styles.css";
import TestStake from "../../StackingComponent/TestStake";
import Layout from "../../components/Layout";
import { useWallet } from "@solana/wallet-adapter-react";
import LandingPage from "../LandingPage";

export interface StakingPoolData {
  name: string;
  maturity_period: string;
  apy: string;
  max_reward_value: string;
  total_locked_tokens: string;
  total_value_locked: string;
  token_price: string;
  status: string;
  id: number;
  account_id: string;
  wallet_id: string;
}

function StakingDetails() {
  const { connected } = useWallet();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [stakingPool, setStakingPool] = useState<StakingPoolData>();
  // const [balance, setBalance] = useState<number>(0);
  // const [isBalanceFetched, setIsBalanceFetched] = useState<boolean>(false);
  // const [isEnoughBalance, setIsEnoughBalance] = useState<boolean>(true);
  // const [amountToStake, setAmountToStake] = useState<number>(0);
  // const [sourceAccount, setSourceAccount] = useState<string>();

  // const [provider, setProvider] = useState<PhantomProvider | undefined>(
  //   undefined
  // );
  // const [walletKey, setWalletKey] = useState<PhantomProvider | undefined>(
  //   undefined
  // );
  const [totalLockedTokens, setTotalLockedTokens] = useState<number>(0);

  // /**
  //  * @description prompts user to connect wallet if it exists
  //  */
  // const connectWallet = async () => {
  //   // @ts-ignore
  //   const { solana } = window;

  //   if (solana) {
  //     let response;
  //     try {
  //       response = await solana.connect();
  //       console.log("wallet account ", response.publicKey.toString());
  //       setWalletKey(response.publicKey.toString());
  //     } catch (err) {
  //       // { code: 4001, message: 'User rejected the request.' }
  //     }

  //     try {
  //       const owner = response.publicKey.toString();
  //       const MINT = new PublicKey(TOKEN_ID);
  //       const request_data = {
  //         jsonrpc: "2.0",
  //         id: 1,
  //         method: "getTokenAccountsByOwner",
  //         params: [owner, { mint: MINT }, { encoding: "jsonParsed" }],
  //       };

  //       console.log(" --- request data ----", { request_data });
  //       const transaction_details: any = await fetch(NETWORK_URL, {
  //         method: "POST",
  //         body: JSON.stringify(request_data),
  //         headers: { "Content-Type": "application/json" },
  //       }).then((res) => res.json());

  //       console.log(
  //         transaction_details.result.value[0].account.data.parsed.info
  //           .tokenAmount.uiAmount
  //       );
  //       if (
  //         transaction_details &&
  //         transaction_details.result &&
  //         transaction_details.result &&
  //         transaction_details.result.value[0] &&
  //         transaction_details.result.value[0].account &&
  //         transaction_details.result.value[0].account.data.parsed &&
  //         transaction_details.result.value[0].account.data.parsed.info &&
  //         transaction_details.result.value[0].account.data.parsed.info
  //           .tokenAmount &&
  //         transaction_details.result.value[0].account.data.parsed.info
  //           .tokenAmount.uiAmount
  //       ) {
  //         setBalance(
  //           transaction_details.result.value[0].account.data.parsed.info
  //             .tokenAmount.uiAmount
  //         );
  //         setIsBalanceFetched(true);
  //         setSourceAccount(transaction_details.result.value[0].pubkey);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  // };

  // /**
  //  * @description disconnect Phantom wallet
  //  */
  // const disconnectWallet = async () => {
  //   // @ts-ignore
  //   const { solana } = window;

  //   if (walletKey && solana) {
  //     await (solana as PhantomProvider).disconnect();
  //     setWalletKey(undefined);
  //   }
  // };

  useEffect(() => {
    async function fetchData() {
      // let provider = getProvider();
      // if (!provider) {
      //   provider = getProvider();
      // }
      // if (provider) setProvider(provider);
      // else setProvider(undefined);

      // GET request using fetch inside useEffect React hook
      const stakeDetailResponse: any = await fetch(
        `${BASEURL}/getStakingPoolDetails/${id}`
      ).then((response) => response.json());
      console.log({ stakeDetailResponse });
      setStakingPool(stakeDetailResponse);
      const request_data = {
        jsonrpc: "2.0",
        id: 1,
        method: "getTokenAccountsByOwner",
        params: [
          stakeDetailResponse.wallet_id,
          { mint: TOKEN_ID },
          { encoding: "jsonParsed" },
        ],
      };

      console.log(" --- request data ----", { request_data });
      const transaction_details: any = await fetch(NETWORK_URL, {
        method: "POST",
        body: JSON.stringify(request_data),
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());
      console.log(transaction_details);
      if (
        transaction_details &&
        transaction_details.result &&
        transaction_details.result.value
      ) {
        const values: Array<any> = transaction_details.result.value;
        const stakePoolAccount = values.find(
          (v) => v.pubkey == stakeDetailResponse.account_id
        );
        if (
          stakePoolAccount &&
          stakePoolAccount.account &&
          stakePoolAccount.account.data
        ) {
          const lockedAmount =
            stakePoolAccount.account.data.parsed.info.tokenAmount.uiAmount;
          console.log(lockedAmount);
          setTotalLockedTokens(lockedAmount);
        }
      }
    }
    fetchData();
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--base','auto');
  }, [])

  return (
    <Layout>
    <div className="tokenclaim-body checkout-body">
      <div className="container">
        {/* <Header></Header> */}

        <div className="yeah" id="content-stacking">
          <div className="left-line liner">
            <div className="block left-block"></div>
          </div>
          <div className="yeah-overlay1"></div>
          <div className="content-container">
            <section className="checkout-section">
              <div className="checkout-content">
                <div className="dudes">
                  <button
                    type="button"
                    className="backbtn"
                    onClick={() => navigate(-1)}
                  >
                    <img src={require("../../imgg/backbtn.png")} alt="" />
                  </button>
                  <div className="checkout-head">
                    <p>
                      TOKEN <span>STAKING</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="token-stakingbox">
                <div className="dudes">
                  <div className="checkout-img line">
                    <img
                      className="token-mainimg"
                      src={require("../../imgg/" +
                        getStakingPoolImage(stakingPool?.name))}
                      alt=""
                    />
                    <div className="token-staking">
                      {/* <h1>{stakingPool?.name}</h1> */}
                      <h1>Reward Program</h1>
                      <div className="dudes">
                        <div className="dude">
                          <p>Max MALL Reward Value</p>
                        </div>
                        <div className="dude right-dude">
                          <p className="num">
                            <img
                              src={require("../../imgg/m-icon.png")}
                              alt=""
                              height="18"
                            />{" "}
                            {stakingPool?.max_reward_value}
                          </p>
                        </div>
                        <div className="dude">
                          <p>APY</p>
                        </div>
                        <div className="dude right-dude">
                          <p className="num">{stakingPool?.apy} %</p>
                        </div>
                        <div className="dude">
                          <p>Total Locked Tokens</p>
                        </div>
                        <div className="dude right-dude">
                          <p className="num">
                            <img
                              src={require("../../imgg/m-icon.png")}
                              alt=""
                              height="18"
                            />{" "}
                            {totalLockedTokens}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="checkout-details">
                    <div className="token-claim-dude">
                      <div>
                        {/* <Wallet stakingPoolDetaills={stakingPool} /> */}
                        <TestStake stakingPoolDetaills={stakingPool} />
                        {/* {provider && !walletKey && (
                          <>
                            <p className="wallet-head">
                              Please connect to phantom wallet
                            </p>
                            <button onClick={connectWallet}>
                              <span>
                                <img src={require("./imgg/phantom.png")} />
                                &nbsp;&nbsp;&nbsp;Connect
                              </span>
                            </button>
                          </>
                        )}
                        {provider && walletKey && (
                          <div className="checkout-details">
                            <div className="token-claim-next-dude">
                              <form action="/action_page.php">
                                <div className="container">
                                  <label>ADDRESS</label>
                                  <button>
                                    <img src={require("./imgg/sollet.png")} />
                                    &emsp;{walletKey}
                                  </button>
                                  <p className="clearfix pb0">
                                    <span className="left">
                                      <strong>Current Balance: </strong>
                                    </span>
                                    <span className="right-text">
                                      <i className="fa fa-check"></i>{" "}
                                      {isBalanceFetched ? "Verified" : ""}
                                    </span>
                                  </p>
                                  <p className="num pt0">
                                    <img
                                      src={require("./imgg/m-icon.png")}
                                      alt=""
                                      height="25"
                                    />{" "}
                                    {balance}
                                  </p>
                                  <label
                                    className="payment-method"
                                    style={{ marginTop: "15px" }}
                                  >
                                    ENTER STAKING AMOUNT
                                  </label>
                                  <input
                                    type="number"
                                    min={0}
                                    value={amountToStake}
                                    onChange={(e) => {
                                      try {
                                        const n = parseInt(e.target.value);
                                        setAmountToStake(n);
                                      } catch (error) {
                                        setAmountToStake(0);
                                      }
                                    }}
                                  />
                                  <label>
                                    {!isEnoughBalance
                                      ? "Entered amount is greater than your current balance"
                                      : ""}
                                  </label>
                                </div>
                              </form>
                              <button
                                type="submit"
                                className="registerbtn"
                                onClick={async () => {
                                  if (amountToStake > balance) {
                                    setIsEnoughBalance(false);
                                  } else {
                                    setIsEnoughBalance(true);
                                  }
                                }}
                              >
                                STAKE
                              </button>
                            </div>
                          </div>
                        )}
                        {!provider && (
                          <>
                            No Provider Found. Please install Phantom extension.
                          </>
                        )} */}
                        {/* <p className="para">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                        </p>
                        <button className="watch-video">
                          <img src={require("./imgg/video.png")} />
                          &nbsp;<span>Watch Video</span>
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="right-line liner">
            <div className="block right-block"></div>
          </div>
        </div>
      </div>
    </div>
    { !connected && <LandingPage/> }
    </Layout>
  );
}

export default StakingDetails;
