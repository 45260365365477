import 'antd/dist/antd.css';
import "./style/App.css";
import "./yeah.css";
//import "./styles/about.css";
// import "./styles/animate.min.css";
// import "./styles/loco.css";
// import "./styles/radiobtn.css";
// import "./styles/style.css";
// import "./styles/yeah_11_2_22.css";
// import "./styles/yeah_16_02_22.css";
// import "./styles/yeah-old-po.css";
// import "./styles/yeah-old.css";
// import "./styles/yeah-today.css";
 import "./styles/yeah.css";
// import "./styles/yeah01-03-2022.css";
// import "./styles/yeah12-03-2022.css";
// import "./styles/yeah17-02-2022.css";
import 'bootstrap/dist/css/bootstrap.css';
import "./styles/yeah28-02-2022.css";
import Router from "./Routes";
//import "./CockpitApp.scss";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
//import * as CryptoJS from 'crypto';
import { ConnectWallet } from "./components/ConnectWallet";


export interface StakingPoolData {
  name: string;
  maturity_period: string;
  apy: string;
  max_reward_value: string;
  total_locked_tokens: string;
  total_value_locked: string;
  token_price: string;
  status: string;
  id: number;
}

function App() {
  const queryClient = new QueryClient();
  return (
    <div className="app-wrapper">
    <QueryClientProvider client={queryClient}>
      <ConnectWallet>
      <Router/>
      </ConnectWallet>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
      </div>
  );
}

export default App;
