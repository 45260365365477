
const ErrorComponent = ({ text, image }) => {
    return(
        <div className={'no-data-found-wrapper'}>
            <div className="error-component-wrapper" >
            {/* <img src={image} alt="" className="error-image" /> */}
            <div className={'text'} >{text}</div>
            </div>
        </div>
    )
}

export default ErrorComponent;