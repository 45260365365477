import React, { Suspense, useEffect, useState } from "react";
import "./Admin.scss";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import Title from "../../components/Title";
import LandingPage from "../LandingPage";
import { fetchBalance } from "../../Solana/GetBalance";
import { useWallet } from "@solana/wallet-adapter-react";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import TokenTransfer from "../TokenTransfer";
import VestingSchuedule from "../VestingSchedule";
import Staking from "../Staking";
import { useQuery } from "react-query";
import ReactQueryKey from "../../Constant/ReactQuery";

const UserDetails = () => {
  const navigation = useNavigate();
  const { publicKey, connected } = useWallet();
  const { wallet_address } = useParams<{ wallet_address: string }>();
  const [balance, setBalance] = useState<string | number>("");

  const AccessToken = useQuery(ReactQueryKey.SOCIAL_MEDIA_LOGIN, () => {}, {
    enabled: false,
  });

  const getBalance = async () => {
    const res = await fetchBalance(wallet_address);
    setBalance(res);
  };

  useEffect(() => {
    getBalance();
  });

  return (
    <>
      <div className={"row adminUserDetails mb-3"}>
        <div style={{ display: "contents" }}>
          <Button
            variant="primary active shadow-none btn-icon"
            onClick={() => {
              navigation(`/cockpit/admin `);
            }}
            style={{ margin: "0 15px" }}
          >
            <FaAngleLeft />
          </Button>
          <Title
            firstName=""
            remainName={`User Derails - ${wallet_address}`}
            showBuyBtn={false}
            imageUrl={""}
          />
        </div>
      </div>

      <div className={"adminUserDetails"}>
        <Suspense fallback={<div>Loading...</div>}>
          <div className={"mall-container"}>
            <div className={"mall-balance"}>
              <span className={"text"}>$MALL BALANCE: </span>
              <span>
                <img
                  className="mal-icon"
                  src={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
                  alt=""
                />
                <span className={"text token"}> {balance}</span>
              </span>
            </div>
          </div>
          <div className={"dashboard-container"}>
            <div className={"dashboard-content-wrapper"}>
              <VestingSchuedule wallet_address={wallet_address} />
              <TokenTransfer wallet_address={wallet_address} />
              <Staking wallet_address={wallet_address} />
            </div>
          </div>
          {/* @ts-ignore */}
          {(AccessToken.data === undefined ? true : false) && <LandingPage />}
        </Suspense>
        {!connected && <LandingPage />}
      </div>
    </>
  );
};

export default UserDetails;
