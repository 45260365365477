import React, { useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

import "./index.scss";

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      className={`metamall-collapse-menu-item ${
        isCurrentEventKey ? "metamall-collapse-menu-item-active" : ""
      }`}
      onClick={decoratedOnClick}
    >
      <div>
        {children}
        <img src={require("../../imgg/backbtn.png")} alt="arrow" />
      </div>
    </button>
  );
};

interface Item {
  key: string;
  title: string;
}

interface MenuItem {
  key: string;
  title: string;
  items: Item[];
}

interface IProps {
  menu: MenuItem[];
  defaultActiveKey?: string;
  activeKey?: string;
  className?: string;
  onChange: Function;
}

const CollapseMenu = ({
  menu,
  defaultActiveKey, // for Toggle item
  className = "",
  activeKey = "", // for sub-item
  onChange,
}: IProps) => {
  return (
    <Accordion
      defaultActiveKey={defaultActiveKey}
      className={`metamall-collapse-menu ${className}`}
      onChange={(e) => {}}
    >
      {menu.map(({ key, title, items }) => {
        return (
          <div style={{ marginBottom: "20px" }} key={key}>
            <ContextAwareToggle eventKey={key} callback={() => {}}>
              {title}
            </ContextAwareToggle>
            <Accordion.Collapse
              className="metamall-collapse-menu-sub-menu"
              eventKey={key}
            >
              <>
                {items.map((item) => (
                  <div
                    className={`metamall-collapse-menu-sub-item ${
                      activeKey === item.key
                        ? "metamall-collapse-menu-active-item"
                        : ""
                    }`}
                    key={item.key}
                    onClick={() => onChange(item.key)}
                  >
                    {item.title}
                  </div>
                ))}
              </>
            </Accordion.Collapse>
          </div>
        );
      })}
    </Accordion>
  );
};

export default CollapseMenu;
