import React from "react";

import "./index.scss";

export const LinesLoader = () => {
  return (
    <div className="vertical-lines-loader">
      <span className="vertical-lines-loader-line line-1" />
      <span className="vertical-lines-loader-line line-2" />
      <span className="vertical-lines-loader-line line-3" />
      <span className="vertical-lines-loader-line line-4" />
      <span className="vertical-lines-loader-line line-5" />
      <span className="vertical-lines-loader-line line-6" />
      <span className="vertical-lines-loader-line line-7" />
      <span className="vertical-lines-loader-line line-8" />
      <span className="vertical-lines-loader-line line-9" />
    </div>
  );
};

export default LinesLoader;
