import { Alert } from "react-bootstrap";

interface ToastMessageProps {
    message: string;
    className?: string;
}

const ToastMessage = ({message, className}: ToastMessageProps) => {
return(
    <Alert key={1} variant={'success'} className={className} >
      {message}
    </Alert>
)
}

export default ToastMessage;