import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Line from "../../components/Line";
import InputBox from "../../components/InputBox";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { BASE_URL, ApiUrl } from "../../Api/ApiUrl";
import ReactQueryKey from "../../Constant/ReactQuery";
import { useQuery } from "react-query";
import axios from "axios";
import AlertModel from "../../components/AlertModel";
import useAccessTocken from "../../Hooks/useAccessTocken";

const SetPassword = ({ changePassword }) => {
  const access_token = useAccessTocken();
  const REDIRECT_URI = "http://localhost:3000";
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState<any>();
  const [cancele, setCancel] = useState(true);
  const [validationClicked, setValidationClicked] = useState(false);
  const [showAlert, setshowAlert] = useState(false);

  // const AccessToken = useQuery(ReactQueryKey.SOCIAL_MEDIA_LOGIN, {
  //   enabled: false,
  // });

  const Axios = axios.create({
    baseURL: BASE_URL,
    //timeout: 1000,
    //@ts-ignore
    headers: { Authorization: `Bearer ${access_token}` },
  });
  const [stateUsedDetails, setUsedDetails] = useState({
    password: "",
    confirmPassword: "",
  });
  let usedDetails = {
    password: "",
    confirmPassword: "",
  };

  const savePassword = async () => {
    setValidationClicked(true);
    if (stateUsedDetails.password === stateUsedDetails.confirmPassword) {
      const res = await Axios.post(ApiUrl.updatePassword, {
        password: stateUsedDetails.password,
      });
      setCancel(false);
      setshowAlert(true);
    }
  };

  const closeModal = async () => {
    //alert("zzzzzzzzzzzzzz");
    setCancel(false);
    //setProvider("");

 
      //navigate("/cockpit/experienceWoWallet");
      //closeSidebarMenu(e);
      // props.handleToggleSidebar(e);
  
    
  };


  console.log("SocialMediaLogin");
  return (
    <>
      <Modal
        show={cancele}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="set-password-wrapper"
        // style={{ backgroundColor: "#0c0a20", opacity: "0.6" }}
      >
        <div className="header-wrapper">
          <div>
            <img className={"landing-logo"} src={`${process.env.PUBLIC_URL}/images/lock.png`} alt="dashboard logo" />
          </div>
          <div className="header-text-wrapper">
            <div className="header-text">Set a password </div>
            <div className="header-sub-text">
              You have successfully linked your Google account. Kindly set a login password to proceed
            </div>
          </div>
        </div>

        <div>
          
            <img
              className={"landing-logo close-btn"}
              src={`${process.env.PUBLIC_URL}/images/close-btn.png`} 
              alt="close logo"  onClick={closeModal}
            />
          </div>
        <Line className="" />
        <div className="password-box">
          <InputBox
            label={"Password"}
            placholder={"Password"}
            onChange={(e) => {
              usedDetails = { ...stateUsedDetails };
              usedDetails.password = e.target.value;
              setUsedDetails(usedDetails);
            }}
            isValid={
              validationClicked &&
              (stateUsedDetails.password !== stateUsedDetails.confirmPassword || stateUsedDetails.password === "")
            }
            message=""
            type="password"
            value={stateUsedDetails.confirmPassword}
          />
        </div>
        <div className="password-box">
          <InputBox
            label={"Confirm Password"}
            placholder={"Confirm Password"}
            onChange={(e) => {
              usedDetails = { ...stateUsedDetails };
              usedDetails.confirmPassword = e.target.value;
              setUsedDetails(usedDetails);
            }}
            isValid={
              validationClicked &&
              (stateUsedDetails.password !== stateUsedDetails.confirmPassword || stateUsedDetails.password === "")
            }
            message="Password mismatch"
            type="password"
            value={stateUsedDetails.confirmPassword}
          />
        </div>
        <div className="alert-message-wrapper" style={{ display: "flex", flexDirection: "row" }}>
        <PrimaryButton name={"set password"} className={"set-password-button"} onClick={savePassword} />

        <PrimaryButton name={"Cancel"} className={"set-password-button"} onClick={() => setCancel(false)} />
        </div>
      </Modal>
      {showAlert && (
        <AlertModel
          image={`${process.env.PUBLIC_URL}/images/SuccessImage.png`}
          headerText={"You are all set!"}
          subText={"You have successfully changed your password!"}
        />
      )}
    </>
  );
};

export default SetPassword;
