import "../style/App.css";
import "../yeah.css";
import "../yeah.css";
import { ReactComponent as Twitter } from "../img/twitter.svg";
import { ReactComponent as Instagram } from "../imgg/instagram.svg";
import { ReactComponent as Telegram } from "../imgg/telegram.svg";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import PrimaryButton from "./PrimaryButton/PrimaryButton";
import { useWallet } from "@solana/wallet-adapter-react";
import { trimString } from "../utils/CommonFunction";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import InputBox from "../components/InputBox";
import { ApiUrl, BASE_URL } from "../Api/ApiUrl";
import axios from "axios";

function Layout({ children, scrolled = false }) {
  const [isEmailModalOpened, setIsEmailModalOpened] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState("");
  const [showComingSoonError, setShowComingSoonError] = useState(false);
  const queryClient = useQueryClient();
  const Axios = axios.create({
    baseURL: BASE_URL,
  });

  const navigate = useNavigate();
  const { publicKey, connect, disconnect, connected, wallet, select } =
    useWallet();

  const handleConnect = () => {
    if(wallet){
      connect();
      window.location.reload(false);
    } else {
      select("Phantom");
    }
  };

  const handleDisconnect = () => {
    localStorage.clear();
    queryClient.invalidateQueries();
    disconnect();
    wallet.adapter._disconnected();
    window.location.reload(false);
  }

  useMemo(() => {
    if (wallet) {
      connect();
    }
  }, [wallet]);

  return (
    <div id="marketplace">
      <div className="nav-container">
        <div className="container-wrapper">
          <div
            className="nav"
            data-scroll
            data-scroll-sticky
            data-scroll-target="body"
            style={{
              backgroundColor: scrolled ? "rgba(6, 4, 16, 0.494)" : "none",
            }}
          >
            <div className="left no-mobile">
              <p>
                {process.env.REACT_APP_SHOW_UNDER_DEVELOPMENT_FEATURES ===
                  "undefined" ||
                  (process.env.REACT_APP_SHOW_UNDER_DEVELOPMENT_FEATURES !==
                    "false" && (
                      <a
                  href="https://metamallproperty.io/"
                  target="_blank"
                  style={{ color: "white", textDecoration: "none" }}
                >
                        MARKET PLACE
                      </a>
                    ))}
              </p>
              <p>
                <Link
                  to={connected?"/cockpit/dashboard":"/cockpit/login"}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    borderBottom: "none",
                  }}
                >
                  <span> COCKPIT </span>
                </Link>
              </p>
              <p>
                <Link
                  to={"/staking"}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    borderBottom: "none",
                  }}
                >
                  <span> </span>
                  STAKING 
                </Link>
              </p>
              { process.env.REACT_APP_HIDE_LAUNCHER &&
                process.env.REACT_APP_HIDE_LAUNCHER === "true" ? (
                <p
                  onClick={() => {
                    setIsEmailModalOpened(true);
                  }}
                >
                  LAUNCHER
                </p>
              ) : (
                <p>
                  <Link
                    to={"/launcher"}
                    style={{ color: "white", textDecoration: "none" }}
                  > 
                    LAUNCHER 
                  </Link>
                </p>
              )}
              <p>
                <a
                  href="https://drive.google.com/file/d/1DzkN5naRJQeW1nyj5b3dByBwG0edZO-m/view?usp=sharing"
                  target="_blank"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  MANUAL 
                </a>
              </p>
              <p>
                <a
                  href="https://drive.google.com/file/d/1cpx76F_F9buWIr_3L8jrEXZfyhTs5PlY/view"
                  target="_blank"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  WHITEPAPER 
                </a>
              </p>
            </div>
            <div className="logo no-mobile">
              <img
                src={require("../img/logo.png")}
                alt=""
                onClick={() => navigate("/")}
              />
            </div>
            <div className="right no-mobile btnConnect">
              <div
                className="header-wallet-wrapper no-mobile"

              >
                <PrimaryButton
                  name={connected ? "disconnect" : "Connect wallet" }
                  onClick={connected ? () => handleDisconnect() : () => handleConnect()}
                  className="primary-connet-wallet shadow-none"
                />
                {connected && (
                  <div className="publick-key-style">
                    {trimString(publicKey.toString())} 
                  </div>
                )}
              </div>
              <Twitter
                onClick={() =>
                  window.open("https://x.com/Metamall_io", "")
                }
                style={{ margin: "8px" }}
                className="social-media"
              />
              <Instagram
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/accounts/login/?next=/metamall_official/",
                    ""
                  )
                }
                style={{ margin: "8px" }}
                className="social-media"
              />
              <Telegram
                onClick={() =>
                  window.open("https://t.me/metamall_announcement", "")
                }
                style={{ margin: "8px" }}
                className="social-media"
              />
            </div>
            <div className="mobile force-mobile">
              <div className="logo">
                <img
                  src={require("../img/logo.png")}
                  alt=""
                  onClick={() => navigate("/")}
                />
              </div>
              <label>
                <input type="checkbox"/>
                  <span className="menu"> <span className="hamburger"></span> </span>
                  <ul>
                    <li> 
                      {process.env.REACT_APP_SHOW_UNDER_DEVELOPMENT_FEATURES ===
                      "undefined" ||
                      (process.env.REACT_APP_SHOW_UNDER_DEVELOPMENT_FEATURES !==
                        "false" && (
                          <a
                          href="https://metamallproperty.io/"
                          target="_blank"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                            MARKET PLACE
                          </a>
                        ))}
                    </li>
                    <li>
                      <Link
                        to={connected?"/cockpit/dashboard":"/cockpit/login"}
                        style={{
                          color: "white",
                          textDecoration: "none",
                          borderBottom: "none",
                        }}
                      >
                        <span> COCKPIT</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/staking"}
                        style={{
                          color: "white",
                          textDecoration: "none",
                          borderBottom: "none",
                        }}
                      >
                        <span> </span>
                        STAKING
                      </Link>
                    </li>
                    {/* process.env.REACT_APP_HIDE_LAUNCHER &&
                      process.env.REACT_APP_HIDE_LAUNCHER === "true" ? (
                      <li
                        onClick={() => {
                          setIsEmailModalOpened(true);
                        }}
                      > 
                        LAUNCHER
                      </li>
                    ) : (
                      <li>
                        <Link
                          to={"/launcher"}
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          LAUNCHER
                        </Link>
                      </li>
                    )*/}
                    <li>
                      <Link
                        to="https://drive.google.com/file/d/1DzkN5naRJQeW1nyj5b3dByBwG0edZO-m/view?usp=sharing"
                        target="_blank"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        MANUAL
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://drive.google.com/file/d/1cpx76F_F9buWIr_3L8jrEXZfyhTs5PlY/view"
                        target="_blank"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        WHITEPAPER  
                      </Link>
                    </li>
                    <li>
                    <div className="connectBtn">
                      <PrimaryButton
                        name={connected ? "disconnect" : "Connect wallet"}
                        onClick={connected ? () => handleDisconnect() : () => handleConnect()}
                        className="primary-connet-wallet shadow-none"
                      />
                      {connected && (
                        <div className="publick-key-style">
                          {trimString(publicKey.toString())}
                        </div>
                      )}
                    </div>
                    </li>



                    <li className="mobile-social">
                    <Twitter
                onClick={() =>
                  window.open("https://x.com/Metamall_io", "")
                }
                style={{ margin: "8px" }}
                className="social-media"
              />
                    </li>

                    <li className="mobile-social">
                    
              <Instagram
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/accounts/login/?next=/metamall_official/",
                    ""
                  )
                }
                style={{ margin: "8px" }}
                className="social-media"
              />
                    </li>

                    <li className="mobile-social">
                    <Telegram
                onClick={() =>
                  window.open("https://t.me/metamall_announcement", "")
                }
                style={{ margin: "8px" }}
                className="social-media"
              />
                    </li>

 













                    
                  </ul>
              </label>
            </div>
            
          </div>
        </div>
        {children}
      </div>
      <Modal
        show={isEmailModalOpened}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge"
      >
        <div className="header-wrapper">
          <div className="header-text-wrapper">
            <div
              className="header-text"
              style={{
                textAlign: "center",
                fontSize: "2rem",
                marginTop: "0.5rem",
              }}
            >
              Coming Soon
            </div>
            <div
              className="header-sub-text"
              style={{ marginTop: "1rem", textAlign: "center" }}
            >
              Enter your email id and we will get back to you
            </div>
            <div className="password-box">
              <InputBox
                label={"Email"}
                placholder={"Email"}
                message=""
                type="text"
                value={enteredEmail.toString()}
                onChange={(e) => {
                  setEnteredEmail(e.target.value);
                }}
              />
            </div>
            {showComingSoonError ? (
              <div>Please provide a valid email address</div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name={"Submit"}
            onClick={async () => {
              setShowComingSoonError(false);
              if (
                enteredEmail
                  .toLowerCase()
                  .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  )
              ) {
                const res = await Axios.post(ApiUrl.notifyMe, {
                  email: enteredEmail,
                });
                console.log(res);
                setIsEmailModalOpened(false);
              } else {
                setShowComingSoonError(true);
              }
            }}
            className="proceed-button"
            style={{ marginRight: "2rem" }}
          />
          <PrimaryButton
            name={"Cancel"}
            onClick={() => {
              setShowComingSoonError(false);
              setIsEmailModalOpened(false);
            }}
            className="proceed-button"
          />
        </div>
      </Modal>
    </div>
  );
}

export default Layout;
