import { Connection, clusterApiUrl, LAMPORTS_PER_SOL } from "@solana/web3.js";
import {
  getParsedNftAccountsByOwner,
  isValidSolanaAddress,
  createConnectionConfig,
  resolveToWalletAddress,
} from "@nfteyez/sol-rayz";
import axios from "axios";
import { PublicKey, Keypair } from "@solana/web3.js";
import { staticApiCall } from "../Api/ApiCall";
import { ApiUrl } from "../Api/ApiUrl";
import { walletEnvironment } from "../Constant/Constant";
import { Metaplex, keypairIdentity } from "@metaplex-foundation/js";

//create a connection of devnet
const createConnection = () => {
  return new Connection(clusterApiUrl("mainnet-beta"));
};

const getProvider = () => {
  if ("solana" in window) {
    //@ts-ignore
    const { solana } = window;
    const provider = solana;
    if (provider.isPhantom) {
      return provider;
    }
  }
};

export const getAllNftData = async () => {
  try {
    if (true) {
      //     const connection = new Connection(clusterApiUrl("mainnet-beta"));
      // const keypair = Keypair.generate();

      // const metaplex = new Metaplex(connection);
      //@ts-ignore
      console.log(
        "getAllNftData data",
        walletEnvironment[process.env.REACT_APP_STAGE || "prod"]
      );
      //@ts-ignore
      const connect = createConnectionConfig(
        clusterApiUrl(walletEnvironment[process.env.REACT_APP_STAGE || "prod"])
      );
      const provider = getProvider();
      let ownerToken = provider.publicKey;
      //const result = isValidSolanaAddress(ownerToken);
      // const publicAddress = await resolveToWalletAddress({
      //   text: "NftEyez.sol",
      // });
      const nfts = await getParsedNftAccountsByOwner({
        publicAddress: ownerToken,
        connection: connect,
        //@ts-ignore
        serialization: true,
      });
      return nfts;
    }
  } catch (error) {
    console.log("nft fetch error", error);
  }
};

export const getNftTokenData = async () => {
  try {
    let nftData: any = await getAllNftData();
    console.log({ nftData });
    const provider = getProvider();
    let ownerToken = provider.publicKey.toString();
    const tokenId = nftData.map((nft: any) => nft.mint);
    const validNFT = await staticApiCall(`${ApiUrl.nftValidate}`, "POST", {
      landsNftTokenAddress: tokenId,
      walletId: ownerToken,
    });
    console.log("validNFT", validNFT, nftData);
    //api call
    const res = tokenId;
    var data = Object.keys(nftData).map((key) => nftData[key]);
    let arr = [];
    let mintData = [];
    let n = data.length;
    for (let i = 0; i < n; i++) {
      if (validNFT.includes(data[i].mint)) {
        let val = await axios.get(data[i].data.uri);
        //@ts-ignore
        arr.push(val);
        //@ts-ignore
        mintData.push(data[i].mint);
      }
    }
    return { arr, nftData: mintData };
  } catch (error) {
    console.log(error);
  }
};
export const getMeta = async () => {
  const connection = new Connection(
    process.env.REACT_APP_ONCHAIN_ENDPOINT ||
      "https://solana-api.syndica.io/access-token/cOzUyVAdbFjWBgGDJEmuRkhzZy1vdlF7thGKu5XXkynB1v2v8aFxrh1j5rCPQdze/rpc/"
  ); // new Connection("https://api.mainnet-beta.solana.com/");
  const tokenMint: string = "4MPsoToSMaAaid12frqCEM3rRjS8k5QVc5tP5bhzjnqL";
  const largestAccounts = await connection.getTokenLargestAccounts(
    new PublicKey(tokenMint)
  );
  const largestAccountInfo = await connection.getParsedAccountInfo(
    largestAccounts.value[0].address
  );
};
