import "./LandingPage.scss";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useRef, useCallback } from "react";
import useSocialLogin from "../../Hooks/useSocialLogin";
import { useState } from "react";
import Line from "../../components/Line";
import { ConnectWallet } from "../../components/ConnectWallet";
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { useQuery } from "react-query";
import ReactQueryKey from "../../Constant/ReactQuery";
import { makeString } from "../../utils/CommonFunction";
import { ApiCall } from "../../Api/ApiCall";
import { ApiUrl } from "../../Api/ApiUrl";
import { useQueryClient } from "react-query";

interface tplotOptions {
  facebookLogin: string;
  "": string;
}

let fbId: null | string = null;

let socialMediaLogin = false;

const LandingPage = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  let redirectSwitch: boolean = true;
  const {
    connect,
    publicKey,
    connected,
    wallet,
    select,
    signMessage,
    disconnect,
  } = useWallet();
  const [walletKey, setWalletKey] = useState<any>(undefined);
  const { data } = useQuery(ReactQueryKey.PATH_NAME);
  const [updateWallateAddress, setUpdateWallateAddress] = useState(true);
  const [crptoMsg, setCrptoMsg] = useState<{
    signedMessage: string;
    message: string;
  }>({ signedMessage: "", message: "" });
  const AccessToken = useQuery(
    ReactQueryKey.SOCIAL_MEDIA_LOGIN,
    () =>
      ApiCall("POST", ApiUrl.walletlogin, {
        walletId: walletKey,
        ...crptoMsg,
      }),
    {
      enabled: false,
    }
  );

  const setAccessToken = (wid, message, signedMessage) => {
    ApiCall("POST", ApiUrl.walletlogin, {
      walletId: wid,
      message: message,
      signedMessage: signedMessage,
    }).then((c) => {
      if (c.access_token === undefined) {
        localStorage.clear();
        queryClient.invalidateQueries();
        disconnect();
        navigate("/");
      }
      localStorage.setItem("access_token", c.access_token);
    });
  };

  const connectWallet = async () => {
    //@ts-ignore
    const { solana } = window;
    console.log("connectWallet function");
    if (solana) {
      try {
        await solana.connect().then((resSolanaConnect) => {
          if (wallet) {
            connect().then(() => {
              const message = makeString(15);
              const encodedMessage = new TextEncoder().encode(message);
              if (updateWallateAddress) {
                solana
                  .request({
                    method: "signMessage",
                    params: {
                      message: encodedMessage,
                      display: "hex",
                    },
                  })
                  .then(async (signedMessage) => {
                    await setAccessToken(
                      signedMessage.publicKey,
                      message,
                      signedMessage.signature
                    );

                    if (
                      signedMessage.publicKey !==
                      resSolanaConnect.publicKey.toString()
                    ) {
                      await disconnect();
                      await setUpdateWallateAddress(false);
                      await connect();
                    }

                    //crptoMsg.message = message;
                    //crptoMsg.signedMessage = signedMessage.signature;
                    const cid = "88qrqeCdcVkThTZvts8J9sQXh8iJKd9VmAEPDNMtjky5";
                    //@ts-ignore
                    setWalletKey(signedMessage.publicKey);
                    //setWalletKey(cid)
                    setCrptoMsg({
                      message,
                      signedMessage: signedMessage.signature,
                    });
                    //setConnected(true);
                  })
                  .catch((err) => {
                    localStorage.clear();
                    queryClient.invalidateQueries();
                    disconnect();
                    navigate("/");
                  });
              }
            });
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  // useEffect(() => {
  //   if (publicKey !== undefined && publicKey !== null && redirectSwitch) {

  //     console.log('path', data);
  //     //@ts-ignore
  //     navigate(data);
  //     redirectSwitch = false;
  //     console.log('redirect');
  //   }
  // }, [publicKey])
  useEffect(() => {
    if (props.autoClick && props.autoClick === "true") {
      console.log("auto clicked!");
    }
    // if (publicKey !== undefined && publicKey !== null) {
    connectWallet();
    // } else {
    //   connect();
    // }
  }, [publicKey, wallet]);

  useEffect(() => {
    console.log("useEffect publicKey", crptoMsg);
    if (crptoMsg.message !== "" && crptoMsg.signedMessage !== "") {
      AccessToken.refetch();
    }
  }, [crptoMsg]);

  useEffect(() => {
    document.documentElement.style.setProperty("--base", "auto");
  }, []);

  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className={"landing-page-wrpper"}>
        <div className={"connect-box"}>
          <div className="connect-wallet-image-wrapper">
            <img
              className={"landing-logo"}
              src={`${process.env.PUBLIC_URL}/images/connectwallet.png`}
              alt="dashboard logo"
            />
          </div>
          <div>
            <div className="heading-text">Connect wallet</div>
            <div className={"des-text"}>
              Kindly connect your wallet to proceed
            </div>
            <div className="connect-wallet-custom-button">
              <WalletModalProvider>
                <WalletMultiButton />
              </WalletModalProvider>
            </div>
            {/* <Button
          onClick={() => {
            handleClick();
            // connectWallet();
            // showWalletConnect = true;
            // socialMediaLogin = false;
          }}
          className={"connect-button"}
        >
          {isConnected ? "Connected" : "Connect Wallet"}
        </Button> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LandingPage;
