import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import Title from "../../components/Title";
import "./Experience.scss";
import Line from "../../components/Line";
import { Modal, Button } from "react-bootstrap";
import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  LoginSocialGoogle,
  LoginSocialAmazon,
  LoginSocialFacebook,
  LoginSocialGithub,
  LoginSocialInstagram,
  LoginSocialLinkedin,
  LoginSocialMicrosoft,
  LoginSocialPinterest,
  LoginSocialTwitter,
  IResolveParams,
} from "reactjs-social-login";
import { SocialMediaAppId } from "../../Constant/Constant";
import { ApiCall } from "../../Api/ApiCall";
import InputBox from "../../components/InputBox";
import { useQuery } from "react-query";
import ReactQueryKey from "../../Constant/ReactQuery";
import { BASE_URL, ApiUrl } from "../../Api/ApiUrl";
import axios from "axios";
import { GoogleLogin } from "react-google-login";
import SocialButton from "./SocialButton";
import { useGoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import ForgotPassword from "./ForgotPassword";
import SetPassword from "./SetPassword";
import Tag from "../../components/Tag";
import AlertModel from "../../components/AlertModel";

//import {gapi} from 'gapi-script';

const Experience = () => {
  const [isEmailModalOpened, setIsEmailModalOpened] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState("");
  const [showComingSoonError, setShowComingSoonError] = useState(false);

  const REDIRECT_URI = "http://localhost:3000";
  let objAccess_token;
  const AccessToken = useQuery(ReactQueryKey.SOCIAL_MEDIA_LOGIN, {
    enabled: false,
  });

  const { data } = useQuery(ReactQueryKey.SOCIAL_MEDIA_LOGIN, () => {}, {
    enabled: false,
  });

  if (data !== undefined) {
    const { access_token } = data;
    objAccess_token = access_token;
    localStorage.setItem("access_token", access_token);
  } else if (!!localStorage.getItem("access_token")) {
    objAccess_token = localStorage.getItem("access_token")?.toString();
  } else {
    localStorage.clear();
    window.location.href = window.location.origin;
  }

  const Axios = axios.create({
    baseURL: BASE_URL,
    //@ts-ignore
    headers: { Authorization: `Bearer ${objAccess_token}` },
  });

  const [isEmailConnected, setIsEmailConnected] = useState(
    //@ts-ignore
    AccessToken.data !== undefined ? AccessToken.data.isEmailConnected : false
  );
  const [isEmailVerified, setIsEmailVerified] = useState(
    //@ts-ignore
    AccessToken.data !== undefined ? AccessToken.data.isEmailVerified : false
  );
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const x = 1;

  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();
  const [cancele, setCancel] = useState(false);
  const [validationClicked, setValidationClicked] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [addPassword, setAddPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [loginClicked, setLoginClicked] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [showDetailsPage, setShowDetailsPage] = useState(false);
  const [showChangePassword, setshowChangePassword] = useState(false);
  const [changeValues, setChangeValues] = useState(false);
  const [isFetchedUserDetails, setIsFetchedUserDetails] = useState(false);

  const [subText, setSubText] = useState("Profile successfully Created!");
  const [showAlert, setshowAlert] = useState(false);
  const [alertHeaderText, setAlertHeaderText] = useState("You are all set!");
  const [stateUsedDetails, setUsedDetails] = useState({
    name: isEmailConnected ? name : "",
    email: isEmailConnected ? email : "",
    password: "",
    confirmPassword: "",
  });
  const [forgotPassword, setForgotPassword] = useState(false);
  let usedDetails = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  function onOKClick() {
    setAlertHeaderText("");
    setSubText("");
    setshowAlert(false);
  }

  const optSubmit = async (otp) => {
    const res = await ApiCall("POST", ApiUrl.verifyOTP, {
      email: stateUsedDetails.email,
      otp: otp,
    });
    console.log({ res });
    if (res.success) {
      setEmailVerified(true);
      setAddPassword(true);
    }
    setForgotPassword(false);
  };

  const createAccount = async () => {
    setValidationClicked(true);
    //const validEmail = validateEmail(stateUsedDetails.email);
    if (
      stateUsedDetails.name !== "" &&
      stateUsedDetails.password === stateUsedDetails.confirmPassword
    ) {
      try {
        const res = await Axios.post(ApiUrl.createAcc, {
          email: stateUsedDetails.email,
          password: stateUsedDetails.password,
          name: stateUsedDetails.name,
        });
        console.log("res", res);
        setAlertHeaderText("You are all set!");
        setSubText(
          "Profile successfully Created! Please verify your email in order to set password and use your account."
        );
        setShowDetailsPage(true);
      } catch (e) {
        console.log({ e });
        setAlertHeaderText("Some error occured");
        if (e instanceof Error) {
          //@ts-ignore
          setSubText(e.response.data.message);
        }
        setshowAlert(true);
      }
    }
  };

  const handleSocialLogin = (user) => {
    console.log(user);
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  const validateEmail = (email) => {
    console.log("email", email);
    if (email === "") {
      setInvalidEmail(true);
      return true;
    }
    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setInvalidEmail(false);
      return false;
    } else {
      setInvalidEmail(true);
      return true;
    }
  };

  useEffect(() => {
    document.documentElement.style.setProperty("--base", "hidden");
  }, []);

  const emailOTPVerification = async (loginFunction) => {
    setForgotPassword(true);
    const res = await ApiCall("POST", ApiUrl.verifyMail, {
      email: stateUsedDetails.email,
    });
  };

  async function SocialLoginFun(e) {
    const token = e.tokenId;
    const Axios = axios.create({
      baseURL: BASE_URL,
      //timeout: 1000,
      //@ts-ignore
      headers: { Authorization: `Bearer ${objAccess_token}` },
    });
    try {
      const res = await Axios.post(ApiUrl.connectGoogle, { tokenId: token });
      e = undefined;
      setSubText("Your google account has been successfully linked.");
      setAlertHeaderText("You are all set!");
      usedDetails = { ...stateUsedDetails };
      console.log("res.data.name", res.data.name);
      console.log("res.data.email", res.data.email);
      usedDetails.name = res.data.name;
      usedDetails.email = res.data.email;
      setUsedDetails(usedDetails);
      setEmailVerified(true);
      setCancel(false);
      setAddPassword(true);
      setLoginClicked(false);
      console.log({ loginClicked });
      setShowDetailsPage(true);
    } catch (e) {
      console.log(e.response.data.message);
      setSubText(e.response.data.message);
      setAlertHeaderText("Error!");
      setshowAlert(true);
    }
  }

  async function onSuccess(e) {
    console.log("onSuccess", e);
    console.log({ loginClicked });
    if (!emailVerified) {
      e && e.tokenId && SocialLoginFun(e);
    }
  }

  async function onFailure(e) {
    console.log("onFailure", e);
  }

  const changeNameFun = async () => {
    if (stateUsedDetails.name !== name) {
      const res = await Axios.post(ApiUrl.updateUserName, {
        name: stateUsedDetails.name,
      });
      console.log("res", res);
      usedDetails = { ...stateUsedDetails };
      usedDetails.name = res.data.name;
      setUsedDetails(usedDetails);
      setSubText("You have successfully changed your Name!");
      setshowAlert(true);
    }
  };

  const saveChanges = async () => {
    let isAnythingUpdated = false;
    setSubText("You have successfully changed your details!");
    setAlertHeaderText("You are all set!");
    if (stateUsedDetails.name !== name) {
      isAnythingUpdated = true;
      const res = await Axios.post(ApiUrl.updateUserName, {
        name: stateUsedDetails.name,
      });
      console.log("res", res);
      usedDetails = { ...stateUsedDetails };
      usedDetails.name = res.data.name;
      setName(res.data.name);
      setUsedDetails(usedDetails);
    }
    if (stateUsedDetails.email !== email) {
      isAnythingUpdated = true;
      try {
        const res = await Axios.post(ApiUrl.updateEmail, {
          email: stateUsedDetails.email,
        });
        console.log("res", res);
        usedDetails = { ...stateUsedDetails };
        usedDetails.email = res.data.email;
        setEmail(res.data.email);
        setUsedDetails(usedDetails);
      } catch (e) {
        console.log(e.response.data.message);
        setSubText(e.response.data.message);
        setAlertHeaderText("Error!");
      }
    }
    if (isAnythingUpdated) {
      setshowAlert(true);
      setChangeValues(!changeValues);
    }
  };

  useEffect(() => {
    Axios.get(ApiUrl.user).then((res) => {
      setName(res.data.name);
      setEmail(res.data.email);
      usedDetails = { ...stateUsedDetails };
      usedDetails.name = res.data.name;
      usedDetails.email = res.data.email;
      setName(res.data.name);
      setEmail(res.data.email);
      setUsedDetails(usedDetails);
      setEmailVerified(!!res.data.emailVerifiedAt);
      setIsFetchedUserDetails(true);
      if (res.data.emailVerifiedAt) {
        setIsEmailConnected(true);
      }
    });
  }, []);

  useEffect(() => {
    // function start() {
    //   gapi.client.init({
    //     clientId: SocialMediaAppId.GOOGLE,
    //     scope: "",
    //   });
    // }
    // gapi.load("client:auth2", start);
  }, []);

  return (
    <div className="experience-wrapper">
      <div className={"mall-container download-exe"}>
        <div className={"mall-balance"}>
          <img
            src={`${process.env.PUBLIC_URL}/images/DownloadLauncher.png`}
            alt=""
          />
          <div className="launcher-wrapper">
            <div className="text">Download Launcher</div>
            <div className="token">
              You need to download the MetaMall Launcher to experience the
              metaverse
            </div>
          </div>
        </div>
        <div className="button-wrapper">
          {/* <PrimaryButton name="BUY ON MEXC" onClick={uniSwap} className="primary-btn" /> */}
          {process.env.REACT_APP_HIDE_LAUNCHER &&
          process.env.REACT_APP_HIDE_LAUNCHER === "true" ? (
            <div className={"coinbase-button"}>
              <PrimaryButton
                name="COMING SOON"
                className="primary-btn download"
                onClick={() => {
                  setIsEmailModalOpened(true);
                }}
              />
            </div>
          ) : (
               <React.Fragment>
            <div className={"coinbase-button"}>
              <a href="https://installer.launcher.xsolla.com/xlauncher-builds/xsolla-launcher-update/3108/bin/web_installer.exe">
                <PrimaryButton
                  name="DOWNLOAD LAUNCHER WINDOWS"
                  className="primary-btn download" 
                />
              </a>
              </div>
              <div className={"coinbase-button"}>
              <a href="https://installer.launcher.xsolla.com/xlauncher-builds/xsolla-launcher-update/3107/bin/launcher.dmg">
                <PrimaryButton
                  name="DOWNLOAD LAUNCHER MAC"
                  className="primary-btn download"
                />
              </a>
            </div>
            </React.Fragment>
          )}
        </div>
      </div>
      {/* @ts-ignore*/}
      {isEmailConnected || showDetailsPage ? (
        <div>
          <div className="mall-container create-accout-screen">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                verticalAlign: "center",
              }}
            >
              <Title
                firstName="PROFILE"
                remainName="DETAILS"
                showBuyBtn={false}
                imageUrl={""}
              />
              <PrimaryButton
                name={changeValues ? "cancel" : "edit"}
                style={{ height: "2rem", marginTop: "1.8rem" }}
                onClick={() => {
                  setChangeValues(!changeValues);
                }}
              />
            </div>

            <Line className="" />
            <div className="input-wrapper">
              <div className="verify-email-tag">
                <InputBox
                  label={"Avatar Name"}
                  placholder={"Name"}
                  onChange={(e) => {
                    usedDetails = { ...stateUsedDetails };
                    usedDetails.name = e.target.value;
                    setUsedDetails(usedDetails);
                  }}
                  message="Enter name"
                  isValid={validationClicked && stateUsedDetails.name === ""}
                  type="Default"
                  value={stateUsedDetails.name}
                  disabled={!changeValues}
                  focus={changeValues}
                />
              </div>
              <div className="verify-email-tag">
                <InputBox
                  label={"Email id"}
                  placholder={"Email id"}
                  onChange={(e) => {
                    usedDetails = { ...stateUsedDetails };
                    validateEmail(e.target.value);
                    usedDetails.email = e.target.value;
                    setUsedDetails(usedDetails);
                  }}
                  isValid={invalidEmail}
                  message="Invalid email"
                  type="Default"
                  value={stateUsedDetails.email}
                  disabled={
                    !isEmailVerified && !emailVerified ? !changeValues : true
                  }
                />
                {!isEmailVerified && !emailVerified ? <></> : <></>}
                <Tag
                  tag={
                    isEmailVerified || emailVerified ? "VERIFIED" : "PENDING"
                  }
                  className="email-tag"
                />
              </div>
              {isEmailVerified || emailVerified ? (
                <div className="verify-email-tag">
                  <InputBox
                    label={"Password"}
                    placholder={"*********"}
                    onChange={(e) => {
                      usedDetails = { ...stateUsedDetails };
                      usedDetails.password = e.target.value;
                      setUsedDetails(usedDetails);
                    }}
                    isValid={invalidEmail}
                    message="Invalid email"
                    type="password"
                    disabled={!showChangePassword}
                  />
                  <PrimaryButton
                    name={"change"}
                    className={"create-account-button change-password"}
                    onClick={() => {
                      setChangePassword(true);
                    }}
                  />
                </div>
              ) : (
                <>
                  {!isEmailVerified && !emailVerified && (
                    <div className={"mall-container download-exe"}>
                      <div className={"mall-balance"}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/VerifyEmail.png`}
                          alt=""
                        />
                        <div className="launcher-wrapper">
                          <div className="text">
                            Verify e-mail address in order to proceed
                          </div>
                          <div className="token">
                            Kindly enter your e-mail address to load data from
                            the MetaMall Metaverse
                          </div>
                        </div>
                      </div>
                      <div className="button-wrapper">
                        {/* <PrimaryButton name="BUY ON MEXC" onClick={uniSwap} className="primary-btn" /> */}
                        <div className={"coinbase-button"}>
                          <PrimaryButton
                            name="send otp"
                            onClick={emailOTPVerification}
                            className="primary-btn download"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className="profile-details-button-wrapper">
                {changeValues && (
                  <PrimaryButton
                    name={"SAVE CHANGES"}
                    className={"create-account-button save-btn"}
                    onClick={saveChanges}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="mall-container create-accout-screen">
            <Title
              firstName="CREATE"
              remainName="NEW ACCOUNT"
              showBuyBtn={false}
              imageUrl={""}
            />
            <Line className="" />
            <div className="input-wrapper">
              <InputBox
                label={"Avatar Name"}
                placholder={"Name"}
                onChange={(e) => {
                  usedDetails = { ...stateUsedDetails };
                  usedDetails.name = e.target.value;
                  setUsedDetails(usedDetails);
                }}
                message="Enter name"
                isValid={validationClicked && stateUsedDetails.name === ""}
                type="Default"
                value={stateUsedDetails.name}
              />
              <InputBox
                label={"Email id"}
                placholder={"Email id"}
                onChange={(e) => {
                  usedDetails = { ...stateUsedDetails };
                  validateEmail(e.target.value);
                  usedDetails.email = e.target.value;
                  setUsedDetails(usedDetails);
                }}
                isValid={invalidEmail}
                message="Invalid email"
                type="Default"
                value={stateUsedDetails.email}
              />
              {/* <InputBox
                label={"Password"}
                placholder={"Password"}
                onChange={(e) => {
                  usedDetails = { ...stateUsedDetails };
                  usedDetails.password = e.target.value;
                  setUsedDetails(usedDetails);
                }}
                type="password"
                isValid={
                  validationClicked &&
                  (stateUsedDetails.password !==
                    stateUsedDetails.confirmPassword ||
                    stateUsedDetails.password === "")
                }
                value={stateUsedDetails.password}
              />
              <InputBox
                label={"Confirm Password"}
                placholder={"Confirm Password"}
                onChange={(e) => {
                  usedDetails = { ...stateUsedDetails };
                  usedDetails.confirmPassword = e.target.value;
                  setUsedDetails(usedDetails);
                }}
                isValid={
                  validationClicked &&
                  (stateUsedDetails.password !==
                    stateUsedDetails.confirmPassword ||
                    stateUsedDetails.password === "")
                }
                message="Password mismatch"
                type="password"
                value={stateUsedDetails.confirmPassword}
              /> */}
              <div style={{ textAlign: "center" }}>
                <PrimaryButton
                  name={"CREATE ACCOUNT"}
                  className={"create-account-button-big"}
                  onClick={createAccount}
                />
              </div>
            </div>
          </div>
          <div className="or-line-wrapper">
            <div className="line"></div>
            <div className="or-text">OR</div>
          </div>
          <div className="mall-container social-media-wrapper">
            <div className="socialmedia-button">
              <div className="google-login-custom-button">
                <GoogleLogin
                  clientId={SocialMediaAppId.GOOGLE}
                  render={(renderProps) => (
                    <Button
                      className="login-button"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <div className="login-wrapper">
                        <img
                          className={"google-logo"}
                          src={`${process.env.PUBLIC_URL}/images/GoogleIcon.png`}
                          alt="dashboard logo"
                        />
                        <div className="text">GOOGLE LOGIN</div>
                      </div>
                    </Button>
                  )}
                  buttonText="Sign In with Google"
                  onSuccess={(e) => onSuccess(e)}
                  onFailure={(e) => onFailure(e)}
                  cookiePolicy={"single_host_origin"}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {forgotPassword && <ForgotPassword optSubmit={optSubmit} />}
      {(addPassword || changePassword) && (
        <SetPassword changePassword={changePassword} />
      )}
      {(showDetailsPage || showAlert) && (
        <AlertModel
          image={`${process.env.PUBLIC_URL}/images/SuccessImage.png`}
          headerText={alertHeaderText}
          subText={subText}
          handleClick={onOKClick}
        />
      )}

      <Modal
        show={isEmailModalOpened}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge"
      >
        <div className="header-wrapper">
          <div className="header-text-wrapper">
            <div
              className="header-text"
              style={{
                textAlign: "center",
                fontSize: "2rem",
                marginTop: "0.5rem",
              }}
            >
              Coming Soon
            </div>
            <div
              className="header-sub-text"
              style={{ marginTop: "1rem", textAlign: "center" }}
            >
              Enter your email id and we will get back to you
            </div>
            <div className="password-box">
              <InputBox
                label={"Email"}
                placholder={"Email"}
                message=""
                type="text"
                value={enteredEmail.toString()}
                onChange={(e) => {
                  setEnteredEmail(e.target.value);
                }}
              />
            </div>
            {showComingSoonError ? (
              <div>Please provide a valid email address</div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name={"Submit"}
            onClick={async () => {
              setShowComingSoonError(false);
              if (
                enteredEmail
                  .toLowerCase()
                  .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  )
              ) {
                const res = await Axios.post(ApiUrl.notifyMe, {
                  email: enteredEmail,
                });
                console.log(res);
                setIsEmailModalOpened(false);
              } else {
                setShowComingSoonError(true);
              }
            }}
            className="proceed-button"
            style={{ marginRight: "2rem" }}
          />
          <PrimaryButton
            name={"Cancel"}
            onClick={() => {
              setShowComingSoonError(false);
              setIsEmailModalOpened(false);
            }}
            className="proceed-button"
          />
        </div>
      </Modal>
    </div>
  );
};

export default Experience;
