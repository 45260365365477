// @ts-nocheck

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { isEqual } from "lodash";
import {
  PublicKey as SolanaPublicKey,
  Transaction,
  Connection,
} from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

import { tokenAddress } from "../../../Constants";
import { NETWORK_URL } from "../../../Constant/StakingConstant";
import { getOrCreateAssociatedTokenAccount } from "../../../components/getOrCreateAssociatedTokenAccount";
import { createTransferInstruction } from "../../../components/createTransferInstructions";
import {
  useTemplateListingDetail,
  postTransferRequest,
  useTradeHistory,
  endListingSale,
  addAuditLog,
  buyTemplate,
} from "../hooks/useAuctionData";
import Button from "../../../components/Button";
import InsufficientFundsModal from "../../../components/InsufficientFundsModal";
import FullscreenLoading from "../../../components/FullscreenLoading";
import TradeHistory from "./TradeHistory";
import { notification } from "antd";
import { ApiUrl } from "../../../Api/ApiUrl";
import { FaAngleLeft } from "react-icons/fa";
import { toast } from "react-hot-toast";

const currencyImg = {
  mall: require("../../../imgg/malltoken.png"),
  usdt: require("../../../imgg/USDT-icon.png"),
};

const fetchBalance = async (
  publicKey: string | undefined,
  tokenAddress: string
) => {
  let walletBalance = 0;
  try {
    const owner = publicKey;
    const MINT = new SolanaPublicKey(tokenAddress);
    const request_data = {
      jsonrpc: "2.0",
      id: 1,
      method: "getTokenAccountsByOwner",
      params: [owner, { mint: MINT }, { encoding: "jsonParsed" }],
    };

    const transaction_details: any = await fetch(NETWORK_URL, {
      method: "POST",
      body: JSON.stringify(request_data),
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());

    if (
      Array.isArray(transaction_details?.result?.value) &&
      transaction_details?.result?.value[0] &&
      transaction_details?.result?.value[0]?.account?.data?.parsed?.info
        ?.tokenAmount?.uiAmount
    ) {
      return transaction_details.result.value[0].account.data.parsed.info
        .tokenAmount.uiAmount;
    }
  } catch (e) {
    console.log(e);
  }
  return walletBalance;
};

const TemplateListingDetail = () => {
  const navigation = useNavigate();
  const [isFundTransferInProgress, setIsFundTransferInProgress] =
    useState(false);
  const [isNFTTransferInProgress, setIsNFTTransferInProgress] = useState(false);
  const [buyFlowSuccess, setBuyFlowSuccess] = useState(false);
  const [endSaleSuccess, setEndSaleSuccess] = useState(false);

  const [message, setMessage] = useState("");
  const [showFundIssue, setShowFundIssue] = useState(false);
  const [mallBalance, setMallBalance] = useState(0);
  const [subMessage, setSubMessage] = useState("");

  const { id = "" } = useParams();

  /** Mainnet RPC connection */
  const mainnet_endpoint = NETWORK_URL;
  const connection = new Connection(mainnet_endpoint);

  const {
    publicKey,
    connect,
    connected,
    disconnect,
    wallet,
    select,
    signTransaction,
  } = useWallet();

  const { data: listing = {} } = useTemplateListingDetail(
    id,
    isNFTTransferInProgress
  );

  const {
    image,
    furnitures = [],
    currency,
    name,
    description,
    price,
    status,
  } = listing;
  const defaultImage = require("../../../img/landsale-banner.png");

  useEffect(() => {
    if (status && (status === "sold" || status === "closed")) {
      setIsNFTTransferInProgress(false);
      if (status === "sold") {
        setBuyFlowSuccess(true);
      }
    }
  }, [status]);

  const handleBuy = async () => {
    const balance = await fetchBalance(publicKey, tokenAddress[currency]);
    setMallBalance(balance);
    if (parseFloat(price) <= balance) {
      transferToken();
    } else {
      setShowFundIssue(true);
    }
    // transferToken();
  };

  const handleButtonClick = () => {
    if (connected) {
      select("Phantom");
      handleBuy();
    } else {
      select("Phantom");
      connect();
    }
  };

  let DECIMALS = 3;

  async function getTransaction(staking_Txn_Hash: string) {
    const request_data = {
      jsonrpc: "2.0",
      id: 1,
      method: "getTransaction",
      params: [staking_Txn_Hash, "json"],
    };

    const transaction_details: any = await fetch(endpoint.url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request_data),
    });

    const content = await transaction_details.json();
    return content;
  }

  async function checkTransaction(error): Promise<Boolean> {
    if (error instanceof Error) {
      const message = error.message;
      try {
        // const message =
        //   'Transaction was not confirmed in 60.00 seconds. It is unknown if it succeeded or failed. Check signature 5NW5jk6vohTteJxSbojB6DDBHT7oSpq4t6vtP1j7TvBBcj2eHC7pA2UqhLfKStz5jejshfAsYeM4JE7Nt4Y5icu7 using the Solana Explorer or CLI tools.';

        const signatureIndex = message.indexOf("Check signature ");
        const subMessage = message.substring(signatureIndex + 16);
        const trxHash = subMessage.substring(0, subMessage.indexOf(" "));

        await connection.confirmTransaction(trxHash, "max");
        await connection.getParsedTransaction(trxHash, "confirmed");

        const content = await getTransaction(trxHash);

        if (!content || !content.result || content.result === null) {
          return false;
        }
      } catch (error) {
        console.log(
          "Error in checkTransaction - Transaction hash is invalid",
          error
        );
        return false;
      }
      console.log("Transaction hash is valid.");
      return true;
    }
    return false;
  }

  const transferToken = async () => {
    console.log("transfer token started");
    const toPublicKey = new SolanaPublicKey(
      listing && process.env.REACT_APP_ESCROW_WALLET_ADDRESS
    );
    const mint = new SolanaPublicKey(tokenAddress[currency]);
    if (currency === "usdt") {
      DECIMALS = 6;
    }

    const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
      // @ts-ignore
      connection,
      publicKey,
      mint,
      publicKey,
      signTransaction
    );

    const toTokenAccount = await getOrCreateAssociatedTokenAccount(
      connection,
      publicKey,
      mint,
      toPublicKey,
      signTransaction
    );

    const transaction = new Transaction().add(
      createTransferInstruction(
        fromTokenAccount.address, // source
        toTokenAccount.address, // dest
        publicKey,
        parseFloat(price) * Math.pow(10, DECIMALS),
        [],
        TOKEN_PROGRAM_ID
      )
    );

    const blockHash = await connection.getRecentBlockhash();
    transaction.feePayer = publicKey;
    transaction.recentBlockhash = await blockHash.blockhash;
    const signed = await signTransaction(transaction);
    setIsFundTransferInProgress(true);
    const finalResponse = await connection.sendRawTransaction(
      signed.serialize()
    );

    let runtimes: number = 1;
    while (runtimes <= 4) {
      try {
        runtimes += 1;
        await connection.confirmTransaction(finalResponse, "max");
        await connection.getParsedTransaction(finalResponse, "confirmed");
        runtimes = 6;
      } catch (e) {
        console.log(e);
        // assignState(true);

        await new Promise((resolve) => setTimeout(resolve, 10000));
        const flag = await checkTransaction(e);
        if (flag) {
          runtimes = 6;
        }
      }
    }

    setTimeout(async () => {
      try {
        let buyReq = {
          templateId: id,
          buyerWalletAddress: publicKey,
          transactionHash: finalResponse,
        };

        // update Audit log
        await addAuditLog({
          action: ApiUrl.marketplaceAuction,
          additionalInfo: "Start => Market place BUY auction for template",
          payload: buyReq,
          walletAddress: publicKey,
        });

        const res = await buyTemplate(buyReq);
      } catch (err) {
        console.log("buyTemplate", err);
      }
      const toastId = toast.loading("Processing transaction...");
      setIsFundTransferInProgress(false);
      toast.success("Template Purchased", {
        id: toastId,
      });
      // setIsNFTTransferInProgress(true);
      // setMessage("Transferring the NFT to your wallet");
      // setSubMessage(
      //   "Your order is being processed. It may take a few minutes to complete the transaction depending upon the performance of the Solana network. In case the NFT is not reflected in your wallet within the next 10 minutes, kindly reach out to us at"
      // );
    }, 1000);
  };

  const getCtaText = () => {
    return "Buy Now";
  };

  return (
    <div className="marketplace-listing-detail">
      <div className="marketplace-listing-detail-safe-area">
        <Button
          variant="primary active shadow-none btn-icon"
          onClick={() => {
            navigation(-1);
          }}
          style={{ marginBottom: "10px", padding: "20px", fontSize: "20px" }}
        >
          <FaAngleLeft />
        </Button>
        <div className="marketplace-listing-detail-card">
          <img
            className="marketplace-listing-detail-img"
            src={image || defaultImage}
            alt="listing_image"
          />
          <div className="marketplace-listing-detail-content">
            <p className="marketplace-listing-detail-title">{name} </p>
            <div className="marketplace-listing-detail-price">
              <img
                src={currencyImg[currency] || currencyImg.mall}
                className="marketplace-listing-card-currency"
                alt={currency}
              />
              <span>{price}</span>
            </div>
            <Button
              onClick={handleButtonClick}
              type="primary"
              style={{
                height: "40px",
                width: "fit-content",
                padding: "0 10px",
              }}
            >
              {connected ? getCtaText() : "Connect wallet"}
            </Button>
            <p className="marketplace-listing-detail-label">DESCRIPTION</p>
            <p className="marketplace-listing-detail-description">
              {description}
            </p>

            <div>
              <div>Furnitures</div>
              <div style={{ width: "100%" }}>
                {furnitures.map(({ id, rowName }, index) => {
                  return (
                    <span
                      style={{
                        backgroundColor: "hsla(0,0%,100%,.15)",
                        borderRadius: "4px",
                        padding: "2px 10px",
                        marginRight: "1rem",
                        marginBottom: "1rem",
                        display: "inline-block",
                      }}
                      key={id}
                    >
                      {rowName}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <InsufficientFundsModal
          onClose={() => setShowFundIssue(false)}
          isModalVisible={showFundIssue}
          message={`insufficient ${currency}`}
          minimumFunds={(price - mallBalance).toFixed(2)}
          currentFunds={mallBalance}
          tokenType={currency}
        />
        <FullscreenLoading
          isFundTransferInProgress={isFundTransferInProgress}
          isNFTTransferInProgress={isNFTTransferInProgress}
          buyFlowSuccess={buyFlowSuccess}
          endSaleSuccess={endSaleSuccess}
        />
      </div>
    </div>
  );
};

export default TemplateListingDetail;
