const ReactQueryKey = {
  STAKING_CLAIMS: "STAKING_CLAIMS",
  WALLET_ADDRESS: "WALLET_ADDRESS",
  TOKEN_TRANSFER: "TOKEN_TRANSFER",
  VESTING_SCHUDLE: "VESTING_SCHUDLE",
  SOCIAL_MEDIA_LOGIN: "ACCESS_TOKEN",
  GET_LIVE_AUCTION: "GET_LIVE_AUCTION",
  GET_LAND_TRANSACTION: "GET_LAND_TRANSACTION",
  PATH_NAME: "PATH_NAME",
  GOOGLE_LOGIN: "GOOGLE_LOGIN",
  GAME_INVENTORY: "ACCESS_TOKEN",
  MARKETPLACE_LISTINGS: "MARKETPLACE_LISTINGS",
  MARKETPLACE_TEMPLATES_LISTINGS: "MARKETPLACE_TEMPLATES_LISTINGS",
  MARKETPLACE_STORE_LISTINGS: "MARKETPLACE_STORE_LISTINGS",
  SELECTED_LISTING: "SELECTED_LISTING",
  SELECTED_TEMPLATE_LISTING: "SELECTED_TEMPLATE_LISTING",
  TRADE_HISTORY: "TRADE_HISTORY",
  ASSETS: "ASSETS",
};

export default ReactQueryKey;
