import { useQuery } from "react-query";
import ReactQueryKey from "../Constant/ReactQuery";
import { staticApiCall } from "../Api/ApiCall";
import { ApiUrl } from "../Api/ApiUrl";
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect } from "react";

const useGetStakingClaims = (wallet_address = "") => {
  const { publicKey, connected } = useWallet();
  //@ts-ignore
  const { isIdle, isLoading, isError, data, error, refetch, isFetching } =
    useQuery(
      ReactQueryKey.STAKING_CLAIMS,
      () =>
        staticApiCall(
          `${ApiUrl.getStaking}${
            wallet_address === "" ? publicKey?.toString() : wallet_address
          }`,
          "GET"
        ),
      {
        enabled: false,
      }
    );
  // if (publicKey?.toString() !== null && data === undefined) {
  //   refetch();
  // }
  useEffect(() => {
    if (data === undefined) {
      connected && refetch();
    }
  }, [publicKey, wallet_address]);

  return {
    isIdle,
    isLoading,
    isError,
    data,
    error,
    isFetching,
    refetch,
  };
};

export default useGetStakingClaims;
