import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../Button";

// @ts-ignore
import CloseButton from "../../images/close.svg";

import "./index.scss";

interface IProps {
  message: string;
  isModalVisible: boolean;
  minimumFunds: any;
  currentFunds: any;
  onClose: () => void;
  tokenType: string | "";
}

const currencyImg = {
  mall: require("../../imgg/malltoken.png"),
  usdt: require("../../imgg/USDT-icon.png"),
};

const InsufficientFundsModal = ({
  isModalVisible,
  minimumFunds,
  currentFunds,
  onClose,
  tokenType,
}: IProps) => {
  // const getAmount = (val) => {
  //   const amount = typeof val === "string" ? parseFloat(val) : val;
  //   let formattedAmount = `${amount}`;
  //   if (amount >= 1) {
  //     formattedAmount = formatAmount(amount);
  //   }
  //   return formattedAmount;
  // };

  return (
    <Modal
      centered
      className="insufficient-funds-modal"
      show={isModalVisible}
      onHide={onClose}
    >
      <Modal.Body>
        <div className="insufficient-funds-modal-header">
          <p>Transaction Alert</p>
          <button onClick={onClose}>
            <img src={CloseButton} alt="close_icon" />
          </button>
        </div>
        <div className="insufficient-funds-modal-content">
          <div className="insufficient-funds-modal-content-section">
            <p className="insufficient-funds-modal-content-label">
              Insufficient funds
            </p>
            <p className="insufficient-funds-modal-content-sub-label">
              YOUR BALANCE
            </p>
            <div className="insufficient-funds-modal-content-price">
              <img
                src={currencyImg[tokenType] || currencyImg.mall}
                alt={tokenType}
              />
              <span>{currentFunds}</span>
            </div>
          </div>
          <div className="insufficient-funds-modal-content-section">
            <p className="insufficient-funds-modal-content-sub-label">
              INSUFFICIENT MALL
            </p>
            <div className="insufficient-funds-modal-content-price">
              <img
                src={currencyImg[tokenType] || currencyImg.mall}
                alt={tokenType}
              />
              <span>{minimumFunds}</span>
            </div>
          </div>
          <p className="insufficient-funds-modal-content-info">Deposit the minimum amount of MALL and try again,</p>
          <Button
            className="insufficient-funds-modal-dismiss-btn"
            type="primary"
            onClick={onClose}
          >
            DISMISS
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InsufficientFundsModal;
