// @ts-nocheck

import React, { useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  PublicKey as SolanaPublicKey,
  Transaction,
  Connection,
} from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

import { tokenAddress } from "../../../../Constants";
import { NETWORK_URL } from "../../../../Constant/StakingConstant";
import FullscreenLoading from "../../../../components/FullscreenLoading";
import { getOrCreateAssociatedTokenAccount } from "../../../../components/getOrCreateAssociatedTokenAccount";
import { createTransferInstruction } from "../../../../components/createTransferInstructions";
import {
  useListingDetail,
  postTransferRequest,
  useTradeHistory,
  createNFT,
} from "../../hooks/useAuctionData";

import { NFT_GENERATION_WALLET } from "../../../../Constant/Constant";
import InputBox from "../../../../components/InputBox";
import { FaEdit } from "react-icons/fa";
import Button from "../../../../components/Button";
import "./CreateNFT.scss";

const CerateNFT = () => {
  const [isGenerateFeeTransferInProgress, setIsGenerateFeeTransferInProgress] =
    useState(false);
  const [isNFTCreationInProgress, setIsNFTCreationInProgress] = useState(false);
  const [createFlowSuccess, setCreateFlowSuccess] = useState(false);
  const [statenftDetails, setNFTDetails] = useState({
    nftName: "",
    symbol: "",
    description: "",
    imageNft: "/images/CKPTLogo.png",
  });

  const {
    publicKey,
    connect,
    connected,
    disconnect,
    wallet,
    select,
    signTransaction,
  } = useWallet();

  /** Mainnet RPC connection */
  const mainnet_endpoint = NETWORK_URL;
  const connection = new Connection(mainnet_endpoint);

  let nftDetails = {
    nftName: "",
    symbol: "",
    // description: "",
    imageNft: "",
  };

  //   async function onSelectLand(objLand: Land) {
  //     setNFTDetails({ ...statenftDetails, landUrl: objLand.image });
  //     nftDetails = { ...statenftDetails };
  //   }

  const [image_file, setImage_file] = useState<string>("");

  const handleImagePreview = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    setNFTDetails({ ...statenftDetails, imageNft: image_as_base64 });
    setImage_file(image_as_files);
  };

  const handleClearImage = () => {
    setNFTDetails({ ...statenftDetails, imageNft: "/images/CKPTLogo.png" });
    setImage_file("");
  };

  async function getTransaction(staking_Txn_Hash: string) {
    const request_data = {
      jsonrpc: "2.0",
      id: 1,
      method: "getTransaction",
      params: [staking_Txn_Hash, "json"],
    };

    const transaction_details: any = await fetch(endpoint.url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request_data),
    });

    const content = await transaction_details.json();
    return content;
  }

  async function checkTransaction(error): Promise<Boolean> {
    if (error instanceof Error) {
      const message = error.message;
      try {
        // const message =
        //   'Transaction was not confirmed in 60.00 seconds. It is unknown if it succeeded or failed. Check signature 5NW5jk6vohTteJxSbojB6DDBHT7oSpq4t6vtP1j7TvBBcj2eHC7pA2UqhLfKStz5jejshfAsYeM4JE7Nt4Y5icu7 using the Solana Explorer or CLI tools.';

        const signatureIndex = message.indexOf("Check signature ");
        const subMessage = message.substring(signatureIndex + 16);
        const trxHash = subMessage.substring(0, subMessage.indexOf(" "));

        await connection.confirmTransaction(trxHash, "max");
        await connection.getParsedTransaction(trxHash, "confirmed");

        const content = await getTransaction(trxHash);

        if (!content || !content.result || content.result === null) {
          return false;
        }
      } catch (error) {
        console.log(
          "Error in checkTransaction - Transaction hash is invalid",
          error
        );
        return false;
      }
      console.log("Transaction hash is valid.");
      return true;
    }
    return false;
  }

  const transferNft = async () => {
    setCreateFlowSuccess(false);
    setIsGenerateFeeTransferInProgress(true);
    // data !== undefined && props.setAttributeDataFun(data);
    const toPublicKey = new SolanaPublicKey(NFT_GENERATION_WALLET);
    const mint = new SolanaPublicKey(tokenAddress.usdt);
    console.log("MINT", { mint }, toPublicKey);
    const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
      connection,
      // @ts-ignore
      publicKey,
      mint,
      publicKey,
      signTransaction
    );
    console.log("fromTokenAccount", fromTokenAccount);

    const toTokenAccount = await getOrCreateAssociatedTokenAccount(
      connection,
      // @ts-ignore
      publicKey,
      mint,
      toPublicKey,
      signTransaction
    );

    console.log("toToken", { toTokenAccount });
    const transaction = new Transaction().add(
      createTransferInstruction(
        fromTokenAccount.address, // source
        toTokenAccount.address, // dest
        publicKey,
        0.8 * Math.pow(10, 6),
        [],
        TOKEN_PROGRAM_ID
      )
    );
    try {
      const blockHash = await connection.getRecentBlockhash();
      // @ts-ignore
      transaction.feePayer = await publicKey;
      transaction.recentBlockhash = await blockHash.blockhash;
      // @ts-ignore
      const signature = await signTransaction(transaction);
      const finalResponse = await connection.sendRawTransaction(
        signature.serialize()
      );
      console.log("finalResponse", finalResponse);
      console.log("finalResponse", { signature });
      //const signatureFormate = bs58.encode(finalResponse);

      //await connection.confirmTransaction(signatureFormate, "processed");
      let runtimes: number = 1;
      while (runtimes <= 4) {
        try {
          runtimes += 1;
          await connection.confirmTransaction(finalResponse, "max");
          await connection.getParsedTransaction(finalResponse, "confirmed");
          runtimes = 6;
        } catch (e) {
          console.log(e);
          console.log(" inside catch ");
          await new Promise((resolve) => setTimeout(resolve, 10000));
          const flag = await checkTransaction(e);
          if (flag) {
            runtimes = 6;
          }
        }
      }

      setTimeout(async () => {
        try {
          setIsGenerateFeeTransferInProgress(false);
          setIsNFTCreationInProgress(true);
          var formData = new FormData();
          formData.append("name", statenftDetails.nftName);
          formData.append("symbol", statenftDetails.symbol);
          formData.append("description", statenftDetails.description);
          formData.append("file", image_file);
          // @ts-ignore
          formData.append("owmerWalletAddress", publicKey);
          formData.append("transactionHash", finalResponse);
          const res = await createNFT(formData);
          setIsNFTCreationInProgress(false);
          setIsGenerateFeeTransferInProgress(false);
          setCreateFlowSuccess(true);
          // setSellFlowSuccess(true);
        } catch (err) {
          setIsNFTCreationInProgress(false);
          setIsGenerateFeeTransferInProgress(false);
          console.log("NFTCreationErr", err);
        }
      }, 1000);
    } catch (e) {
      setIsNFTCreationInProgress(false);
      setIsGenerateFeeTransferInProgress(false);
      //setLoading(false);
      //setShowAlert={(alertTemp) => assignState(alertTemp)}
      console.log(e);
      // props.setShowAlert(true);
      //setErrorMsg("Blockhash has been expired, please try again!");
      throw e;
    }
  };

  const handleOnCreateNFT = () => {
    transferNft();
    // alert(JSON.stringify(statenftDetails));
  };

  return (
    <div className="blkCreateNFT experience-wrapper">
      <InputBox
        label={"NFT name"}
        placholder={"NFT name"}
        onChange={(e) => {
          nftDetails = { ...statenftDetails };
          nftDetails.nftName = e.target.value;
          setNFTDetails(nftDetails);
        }}
        message="Enter NFT name"
        type="Default"
        value={statenftDetails.nftName}
        isValid={statenftDetails.nftName.trim() === "" ? true : false}
      />

      <InputBox
        label={"Symbol"}
        placholder={"Symbol"}
        onChange={(e) => {
          nftDetails = { ...statenftDetails };
          nftDetails.symbol = e.target.value;
          setNFTDetails(nftDetails);
        }}
        message="Enter symbol"
        type="Default"
        value={statenftDetails.symbol}
        isValid={statenftDetails.symbol.trim() === "" ? true : false}
      />

      {/* <InputBox
        label={"Description"}
        placholder={"Description"}
        onChange={(e) => {
          nftDetails = { ...statenftDetails };
          nftDetails.description = e.target.value;
          setNFTDetails(nftDetails);
        }}
        message="Enter Description"
        type="text-area"
        value={statenftDetails.description}
      /> */}

      <div className="inputbox-style">
        <div className="lable">
          Image
          <label onChange={(e) => handleImagePreview(e)}>
            <input type="file" title="nft" />
            <FaEdit className="icon" />
          </label>
          {image_file !== "" && (
            <span className="clearImgSelection" onClick={handleClearImage}>
              Remove
            </span>
          )}
          <input type="file" className="btn-file" title="nft"></input>
        </div>
        <img src={statenftDetails.imageNft} alt="NFT Image" key={Date.now()} />
      </div>

      <div
        className="alert-message-wrapper"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Button
          onClick={() => {
            handleOnCreateNFT();
          }}
          className="proceed-button"
          disabled={
            !statenftDetails.nftName.trim() || !statenftDetails.symbol.trim()
          }
        >
          Create
        </Button>
      </div>
      <FullscreenLoading
        isGenerateFeeTransferInProgress={isGenerateFeeTransferInProgress}
        isNFTCreationInProgress={isNFTCreationInProgress}
        createFlowSuccess={createFlowSuccess}
        redirect="/mplace/assets"
      />
    </div>
  );
};

export default CerateNFT;
