//export const BASEURL = "http://65.0.17.77";
export const BASEURL = "https://staking.metamalls.io/api";
//export const BASEURL = "https://staking.metamalls.io/api";
export const TOKEN_ID = "5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i";
// export const NETWORK_URL = "https://api.mainnet-beta.solana.com";
export const NETWORK_URL =
  process.env.REACT_APP_ONCHAIN_ENDPOINT ||
  "https://solana-api.syndica.io/access-token/cOzUyVAdbFjWBgGDJEmuRkhzZy1vdlF7thGKu5XXkynB1v2v8aFxrh1j5rCPQdze/rpc/"; //"https://morning-snowy-rain.solana-mainnet.quiknode.pro/";

export const STACKING_BASE_URL = "https://staking.metamalls.io/api/";
export const MALL_TOKEN_ID = "5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i"; //"CedGzCs94g69Qjpe4KWWfhwQc5ViGx4oDB6K7daQdL28";
