import "./InputBox.scss";
import { InputGroup, Form } from "react-bootstrap";
import { useRef } from "react";
import { useEffect } from "react";

const InputBox = ({
  label,
  placholder,
  onChange,
  isValid = false,
  value = "",
  message = "",
  type = "Default",
  disabled = false,
  focus = false,
  rows = 0,
  min = 1,
}) => {
  console.log(placholder, type, focus);
  const input = useRef();
  useEffect(() => {
    if (focus) {
      //@ts-ignore
      input.current.focus();
    }
  }, [focus]);
  if (type === "Default") {
    return (
      <div className="inputbox-style">
        {label !== "" ? <div className="lable">{label}</div> : <div className="lable">&nbsp;</div>}
        <InputGroup className="mb-3">
          <Form.Control
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            placeholder={placholder}
            onChange={onChange}
            style={isValid ? { border: "1px solid rgb(255, 77, 79)" } : {}}
            value={value}
            disabled={disabled}
            //@ts-ignore
            ref={input}
          />
        </InputGroup>
        {isValid && <div className="invalid-labellable">{message}</div>}
      </div>
    );
  } else if (type === "password") {
    return (
      <div className="inputbox-style">
        <div className="lable">{label}</div>
        <InputGroup className="mb-3">
          <Form.Control
            type="password"
            placeholder={placholder}
            onChange={onChange}
            style={isValid ? { border: "1px solid rgb(255, 77, 79)" } : {}}
            disabled={disabled}
          />
        </InputGroup>
        {isValid && <div className="invalid-labellable">{message}</div>}
      </div>
    );
  } else if (type === "text-area") {
    return (
      <div className="inputbox-style">
        <div className="lable">{label}</div>
        <InputGroup className="mb-3">
          <Form.Control
            type="text"
            placeholder={placholder}
            onChange={onChange}
            style={{ height: "auto" }}
            disabled={disabled}
            as="textarea"
            rows={rows}
            value={value}
          />
        </InputGroup>
        {isValid && <div className="invalid-labellable">{message}</div>}
      </div>
    );
  } else if (type === "number") {
    return (
      <div className="inputbox-style">
        {label !== "" ? <div className="lable">{label}</div> : <div className="lable">&nbsp;</div>}
        <InputGroup className="mb-3">
          <Form.Control
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            placeholder={placholder}
            onChange={onChange}
            style={isValid ? { border: "1px solid rgb(255, 77, 79)" } : {}}
            type="number"
            value={value}
            min={min}
          />
        </InputGroup>
        {isValid && <div className="invalid-labellable">{message}</div>}
      </div>
    );
  }
  return (
    <div className="inputbox-style">
      <div className="lable">{label}</div>
      <InputGroup className="mb-3">
        <Form.Control
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          placeholder={placholder}
          onChange={onChange}
          style={isValid ? { border: "1px solid rgb(255, 77, 79)" } : {}}
        />
      </InputGroup>
      {isValid && <div className="invalid-labellable">{message}</div>}
    </div>
  );
};

export default InputBox;
