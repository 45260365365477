import "../style/App.css";
import "../yeah.css";
import "../yeah.css";
import { StakingPoolData } from "../App";
import { useNavigate } from "react-router-dom";
import { getStakingPoolImage } from "../FEUtils";
import React from "react";
import PrimaryButton from "./PrimaryButton/PrimaryButton";

function StakingPool(props: { pool: StakingPoolData }) {
  const navigate = useNavigate();
  console.log('getStakingPoolImage(props.pool.name)', getStakingPoolImage(props.pool.name), props.pool.name);
  return (
    <div className="dude">
      <div className="checkout-img line">
        <img
          className="token-mainimg"
          src={require("../imgg/" + getStakingPoolImage(props.pool.name))}
          alt=""
        />
        <div className="token-staking">
          <h1>
            {props.pool.name}&nbsp;
            <span
              className={
                props.pool.status === "OPEN" ? "stake-green" : "stake-orange"
              }
            >
              {props.pool.status}
            </span>
          </h1>
          <div className="dudes">
            <div className="dude">
              <p>Maturity Period</p>
            </div>
            <div className="dude right-dude">
              <p>
                <span className="num">{props.pool.maturity_period} days</span>
              </p>
            </div>
            <div className="dude">
              <p>APY</p>
            </div>
            <div className="dude right-dude">
              <p className="num">{props.pool.apy} %</p>
            </div>
          </div>
        </div>
        {/* <button
          type="button"
          className="buy-land btn-100"
          disabled={props.pool.status === "OPEN" ? false : true}
          onClick={() => navigate(`/staking/staking-details/${props.pool.id}`)}
        >
          {props.pool.status === "OPEN" ? "STAKE MALL" : "CLOSED"}
        </button> */}
        <PrimaryButton
          name={props.pool.status === "OPEN" ? "STAKE MALL" : "CLOSED"}
          className=""
          onClick={() => navigate(`/staking/staking-details/${props.pool.id}`)}
          //@ts-ignore
          disabled={props.pool.status === "OPEN" ? false : true}
        />
      </div>
    </div>
  );
}

export default StakingPool;
