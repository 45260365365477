import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import "./MetamallWallet.scss";
import React, { useCallback, useRef, useState, useEffect } from "react";
import { useQuery } from "react-query";
import ReactQueryKey from "../../Constant/ReactQuery";
import axios from "axios";
import { ApiUrl, BASE_URL } from "../../Api/ApiUrl";
import { Modal } from "react-bootstrap";
import InputBox from "../../components/InputBox";
import { useWallet } from "@solana/wallet-adapter-react";
import { fetchBalance } from "../../Solana/GetBalance";
import { toast } from "react-hot-toast";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import {
  Transaction,
  PublicKey,
  LAMPORTS_PER_SOL,
  Connection,
} from "@solana/web3.js";
import { MALL_TOKEN_ID } from "../../Constants";
import { NETWORK_URL } from "../../Constant/StakingConstant";
import { getOrCreateAssociatedTokenAccount } from "../../components/getOrCreateAssociatedTokenAccount";
import { createTransferInstruction } from "../../components/createTransferInstructions";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import Loader from "../../components/Loader";
import { ADMIN_GAME_WALLET } from "../../Constant/Constant";
import useAccessTocken from "../../Hooks/useAccessTocken";

interface TransactionHistoryResponse {
  transactions: Array<{
    type: string;
    amount: number;
  }>;
}

const MetaMallWallet = () => {
  const access_token = useAccessTocken();
  const [isRechargeModalOpened, setIsRechargeModalOpened] = useState(false);
  const [isRedeemModalOpened, setIsRedeemModalOpened] = useState(false);
  let x = 1;
  const emptyTransactionHistoryResponse: TransactionHistoryResponse = {
    transactions: [],
  };
  const [transactions, setTransactions] = useState(
    emptyTransactionHistoryResponse
  );

  const AccessToken = useQuery(ReactQueryKey.SOCIAL_MEDIA_LOGIN, {
    enabled: false,
  });

  const Axios = axios.create({
    baseURL: BASE_URL,
    //@ts-ignore
    headers: { Authorization: `Bearer ${access_token}` },
  });

  const [balance, setBalance] = useState(0);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [amountToAdd, setAmountToAdd] = useState<number>(0);
  const [amountToRedeem, setAmountToRedeem] = useState<number>(0);

  let [loading, setLoading] = useState(false);
  const [isRechargeClicked, setIsRechargeClicked] = useState(false);
  const [isRedeemClicked, setIsRedeemClicked] = useState(false);

  const { publicKey, signTransaction, sendTransaction } = useWallet();

  const [phnatomWalletBalance, setPhnatomWalletBalance] = useState<
    string | number
  >("");

  /** Mainnet RPC connection */
  const mainnet_endpoint = NETWORK_URL;
  const connection = new Connection(mainnet_endpoint);

  const onSendSPLTransaction = async (toPubkey: string, amount: number) => {
    console.log(toPubkey);
    console.log(amount);
    // console.log(signTransaction);
    // console.log("publicKey", { publicKey }, props);

    if (!toPubkey || !amount) return;

    const toastId = toast.loading("Processing transaction...");

    try {
      if (!publicKey || !signTransaction) {
        console.log("Wallet not connected");
        throw new WalletNotConnectedError();
      }
      const toPublicKey = new PublicKey(toPubkey);
      const mint = new PublicKey(MALL_TOKEN_ID);
      console.log("MINT", { mint });
      console.log(connection);

      const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
        connection,
        publicKey,
        mint,
        publicKey,
        signTransaction
      );
      console.log("fromToken", { fromTokenAccount });

      const toTokenAccount = await getOrCreateAssociatedTokenAccount(
        connection,
        publicKey,
        mint,
        toPublicKey,
        signTransaction
      );

      console.log("toToken", { toTokenAccount });

      const transaction = new Transaction().add(
        createTransferInstruction(
          fromTokenAccount.address, // source
          toTokenAccount.address, // dest
          publicKey,
          amount * 1000,
          [],
          TOKEN_PROGRAM_ID
        )
      );

      transaction.feePayer = await publicKey;

      const signature = await sendTransaction(transaction, connection);

      console.log("finalResponse", { signature });

      setLoading(true);

      try {
        await connection.confirmTransaction(signature, "processed");
        await connection.confirmTransaction(signature, "max");
        await connection.getParsedTransaction(signature, "confirmed");
      } catch (e) {
        setLoading(false);
        console.log(e);
        setErrorMsg("Blockhash has been expired, please try again!");
        throw e;
      }
      Axios.post(ApiUrl.addFunds, {
        amount: amount,
        transactionHash: signature,
      }).then((res) => {
        console.log(res);
        setLoading(false);
        setErrorMsg("");
        setIsRechargeModalOpened(false);
        setIsRechargeClicked(false);
        getBalance();
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(`Transaction failed: ${error.message}`, {
        id: toastId,
      });
      setLoading(false);
      setErrorMsg("Error: Transaction Failed.");
    }
  };

  const getBalance = async () => {
    const res = await fetchBalance(publicKey?.toString());
    setPhnatomWalletBalance(res);
  };

  useEffect(() => {
    getBalance();
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty("--base", "hidden");
  }, []);

  useEffect(() => {
    Axios.get(ApiUrl.getBalance).then((res) => {
      console.log(res);
      if (res?.data?.balance) {
        setBalance(res.data.balance);
      }
    });
  }, []);
  useEffect(() => {
    Axios.get(ApiUrl.transactionHistory).then((res) => {
      //  console.log(res);
      if (res?.data?.transactions) {
        setTransactions(res.data);
      }
    });
  }, [x]);

  return (
    <div className="experience-wrapper">
      <div className={"mall-container download-exe"}>
        <div className={"mall-balance"}>
          <img
            src={`${process.env.PUBLIC_URL}/images/TokenIcon.png`}
            style={{ width: "4rem", height: "4rem" }}
            alt=""
          />
          <div className="launcher-wrapper">
            <div className="text">{balance.toFixed(3)}</div>
          </div>
        </div>
        <div className="button-wrapper">
          {/* <PrimaryButton name="BUY ON MEXC" onClick={uniSwap} className="primary-btn" /> */}
          <div className={"coinbase-button"}>
            <PrimaryButton
              name="Recharge"
              className="primary-btn download"
              onClick={() => {
                setIsRechargeModalOpened(true);
              }}
            />
            <PrimaryButton
              name="Redeem"
              className="primary-btn download"
              onClick={() => {
                setIsRedeemModalOpened(true);
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={"mall-container download-exe"}
        style={{ flexDirection: "column" }}
      >
        <div style={{ fontSize: "1.5rem" }}>Transaction History</div>
        <br />
        <div>
          {transactions.transactions.map((t) => {
            return (
              <div style={{ display: "flex" }}>
                <div style={{ width: "10rem" }}>{t.amount}</div>
                <div>{t.type}</div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        show={isRechargeModalOpened}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge"
      >
        <div className="header-wrapper">
          <div>
            <img
              className={"landing-logo"}
              src={`${process.env.PUBLIC_URL}/images/lock.png`}
              alt="dashboard logo"
            />
          </div>
          <div className="header-text-wrapper">
            <div className="header-text">Recharge Your Wallet</div>
            <div className="header-sub-text">
              Enter number of mall tokens you wish to add to your Metamall
              wallet
            </div>
          </div>
        </div>
        <div className="header-wrapper">
          <div className="header-text-wrapper" style={{ width: "100%" }}>
            <div className="password-box">
              <InputBox
                label={"Enter Amount"}
                placholder={"Amount"}
                message=""
                type="number"
                value={amountToAdd.toString()}
                onChange={(e) => {
                  try {
                    const n = parseFloat(e.target.value);
                    setAmountToAdd(n);
                  } catch (error) {
                    setAmountToAdd(0);
                  }
                }}
              />
            </div>
            <div
              className="header-sub-text"
              style={{ marginTop: "0.5rem", textAlign: "left" }}
            >
              Available Mall token: {phnatomWalletBalance}
            </div>
            <div style={{ textAlign: "center" }}>{errorMsg}</div>
            <div style={{ height: "3rem" }}>
              {loading && <Loader className={"recharge-loader"} />}
            </div>
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name={"Recharge"}
            disabled={isRechargeClicked}
            onClick={async () => {
              setErrorMsg("");
              if (amountToAdd > phnatomWalletBalance) {
                setErrorMsg(
                  "Error: Entered amount must be less than or equal to your available phantom wallet balance."
                );
                return;
              }
              setIsRechargeClicked(true);
              const resp = await onSendSPLTransaction(
                ADMIN_GAME_WALLET,
                amountToAdd
              );
              x++;
              setIsRechargeClicked(false);
              console.log("transfer done");
              console.log(resp);
            }}
            className="proceed-button"
            style={{ marginRight: "2rem" }}
          />
          <PrimaryButton
            name={"Cancel"}
            disabled={isRechargeClicked}
            onClick={() => {
              setErrorMsg("");
              setIsRechargeModalOpened(false);
              setIsRechargeClicked(false);
            }}
            className="proceed-button"
          />
        </div>
      </Modal>
      <Modal
        show={isRedeemModalOpened}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge"
      >
        <div className="header-wrapper">
          <div>
            <img
              className={"landing-logo"}
              src={`${process.env.PUBLIC_URL}/images/lock.png`}
              alt="dashboard logo"
            />
          </div>
          <div className="header-text-wrapper">
            <div className="header-text"> Redeem amount</div>
            <div className="header-sub-text">
              Enter number of Mall tokens you want to redeem from your Metamall
              wallet
            </div>
          </div>
        </div>
        <div className="header-wrapper">
          <div className="header-text-wrapper">
            <div className="password-box">
              <InputBox
                label={"Quantity"}
                placholder={"Quantity"}
                message=""
                type="number"
                value={amountToRedeem.toString()}
                onChange={(e) => {
                  try {
                    const n = parseFloat(e.target.value);
                    setAmountToRedeem(n);
                  } catch (error) {
                    setAmountToRedeem(0);
                  }
                }}
              />
            </div>
            <div
              className="header-sub-text"
              style={{ marginTop: "0.5rem", textAlign: "left" }}
            >
              Available Metamall Wallet Balance: {balance}
            </div>
            <div style={{ textAlign: "center" }}>{errorMsg}</div>
            <div style={{ height: "3rem" }}>
              {loading && <Loader className={"recharge-loader"} />}
            </div>
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name={"Redeem"}
            disabled={isRedeemClicked}
            onClick={async () => {
              setErrorMsg("");
              if (amountToRedeem > balance) {
                setErrorMsg(
                  "Error: Entered amount must be less than or equal to your available Metamall wallet balance."
                );
                return;
              }
              setIsRedeemClicked(true);
              setLoading(true);
              const res = await Axios.post(ApiUrl.withdrawFunds, {
                amount: amountToRedeem,
              });
              console.log(res);
              setLoading(false);
              setErrorMsg("");
              x++;
              setIsRedeemModalOpened(false);
              setIsRedeemClicked(false);
              console.log("redeem done");
              console.log(res);
            }}
            className="proceed-button"
            style={{ marginRight: "2rem" }}
          />
          <PrimaryButton
            name={"Cancel"}
            disabled={isRedeemClicked}
            onClick={() => {
              setErrorMsg("");
              setIsRedeemModalOpened(false);
              setIsRedeemClicked(false);
            }}
            className="proceed-button"
          />
        </div>
      </Modal>
    </div>
  );
};

export default MetaMallWallet;
