import { FaExclamationTriangle } from "react-icons/fa";

const NotFound = ({ title = "" }) => {
  return (
    <div className="content-center-div">
      <h4 className="mb-0">
        <FaExclamationTriangle className="icon-title" />
      </h4>
      <span className="notfound-text"> Not Found</span>
    </div>
  );
};

export default NotFound;
