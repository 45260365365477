import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { FaAngleLeft } from "react-icons/fa";

const BackButton = () => {
  const navigation = useNavigate();
  return (
    <Button
      variant="primary active shadow-none btn-icon"
      onClick={() => {
        navigation(-1);
      }}
      style={{ marginBottom: "10px", padding: "20px", fontSize: "20px" }}
    >
      <FaAngleLeft />
    </Button>
  );
};

export default BackButton;
