import { useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./MyStore.scss";
import { ApiUrl } from "../../Api/ApiUrl";
import { ApiCall } from "../../Api/ApiCall";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";

const StoreSummary = ({
  storeId,
  storeName,
  imgPath,
  attributes,
  isPublished,
  nftTokenAddress,
}) => {
  const navigation = useNavigate();

  //handle default image
  const defaultImg = "/images/CKPTLogo.png";
  const [imgSrc, setImgSrc] = useState(
    imgPath === null || imgPath === undefined ? defaultImg : imgPath
  );

  const handleError = () => setImgSrc(defaultImg);

  // udpate toggle ispublished
  const [publish, setPublish] = useState(isPublished);

  const [isShowPromotModel, setIsShowPromotModel] = useState(false);

  // update publish status
  const onChkPublished = async () => {
    await ApiCall(
      "POST",
      `${ApiUrl.updateStorePublished}/${nftTokenAddress}/${!publish}`
    ).then(() => {
      setPublish(!publish);
    });
  };

  return (
    <>
      <Card className="" key={storeId}>
        <Card.Body>
          <div className="row">
            <div className="col-xs-4 col-sm-4 col-lg-3">
              <div className="imgBlock d-flex justify-content-center">
                <img
                  src={imgSrc}
                  onError={handleError}
                  alt="store image"
                  className="img img-reponsive link-primary"
                  key={Date.now()}
                  onClick={() => {
                    navigation(`/mplace/store/${storeId}`);
                  }}
                />
              </div>
            </div>
            <div className="col-xs-8 col-sm-8 col-lg-9">
              <Card.Title>
                <span
                  className="link-primary"
                  onClick={() => {
                    navigation(`/mplace/store/${storeId}`);
                  }}
                >
                  {storeName}
                </span>
              </Card.Title>
              {attributes.map((attr) => {
                return <span className="badge">{attr}</span>;
              })}
              <br></br>
              {publish && <span className="Publishbadge ">PUBLISHED</span>}
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <div className="row">
            <div className="col-sm-8 col-lg-12 col-xl-8">
              <Button
                variant="primary active shadow-none"
                onClick={async () => {
                  navigation(`/cockpit/mystores/mystoresudpate/${storeId}`);
                }}
              >
                Settings
              </Button>{" "}
              <Button
                variant="primary shadow-none"
                onClick={() => {
                  setIsShowPromotModel(true);
                }}
              >
                Promote
              </Button>{" "}
            </div>
            <div className="col-sm-4 col-lg-12 col-xl-4">
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Published"
                  checked={publish}
                  onChange={onChkPublished}
                />
              </Form>
            </div>
          </div>
        </Card.Footer>
      </Card>

      {/* -----  MODAL TO Promote future functionality ----- */}
      <Modal
        show={isShowPromotModel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="alert-model-recharge"
      >
        <div className="header-wrapper">
          <div
            className="header-text-wrapper"
            style={{ width: "100%", marginLeft: 0 }}
          >
            <div style={{ textAlign: "center" }}>
              This feature will be coming soon
            </div>
          </div>
        </div>
        <div
          className="alert-message-wrapper"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <PrimaryButton
            name={"Cancel"}
            onClick={() => {
              setIsShowPromotModel(false);
            }}
            className="proceed-button"
          />
        </div>
      </Modal>
    </>
  );
};

export default StoreSummary;
