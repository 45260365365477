import React from "react";
import { isEmpty } from "lodash";
import Table from "react-bootstrap/Table";

import { useTradeHistory } from "../hooks/useAuctionData";

const TradeHistory = ({ nftAddress }: { nftAddress: string }) => {
  const { data: tradeHistory = {} } = useTradeHistory(nftAddress);

  const getTradeHistoryRows = () => {
    return tradeHistory.map(([a, b, c]) => (
      <tr key={`${a}${b}${c}`}>
        <td>{a}</td>
        <td>{b}</td>
        <td>{c}</td>
      </tr>
    ));
  };

  return (
    <>
      {!isEmpty(tradeHistory) && (
        <div className="marketplace-listing-detail-card">
          <div
            className="marketplace-listing-detail-content"
            style={{ width: "100%" }}
          >
            <p className="marketplace-listing-trade-history-title">
              Trade History
            </p>
            <div className="marketplace-listing-trade-history">
              <Table>
                <thead>
                  <tr>
                    <th>Signature</th>
                    <th>Buyer</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>{getTradeHistoryRows()}</tbody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TradeHistory;
